export type Props = {
  color?: string;
};

export const IconCancelTaxi: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.092 20.185v1.428a.239.239 0 0 1-.24.239h-2.3a.239.239 0 0 1-.238-.239v-1.428h2.778Zm12.777 0v1.428a.239.239 0 0 1-.239.239h-2.3a.239.239 0 0 1-.238-.239v-1.428h2.777ZM12.8 9.63a6.089 6.089 0 0 0 .541 2.791l-3.975-.013a.112.112 0 0 0-.078.031l-.018.023-1.407 2.362a.239.239 0 0 0 .16.357l.045.004 7.786.001a6.128 6.128 0 0 0 4.532.635l-.516 1.03v3.334H4.314v-3.332l-.938-1.877a.24.24 0 0 1 .17-.343l.043-.003h1.722l2.261-3.773a.24.24 0 0 1 .156-.111l.05-.005h2.09l.386-.96a.239.239 0 0 1 .222-.151H12.8Zm-3.724 7.222h-2.3c-.119 0-.217.13-.236.3l-.003.058v.95c0 .178.087.326.2.354l.039.005h2.3c.119 0 .217-.13.236-.3l.003-.059v-.95c0-.197-.107-.358-.24-.358Zm8.333 0h-2.3c-.118 0-.217.13-.236.3l-.003.058v.95c0 .178.087.326.2.354l.04.005h2.3c.118 0 .216-.13.235-.3l.003-.059v-.95c0-.197-.107-.358-.239-.358Zm-11.984-.557v.002h13.334v-.001l-13.334-.001ZM18.906 5.446a4.444 4.444 0 1 1 0 8.89 4.444 4.444 0 0 1 0-8.89Zm2.261 2.116a.644.644 0 0 0-.91-.008l-.008.008-1.38 1.402-1.38-1.402-.008-.008a.643.643 0 0 0-.91.008.656.656 0 0 0 0 .92l1.387 1.409-1.386 1.409a.656.656 0 0 0 0 .92c.25.253.657.257.91.007l.008-.007 1.38-1.403 1.38 1.403.007.007c.254.25.661.246.91-.007a.656.656 0 0 0 0-.92l-1.386-1.409 1.386-1.41a.656.656 0 0 0 0-.92Z"
        fill={color}
      />
    </svg>
  );
};
