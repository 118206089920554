import { FormHelperTextProps, FormHelperText as MuiFormHelperText, styled } from "@mui/material";

export type Props = FormHelperTextProps;

export const FormHelperText: React.FC<Props> = (props) => {
  return <StyledFormHelperText {...props} />;
};

const StyledFormHelperText = styled(MuiFormHelperText)({
  fontSize: "1rem",
  lineHeight: "1.4",
});
