import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Container, LayoutGeneralError } from "@/components";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";
import getPortalType from "@/utils/getPortalType";

export const Error404 = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const defaultState = "E404-01";
  const errorCode = location.state?.errorCode || defaultState;

  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata(errorCode);
  const portalType = getPortalType();

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  const buttons = useMemo(
    () =>
      portalType === "crew"
        ? [
            {
              title: t("ACCOUNT_REGISTRATION_HERE"),
              label: t("ACCOUNT_REGISTRATION"),
              to: "/crew_portal/register",
            },
            {
              title: t("LOGIN_HERE_CREW"),
              label: t("LOGIN"),
              to: "/crew_portal/login",
            },
          ]
        : portalType === "driver"
          ? [
              {
                title: t("LOGIN_HERE_DRIVER"),
                label: t("LOGIN"),
                to: "/driver_portal/login",
              },
            ]
          : [],
    [t]
  );
  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError title={t(title)} message={t(message)} footerButtons={buttons} />
    </Container>
  );
};
