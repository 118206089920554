import { Link } from "react-router-dom";

import { ButtonOutlined, TargetBlank } from "@/components";
import { ButtonLinkProps } from "@/components/ButtonLink";

export type Props = ButtonLinkProps;

export const ButtonOutlinedLink: React.FC<Props> = ({ children, ...props }) => {
  return (
    <ButtonOutlined
      component={Link}
      afterIcon={props.target === "_blank" && <TargetBlank />}
      disableOnClick={false}
      {...props}
    >
      {children}
    </ButtonOutlined>
  );
};
