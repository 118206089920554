import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  ButtonOutlined,
  ButtonOutlinedLink,
  Card,
  Container,
  LayoutButtonWithTypography,
  LayoutVertical,
  Typography,
} from "@/components";
import { navigateToAppStore } from "@/utils/errorHandling";

import { LoginForm } from "./components/Forms";

export const Login = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
  }, []);

  return (
    <Container>
      <Helmet title={t("LOGIN")} />
      <LayoutVertical>
        <Card>
          <Typography variant="h2" gutterBottom>
            {t("LOGIN")}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "24px" }}>
            {t("LOGIN_AS_DRIVER_NOTICE")}
          </Typography>
          <LoginForm />
        </Card>

        <LayoutButtonWithTypography>
          <Typography>{t("FORGOT_PASSWORD")}</Typography>
          {isMobile ? (
            <ButtonOutlined
              onClick={() => {
                navigateToAppStore();
              }}
            >
              {t("RESET_PASSWORD")}
            </ButtonOutlined>
          ) : (
            <ButtonOutlinedLink to="/driver_portal/mobile_device_warning">{t("RESET_PASSWORD")}</ButtonOutlinedLink>
          )}
        </LayoutButtonWithTypography>

        <LayoutButtonWithTypography>
          <Typography>{t("WHO_CHANGED_EMAIL")}</Typography>
          {isMobile ? (
            <ButtonOutlined
              onClick={() => {
                navigateToAppStore();
              }}
            >
              {t("DO_CHANGE_EMAIL")}
            </ButtonOutlined>
          ) : (
            <ButtonOutlinedLink to="/driver_portal/mobile_device_warning">{t("DO_CHANGE_EMAIL")}</ButtonOutlinedLink>
          )}
        </LayoutButtonWithTypography>
      </LayoutVertical>
    </Container>
  );
};
