import React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ListPoint, ListPointItem, Loader, Typography } from "@/components";
import { PointType } from "@/components/ListPointItem/ListPointItem";
import { FetchContext as context } from "@/contexts/FetchContext";
import { PointHistory, getTransferedParams } from "@/types/pointHistory";

const pointTypeToMessage = (pointType: PointType) => {
  switch (pointType) {
    case "UNSPECIFIED":
      return "POINT_EXCHANGE";
    case "USER_TIP":
      return "TIP";
    case "USER_CANCEL_PAYMENT":
      return "CANCEL";
    default:
      return "";
  }
};

export interface CardsPointHistoryProps {
  pointHistories: PointHistory[];
  top?: boolean;
}

export const CardsPointHistory: React.FC<CardsPointHistoryProps> = ({ pointHistories, top }) => {
  const { t } = useTranslation();
  const { state } = useContext(context);
  const [captions, setCaptions] = useState<string[]>([]);

  const splitString = (str: string) => {
    // ex) note:"Expired,配車承諾によるポイント付与" => 配車承諾によるポイント付与
    const label = str.includes(",") ? str.split(",")[1] : str;
    // 旧データ用処理
    const result = label === "Expired" ? t("CAMPAIGN") : label;
    return result;
  };

  const getLabel = (pointHistory: PointHistory) => {
    let label = "";
    if (pointHistory.category === "UNSPECIFIED") {
      if (pointHistory.note === "ErrorFee") {
        label = "ERROR_FEE";
      } else if (pointHistory.note === "Exchanged") {
        label = "POINT_EXCHANGE";
      } else if (pointHistory.note === "Transfered") {
        label = "POINT_COMBINED_APPLICATION_EXECUTED";
      } else if (pointHistory.note.startsWith("Reverted")) {
        label = "POINT_EXCHANGE";
      } else if (pointHistory.note.startsWith("TransferReverted")) {
        label = "POINT_COMBINED_APPLICATION_REVERTED";
      }
    }
    return label;
  };

  const getType = (pointHistory: PointHistory) => {
    let type = "";
    if (pointHistory.category === "UNSPECIFIED") {
      if (["ErrorFee", "Exchanged", "Transfered"].includes(pointHistory.note)) {
        type = `${pointHistory.category}_${pointHistory.note}${
          pointHistory.note === "Transfered" ? (pointHistory.addPoint > 0 ? "_Positive" : "_Negative") : ""
        }`;
      } else if (pointHistory.note.startsWith("TransferReverted")) {
        type = `${pointHistory.category}_TransferReverted${pointHistory.addPoint > 0 ? "_Positive" : "_Negative"}`;
      } else {
        type = pointHistory.category;
      }
    }
    return type as PointType;
  };

  useEffect(() => {
    if (pointHistories.length > 0 && state.data?.user?.driverId) {
      const captions = pointHistories.map((pointHistory: PointHistory) => {
        const params = getTransferedParams(pointHistory, state.data.user.driverId);
        if (!params?.driverCode) {
          return "";
        }
        return t(params.caption).replace("%s", params.driverCode);
      });
      setCaptions(captions);
    }
  }, [pointHistories, state.data?.user?.driverId, t]);

  return (
    <>
      {state.loading ? (
        <Loader />
      ) : (
        <>
          {pointHistories.map((pointHistory: PointHistory, index: any) => {
            const currentMonth = new Date(pointHistory.updatedAt).getMonth();
            const previousMonth = index > 0 ? new Date(pointHistories[index - 1].updatedAt).getMonth() : -1;

            return (
              <React.Fragment key={index}>
                {!top && (index === 0 || currentMonth !== previousMonth) && (
                  <Typography variant="h2" textAlign="center" gutterBottom>
                    {new Date(pointHistory.updatedAt).toLocaleString("ja-JP", {
                      year: "numeric",
                      month: "long",
                    })}
                  </Typography>
                )}
                <ListPoint variant={!top ? "card" : undefined}>
                  {pointHistory.category === "UNSPECIFIED" ? (
                    <ListPointItem
                      label={t(getLabel(pointHistory))}
                      type={getType(pointHistory)}
                      returnDate={
                        !["ErrorFee", "Exchanged"].includes(pointHistory.note) &&
                        !pointHistory.note.startsWith("Transfer")
                          ? pointHistory.updatedAt
                          : undefined
                      }
                      errorDate={pointHistory.note === "ErrorFee" ? pointHistory.updatedAt : undefined}
                      applicationDate={pointHistory.note === "Exchanged" ? pointHistory.updatedAt : undefined}
                      moveDate={
                        pointHistory.note.startsWith("Transfer") && pointHistory.addPoint < 0
                          ? pointHistory.updatedAt
                          : undefined
                      }
                      acquisitionDate={
                        pointHistory.note.startsWith("Transfer") && pointHistory.addPoint > 0
                          ? pointHistory.updatedAt
                          : undefined
                      }
                      point={pointHistory.addPoint}
                      caption={captions[index]}
                    />
                  ) : (
                    <ListPointItem
                      label={`${
                        t(pointTypeToMessage(pointHistory.category as PointType)) ||
                        "" ||
                        splitString(pointHistory.note)
                      }`}
                      type={pointHistory.category as PointType}
                      acquisitionDate={pointHistory.updatedAt}
                      expireDate={pointHistory.expiredAt}
                      point={pointHistory.addPoint}
                    />
                  )}
                </ListPoint>
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
