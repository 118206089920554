import { useTranslation } from "react-i18next";

import { FormHelperText, InputLabel, MenuItem as MuiMenuItem, Select as MuiSelect, SelectProps } from "@mui/material";
import { styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

export type Props = SelectProps & {
  label: string;
  items: string[];
  helperText?: string;
};

export const Select: React.FC<Props> = ({ label, items, helperText, ...props }) => {
  const { t } = useTranslation();
  return (
    <>
      <InputLabel
        style={{
          color: COLORS.TEXT_PLACEHOLDER,
          fontSize: "1rem",
          lineHeight: 1.4,
        }}
        id={"select-label-" + label}
      >
        {label}
      </InputLabel>
      <StyledSelect variant="standard" {...props}>
        {items.map((item, index) => (
          <StyledMenuItem key={index} value={item}>
            {t(item)}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      <FormHelperText>{helperText && t(helperText)}</FormHelperText>
    </>
  );
};

const StyledMenuItem = styled(MuiMenuItem)({
  "&:hover": {
    backgroundColor: COLORS.BG_02,
  },
  "&.Mui-selected": {
    backgroundColor: COLORS.BG_03,
  },
});

const StyledSelect = styled(MuiSelect, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "fullWidth",
})(({ fullWidth }) => ({
  width: fullWidth ? "100%" : undefined,
  "& .MuiInputBase-input": {
    fontSize: 18,
    padding: "10px 26px 10px 0px",
  },
  "&:hover": {
    backgroundColor: COLORS.BG_03,
  },
  "&.Mui-selected": {
    backgroundColor: COLORS.BG_03,
  },
  "&:focus": {
    backgroundColor: COLORS.BG_03,
  },
  "& .MuiSelect-icon": {
    color: COLORS.TEXT_01,
    position: "relative",
    top: "-12px",
    width: "24px",
    height: "24px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.PRIMARY,
  },
}));
