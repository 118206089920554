import { Card, Steps } from "../";

export type Props = React.ComponentProps<typeof Card> & {
  children?: React.ReactNode;
  activestep: number;
  steplength: number;
};

export const StepCard: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Card {...props}>
      <Steps active={props.activestep + 1} length={props.steplength} gutterBottom={true} />
      {children}
    </Card>
  );
};
