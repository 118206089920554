import { Typography as MuiTypography, TypographyProps, styled } from "@mui/material";

export type Props = TypographyProps;

export const Typography: React.FC<Props> = ({ children, ...props }) => {
  return <StyledTypography {...props}>{children}</StyledTypography>;
};

const StyledTypography = styled(MuiTypography, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "gutterBottom",
})(({ gutterBottom }) => ({
  marginBottom: !gutterBottom ? 0 : undefined,
  wordWrap: "break-word",
  overflowWrap: "break-word",
}));
