import { AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";
import { format, parseISO } from "date-fns";
import { enUS, ja } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Container, LayoutGeneralError } from "@/components";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";
import { Maintenance } from "@/types/maintenance";
import { axiosInstance } from "@/utils/axios";
import getPortalType from "@/utils/getPortalType";

export const Error503 = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [dispMessage, setDispMessage] = useState("");
  const stateErrorCode = location.state?.errorCode;
  const errorCode = stateErrorCode ? stateErrorCode.split("/")[0] : "E503-01";
  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata(errorCode);

  useEffect(() => {
    const makeDispPeriod = (startAt: string, endAt: string) => {
      const sDate = format(parseISO(startAt), "M/d(E)", {
        locale: ja,
      });
      const sTime = format(parseISO(startAt), "ah:mm", {
        locale: enUS,
      });
      const eDate = format(parseISO(endAt), "M/d(E)", {
        locale: ja,
      });
      const eTime = format(parseISO(endAt), "ah:mm", {
        locale: enUS,
      });

      return sDate === eDate
        ? `\n\n【${t("MAINTENANCE_PERIOD")}】
      ${sDate}${sTime}〜${eTime}${t("MAINTENANCE_PERIOD_SUFFIX")}`
        : `\n\n【${t("MAINTENANCE_PERIOD")}】
      ${sDate}${sTime}〜\n${eDate}${eTime}${t("MAINTENANCE_PERIOD_SUFFIX")}`;
    };

    const portalType = getPortalType();
    const portalTypeName = t(`DEFAULT_${portalType.toUpperCase()}_TITLE`);
    setDispMessage(t(message).replace("%s", portalTypeName));

    // FE側メンテナンスモード (例: portlandDBメンテナンス時)
    if (location.state?.startAt && location.state?.endAt) {
      const dispPeriod = makeDispPeriod(location.state.startAt, location.state.endAt);
      setDispMessage((m) => m + dispPeriod);
      return;
    }

    // BE側メンテナンスモード (portlandDB.maintenances参照)
    axiosInstance
      .get("/api/maintenance")
      .then((res: AxiosResponse<Maintenance>) => {
        res.data = camelcaseKeys(res.data, { deep: true });
        const period = (() => {
          if (window.location.href.includes("/points/error/503")) {
            return res.data.pointTransfer;
          } else if (window.location.href.includes("/driver_portal/503")) {
            return res.data.driverPortal;
          } else if (window.location.href.includes("/crew_portal/503")) {
            return res.data.crewPortal;
          }
        })();
        if (period) {
          const dispPeriod = makeDispPeriod(period.startAt, period.endAt);
          setDispMessage((m) => m + dispPeriod);
        }
      })
      .catch(() => {
        navigate(`/${portalType}_portal/500`);
      });
  }, [location.state, message, navigate, t]);

  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError title={t(title)} message={dispMessage} />
    </Container>
  );
};
