import { To } from "history";

import { styled } from "@mui/material";

import { ChipNotificationStatus, ChipNotificationStatusProps, ListLinkItem } from "@/components";
import { Notification } from "@/types/notification";

export type Props = {
  status: ChipNotificationStatusProps["status"];
  date: string;
  label: string;
  to: To;
  notification: Notification;
};

export const ListNotificationItem: React.FC<Props> = ({ status, date, label, to, notification }) => (
  <ListLinkItem to={to} header={<Header status={status} date={date} />} state={{ notification }}>
    <Wrap>{label}</Wrap>
  </ListLinkItem>
);

type HeaderProps = {
  status: Props["status"];
  date: string;
};
const Header: React.FC<HeaderProps> = ({ status, date }) => {
  return (
    <>
      {status === "important" && <ChipNotificationStatus status={status} />}
      <span>{date}</span>
    </>
  );
};

const Wrap = styled("span")({
  display: "flex",
  justifyContent: "space-between",
});
