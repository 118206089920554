import {
  ButtonContained,
  Checkbox,
  FormBox,
  FormControlLabel,
  LayoutButton,
  LayoutVertical,
  Loader,
  TextField,
  TextFieldEmail,
} from "@/components";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { emailValidator, passwordValidatorForLogin, validationSchema } from "@/utils/validators";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { logInByEmail } = useAuth();
  const { t } = useTranslation();
  type FormData = {
    email: string;
    password: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        email: emailValidator,
        password: passwordValidatorForLogin,
      })
    ),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      await logInByEmail(data.email.trim(), data.password);
      navigate("/driver_portal");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorCode = error.response?.data.error_code;
        if (["E400-02"].includes(errorCode)) {
          // E400-02(入力値エラー)の場合は自画面エラー
          setError("email", { type: "server", message: "" });
          setError("password", {
            type: "server",
            message: t(`ERROR_CODE_${errorCode}/api/driver/login_MESSAGE`),
          }); // パスワード項目にてエラー文言表示
        } else {
          navigateError({
            error: error,
            endpoint: "/api/driver/login",
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="loginForm">
        <LayoutVertical>
          <Controller
            name="email"
            control={control}
            rules={{ required: t("EMAIL_REQUIRED") }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormBox>
                <TextFieldEmail
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  value={value}
                  type="email"
                  label={t("EMAIL")}
                  fullWidth
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              </FormBox>
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: t("PASSWORD_REQUIRED") }}
            render={({ field: { onChange, onBlur, value } }) => (
              <FormBox>
                <TextField
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  value={value}
                  type={showPassword ? "text" : "password"}
                  label={t("PASSWORD")}
                  fullWidth
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  inputProps={{ maxLength: 64 }}
                />
              </FormBox>
            )}
          />
          <FormControlLabel
            control={<Checkbox checked={showPassword} onChange={handleShowPassword} name="show_password" />}
            label={t("SHOW_PASSWORD")}
          />
          {isLoading ? (
            <Loader />
          ) : (
            <LayoutButton>
              <ButtonContained size="large" type="submit" disabled={!isValid}>
                {t("LOGIN")}
              </ButtonContained>
            </LayoutButton>
          )}
        </LayoutVertical>
      </form>
    </>
  );
};
