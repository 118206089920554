import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";

import { ButtonContainedLink, Card, Container, LayoutButton, LayoutVertical, Steps, Typography } from "@/components";
import getPortalType from "@/utils/getPortalType";

export const GmoBankAccountRegistrationResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const portalType = getPortalType();

  const params = new URLSearchParams(location.search);
  const bankId = params.get("BankId");
  const result = params.get("Result");
  const isParamsValid = bankId && result;

  const succeeded = result === "2";

  const title = succeeded ? t("BANK_ACCOUNT_REGISTRATION_COMPLETED") : t("BANK_ACCOUNT_REGISTRATION_FAILED");
  const message = succeeded
    ? t("BANK_ACCOUNT_REGISTRATION_COMPLETED_MESSAGE")
    : t("BANK_ACCOUNT_REGISTRATION_FAILED_MESSAGE");

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    if (!isParamsValid) {
      navigate(((p): string => (p ? `/${p}_portal/points/transfer` : "/"))(portalType));
    }
  }, [isParamsValid, navigate]);

  return (
    <Container>
      <Helmet title={title} />

      <Steps active={4} length={4} gutterBottom />

      <LayoutVertical>
        <Typography variant="h1">{title}</Typography>

        <Card>
          {!succeeded && (
            <Typography variant="h2" gutterBottom>
              {t("BANK_ACCOUNT_REGISTRATION_TITLE")}
            </Typography>
          )}

          <LayoutVertical>
            <Typography>{message}</Typography>

            <LayoutButton>
              <ButtonContainedLink
                to={((p): string => (p ? `/${p}_portal/points/transfer` : "/"))(portalType)}
                size="large"
              >
                {t("TO_POINT_EXCHANGE_APPLICATION")}
              </ButtonContainedLink>
            </LayoutButton>
          </LayoutVertical>
        </Card>
      </LayoutVertical>
    </Container>
  );
};
