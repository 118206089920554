import * as Sentry from "@sentry/react";
import "chart.js/auto";

import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { ENVIRONMENT, SENTRY_DSN } from "./constants";
import { isLocal, isProd } from "./utils/env";

//import "./mocks";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: isProd() ? 0.1 : 1.0,
  environment: ENVIRONMENT,
  enabled: !isLocal(),
  beforeSend(event) {
    if (event.exception) {
      // 現在のURLが"/crew_portal/error","/driver_portal/error"でない場合のみリダイレクト
      const pathname = window.location.pathname;
      if (pathname.includes("crew") && !pathname.startsWith("/crew_portal/error")) {
        window.location.href = "/crew_portal/error";
      } else if (pathname.includes("driver") && !pathname.startsWith("/driver_portal/error")) {
        window.location.href = "/driver_portal/error";
      }
    }
    return event;
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
