export type PointTransferMethod = {
  receiveMethod: string;
  bankAccountInfo: {
    bankName: string;
    branchName: string;
    accountName: string;
    maskedAccountNumber: string;
    failure: boolean;
  } | null;
  limitAmount: number;
  currentAmount: number;
  transferFee: number;
};

export const pointTransferMethodDefaultValues: PointTransferMethod = {
  receiveMethod: "BANK_ACCOUNT",
  bankAccountInfo: {
    bankName: "",
    branchName: "",
    accountName: "",
    maskedAccountNumber: "",
    failure: false,
  },
  limitAmount: 0,
  currentAmount: 0,
  transferFee: 0,
};
