import React from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material";

import { Typography } from "@/components";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";

export type Props = {
  component: React.ElementType;
};

export const LogoHorizontal: React.FC<Props> = ({ component }) => {
  const { t } = useTranslation();

  return (
    <Logo component={component}>
      <LogoImage src="/static/img/brands/go.png" alt="GO" />
      <LogoText>{((p): string => (p ? t(`${p}_PORTAL`) : ""))(getPortalType().toUpperCase())}</LogoText>
    </Logo>
  );
};

const Logo = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 700,
  lineHeight: 1.5,
  color: COLORS.TEXT_LOGO,
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0, 2),
  minHeight: "56px",
}));

const LogoImage = styled("img")({
  font: "inherit",
  width: "40px",
  height: "auto",
});

const LogoText = styled("span")({
  font: "inherit",
});
