import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Box, Container, LayoutVertical, StepCard, Typography } from "@/components";

import { PhoneNumberChangeAuthSmsForm } from "./components/PhoneNumberChange/Forms";

export const PhoneNumberChangeAuthSms = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Helmet title={t("CHANGE_PHONE_NUMBER")} />
      <LayoutVertical>
        <Typography variant="h1">{t("CHANGE_PHONE_NUMBER")}</Typography>
        <StepCard activestep={2} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_INPUT_NEW_PHONE_NUMBER")}
          </Typography>
          <Typography component="h3" variant="body1">
            {t("SEND_CONFIRM_SMS")}
          </Typography>
          <Box textAlign="center">
            <PhoneNumberChangeAuthSmsForm />
          </Box>
        </StepCard>
      </LayoutVertical>
    </Container>
  );
};
