import React from "react";

import { styled } from "@mui/material";

import { ButtonLogout, Skeleton, Typography } from "@/components";

export type Props = {
  name?: string;
};
export const HeaderUtility: React.FC<Props> = ({ name }) => {
  return (
    <Wrap>
      <HeaderAccount>
        {!name ? <Skeleton variant="text" width="7rem" /> : <Typography component="span">{name}</Typography>}
      </HeaderAccount>
      <HeaderLogout />
    </Wrap>
  );
};

const Wrap = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "flex-start",
  gap: theme.spacing(0, 2),
  position: "absolute",
  top: theme.spacing(4),
  right: theme.spacing(4),
}));

const HeaderAccount = styled("p")({
  margin: 0,

  span: {
    fontSize: "1rem",
    lineHeight: 1.5,
    verticalAlign: "top",
  },
});

const HeaderLogout = styled(ButtonLogout)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: 1.1,
  marginTop: "1px",
  padding: theme.spacing(1, 2),
}));
