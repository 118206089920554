import { styled } from "@mui/material";

import { Typography } from "@/components";
import { COLORS } from "@/utils/colors";

export type Props = {
  color?: "weak" | "alert";
  children?: React.ReactNode;
};

export const TypographyNote: React.FC<Props> = (props) => {
  return <Container variant="caption" component="p" {...props} />;
};

const Container = styled(Typography, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "alert",
})<{ color?: Props["color"] }>(({ color }) => ({
  lineHeight: 1.4,
  color: color === "alert" ? COLORS.ALERT : color === "weak" ? COLORS.TEXT_WEAK : undefined,
}));
