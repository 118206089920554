import { MenuProps, Menu as MuiMenu, styled } from "@mui/material";

type Props = MenuProps;

export const Menu: React.FC<Props> = (props) => {
  return (
    <StyledMenu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  );
};

export const StyledMenu = styled(MuiMenu)({
  ".MuiMenu-paper": {
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
  },
  "& .MuiMenu-list": {
    padding: "0",
  },
});
