import {
  Alert,
  AlertInfo,
  Box,
  ButtonContained,
  ButtonTextLink,
  Checkbox,
  FormBox,
  FormControlLabel,
  InfoMessage,
  ModalBasic,
  TextField,
  TextFieldEmail,
  TextFieldPhoneNumber,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { COLORS } from "@/utils/colors";
import {
  codeLength,
  confirmCodeValidator,
  emailValidator,
  passwordConfirmValidator,
  passwordValidator,
  phoneNumberValidatorDelimited,
  pinCodeValidator,
  validationSchema,
} from "@/utils/validators";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export const RegisterForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { signUp } = useAuth();
  const { t } = useTranslation();
  const [submitError] = useState("");
  type FormData = {
    phoneNumber: string;
    pinCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        phoneNumber: phoneNumberValidatorDelimited,
        pinCode: pinCodeValidator,
      })
    ),
    defaultValues: {
      phoneNumber: "",
      pinCode: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await signUp(data.phoneNumber.replaceAll(" ", ""), data.pinCode); // NOTE: TextFieldPhoneNumber使用するためtrim()ではなくreplaceAll()でスペース除去
      navigate("/crew_portal/register/auth_mail");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/register/auth_code",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="registerForm">
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="phoneNumber"
          control={control}
          rules={{ required: t("PHONE_NUMBER_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PHONE_NUMBER_NOTE")}>
              <TextFieldPhoneNumber
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type="tel"
                label={t("PHONE_NUMBER")}
                fullWidth
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
                style={{ marginTop: "24px" }}
              />
            </FormBox>
          )}
        />
        <Controller
          name="pinCode"
          control={control}
          rules={{ required: t("PIN_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PIN_CODE_NOTE")}>
              <TextField
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type="tel"
                label={t("PIN_CODE")}
                fullWidth
                error={Boolean(errors.pinCode)}
                helperText={errors.pinCode?.message}
                inputProps={{ maxLength: 4 }}
                style={{ marginTop: "24px" }}
              />
            </FormBox>
          )}
        />
        <Box textAlign="center" style={{ marginTop: "24px" }}>
          <ButtonContained type="submit" color="primary" size="large" disabled={!isDirty || isSubmitting}>
            {t("AUTHENTICATE")}
          </ButtonContained>
        </Box>
      </form>
    </>
  );
};

export const AuthMailForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyEmail } = useAuth();
  const { t } = useTranslation();
  const [submitError] = useState("");
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  type FormData = {
    email: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        email: emailValidator,
      })
    ),
    defaultValues: {
      email: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyEmail(data.email);
      setEmail(data.email);
      setIsModalOpen(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/register/auth_mail",
          state: { newEmail: email },
        });
      }
    }
  };

  const handleClose = async () => {
    navigate("/crew_portal/register/auth_mail_code", {
      state: { newEmail: email },
    });
  };

  return (
    <>
      <ModalBasic
        open={isModalOpen}
        onClose={() => {
          // biome-ignore suppressions/unused: need to keep empty block
        }}
        title={t("EMAIL_SENT_FOR_CONFIRMATION")}
        actionButton={{
          label: t("NEXT"),
          onClick: handleClose,
        }}
      >
        <>
          <span style={{ color: COLORS.WARNING, fontWeight: "bold" }}>{t("PLEASE_CHECK_YOUR_EMAIL")}</span>
          <InfoMessage variant="primary" sx={{ marginTop: "24px" }}>
            {t("IF_YOU_DO_NOT_RECEIVE_CONFIRMATION_EMAIL")}
          </InfoMessage>
          <AlertInfo style={{ marginBottom: "20px" }}>
            <Typography>{t("CHECK_BELOW_FAQ")}</Typography>
          </AlertInfo>
          <ButtonTextLink
            to={SUPPORT_URLS.crew.WHAT_IS_CONFIRMATION_EMAIL_NOT_ARRIVED}
            target="_blank"
            sx={{ textAlign: "left" }}
            isTargetBlankFixed
          >
            {t("WHAT_IS_CONFIRMATION_EMAIL_NOT_ARRIVED")}
          </ButtonTextLink>
        </>
      </ModalBasic>
      <form onSubmit={handleSubmit(onSubmit)} name="authMailForm" noValidate>
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="email"
          control={control}
          rules={{ required: t("EMAIL_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldEmail
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
              value={value}
              type="email"
              label={t("EMAIL")}
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              style={{ marginTop: "24px" }}
            />
          )}
        />
        <Box textAlign="center" style={{ marginTop: "24px" }}>
          <ButtonContained type="submit" color="primary" size="large" disabled={!isDirty || isSubmitting}>
            {t("SEND")}
          </ButtonContained>
        </Box>
      </form>
    </>
  );
};

export const AuthMailCodeForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifySigningUp } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const newEmail = location.state?.newEmail || "";

  const [submitError] = useState("");
  type FormData = {
    confirmCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        confirmCode: confirmCodeValidator,
      })
    ),
    defaultValues: {
      confirmCode: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifySigningUp(newEmail, data.confirmCode);
      navigate("/crew_portal/register/password");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/register/auth_mail_code",
          state: { newEmail: newEmail },
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="authMailCodeForm">
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="confirmCode"
          control={control}
          rules={{ required: t("CONFIRM_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
                if (e.target.value.length === codeLength) {
                  handleSubmit(onSubmit)();
                }
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("CONFIRM_CODE")}
              fullWidth
              error={Boolean(errors.confirmCode)}
              helperText={errors.confirmCode?.message}
              inputProps={{ maxLength: 4 }}
              style={{ marginTop: "24px" }}
            />
          )}
        />
      </form>
    </>
  );
};

export const PasswordForm = () => {
  const [showPassword, setShowPassword] = useState(true);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const { registerPassword } = useAuth();
  const { t } = useTranslation();
  const { navigateError } = useNavigationError();
  type FormData = {
    password: string;
    passwordConfirmation: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        password: passwordValidator,
        passwordConfirmation: passwordConfirmValidator,
      })
    ),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await registerPassword(data.password);
      navigate("/crew_portal/top", {
        state: { status: "REGISTRATION_SUCCESS" },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/register/password",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="passwordForm">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: t("PASSWORD_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PASSWORD_NOTE")}>
              <TextField
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type={showPassword ? "text" : "password"}
                label={t("NEW_PASSWORD")}
                fullWidth
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                style={{ marginTop: "24px" }}
                inputProps={{ maxLength: 64, inputMode: "url" }} // NOTE: パスワード設定時のみurlキーボードを利用する(Crew向け)
              />
            </FormBox>
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          defaultValue=""
          rules={{ required: t("PASSWORD_CONFIRM_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PASSWORD_CONFIRM_NOTE")}>
              <TextField
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type={showPassword ? "text" : "password"}
                label={t("NEW_PASSWORD_CONFIRM")}
                fullWidth
                error={Boolean(errors.passwordConfirmation)}
                helperText={errors.passwordConfirmation?.message}
                style={{ marginTop: "24px" }}
                inputProps={{ maxLength: 64, inputMode: "url" }} // NOTE: パスワード設定時のみurlキーボードを利用する(Crew向け)
              />
            </FormBox>
          )}
        />
        <FormControlLabel
          control={<Checkbox checked={showPassword} onChange={handleShowPassword} name="show_password" />}
          label={t("SHOW_PASSWORD")}
          style={{ marginTop: "24px" }}
        />
        <Box textAlign="center">
          <ButtonContained
            type="submit"
            color="primary"
            disabled={!isValid || isSubmitting}
            style={{ marginTop: "24px" }}
            size="large"
          >
            {t("SET")}
          </ButtonContained>
        </Box>
      </form>
    </>
  );
};
