import { styled } from "@mui/material";

import { Card, ChipNotificationStatus, ChipNotificationStatusProps, Typography } from "@/components";
import { COLORS } from "@/utils/colors";

export type Props = {
  title: string;
  status: ChipNotificationStatusProps["status"];
  date: string;
  children?: React.ReactNode;
};

export const CardNotification: React.FC<Props> = ({ title, status, date, children }) => {
  return (
    <Card>
      <StyledTypography variant="h2">{title}</StyledTypography>
      <Layout>
        <Caption>
          <ChipNotificationStatus status={status} />
          <span>{date}</span>
        </Caption>
      </Layout>
      {children}
    </Card>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Caption = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  fontSize: "0.875rem",
  lineHeight: 1.4,
  letterSpacing: "0.04em",
  color: COLORS.TEXT_WEAK,
}));

const Layout = styled("div")(({ theme }) => ({
  "&:not(:last-of-type)": {
    marginBottom: theme.spacing(6),
  },
}));
