import React from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/material";

import { TableCell, TableRow } from "@/components";

export type Props = {
  label: string;
  open: boolean;
  onClick: VoidFunction;
  colSpan?: number;
};

export const TableExpandTrigger: React.FC<Props> = ({ label, open, onClick, colSpan }) => {
  return (
    <TableRow>
      <StyledTableCell colSpan={colSpan}>
        <StyledButton onClick={onClick}>
          {label}
          <StyledExpandIcon>{open ? <ExpandLess /> : <ExpandMore />}</StyledExpandIcon>
        </StyledButton>
      </StyledTableCell>
    </TableRow>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2, 4),
}));

const StyledButton = styled("button")(({ theme }) => ({
  font: "inherit",
  fontSize: "1rem",
  lineHeight: 1.2,
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(0, 2),
  background: "none",
  border: "none",
  padding: theme.spacing(1, 2),
  cursor: "pointer",
}));

const StyledExpandIcon = styled("span")({
  display: "inline-block",
  width: "20px",
  height: "20px",

  svg: {
    width: "100%",
    height: "100%",
  },
});
