import { ButtonProps, styled } from "@mui/material";

import { Button } from "@/components";

export type Props = Omit<ButtonProps, "variant">;

export const ButtonText: React.FC<Props> = ({ children, ...props }) => {
  return (
    <StyledButton variant="text" {...props}>
      <span>{children}</span>
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  padding: theme.spacing(2, 0),
  fontWeight: 400,
  lineHeight: 1.2,

  "&.Mui-disabled": {
    color: theme.palette.action.disabledBackground,
  },

  "&.MuiButton-sizeSmall": {
    fontSize: "1rem",
    padding: theme.spacing(1, 0),
  },

  "& > span > .MuiTypography-caption": {
    display: "block",
    marginTop: theme.spacing(1),
  },
}));
