import {
  ButtonOutlinedLink,
  Card,
  Container,
  LayoutVertical,
  ListExpired,
  ListExpiredItem,
  Loader,
  Typography,
  TypographyPoint,
} from "@/components";
import axios from "axios";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "@/components/Loader/Loader";
import { FetchContext as context } from "@/contexts/FetchContext";
import { removeAuthToken } from "@/contexts/JWTContext";
import { useNavigationError } from "@/hooks/useNavigationError";
import { PointBalance } from "@/types/pointSummary";
import { axiosAccountInstance } from "@/utils/axios";
import { formatExpiringDate, formatRequestDate } from "@/utils/formatter";
import getPortalType from "@/utils/getPortalType";

export const PointsExpire = () => {
  const { state } = useContext(context);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const [pointBalances, setPointBalances] = useState<PointBalance[]>([]);
  const portalType = getPortalType();

  const expireLimit = parseInt(id || "0", 10);
  const expireLimitMonth = Number(expireLimit / 30);

  const now = new Date();
  const start = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000 * (expireLimitMonth - 1));
  const end = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000 * expireLimitMonth);

  // 初回読み込み時
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    // 不適切な値の場合は保有ポイントに戻る
    if (![1, 2, 3, 4, 5, 6].includes(expireLimitMonth)) {
      navigate(((p): string => (p ? `/${p}_portal/points` : "/"))(portalType));
    }

    const fetch = async () => {
      setIsLoading(true);
      try {
        removeAuthToken();
        const response = await axiosAccountInstance.get("/api/point/balance/expiration", {
          params: {
            start_at: formatRequestDate(start),
            end_at: formatRequestDate(end),
          },
        });
        setPointBalances(() => {
          return response.data.map((data: { point_balance: number; expired_at: string }) => {
            return {
              pointBalance: data.point_balance,
              expiredAt: data.expired_at,
            };
          });
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          navigateError({
            error: error,
            endpoint: "/api/point/balance/expiration",
            appName: "OWNED_POINT",
            path: "points",
          });
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <Container>
      {state.loading ? (
        <Loader />
      ) : (
        <>
          <Helmet title={t("OWNED_POINT")} />

          <LayoutVertical>
            <Typography variant="h1" gutterBottom>
              {t("OWNED_POINT")}
            </Typography>
          </LayoutVertical>

          <ButtonOutlinedLink to={`/${portalType}_portal/points`} style={{ marginBottom: "24px" }}>
            {t("BACK")}
          </ButtonOutlinedLink>

          <LayoutVertical>
            <Card>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <Typography variant="h2" gutterBottom>
                    {expireLimitMonth === 1
                      ? t("AFTER_DAY").replace("%", "30")
                      : t("AFTER_MONTH")
                          .replace("%1", `${expireLimitMonth - 1}`)
                          .replace("%2", `${expireLimitMonth}`)}
                  </Typography>
                  <LayoutVertical>
                    <TypographyPoint
                      point={pointBalances.reduce((total, item) => total + item.pointBalance, 0)}
                      gutterBottom
                    />
                  </LayoutVertical>
                  <ListExpired>
                    {pointBalances.map((item: any, index: any) => {
                      return (
                        <ListExpiredItem
                          key={index}
                          label={t("UNTIL_DATE").replace("%", `${formatExpiringDate(item.expiredAt)}`)}
                          point={item.pointBalance}
                        />
                      );
                    })}
                  </ListExpired>
                </>
              )}
            </Card>
          </LayoutVertical>

          {!isLoading && (
            <ButtonOutlinedLink to={`/${portalType}_portal/points`} style={{ marginTop: "24px" }}>
              {t("BACK")}
            </ButtonOutlinedLink>
          )}
        </>
      )}
    </Container>
  );
};
