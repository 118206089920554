import { styled } from "@mui/material";

import { AlertProps } from "@/components";

// ref: https://github.com/mui/material-ui/issues/35922#issuecomment-1482941075
import { Alert } from "../../components/Alert/Alert";

export type Props = Omit<AlertProps, "icon"> & {
  fullWidth?: boolean;
};

export const AlertBox: React.FC<Props> = ({ children, ...props }) => {
  return (
    <StyledAlert icon={false} {...props}>
      {children}
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.4",
  letterSpacing: "0.04em",
  color: "inherit",
  padding: theme.spacing(4),
  borderRadius: "8px",
  margin: "0 auto",
  width: "100%",

  "*:not(.MuiAlertTitle-root, .MuiButton-root)": {
    fontSize: "inherit",
    letterSpacing: "0.04em",
  },

  "> .MuiAlert-message": {
    padding: 0,
    overflow: "initial",
    width: "100%",
  },
}));
