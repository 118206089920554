import React from "react";
import { useTranslation } from "react-i18next";

import {
  AlertInfo,
  ButtonTextLink,
  Card,
  HeadingRounded,
  InfoMessage,
  LayoutButton,
  LayoutInfo,
  LayoutVertical,
  ListBullet,
  ListBulletItem,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import getPortalType from "@/utils/getPortalType";

export interface CardReceiveMethodProps {
  isFreeTransfer: boolean;
  isTransferHistory?: boolean;
}

export const CardReceiveMethod: React.FC<CardReceiveMethodProps> = ({ isFreeTransfer, isTransferHistory }) => {
  const { t } = useTranslation();
  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  const AlertInfoFeeBankAcount = () => (
    <AlertInfo>
      <Typography>{t("FEE_BANK_ACCOUNT")}</Typography>
      <ListBullet>
        <ListBulletItem>{t("FEE_BANK_ACCOUNT_LESS_30000")}</ListBulletItem>
        <ListBulletItem>{t("FEE_BANK_ACCOUNT_MORE_30000")}</ListBulletItem>
      </ListBullet>
      <Typography>{t("FEE_BANK_ACCOUNT_NOTE_1")}</Typography>
      <Typography sx={{ mt: 6 }}>{t("FEE_BANK_ACCOUNT_NOTE_4")}</Typography>
    </AlertInfo>
  );

  return (
    <>
      <Card>
        <Typography variant="h2" gutterBottom>
          {t("RECEIVE_METHOD")}
        </Typography>
        <HeadingRounded component="h3" gutterBottom>
          {t("BANK_TRANSFER")}
        </HeadingRounded>
        <LayoutVertical>
          <LayoutInfo>
            {isFreeTransfer ? (
              <>
                <InfoMessage variant="secondary">{t("FEE_FREE")}</InfoMessage>
                {isTransferHistory ? (
                  <AlertInfoFeeBankAcount />
                ) : (
                  <AlertInfo>
                    <Typography>{t("FEE_BANK_ACCOUNT_NOTE_4")}</Typography>
                  </AlertInfo>
                )}
              </>
            ) : (
              <>
                <InfoMessage variant="primary">{t("FEE_NOT_FREE")}</InfoMessage>
                <AlertInfoFeeBankAcount />
              </>
            )}
          </LayoutInfo>
          <LayoutInfo>
            <InfoMessage variant="primary">{t("FEE_BANK_ACCOUNT_NOTE_2")}</InfoMessage>
            <AlertInfo>
              <Typography>{t("BANK_TRANSFER_EXAMPLE_1")}</Typography>
            </AlertInfo>
          </LayoutInfo>
          <LayoutInfo>
            <InfoMessage variant="primary">{t("FEE_BANK_ACCOUNT_NOTE_3")}</InfoMessage>
            <AlertInfo>
              <Typography>{t("BANK_TRANSFER_EXAMPLE_3")}</Typography>
            </AlertInfo>
          </LayoutInfo>
          <LayoutButton>
            <ButtonTextLink to={supportUrls["ABOUT_BANK_ACCOUNT"]} target="_blank">
              {t("ABOUT_BANK_ACCOUNT")}
            </ButtonTextLink>
          </LayoutButton>
        </LayoutVertical>
      </Card>
    </>
  );
};
