import { ChipProps, Chip as MuiChip, styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

export type Props = ChipProps & {
  charColor?: string;
  bgColor?: string;
  wraptext?: "nowrap" | "normal" | "pre-line" | "pre-wrap";
};

export const Chip: React.FC<Props> = (props) => {
  return <StyledChip {...props} />;
};

const StyledChip = styled(
  // biome-ignore suppressions/unused: need not to watch
  ({ charColor, bgColor, wraptext, ...otherProps }: Props) => <MuiChip {...otherProps} />
)<Props>(({ theme, charColor, bgColor, wraptext }) => ({
  letterSpacing: "0.01em",
  borderRadius: "24px",

  "&.MuiChip-colorDefault": {
    color: charColor || COLORS.TEXT_01,
    backgroundColor: bgColor || COLORS.BG_02,
  },
  "&.MuiChip-colorError": {
    color: COLORS.TEXT_02,
    backgroundColor: COLORS.ALERT,
  },
  "&.MuiChip-colorWarning": {
    color: COLORS.TEXT_02,
    backgroundColor: COLORS.WARNING,
  },
  "&.MuiChip-sizeSmall": {
    fontSize: "0.875rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.75rem",
    },
  },
  "&.MuiChip-sizeMedium": {
    justifyContent: "flex-start",
    padding: "8px 18px",
    minHeight: "36px",
    height: "auto",
    lineHeight: "1.5",
    display: "inline-block",
    textAlign: "left",
    width: "279px",
  },

  "& > .MuiChip-label": {
    paddingRight: "6px",
    paddingLeft: "6px",
    whiteSpace: wraptext || "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    marginInline: "auto",
    maxInlineSize: "max-content",
    maxWidth: "100%",
    justifyContent: "flex-start",
  },
}));
