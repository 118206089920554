import React from "react";

import { CardProps, Card as MuiCard, styled } from "@mui/material";

export type Props = CardProps;

export const Card = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return <StyledCard ref={ref} {...props} />;
});

const StyledCard = styled(MuiCard)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: "8px",
}));
