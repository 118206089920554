import { CSSProperties, ReactNode } from "react";

import { styled } from "@mui/material";

import { IconExclamation, IconInfo, IconWarning, Typography } from "@/components";
import { COLORS } from "@/utils/colors";

export type Props = {
  variant?: Variant;
  children: ReactNode;
  gutterBottom?: boolean;
  sx?: CSSProperties;
};

type Variant = "primary" | "secondary" | "warning" | "error";

type ColorMap = {
  [key in Variant]: string;
};
const colorMap: ColorMap = {
  primary: COLORS.PRIMARY,
  secondary: COLORS.SECONDARY,
  warning: COLORS.WARNING,
  error: COLORS.ALERT,
};

type IconMap = {
  [key in Variant]: React.ReactElement;
};
const iconMap: IconMap = {
  primary: <IconInfo />,
  secondary: <IconInfo />,
  warning: <IconWarning />,
  error: <IconExclamation />,
};

export const InfoMessage: React.FC<Props> = ({ variant = "primary", children, sx, ...props }) => {
  return (
    <StyledTypography color={colorMap[variant]} style={sx} {...props} component="div">
      <StyledIcon>{iconMap[variant]}</StyledIcon>
      <span>{children}</span>
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(0, 2),
  lineHeight: 1.4,
}));

const StyledIcon = styled("span")(() => ({
  display: "flex",
}));
