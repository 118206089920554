import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { APP_DEEPLINK } from "@/constants";
import getPortalType from "@/utils/getPortalType";

export const useNavigation = () => {
  const { t } = useTranslation();
  const portalType = getPortalType();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
  }, []);

  const navigations = [
    { path: `/${portalType}_portal/top`, label: t("TOP") },
    {
      path: `/${portalType}_portal/points`,
      label: t("POINT"),
      children: [
        { path: `/${portalType}_portal/points`, label: t("OWNED_POINT") },
        {
          path: `/${portalType}_portal/points/transfer`,
          label: t("POINT_EXCHANGE_APPLICATION"),
        },
        {
          path: `/${portalType}_portal/points/transfer/histories`,
          label: t("POINT_EXCHANGE_APPLICATION_HISTORY"),
        },
        {
          path: `/${portalType}_portal/points/history`,
          label: t("POINT_HISTORY"),
        },
      ],
    },
    { path: `/${portalType}_portal/notifications`, label: t("NOTIFICATION") },
    {
      path: `/${portalType}_portal/account`,
      label: t("MY_PAGE"),
      children:
        portalType === "crew"
          ? [
              { path: "/crew_portal/account", label: t("MY_PAGE") },
              {
                path: "/crew_portal/account/phone_number_change/auth_mail",
                label: t("CHANGE_PHONE_NUMBER"),
              },
              {
                path: "/crew_portal/account/mail_address_change/auth_sms",
                label: t("CHANGE_EMAIL"),
              },
              {
                path: "/crew_portal/account/password_change/auth_mail",
                label: t("CHANGE_PASSWORD"),
              },
            ]
          : [
              { path: "/driver_portal/account", label: t("MY_PAGE") },
              {
                path: isMobile ? APP_DEEPLINK : "/driver_portal/mobile_device_warning",
                label: t("CHANGE_EMAIL"),
              },
              {
                path: isMobile ? APP_DEEPLINK : "/driver_portal/mobile_device_warning",
                label: t("CHANGE_PASSWORD"),
              },
            ],
    },
  ];

  return {
    navigations,
  };
};
