import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Container, LayoutVertical, StepCard, Typography } from "@/components";

import { PasswordChangePasswordForm } from "./components/PasswordChange/Forms";

export const PasswordChangePassword = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Helmet title={t("CHANGE_PASSWORD")} />
      <LayoutVertical>
        <Typography variant="h1">{t("CHANGE_PASSWORD")}</Typography>
        <StepCard activestep={2} steplength={4}>
          <Typography variant="h2">{t("PLEASE_INPUT_PASSWORD")}</Typography>
          <PasswordChangePasswordForm />
        </StepCard>
      </LayoutVertical>
    </Container>
  );
};
