import { Chip, ChipProps } from "@/components";

type Status = "important" | "none";

type StatusMap = {
  [key in Status]: {
    label: string | undefined;
    color: ChipProps["color"] | undefined;
  };
};
const statusTypeMap: StatusMap = {
  important: {
    label: "重要",
    color: "error",
  },
  none: {
    label: undefined,
    color: undefined,
  },
};

export type Props = {
  status: Status;
};

export const ChipNotificationStatus: React.FC<Props> = ({ status }) => {
  return status === "none" ? null : (
    <Chip
      color={statusTypeMap[status].color}
      label={statusTypeMap[status].label}
      size="small"
      component="span"
      wraptext="nowrap"
    />
  );
};
