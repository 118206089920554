import { styled } from "@mui/material";

import { Typography, TypographyProps } from "@/components";
import { COLORS } from "@/utils/colors";

type StyleProps = {
  size?: "large";
  displaytext?: "flex" | "block";
};

export type Props = TypographyProps & StyleProps;

export const TypographyRounded: React.FC<Props> = (props) => {
  return <StyledTypography {...props} />;
};

const StyledTypography = styled(Typography, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "gutterBottom" && propName !== "size",
})<StyleProps>(({ theme, gutterBottom, size, displaytext }) => ({
  display: displaytext || "block",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: `6px ${theme.spacing(4)}`,
  width: "100%",
  minHeight: size === "large" ? "56px" : 0,
  fontSize: size === "large" ? "1.25rem" : "1.125rem",
  fontWeight: 400,
  lineHeight: 1.2,
  letterSpacing: "0.02em",
  color: COLORS.TEXT_01,
  backgroundColor: COLORS.BG_02,
  borderRadius: "1000px",
  marginBottom: !gutterBottom ? 0 : theme.spacing(6),
}));
