import { CSSProperties } from "react";

import { styled } from "@mui/material";

import { IconWindow } from "@/components";

export type TargetBlankProps = {
  style?: CSSProperties;
  color?: string;
};

export const TargetBlank: React.FC<TargetBlankProps> = ({ style, color }) => {
  return (
    <IconWrap style={style}>
      <IconWindow color={color} />
    </IconWrap>
  );
};

const IconWrap = styled("span")(({ theme }) => ({
  display: "inline-block",
  verticalAlign: "middle",
  marginLeft: theme.spacing(2),

  ".MuiButton-contained &, .MuiButton-outlined &": {
    width: "21px",
    height: "20px",
    marginLeft: theme.spacing(1),
  },
}));
