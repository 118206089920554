import { Link } from "react-router-dom";

import { ButtonText, TargetBlank } from "@/components";
import { ButtonLinkProps } from "@/components/ButtonLink";
import { COLORS } from "@/utils/colors";

export type Props = ButtonLinkProps & {
  isTargetBlankFixed?: boolean;
};

export const ButtonTextLink: React.FC<Props> = ({ children, isTargetBlankFixed, ...props }) => {
  if (isTargetBlankFixed) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ButtonText component={Link} {...props} style={{ marginRight: "24px" }}>
            {children}
          </ButtonText>
          {props.target === "_blank" && (
            <TargetBlank style={{ position: "absolute", right: 0 }} color={COLORS.PRIMARY} />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonText component={Link} {...props}>
          {children}
          {props.target === "_blank" && <TargetBlank />}
        </ButtonText>
      </div>
    );
  }
};
