import { FormHelperTextProps, styled } from "@mui/material";

import { FormHelperText } from "@/components";

export type Props = {
  children: React.ReactNode;
  helperText?: React.ReactNode;
  helperTextProps?: FormHelperTextProps;
};

export const FormBox: React.FC<Props> = ({ children, helperText, helperTextProps }) => {
  return (
    <Container>
      {children}
      {!!helperText && <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>}
    </Container>
  );
};

const Container = styled("div")({});
