import { MenuItemProps, MenuItem as MuiMenuItem, styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

type Props = MenuItemProps;

export const MenuItem: React.FC<Props> = (props) => {
  return <StyledMenuItem {...props} />;
};

const StyledMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: 1.25,
  minHeight: 0,
  padding: theme.spacing(2, 4),

  "&:hover, &.Mui-focusVisible": {
    backgroundColor: COLORS.BG_01,
  },
}));
