import { useContext } from "react";

import { ModalContext } from "@/contexts/ModalContext";

const useErrorModal = () => {
  const context = useContext(ModalContext);

  if (!context) throw new Error("ModalContext must be placed within ModalProvider");

  return context;
};

export default useErrorModal;
