import "@mui/lab/themeAugmentation";
import { createTheme as createMuiTheme } from "@mui/material/styles";

import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";
import typography from "./typography";
import { VariantType, defaultVariant } from "./variants";

const createTheme = (variant: VariantType) => {
  return createMuiTheme({
    spacing: 4,
    breakpoints,
    components,
    typography,
    shadows,
    palette: variant.palette,
  });
};

export const theme = createTheme(defaultVariant);
