import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  Container,
  LayoutVertical,
  ListExpired,
  ListExpiredItem,
  Loader,
  Typography,
  TypographyPoint,
} from "@/components";
import "@/components/Loader/Loader";
import { FetchContext as context } from "@/contexts/FetchContext";
import getPortalType from "@/utils/getPortalType";

import { AlertPointExpiring } from "./components";

export const Points = () => {
  const { state } = useContext(context);
  const { t } = useTranslation();
  const portalType = getPortalType();

  return (
    <Container>
      {state.loading || !state.data?.user.driverCode ? (
        <Loader url={((p): string => (p ? `/${p}_portal/points/error/500` : "/"))(portalType)} />
      ) : (
        <>
          <Helmet title={t("OWNED_POINT")} />

          <LayoutVertical>
            <Typography variant="h1">{t("OWNED_POINT")}</Typography>

            <Card>
              <Typography variant="h2" gutterBottom>
                {t("OWNED_POINT")}
              </Typography>
              <LayoutVertical>
                <TypographyPoint point={state.data.pointSummary.currentPoint} />
                <AlertPointExpiring pointSummary={state.data.pointSummary} />
              </LayoutVertical>
            </Card>

            <Card>
              <Box textAlign="center">
                <Typography variant="h2" gutterBottom>
                  {t("POINT_EXPIRE_TO")}
                </Typography>
              </Box>
              <ListExpired sx={{ mt: -4 }}>
                {Object.entries(state.data.pointSummary.expirePointAmounts).map(
                  // biome-ignore suppressions/unused: need not to watch @typescript-eslint/no-unused-vars
                  ([_, value], index) => {
                    const numericValue = value as number;
                    return (
                      <ListExpiredItem
                        key={index}
                        label={
                          index === 0
                            ? t("AFTER_DAY_2").replace("%", "30")
                            : t("AFTER_MONTH_2")
                                .replace("%1", index.toString())
                                .replace("%2", (index + 1).toString())
                        }
                        point={numericValue}
                        to={
                          numericValue > 0
                            ? `/${portalType}_portal/points/expire/${(30 * (index + 1)).toString()}`
                            : undefined
                        }
                      />
                    );
                  }
                )}
              </ListExpired>
            </Card>
          </LayoutVertical>
        </>
      )}
    </Container>
  );
};
