import { Radio as MuiRadio, RadioProps, styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

export type Props = RadioProps;

export const Radio: React.FC<Props> = (props) => {
  return <StyledRadio {...props} />;
};

const StyledRadio = styled(MuiRadio)(({ theme }) => ({
  alignSelf: "flex-start",
  padding: theme.spacing(2),

  "&:not(.Mui-disabled, .Mui-error)": {
    color: COLORS.PRIMARY,
  },
}));
