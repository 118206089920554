import { styled } from "@mui/material";

export const LayoutVertical = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(6, 0),

  "& > .layoutButton": {
    marginTop: theme.spacing(-4),
  },
}));
