import * as React from "react";

import { AlertProps, Alert as MuiAlert, styled } from "@mui/material";

import { IconCheck, IconExclamation, IconInfo, IconWarning, Snackbar, SnackbarProps } from "@/components";
import { COLORS } from "@/utils/colors";

export type AlertType = "danger" | "info" | "success" | "warning";

type StyledProps = {
  type?: AlertType;
};

type Props = Omit<SnackbarProps, "anchorOrigin" | "autoHideDuration" | "children"> &
  StyledProps & {
    message: React.ReactNode;
    onClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  };

const styleMap = {
  danger: {
    icon: <IconExclamation />,
    color: COLORS.ALERT,
  },
  info: {
    icon: <IconInfo />,
    color: COLORS.PRIMARY,
  },
  success: {
    icon: <IconCheck />,
    color: COLORS.SECONDARY,
  },
  warning: {
    icon: <IconWarning />,
    color: COLORS.WARNING,
  },
};

export const SnackbarAlert: React.FC<Props> = ({ type, message, onClose, ...props }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      autoHideDuration={6000}
      onClose={onClose}
      {...props}
    >
      <Alert type={type} icon={type ? styleMap[type].icon : false} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps & StyledProps>((props, ref) => {
  return <StyledAlert ref={ref} {...props} />;
});

const StyledAlert = styled(MuiAlert, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "type",
})<StyledProps>(({ theme, type }) => ({
  lineHeight: 1.4,
  color: COLORS.TEXT_02,
  backgroundColor: type ? styleMap[type].color : COLORS.TEXT_01,
  width: "800px",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow: "0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 4px 0 rgba(0, 0, 0, 0.25)",

  ".MuiAlert-action, .MuiButtonBase-root": {
    padding: 0,
  },

  ".MuiAlert-message": {
    padding: 0,
    overflow: "hidden",
  },

  ".MuiAlert-action": {
    marginRight: 0,
    alignSelf: "center",
  },

  ".MuiAlert-icon": {
    margin: 0,
    padding: 0,
    opacity: 1,
    alignSelf: "center",
    color: "inherit",
  },

  ".MuiSvgIcon-root": {
    fontSize: "24px",
  },
}));
