export type Notification = {
  id: number;
  title: string;
  content: string;
  flagType: string;
  postedAt: string;
};

export type Notifications = Notification[];

export const notificationsDefaultValues: Notifications = [];
