import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Box,
  ButtonContained,
  Checkbox,
  FormBox,
  FormControlLabel,
  Link,
  ListBullet,
  ListBulletItem,
  Select,
  SnackbarAlert,
  TextField,
  TextFieldEmail,
  Typography,
} from "@/components";
import { FOOTER_URLS, SUPPORT_URLS } from "@/constants";
import { useNavigationError } from "@/hooks/useNavigationError";
import { ErrorResponse } from "@/types/errorResponse";
import { axiosAccountInstance, axiosAuthInstance } from "@/utils/axios";
import getPortalType from "@/utils/getPortalType";
import {
  companyNameValidator,
  contactContentsValidator,
  contactTypeValidator,
  crewNumberValidator,
  emailValidator,
  nameValidator,
  officeNameValidator,
  telephoneNumberValidator,
  validationSchema,
} from "@/utils/validators";

export const ContactFormCrew = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { navigateError } = useNavigationError();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [contactTypeFromUrl, setContactTypeFromUrl] = useState("");
  const footerUrls: { [key: string]: string } = FOOTER_URLS.crew;

  type FormData = {
    email: string;
    telephoneNumber: string;
    name: string;
    companyName: string;
    officeName: string;
    crewNumber: string;
    contactContents: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        email: emailValidator,
        telephoneNumber: telephoneNumberValidator,
        name: nameValidator,
        companyName: companyNameValidator,
        officeName: officeNameValidator,
        crewNumber: crewNumberValidator,
        contactContents: contactContentsValidator,
      })
    ),
    defaultValues: {
      email: "",
      telephoneNumber: "",
      name: "",
      companyName: "",
      officeName: "",
      crewNumber: "",
      contactContents: "",
    },
    mode: "onChange",
  });

  const onSubmitError = () => {
    setSnackbarMessage(t("UNFILLED_FIELDS_NOTICE"));
  };

  const onSubmit = async (data: FormData) => {
    if (!isCheckboxChecked) {
      setSnackbarMessage(t("PRIVACY_POLICY_UNAGREED"));
      return;
    }

    await axiosAuthInstance
      .post("/api/contact", {
        mail_address: data.email,
        phone_number: data.telephoneNumber,
        name: data.name,
        company_name: data.companyName,
        office_name: data.officeName,
        driver_code: data.crewNumber,
        contents: data.contactContents,
        driver_type: "crew",
        contact_type: t(contactTypeFromUrl) || undefined,
      })
      .then(() => {
        navigate("./received");
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        navigateError({
          error: error,
          endpoint: "/api/contact",
        });
      });
  };

  // biome-ignore suppressions/unused: need not to watch
  useEffect(() => {
    // クエリストリングにstatusがある場合それをcontactTypeにセット
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    if (status) {
      try {
        // statusがcontactTypeValidatorを満たすかも確認
        contactTypeValidator.validateSync(status);
        setContactTypeFromUrl(status);
      } catch (_error) {
        // biome-ignore suppressions/unused: need not to watch
      }
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit, onSubmitError)} name="contactForm">
      {/* メールアドレス */}
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextFieldEmail
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            type="email"
            label={t("EMAIL")}
            fullWidth
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            style={{ marginTop: "12px" }}
          />
        )}
      />

      {/* 電話番号 */}
      <Controller
        name="telephoneNumber"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormBox helperText={t("TELEPHONE_NUMBER_NOTE")}>
            <TextField
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("TELEPHONE_NUMBER")}
              fullWidth
              error={Boolean(errors.telephoneNumber)}
              helperText={errors.telephoneNumber?.message}
              inputProps={{ maxLength: 11 }}
              style={{ marginTop: "24px" }}
            />
          </FormBox>
        )}
      />

      {/* お名前 */}
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            label={t("NAME")}
            fullWidth
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            inputProps={{ maxLength: 64 }}
            style={{ marginTop: "24px" }}
          />
        )}
      />

      {/* 所属する会社名 */}
      <Controller
        name="companyName"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormBox helperText={t("COMPANY_NAME_NOTE")}>
            <TextField
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
              value={value}
              label={t("COMPANY_NAME")}
              fullWidth
              error={Boolean(errors.companyName)}
              helperText={errors.companyName?.message}
              inputProps={{ maxLength: 255 }}
              style={{ marginTop: "24px" }}
            />
          </FormBox>
        )}
      />

      {/* 所属する営業所名 */}
      <Controller
        name="officeName"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            label={t("OFFICE_NAME")}
            fullWidth
            error={Boolean(errors.officeName)}
            helperText={errors.officeName?.message}
            inputProps={{ maxLength: 255 }}
            style={{ marginTop: "24px" }}
          />
        )}
      />

      {/* 乗務員番号 */}
      <Controller
        name="crewNumber"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormBox helperText={t("CONTACT_CREW_NUMBER_NOTE")} helperTextProps={{ sx: { whiteSpace: "pre-wrap" } }}>
            <TextField
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
              value={value}
              label={t("CREW_NUMBER")}
              fullWidth
              error={Boolean(errors.crewNumber)}
              helperText={errors.crewNumber?.message}
              inputProps={{ maxLength: 64 }}
              style={{ marginTop: "24px" }}
            />
          </FormBox>
        )}
      />

      {/* お問い合わせ内容 */}
      <Controller
        name="contactContents"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            label={t("CONTACT_CONTENTS")}
            fullWidth
            error={Boolean(errors.contactContents)}
            helperText={errors.contactContents?.message}
            inputProps={{ maxLength: 2001 }}
            style={{ marginTop: "24px" }}
            multiline
          />
        )}
      />

      <Typography component="div" color="text.secondary" sx={{ my: 6, fontSize: 16 }}>
        <ListBullet>
          <ListBulletItem>{t("CONTACT_NOTICE_1")}</ListBulletItem>
          <ListBulletItem>{t("CONTACT_NOTICE_2")}</ListBulletItem>
          <ListBulletItem>{t("CONTACT_NOTICE_3")}</ListBulletItem>
        </ListBullet>
      </Typography>

      <FormControlLabel
        control={<Checkbox />}
        label={
          <Trans
            i18nKey="CONTACT_PRIVACY_POLICY_AGREE"
            components={{
              Link: <Link href={footerUrls["PRIVACY_POLICY"]} target="_blank"></Link>,
            }}
          />
        }
        checked={isCheckboxChecked}
        onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
      />

      <Box textAlign="center" style={{ marginTop: "24px" }}>
        <ButtonContained
          type="submit"
          color="primary"
          size="large"
          className={!isValid || !isCheckboxChecked ? "disabled" : ""}
          disabled={isSubmitting}
        >
          {t("SEND")}
        </ButtonContained>
      </Box>

      <SnackbarAlert
        type="danger"
        open={!!snackbarMessage}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage("")}
      />
    </form>
  );
};

export const ContactFormDriver = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { navigateError } = useNavigationError();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const footerUrls: { [key: string]: string } = FOOTER_URLS.driver;

  type FormData = {
    email: string;
    name: string;
    companyName: string;
    officeName: string;
    crewNumber: string;
    contactContents: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        email: emailValidator,
        name: nameValidator,
        companyName: companyNameValidator,
        officeName: officeNameValidator,
        crewNumber: crewNumberValidator,
        contactContents: contactContentsValidator,
      })
    ),
    defaultValues: {
      email: "",
      name: "",
      companyName: "",
      officeName: "",
      crewNumber: "",
      contactContents: "",
    },
    mode: "onChange",
  });

  const onSubmitError = () => {
    setSnackbarMessage(t("UNFILLED_FIELDS_NOTICE"));
  };

  const onSubmit = async (data: FormData) => {
    if (!isCheckboxChecked) {
      setSnackbarMessage(t("PRIVACY_POLICY_UNAGREED"));
      return;
    }

    await axiosAuthInstance
      .post("/api/contact", {
        mail_address: data.email,
        // phone_numberは不要
        name: data.name,
        company_name: data.companyName,
        office_name: data.officeName,
        driver_code: data.crewNumber,
        contents: data.contactContents,
        driver_type: "driver",
      })
      .then(() => {
        navigate("./received");
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        navigateError({
          error: error,
          endpoint: "/api/contact",
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onSubmitError)} name="contactForm">
      {/* メールアドレス */}
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextFieldEmail
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            type="email"
            label={t("EMAIL")}
            fullWidth
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            style={{ marginTop: "12px" }}
          />
        )}
      />

      {/* 電話番号は不要 */}

      {/* お名前 */}
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            label={t("NAME")}
            fullWidth
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            inputProps={{ maxLength: 64 }}
            style={{ marginTop: "24px" }}
          />
        )}
      />

      {/* 所属する会社名 */}
      <Controller
        name="companyName"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            label={t("COMPANY_NAME")}
            fullWidth
            error={Boolean(errors.companyName)}
            helperText={errors.companyName?.message}
            inputProps={{ maxLength: 255 }}
            style={{ marginTop: "24px" }}
          />
        )}
      />

      {/* 所属する営業所名 */}
      <Controller
        name="officeName"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            label={t("OFFICE_NAME")}
            fullWidth
            error={Boolean(errors.officeName)}
            helperText={errors.officeName?.message}
            inputProps={{ maxLength: 255 }}
            style={{ marginTop: "24px" }}
          />
        )}
      />

      {/* 乗務員番号 */}
      <Controller
        name="crewNumber"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <FormBox helperText={t("CONTACT_DRIVER_NUMBER_NOTE")}>
            <TextField
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
              value={value}
              label={t("DRIVER_NUMBER")}
              fullWidth
              error={Boolean(errors.crewNumber)}
              helperText={errors.crewNumber?.message}
              inputProps={{ maxLength: 64 }}
              style={{ marginTop: "24px" }}
            />
          </FormBox>
        )}
      />

      {/* お問い合わせ内容 */}
      <Controller
        name="contactContents"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            label={t("CONTACT_CONTENTS")}
            fullWidth
            error={Boolean(errors.contactContents)}
            helperText={errors.contactContents?.message}
            inputProps={{ maxLength: 2001 }}
            style={{ marginTop: "24px" }}
            multiline
          />
        )}
      />

      <Typography component="div" color="text.secondary" sx={{ my: 6, fontSize: 16 }}>
        <ListBullet>
          <ListBulletItem>{t("CONTACT_NOTICE_1")}</ListBulletItem>
          <ListBulletItem>{t("CONTACT_NOTICE_2")}</ListBulletItem>
          <ListBulletItem>{t("CONTACT_NOTICE_3")}</ListBulletItem>
        </ListBullet>
      </Typography>

      <FormControlLabel
        control={<Checkbox />}
        label={
          <Trans
            i18nKey="CONTACT_PRIVACY_POLICY_AGREE"
            components={{
              Link: <Link href={footerUrls["PRIVACY_POLICY"]} target="_blank"></Link>,
            }}
          />
        }
        checked={isCheckboxChecked}
        onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
      />

      <Box textAlign="center" style={{ marginTop: "24px" }}>
        <ButtonContained
          type="submit"
          color="primary"
          size="large"
          className={!isValid || !isCheckboxChecked ? "disabled" : ""}
          disabled={isSubmitting}
        >
          {t("SEND")}
        </ButtonContained>
      </Box>

      <SnackbarAlert
        type="danger"
        open={!!snackbarMessage}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage("")}
      />
    </form>
  );
};

export const ContactAccountForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { navigateError } = useNavigationError();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isAccountReset, setIsAccountReset] = useState(false);
  const [isUrlClicked, setIsUrlClicked] = useState(false);
  const [contactTypeFromUrl, setContactTypeFromUrl] = useState("");
  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];
  const footerUrls: { [key: string]: string } = FOOTER_URLS[portalType];

  type FormData = {
    contactType: string;
    contactContents: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        contactType: contactTypeValidator,
        contactContents: contactContentsValidator,
      })
    ),
    defaultValues: {
      contactType: "CONTACT",
      contactContents: "",
    },
    mode: "onChange",
  });

  const onSubmitError = () => {
    setSnackbarMessage(t("UNFILLED_FIELDS_NOTICE"));
  };

  const onSubmit = async (data: FormData) => {
    if (!isCheckboxChecked) {
      setSnackbarMessage(t("PRIVACY_POLICY_UNAGREED"));
      return;
    }

    if (isAccountReset && !isUrlClicked) {
      setSnackbarMessage(t("UNFILLED_FIELDS_NOTICE"));
      return;
    }

    await axiosAccountInstance
      .post("/api/account/contact", {
        contents: data.contactContents,
        contact_type: t(contactTypeFromUrl || data.contactType),
      })
      .then(() => {
        navigate("./received");
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        navigateError({
          error: error,
          endpoint: "/api/account/contact",
        });
      });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    // クエリストリングにstatusがある場合それをcontactTypeにセット
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    if (status && portalType === "crew") {
      try {
        // statusがcontactTypeValidatorを満たすかも確認
        contactTypeValidator.validateSync(status);
        setContactTypeFromUrl(status);
      } catch (_error) {
        // biome-ignore suppressions/unused: need not to watch
      }
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onSubmitError)}
      name="contactForm"
      style={{ marginTop: portalType === "crew" ? "24px" : "0px" }}
    >
      {/* お問い合わせ種別 */}
      {portalType === "crew" && !contactTypeFromUrl && (
        <Controller
          name="contactType"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              onChange={(e) => {
                onChange(e);
                setIsAccountReset(e.target.value === "ACCOUNT_RESET_REQUEST");
              }}
              onBlur={onBlur}
              value={value}
              label={t("CONTACT_TYPE")}
              fullWidth
              error={Boolean(errors.contactType)}
              helperText={errors.contactType?.message}
              items={["CONTACT", "ACCOUNT_RESET_REQUEST"]}
            />
          )}
        />
      )}

      {/* アカウントリセットに伴う注意事項 */}
      {isAccountReset && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isUrlClicked}
              onClick={(e: any) => {
                setIsUrlClicked(e.target.checked);
              }}
            />
          }
          label={
            <Trans
              i18nKey="CONTACT_NOTICE_4"
              components={{
                Link: <Link href={supportUrls["NOTE_ON_ACCOUNT_RESET"]} target="_blank"></Link>,
              }}
            />
          }
          sx={{ mt: "24px", mb: "24px" }}
        />
      )}

      {/* お問い合わせ内容 */}
      <Controller
        name="contactContents"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={onBlur}
            value={value}
            label={t("CONTACT_CONTENTS")}
            fullWidth
            error={Boolean(errors.contactContents)}
            helperText={errors.contactContents?.message}
            inputProps={{ maxLength: 2001 }}
            style={{ marginTop: isAccountReset ? "0px" : "24px" }}
            multiline
          />
        )}
      />

      <Typography component="div" color="text.secondary" sx={{ my: 6, fontSize: 16 }}>
        <ListBullet>
          <ListBulletItem>{t("CONTACT_NOTICE_1")}</ListBulletItem>
          <ListBulletItem>{t("CONTACT_NOTICE_2")}</ListBulletItem>
          <ListBulletItem>{t("CONTACT_NOTICE_3")}</ListBulletItem>
        </ListBullet>
      </Typography>

      <FormControlLabel
        control={<Checkbox />}
        label={
          <Trans
            i18nKey="CONTACT_PRIVACY_POLICY_AGREE"
            components={{
              Link: <Link href={footerUrls["PRIVACY_POLICY"]} target="_blank"></Link>,
            }}
          />
        }
        checked={isCheckboxChecked}
        onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
      />

      <Box textAlign="center" style={{ marginTop: "24px" }}>
        <ButtonContained
          type="submit"
          color="primary"
          size="large"
          className={!isValid || (isAccountReset ? !isUrlClicked : false) || !isCheckboxChecked ? "disabled" : ""}
          disabled={isSubmitting}
        >
          {t("SEND")}
        </ButtonContained>
      </Box>

      <SnackbarAlert
        type="danger"
        open={!!snackbarMessage}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage("")}
      />
    </form>
  );
};
