import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Card, Container, LayoutVertical, Typography } from "@/components";

export const ContactReceived = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Helmet title={t("CONTACT_RECEIVED")} />
      <LayoutVertical>
        <Card>
          <Typography variant="h2">{t("CONTACT_RECEIVED")}</Typography>
          <Typography sx={{ mt: 8 }}>{t("CONTACT_RESPONSE_TIME_NOTICE")}</Typography>
        </Card>
      </LayoutVertical>
    </Container>
  );
};
