import { useTranslation } from "react-i18next";

import { styled } from "@mui/material";

import { IconExchange, IconMoney, IconPoint, IconWarning, ListItem, ListItemAvatar } from "@/components";
import { COLORS } from "@/utils/colors";
import { formatDisplayDate } from "@/utils/formatter";

// ref: https://github.com/mui/material-ui/issues/35922#issuecomment-1482941075
import { Avatar } from "../../components/Avatar/Avatar";
import { IconCancelTaxi } from "../../components/IconCancelTaxi/IconCancelTaxi";
import { IconPriorityPath } from "../../components/IconPriorityPath/IconPriorityPath";
import { IconTimeFill } from "../../components/IconTimeFill/IconTimeFill";
import { IconTotalPoint } from "../../components/IconTotalPoint/IconTotalPoint";
import { IconTotalPointCancel } from "../../components/IconTotalPointCancel/IconTotalPointCancel";
import { ListItemText } from "../../components/ListItemText/ListItemText";

export type PointType =
  | "UNSPECIFIED"
  | "UNSPECIFIED_ErrorFee"
  | "UNSPECIFIED_Exchanged"
  | "UNSPECIFIED_Transfered_Positive"
  | "UNSPECIFIED_Transfered_Negative"
  | "UNSPECIFIED_TransferReverted_Positive"
  | "UNSPECIFIED_TransferReverted_Negative"
  | "USER_TIP"
  | "USER_CANCEL_PAYMENT"
  | "PRIORITY_PASS"
  | "PICK_UP"
  | "RESERVATION";

export type Props = {
  label: string;
  caption?: string;
  point: number;
  type: PointType;
  acquisitionDate?: string;
  expireDate?: string;
  applicationDate?: string;
  returnDate?: string;
  errorDate?: string;
  moveDate?: string;
};

type IconType = {
  [key in PointType]: {
    icon: React.ReactElement;
    color: string;
  };
};

const iconMap: IconType = {
  UNSPECIFIED: {
    icon: <IconExchange />,
    color: COLORS.NEGATIVE,
  },
  UNSPECIFIED_ErrorFee: {
    icon: <IconWarning />,
    color: COLORS.NEGATIVE,
  },
  UNSPECIFIED_Exchanged: {
    icon: <IconExchange />,
    color: COLORS.NEGATIVE,
  },
  UNSPECIFIED_Transfered_Positive: {
    icon: <IconTotalPoint />,
    color: COLORS.SECONDARY,
  },
  UNSPECIFIED_Transfered_Negative: {
    icon: <IconTotalPoint />,
    color: COLORS.NEGATIVE,
  },
  UNSPECIFIED_TransferReverted_Positive: {
    icon: <IconTotalPointCancel />,
    color: COLORS.SECONDARY,
  },
  UNSPECIFIED_TransferReverted_Negative: {
    icon: <IconTotalPointCancel />,
    color: COLORS.NEGATIVE,
  },
  USER_TIP: {
    icon: <IconMoney />,
    color: COLORS.ACCENT,
  },
  USER_CANCEL_PAYMENT: {
    icon: <IconCancelTaxi />,
    color: COLORS.SECONDARY,
  },
  PRIORITY_PASS: {
    icon: <IconPriorityPath />,
    color: COLORS.SECONDARY,
  },
  PICK_UP: {
    icon: <IconPoint />,
    color: COLORS.SECONDARY,
  },
  RESERVATION: {
    icon: <IconTimeFill />,
    color: COLORS.SECONDARY,
  },
};

export const ListPointItem: React.FC<Props> = ({
  label,
  caption,
  point,
  type,
  acquisitionDate,
  expireDate,
  applicationDate,
  returnDate,
  errorDate,
  moveDate,
}) => {
  const { t } = useTranslation();
  const isMinus = point < 0;
  return (
    <ListItem className="listPointItem">
      <ListItemAvatar>
        <StyledAvatar
          color={iconMap[type].color}
          minus={isMinus}
          reverse={["UNSPECIFIED", "UNSPECIFIED_Exchanged"].includes(type)}
        >
          {iconMap[type].icon}
        </StyledAvatar>
      </ListItemAvatar>
      <StyledListItemText>
        <Labels>
          <span>{label}</span>
          <Point minus={isMinus}>
            {!isMinus && "+"}
            {point.toLocaleString()}
            <span style={{ color: "initial" }}>P</span>
          </Point>
        </Labels>
        {caption && <Caption>{caption}</Caption>}
        {!isMinus && acquisitionDate && (
          <DateText>
            {formatDisplayDate(acquisitionDate)} {t("EARN")}
          </DateText>
        )}
        {isMinus && expireDate && (
          <DateText minus>
            {formatDisplayDate(expireDate)} {t("EXPIRE")}
          </DateText>
        )}
        {applicationDate && (
          <DateText>
            {formatDisplayDate(applicationDate)} {t("APPLY")}
          </DateText>
        )}
        {returnDate && (
          <DateText minus>
            {formatDisplayDate(returnDate)} {t("RETURN")}
          </DateText>
        )}
        {errorDate && (
          <DateText minus>
            {formatDisplayDate(errorDate)} {t("ERROR")}
          </DateText>
        )}
        {moveDate && (
          <DateText minus>
            {formatDisplayDate(moveDate)} {t("MOVE")}
          </DateText>
        )}
      </StyledListItemText>
    </ListItem>
  );
};

const StyledListItemText = styled(ListItemText)({
  margin: 0,
});

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "minus" && propName !== "reverse",
})<{ minus?: boolean; reverse?: boolean }>(({ minus, color, reverse }) => ({
  width: "46px",
  height: "46px",
  backgroundColor: minus ? (!reverse ? COLORS.NEGATIVE : COLORS.PRIMARY) : color,
}));

export const Labels = styled("span")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontWeight: 700,
  lineHeight: 1.4,
  marginBottom: theme.spacing(1),
}));

const Caption = styled("span")(({ theme }) => ({
  display: "block",
  fontSize: "0.875rem",
  lineHeight: 1.4,
  letterSpacing: "0.04em",
  color: COLORS.TEXT_WEAK,
  marginBottom: theme.spacing(1),
}));

export const DateText = styled("span", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "minus",
})<{ minus?: boolean }>(({ theme, minus }) => ({
  display: "block",
  fontSize: "0.875rem",
  lineHeight: 1.4,
  letterSpacing: "0.04em",
  color: minus ? COLORS.DEFICIT : COLORS.TEXT_WEAK,

  "&:not(:last-of-type)": {
    marginBottom: theme.spacing(1),
  },
}));

export const Point = styled("span", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "minus",
})<{ minus?: boolean }>(({ minus }) => ({
  color: minus ? COLORS.DEFICIT : undefined,
  whiteSpace: "nowrap",
  marginLeft: "8px",
}));
