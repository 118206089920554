import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  ButtonOutlinedLink,
  ButtonTextLink,
  Card,
  Container,
  LayoutButton,
  LayoutButtonWithTypography,
  LayoutVertical,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";

import { LoginForm } from "./components/Forms";

export const Login = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Helmet title={t("LOGIN")} />
      <LayoutVertical>
        <Card>
          <Typography variant="h2" gutterBottom>
            {t("LOGIN")}
          </Typography>
          <LoginForm />
        </Card>

        <LayoutButtonWithTypography>
          <Typography>{t("FORGOT_PASSWORD")}</Typography>
          <ButtonOutlinedLink to="/crew_portal/password_reset">{t("RESET_PASSWORD")}</ButtonOutlinedLink>
        </LayoutButtonWithTypography>

        <LayoutButtonWithTypography>
          <Typography>{t("WHO_DO_NOT_HAVE_ACCOUNT")}</Typography>
          <ButtonOutlinedLink to="/crew_portal/register">{t("REGISTER_ACCOUNT")}</ButtonOutlinedLink>
        </LayoutButtonWithTypography>

        <LayoutButton>
          <ButtonTextLink to={SUPPORT_URLS["crew"]["WHO_CHANGED_PHONE_NUMBER"]} target="_blank">
            {t("WHO_CHANGED_PHONE_NUMBER")}
          </ButtonTextLink>
        </LayoutButton>
      </LayoutVertical>
    </Container>
  );
};
