import { AlertTitleProps, AlertTitle as MuiAlertTitle, styled } from "@mui/material";

export type Props = AlertTitleProps & {
  icon?: React.ReactNode;
};

export const AlertTitle: React.FC<Props> = ({ children, icon, ...props }) => {
  return (
    <StyledAlertTitle {...props}>
      {icon}
      {children}
    </StyledAlertTitle>
  );
};

const StyledAlertTitle = styled(MuiAlertTitle)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(2),
  lineHeight: 1.4,
  fontWeight: 400,

  "> svg": {
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
}));
