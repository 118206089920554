import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Container, LayoutGeneralError } from "@/components";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";

export const Error500 = () => {
  const { t } = useTranslation();
  const errorCode = "E500-01";
  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata(errorCode);

  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError title={t(title)} message={t(message)} />
    </Container>
  );
};
