import { IconStar } from "../../components/IconStar/IconStar";

export type Props = {
  rating?: string;
};

export const RatingStars: React.FC<Props> = ({ rating = "0.0" }) => {
  const [whole, fraction] = rating.split(".").map(Number);

  const rates = Array.from({ length: 5 }, (_, i) => (i < whole ? 10 : i === whole ? fraction : 0));

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {rates.map((rate, index) => (
          <IconStar key={index} style={{ marginRight: index < rates.length - 1 ? "4px" : "0" }} rate={rate} />
        ))}
      </div>
    </>
  );
};
