import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  Box,
  ButtonContained,
  ButtonTextLink,
  Chip,
  Container,
  LayoutVertical,
  StepCard,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import getPortalType from "@/utils/getPortalType";

import { MailAddressChangeAuthMailCodeForm } from "./components/MailAddressChange/Forms";

export const MailAddressChangeAuthMailCode = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [newEmail, setNewEmail] = useState("");
  const navigate = useNavigate();
  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  useEffect(() => {
    if (location.state?.newEmail) {
      setNewEmail(location.state?.newEmail);
    }
  }, [location]);

  return (
    <Container>
      <Helmet title={t("CHANGE_EMAIL")} />
      <LayoutVertical>
        <Typography variant="h1">{t("CHANGE_EMAIL")}</Typography>
        <StepCard activestep={3} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_INPUT_CONFIRM_CODE_BY_EMAIL")}
          </Typography>
          {newEmail && (
            <Box textAlign="center" style={{ marginBottom: "24px" }}>
              <Chip label={newEmail} size="medium" />
            </Box>
          )}
          <Typography component="h3" variant="body1">
            {t("CONFIRM_CODE_SENT_BY_EMAIL")}
          </Typography>
          <Box textAlign="center">
            <MailAddressChangeAuthMailCodeForm newEmail={newEmail} />
          </Box>
        </StepCard>

        <Box textAlign="center">
          <ButtonTextLink to={supportUrls["WHO_DO_NOT_RECEIVE_CONFIRM_CODE"]} target="_blank">
            {t("WHO_DO_NOT_RECEIVE_CONFIRM_CODE")}
          </ButtonTextLink>
        </Box>
        <Box textAlign="center">
          <ButtonTextLink to={supportUrls["WHAT_IS_CONFIRM_CODE"]} target="_blank">
            {t("WHAT_IS_CONFIRM_CODE")}
          </ButtonTextLink>
        </Box>

        <Typography component="h3" variant="body1">
          {t("WHO_DO_NOT_RECEIVE_CONFIRM_CODE_OR_CHALLENGE_RESENDING")}
        </Typography>
        <Box textAlign="center">
          <ButtonContained
            type="submit"
            color="primary"
            onClick={async () => {
              navigate(
                ((p): string => (p ? `/${p}_portal/account/mail_address_change/auth_mail` : "/"))(getPortalType())
              );
            }}
            size="medium"
          >
            {t("TRY_AGAIN")}
          </ButtonContained>
        </Box>
      </LayoutVertical>
    </Container>
  );
};
