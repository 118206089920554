export type PointBalance = {
  pointBalance: number;
  expiredAt: string;
};

export type PointSummary = {
  currentPoint: number;
  expirePointBalances: PointBalance[];
  expirePointAmounts: {
    expirePointAmount30Days: number;
    expirePointAmount60Days: number;
    expirePointAmount90Days: number;
    expirePointAmount120Days: number;
    expirePointAmount150Days: number;
    expirePointAmount180Days: number;
  };
};

export const pointSummaryDefaultValues: PointSummary = {
  currentPoint: 0,
  expirePointBalances: [],
  expirePointAmounts: {
    expirePointAmount30Days: 0,
    expirePointAmount60Days: 0,
    expirePointAmount90Days: 0,
    expirePointAmount120Days: 0,
    expirePointAmount150Days: 0,
    expirePointAmount180Days: 0,
  },
};
