export type Props = {
  color?: string;
};

export const IconMoney: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25045 4.97217C1.78313 4.97217 1.4043 5.351 1.4043 5.81832V20.1818C1.4043 20.6491 1.78313 21.0279 2.25045 21.0279H23.7498C24.2171 21.0279 24.596 20.6491 24.596 20.1818V5.81832C24.596 5.351 24.2171 4.97217 23.7498 4.97217H2.25045ZM17.2371 12.2173C17.2371 11.9179 16.9721 11.7195 16.6843 11.7195H14.7729L16.7242 9.76827C16.9223 9.57017 16.8645 9.28123 16.6856 9.10233L16.286 8.70276C16.1071 8.52384 15.8183 8.4666 15.6204 8.6645L13.3346 10.9503L11.0489 8.6645C10.851 8.4666 10.5622 8.52384 10.3832 8.70276L9.98367 9.10233C9.80477 9.28123 9.74698 9.57017 9.94509 9.76827L11.8964 11.7195H9.98492C9.6976 11.7195 9.43218 11.9179 9.43218 12.2173V12.7823C9.43218 13.0815 9.69755 13.2805 9.98492 13.2805H12.5376V14.3955H9.98492C9.6976 14.3955 9.43218 14.5938 9.43218 14.8932V15.4583C9.43218 15.7579 9.69743 15.9565 9.98492 15.9565H12.5376V17.7729C12.5376 18.0443 12.7391 18.2866 13.021 18.2866H13.6147C13.8968 18.2866 14.0986 18.0445 14.0986 17.7729V15.9565H16.6843C16.9723 15.9565 17.2371 15.7579 17.2371 15.4583V14.8932C17.2371 14.5938 16.9721 14.3955 16.6843 14.3955H14.0986V13.2805H16.6843C16.9722 13.2805 17.2371 13.0815 17.2371 12.7823V12.2173Z"
        fill={color}
      />
    </svg>
  );
};
