const prefix = "POINT_TRANSFER_HISTORY_";

export const PointTransferHisotyResources = {
  en: {
    [`${prefix}REGISTERED`]: "Registered",
    [`${prefix}PREPARED`]: "Prepared",
    [`${prefix}CANCELED`]: "Canceled",
    [`${prefix}EXECUTING`]: "Executing",
    [`${prefix}EXECUTED`]: "Executed",
    [`${prefix}FAILED`]: "Failed",
    [`${prefix}BANK_ACCOUNT`]: "Bank transfer",
    RECEIVE_METHOD: "Receive method",
    FEE_FREE: "Free for the first time!",
    FEE_NOT_FREE: "A transfer fee will be charged",
    FEE_BANK_ACCOUNT: "A transfer fee (1P = 1 yen) will be charged for bank transfers.",
    FEE_BANK_ACCOUNT_LESS_30000: "Less than 30,000 yen [220P]",
    FEE_BANK_ACCOUNT_MORE_30000: "30,000 yen or more [330P]",
    FEE_BANK_ACCOUNT_NOTE_1: "The actual account transfer amount is the exchange amount minus the above points.",
    FEE_BANK_ACCOUNT_NOTE_2: "It takes several days to transfer",
    FEE_BANK_ACCOUNT_NOTE_3: "Account registration is required",
    FEE_BANK_ACCOUNT_NOTE_4: "Remittance fees will be waived once on January 1st and July 1st each year.",
    ABOUT_BANK_ACCOUNT: "About bank transfer",
    [`${prefix}NOTHING`]: "No details",
    [`${prefix}ACCOUNT_NOT_APPLICABLE`]: "No applicable account",
    [`${prefix}DIFFERENT_ACCOUNT_NAME`]: "Different account name",
    [`${prefix}DIFFERENT_ACCOUNT_NUMBER`]: "Different account number",
    [`${prefix}DIFFERENT_ACCOUNT_TYPE`]: "Different account type",
    [`${prefix}ACCOUNT_CANCELED`]: "Account canceled",
    [`${prefix}OUT_OF_SERVICE_TIME`]: "Out of service time",
    [`${prefix}OTHERS`]: "Others",
    NOTICE: "Notice",
    [`${prefix}NOTICE_0`]:
      "The destination account is registered on the GMO-PG site.\nPlease enter the account information correctly.",
    [`${prefix}NOTICE_1`]:
      "If a transfer error occurs due to an input error in the destination account, an error fee (220P) * will be charged.",
    [`${prefix}NOTICE_2`]: "* If you apply for free transfer fee, the error fee will be free.",
    [`${prefix}NOTICE_3`]: "If you transfer to the wrong destination, we will not return it.",
    [`${prefix}NOTICE_4`]: 'For more details, please check "About bank transfer".',
    BANK_NAME: "Bank name",
    BRANCH_NAME: "Branch name",
    ACCOUNT_NUMBER: "Account number",
    ACCOUNT_NAME: "Account name",
    CAMPAIGN: "Campaign",
    CURRENT: "Current",
    CHECKING: "Checking",
    SAVINGS: "Savings",
  },
  jp: {
    [`${prefix}REGISTERED`]: "送金準備完了前",
    [`${prefix}PREPARED`]: "認証待ち",
    [`${prefix}CANCELED`]: "キャンセル",
    [`${prefix}EXECUTING`]: "手続き中",
    [`${prefix}EXECUTED`]: "送金完了",
    [`${prefix}FAILED`]: "エラー",
    [`${prefix}BANK_ACCOUNT`]: "銀行振込",
    RECEIVE_METHOD: "受け取り方法",
    FEE_FREE: "送金手数料1回分無料です！",
    FEE_NOT_FREE: "送金手数料がかかります",
    FEE_BANK_ACCOUNT: "銀行振込には交換金額に応じて送金手数料(1P=1円)がかかります。",
    FEE_BANK_ACCOUNT_LESS_30000: "3万円未満の場合【220P】",
    FEE_BANK_ACCOUNT_MORE_30000: "3万円以上の場合【330P】",
    FEE_BANK_ACCOUNT_NOTE_1: "上記ポイントを差し引いた金額が実際の口座振込金額です。",
    FEE_BANK_ACCOUNT_NOTE_2: "振込まで数日かかります",
    FEE_BANK_ACCOUNT_NOTE_3: "振込先口座の登録が必要です",
    FEE_BANK_ACCOUNT_NOTE_4: "送金手数料は、毎年1月1日と7月1日に1回分無料となります。",
    ABOUT_BANK_ACCOUNT: "銀行振込について",
    [`${prefix}NOTHING`]: "詳細情報なし",
    [`${prefix}ACCOUNT_NOT_APPLICABLE`]: "該当口座なし",
    [`${prefix}DIFFERENT_ACCOUNT_NAME`]: "口座名義人相違",
    [`${prefix}DIFFERENT_ACCOUNT_NUMBER`]: "口座番号相違",
    [`${prefix}DIFFERENT_ACCOUNT_TYPE`]: "科目相違",
    [`${prefix}ACCOUNT_CANCELED`]: "口座解約",
    [`${prefix}OUT_OF_SERVICE_TIME`]: "受付時間外エラー",
    [`${prefix}OTHERS`]: "その他",
    NOTICE: "注意事項",
    [`${prefix}NOTICE_0`]: "振込先口座はGMO-PGサイトでの登録になります。\n口座情報は正しく入力してください。",
    [`${prefix}NOTICE_1`]: "振込先口座の入力誤りにより振込エラーが発生した場合は、エラー手数料（220P）*がかかります。",
    [`${prefix}NOTICE_2`]: "※送金手数料無料の申請時は、エラー手数料が無料になります。",
    [`${prefix}NOTICE_3`]: "誤った振込先に振込完了した場合、組み戻し(振込依頼の撤回)はいたしません。",
    [`${prefix}NOTICE_4`]: "より詳細については「銀行振込について」をご確認ください。",
    BANK_NAME: "銀行名",
    BRANCH_NAME: "支店名",
    ACCOUNT_NUMBER: "口座番号",
    ACCOUNT_NAME: "名義人",
    CAMPAIGN: "キャンペーン",
    CURRENT: "普通口座",
    CHECKING: "当座口座",
    SAVINGS: "貯蓄口座",
  },
};
