import { Autocomplete, Box, FilterOptionsState } from "@mui/material";
import { Props, TextField } from "../TextField/TextField";
import { useEffect, useState } from "react";

export const TextFieldEmail: React.FC<Props> = (props) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const optionDomains = Object.freeze([
    // cf. https://j.cocacola.co.jp/member/terms/domain.html
    "@yahoo.co.jp",
    "@gmail.com",
    "@ezweb.ne.jp",
    "@au.com",
    "@docomo.ne.jp",
    "@i.softbank.jp",
    "@softbank.ne.jp",
    "@excite.co.jp",
    "@googlemail.com",
    "@hotmail.co.jp",
    "@hotmail.com",
    "@icloud.com",
    "@live.jp",
    "@me.com",
    "@mineo.jp",
    "@nifty.com",
    "@outlook.com",
    "@outlook.jp",
    "@yahoo.ne.jp",
    "@ybb.ne.jp",
    "@ymobile.ne.jp",
    // others（ユーザ入力値を元に追加）
    "@t.vodafone.ne.jp",
    "@k.vodafone.ne.jp",
    "@ymail.ne.jp",
  ]);

  const filterOptions = (options: string[], state: FilterOptionsState<string>) => {
    // iOS17未満のバージョンでは表示しない（Safariの旧バージョンで発覚）
    const version = Number(/iP(hone|ad|od) OS ([1-9]*)/i.exec(navigator.userAgent)?.[2]) || undefined;
    if (version && version < 17) {
      return [];
    }
    // CSS「direction:rtl」がサポートされていない場合は表示しない
    if (!CSS.supports("direction", "rtl")) {
      return [];
    }
    // 文頭が記号の場合は表示しない（rtl指定の場合、なぜか文頭の記号が右端に表示されてしまう..）
    if (/^[^a-zA-Z0-9]/.test(state.inputValue)) {
      return [];
    }
    // @マーク以降の文字が入力された場合にドメイン候補を表示
    if (/@./.test(state.inputValue)) {
      const localPart = state.inputValue.split("@")[0];
      const newOptions = options.map((option) => localPart + option);
      const matchedOptions = newOptions.filter((option) => option.includes(state.inputValue));
      return matchedOptions;
    }
    return [];
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={optionDomains}
      filterOptions={filterOptions}
      renderOption={(props, option) => (
        <Box
          component={"li"}
          sx={{
            display: "block !important",
            overflow: "hidden",
            textOverflow: "ellipsis",
            direction: "rtl",
            whiteSpace: "nowrap",
          }}
          {...props}
        >
          {option}
        </Box>
      )}
      onInputChange={(_event, newInputValue) => {
        if (props.onChange) {
          const newEvent = {
            target: { value: newInputValue },
          } as React.ChangeEvent<HTMLInputElement>;
          props.onChange(newEvent);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} {...props} value={value} inputProps={{ ...params.inputProps, maxLength: 255 }} />
      )}
    />
  );
};
