import React from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material";

import { LayoutVertical, ModalBasic, ModalBasicProps, Typography } from "@/components";
import { ModalRegisteredImage } from "@/features/common/Top/components";

type Props = Pick<ModalBasicProps, "open" | "onClose">;

export const ModalRegistered: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  return (
    <ModalBasic
      open={open}
      onClose={onClose}
      titleHeader={
        <TitleHeader>
          <ModalRegisteredImage />
        </TitleHeader>
      }
      title={t("REGISTRATION_SUCCESS")}
      actionButton={{
        label: "OK",
        onClick: onClose,
      }}
    >
      <LayoutVertical>
        <Typography>{t("ACCOUNT_REGISTERED_EXAMPLE")}</Typography>
        <Typography>{t("BOOKMARK_THIS_PAGE")}</Typography>
      </LayoutVertical>
    </ModalBasic>
  );
};

const TitleHeader = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(6),
  textAlign: "center",
}));
