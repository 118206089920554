import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  Box,
  ButtonContainedLink,
  ButtonOutlinedLink,
  Card,
  CardAccount,
  type ChipNotificationStatusProps,
  Container,
  Information,
  LayoutButton,
  LayoutVertical,
  ListNotification,
  ListNotificationItem,
  Typography,
  TypographyPoint,
} from "@/components";
import { Loader } from "@/components/Loader/Loader";
import { FetchContext as context } from "@/contexts/FetchContext";
import { ModalRegistered } from "@/features/common/Top/components";
import { useModal } from "@/hooks/useModal";
import type { Notification } from "@/types/notification";
import type { MappingRequest } from "@/types/user";
import { formatNotificationDate } from "@/utils/formatter";
import getPortalType from "@/utils/getPortalType";

import { AlertPointExpiring, CardsPointHistory } from "../Points/components";

export const Top = () => {
  const { state } = useContext(context);
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const portalType = getPortalType();

  // TODO 表示確認用のため適宜修正
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    handleOpen("modal-registered");
  }, []);

  const handleCloseModal = () => {
    handleClose();
    // モーダルの意図しない再描画を停止
    navigate(((p): string => (p ? `/${p}_portal/top` : "/"))(portalType));
  };

  return (
    <Container>
      {state.loading || !state.data?.user.driverCode ? (
        <Loader />
      ) : (
        <>
          <Helmet title={t("TOP")} />

          <LayoutVertical>
            {state.data.pointTransferMethod.bankAccountInfo?.failure && (
              <Information
                onClick={() => {
                  navigate(((p): string => (p ? `/${p}_portal/points/transfer/histories` : "/"))(portalType));
                }}
              >
                {t("POINT_RETURN_NOTICE_TITLE")}
              </Information>
            )}
            <CardAccount
              customerName={state.data.user.driverName}
              customerDriverScore={state.data.user.driverScore}
              infoMessage={
                state.data.user.mappingRequests.some(
                  (mappingRequest: MappingRequest) => mappingRequest.state === "unapplied"
                ) &&
                (state.data.user.mappingRequests.length > 1
                  ? t("COMBINED_APPLICATION_MORE")
                  : t("COMBINED_APPLICATION"))
              }
              enableWelcomeBack={true}
            >
              <LayoutButton sx={{ justifyContent: "flex-end" }}>
                <ButtonOutlinedLink to={((p): string => (p ? `/${p}_portal/account` : "/"))(portalType)}>
                  {t("SEE_MY_PAGE")}
                </ButtonOutlinedLink>
              </LayoutButton>
            </CardAccount>

            <Card>
              <Typography variant="h2" gutterBottom>
                {((p): string => (p ? t(`POINT_EXCHANGE_OF_GO_${p}`) : ""))(portalType.toUpperCase())}
              </Typography>
              <LayoutVertical>
                <Box textAlign="center">
                  <Typography variant="h3">{t("OWNED_POINT")}</Typography>
                  <TypographyPoint point={state.data.pointSummary.currentPoint} />
                </Box>
                <Box>
                  <AlertPointExpiring pointSummary={state.data.pointSummary} />
                  <LayoutButton>
                    <ButtonOutlinedLink
                      to={((p): string => (p ? `/${p}_portal/points` : "/"))(portalType)}
                      color="warning"
                      sx={{ px: "8px" }}
                    >
                      {t("SEE_EXPIRE_POINT")}
                    </ButtonOutlinedLink>
                  </LayoutButton>
                </Box>
                <LayoutButton>
                  <ButtonContainedLink
                    to={((p): string => (p ? `/${p}_portal/points/transfer` : "/"))(portalType)}
                    size="large"
                  >
                    {t("EXCHANGE_POINT")}
                    {state.data.pointTransferMethod.transferFee === 0 && (
                      <Typography variant="caption">{t("ONE_TIME_REMITTANCE_FEE_IS_WAIVED")}</Typography>
                    )}
                  </ButtonContainedLink>
                  <ButtonContainedLink
                    to={((p): string => (p ? `/${p}_portal/points/transfer/histories` : "/"))(portalType)}
                    color="secondary"
                    size="large"
                  >
                    {t("CHECK_APPLICATION_STATUS")}
                  </ButtonContainedLink>
                </LayoutButton>
              </LayoutVertical>
            </Card>
            <Card>
              <Typography variant="h2" gutterBottom>
                {t("EARNED_POINT")}
              </Typography>
              <LayoutVertical>
                <CardsPointHistory pointHistories={state.data.pointHistories} top={true} />
                <LayoutButton>
                  <ButtonOutlinedLink to={((p): string => (p ? `/${p}_portal/points/history` : "/"))(portalType)}>
                    {t("SEE_POINT_HISTORY")}
                  </ButtonOutlinedLink>
                </LayoutButton>
              </LayoutVertical>
            </Card>

            <Card>
              <Typography variant="h2" gutterBottom>
                {t("NOTIFICATION")}
              </Typography>
              <LayoutVertical>
                <ListNotification>
                  {state.data.notifications.map((notification: Notification, index: any) => (
                    <ListNotificationItem
                      key={index}
                      status={notification.flagType as ChipNotificationStatusProps["status"]}
                      date={formatNotificationDate(notification.postedAt)}
                      label={notification.title}
                      to={`/${portalType}_portal/notification/${notification.id}`}
                      notification={notification}
                    />
                  ))}
                </ListNotification>
                <LayoutButton>
                  <ButtonOutlinedLink to={((p): string => (p ? `/${p}_portal/notifications` : "/"))(portalType)}>
                    {t("SEE_MORE_NOTIFICATION")}
                  </ButtonOutlinedLink>
                </LayoutButton>
              </LayoutVertical>
            </Card>
          </LayoutVertical>
        </>
      )}
      {location.state && location.state.status === "REGISTRATION_SUCCESS" && (
        <ModalRegistered open={open("modal-registered")} onClose={handleCloseModal} />
      )}
    </Container>
  );
};
