import { styled } from "@mui/material";

import { List, ListProps } from "@/components";

export type Props = ListProps;

export const ListLink: React.FC<Props> = ({ children, ...props }) => {
  return <StyledList {...props}>{children}</StyledList>;
};

const StyledList = styled(List)(({ theme }) => ({
  "&:first-of-type": {
    marginTop: theme.spacing(-4),
  },
}));
