import { useTranslation } from "react-i18next";

import { ButtonOutlined, ModalLogout } from "@/components";
import { ButtonOutlinedProps } from "@/components/ButtonOutlined";
import { useModal } from "@/hooks/useModal";

export type Props = ButtonOutlinedProps;

export const ButtonLogout: React.FC<Props> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <ButtonOutlined {...props} onClick={() => handleOpen("modal-logout")} disableOnClick={false}>
        {t("LOGOUT")}
        {children}
      </ButtonOutlined>

      <ModalLogout open={open("modal-logout")} onClose={handleClose} />
    </>
  );
};
