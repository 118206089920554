import React from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material";

import { Typography } from "@/components";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";

export type Props = {
  component?: React.ElementType;
};

export const LogoVertical: React.FC<Props> = ({ component }) => {
  const { t } = useTranslation();

  return (
    <Logo component={component}>
      <LogoImage src="/static/img/brands/go.png" alt="GO" />
      <LogoText>{((p): string => (p ? t(`${p}_PORTAL`) : ""))(getPortalType().toUpperCase())}</LogoText>
    </Logo>
  );
};

const Logo = styled(Typography)({
  fontSize: "1.25rem",
  fontWeight: 700,
  lineHeight: 1.5,
  color: COLORS.TEXT_LOGO,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const LogoImage = styled("img")({
  font: "inherit",
  width: "100px",
  height: "auto",
  marginBottom: "8px",
});

const LogoText = styled("span")({
  font: "inherit",
});
