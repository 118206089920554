import { styled } from "@mui/material";

import { List } from "@/components";

export type Props = {
  children: React.ReactNode;
};

export const ListBullet: React.FC<Props> = ({ children }) => {
  return <StyledList>{children}</StyledList>;
};

const StyledList = styled(List)({
  lineHeight: 1.7,
});
