import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { AppBar, LogoHorizontal } from "@/components";

export type Props = {
  children?: React.ReactNode;
};

export const Header: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const logoComponent = useMemo(() => (location.pathname.endsWith("/top") ? "h1" : "p"), [location]);
  return (
    <AppBar position="sticky">
      <LogoHorizontal component={logoComponent} />
      {children}
    </AppBar>
  );
};
