export type Props = {
  color?: string;
};

export const IconInfo: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12.5C22 18.0231 17.5231 22.5 12 22.5C6.47692 22.5 2 18.0231 2 12.5C2 6.97692 6.47692 2.5 12 2.5C17.5231 2.5 22 6.97692 22 12.5ZM11.0908 17.8846C10.9554 17.8846 10.8462 17.7754 10.8462 17.64V13.5131C10.8462 13.3785 10.9554 13.2692 11.0908 13.2692H12.91C13.0446 13.2692 13.1538 13.3785 13.1538 13.5131V17.64C13.1538 17.7754 13.0446 17.8846 12.91 17.8846H11.0908ZM10.4615 9.42308C10.4615 10.2723 11.1508 10.9615 12 10.9615C12.8492 10.9615 13.5385 10.2723 13.5385 9.42308C13.5385 8.57385 12.8492 7.88462 12 7.88462C11.1508 7.88462 10.4615 8.57385 10.4615 9.42308Z"
        fill={color}
      />
    </svg>
  );
};
