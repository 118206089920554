import { styled } from "@mui/material";

export type Props = {
  children: React.ReactNode;
};

export const ListDescription: React.FC<Props> = (props) => {
  return <Container {...props} />;
};

const Container = styled("dl")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  margin: 0,
  padding: 0,
  font: "inherit",
}));
