import { Divider as MuiDivider, styled } from "@mui/material";

export type Props = React.ComponentProps<typeof MuiDivider> & {
  my?: number;
};

export const Divider: React.FC<Props> = (props) => {
  return <StyledDivider {...props} />;
};

const StyledDivider = styled(MuiDivider)<Props>(({ theme, my = 6 }) => ({
  marginTop: theme.spacing(my),
  marginBottom: theme.spacing(my),
}));
