import { To } from "history";
import { useNavigate } from "react-router-dom";

import { ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/material";

import { ListItem } from "@/components";
import { COLORS } from "@/utils/colors";

// ref: https://github.com/mui/material-ui/issues/35922#issuecomment-1482941075
import { ListItemButton } from "../../components/ListItemButton/ListItemButton";
import { ListItemIcon } from "../../components/ListItemIcon/ListItemIcon";
import { ListItemText } from "../../components/ListItemText/ListItemText";

export type Props = {
  to?: To;
  header?: React.ReactNode;
  state?: any;
  children: React.ReactNode;
};

export const ListLinkItem: React.FC<Props> = ({ to, header, state, children }) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (!to) {
      return false;
    }
    navigate(to, { state });
  };
  return (
    <StyledListItem>
      <StyledListItemButton disabled={!to} onClick={onClick}>
        <StyledListItemText>
          {header && <Header>{header}</Header>}
          <Content>{children}</Content>
        </StyledListItemText>
        {to && (
          <StyledListItemIcon>
            <ChevronRight />
          </StyledListItemIcon>
        )}
      </StyledListItemButton>
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)({
  borderBottom: `1px solid ${COLORS.BORDER}`,
});

const StyledListItemButton = styled(ListItemButton)(({ theme, disabled }) => ({
  padding: theme.spacing(4, 0),
  paddingRight: disabled ? theme.spacing(4) : undefined,
  gap: theme.spacing(4),

  "&:hover, &.Mui-focusVisible": {
    backgroundColor: "inherit",
  },
}));

const StyledListItemText = styled(ListItemText)({
  margin: 0,
  lineHeight: 1.4,

  "> *": {
    lineHeight: 1.4,
  },
});

const StyledListItemIcon = styled(ListItemIcon)({
  color: "inherit",
  minWidth: 0,

  "& > .MuiSvgIcon-root": {
    width: "24px",
    height: "24px",
  },
});

const Header = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0, 2),
  fontSize: "0.875rem",
  letterSpacing: "0.04em",
  color: COLORS.TEXT_WEAK,
  marginBottom: theme.spacing(4),
}));

const Content = styled("span")({});
