import { styled } from "@mui/material";

import { ButtonContained, ButtonOutlined, LayoutButton, ModalProps, Modal as MuiModal, Typography } from "@/components";
import { COLORS } from "@/utils/colors";

type ActionButton = {
  label: React.ReactNode;
  disabled?: boolean;
  onClick: VoidFunction;
};

export type Props = Partial<ModalProps> & {
  title: string;
  open: boolean;
  onClose: VoidFunction;
  titleHeader?: React.ReactNode;
  actionButton?: ActionButton;
  weakActionButton?: ActionButton;
};

export const ModalBasic: React.FC<Props> = ({
  title,
  open,
  onClose,
  children,
  titleHeader,
  actionButton,
  weakActionButton,
  ...props
}) => {
  const buttonColor = props.color || COLORS.PRIMARY;

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" {...props}>
      <ModalInner>
        <ModalHeader id="modal-title">
          <>
            {titleHeader}
            <Typography variant="h2">{title}</Typography>
          </>
        </ModalHeader>
        {!!children && <ModalContent>{children}</ModalContent>}
        {(!!actionButton || !!weakActionButton) && (
          <ModalFooter>
            <ModalLayoutButton>
              {actionButton ? (
                <ButtonContained
                  size="large"
                  disabled={actionButton.disabled}
                  onClick={actionButton.onClick}
                  style={{ backgroundColor: buttonColor }}
                >
                  {actionButton.label}
                </ButtonContained>
              ) : (
                <></>
              )}
              {weakActionButton ? (
                <ButtonOutlined size="large" disabled={weakActionButton.disabled} onClick={weakActionButton.onClick}>
                  {weakActionButton.label}
                </ButtonOutlined>
              ) : (
                <></>
              )}
            </ModalLayoutButton>
          </ModalFooter>
        )}
      </ModalInner>
    </Modal>
  );
};

const Modal = styled(MuiModal)({
  ".MuiModal-backdrop": {
    transition: "none !important",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

const ModalInner = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: COLORS.BG_01,
  width: "640px",
  maxWidth: `calc(100% - ${theme.spacing(6 * 2)})`,
  padding: theme.spacing(6),
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  maxHeight: `calc(100% - ${theme.spacing(6 * 2)})`,
  overflowY: "auto",

  "> *:last-child": {
    marginBottom: 0,
  },
}));

const ModalHeader = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const ModalContent = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const ModalFooter = styled("div")({});

const ModalLayoutButton = styled(LayoutButton)(({ theme }) => ({
  marginTop: theme.spacing(-4),
}));
