import { Link } from "react-router-dom";

import { ButtonContained, TargetBlank } from "@/components";
import { ButtonLinkProps } from "@/components/ButtonLink";

export type Props = ButtonLinkProps;

export const ButtonContainedLink: React.FC<Props> = ({ children, ...props }) => {
  return (
    <ButtonContained
      component={Link}
      afterIcon={props.target === "_blank" && <TargetBlank />}
      disableOnClick={false}
      {...props}
    >
      {children}
    </ButtonContained>
  );
};
