import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { ButtonTextLink, Container, LayoutVertical, StepCard, Typography } from "@/components";
import { SUPPORT_URLS } from "@/constants";

import { PasswordResetForm } from "./components/Forms";

export const PasswordReset = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Helmet title={t("RESET_PASSWORD")} />
      <LayoutVertical>
        <StepCard activestep={0} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("RESET_PASSWORD_EXAMPLE")}
          </Typography>
          <Typography component="h3" variant="body1">
            {t("PLEASE_INPUT_EXAMPLE")}
          </Typography>

          <PasswordResetForm />
        </StepCard>
        <ButtonTextLink to={SUPPORT_URLS["crew"]["WHO_CHANGED_PHONE_NUMBER"]} target="_blank">
          {t("WHO_CHANGED_PHONE_NUMBER")}
        </ButtonTextLink>
      </LayoutVertical>
    </Container>
  );
};
