import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Box,
  ButtonContained,
  ButtonTextLink,
  Chip,
  Container,
  LayoutVertical,
  Loader,
  StepCard,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import { FetchContext as context } from "@/contexts/FetchContext";
import getPortalType from "@/utils/getPortalType";

import { MailAddressChangeAuthSmsCodeForm } from "./components/MailAddressChange/Forms";

export const MailAddressChangeAuthSmsCode = () => {
  const { t } = useTranslation();
  const { state } = useContext(context);

  const [isDisabled, setIsDisabled] = useState(false);

  const navigate = useNavigate();

  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  useEffect(() => {
    if (isDisabled) {
      const timer = setTimeout(() => setIsDisabled(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [isDisabled]);

  return (
    <Container>
      <Helmet title={t("CHANGE_EMAIL")} />
      <LayoutVertical>
        {state.loading ? (
          <Loader />
        ) : (
          <>
            <Typography variant="h1">{t("CHANGE_EMAIL")}</Typography>
            <StepCard activestep={1} steplength={5}>
              <Typography variant="h2" gutterBottom>
                {t("PLEASE_INPUT_PIN_CODE_FROM_SMS")}
              </Typography>
              <Box textAlign="center" style={{ marginTop: "24px", marginBottom: "24px" }}>
                <Chip label={state.data.user.maskedPhoneNumber} size="medium" />
              </Box>
              <Typography component="h3" variant="body1">
                {t("PIN_CODE_SENT_TO_SMS_EXMAPLE_1")}
                {t("PIN_CODE_SENT_TO_SMS_EXMAPLE_2")}
              </Typography>
              <Box textAlign="center">
                <MailAddressChangeAuthSmsCodeForm />
              </Box>
            </StepCard>
            <Box textAlign="center">
              <ButtonTextLink to={supportUrls["WHO_DO_NOT_RECEIVE_SMS"]} target="_blank">
                {t("WHO_DO_NOT_RECEIVE_SMS")}
              </ButtonTextLink>
            </Box>
            <Box textAlign="center">
              <ButtonTextLink to={supportUrls["WHAT_IS_PIN_CODE"]} target="_blank">
                {t("WHAT_IS_PIN_CODE")}
              </ButtonTextLink>
            </Box>

            <Typography component="h3" variant="body1">
              {t("WHO_DO_NOT_RECEIVE_PIN_CODE_OR_CHALLENGE_RESENDING")}
            </Typography>
            <Box textAlign="center">
              <ButtonContained
                type="submit"
                color="primary"
                onClick={async () => {
                  navigate(
                    ((p): string => (p ? `/${p}_portal/account/mail_address_change/auth_sms` : "/"))(getPortalType())
                  );
                }}
                size="medium"
              >
                {t("TRY_AGAIN")}
              </ButtonContained>
            </Box>
          </>
        )}
      </LayoutVertical>
    </Container>
  );
};
