import { styled } from "@mui/material";

import { ListItem } from "@/components";

export type Props = {
  children: React.ReactNode;
};

export const ListBulletItem: React.FC<Props> = ({ children }) => {
  return <StyledListItem>{children}</StyledListItem>;
};

const StyledListItem = styled(ListItem)({
  position: "relative",
  paddingLeft: "1.5rem",
  display: "inherit",

  "&::before": {
    content: '""',
    display: "block",
    width: "4px",
    height: "4px",
    borderRadius: "2px",
    backgroundColor: "currentColor",
    position: "absolute",
    top: "0.75rem",
    left: "0.6rem",
  },
});
