import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ModalBasic } from "@/components";

interface ModalContextProps {
  isModalOpen: boolean;
  message: string;
  title: string;
  openModal: (title: string, message: string) => void;
  closeModal: () => void;
}

const ModalContext = React.createContext<ModalContextProps | undefined>(undefined);

const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const openModal = (title: string, message: string) => {
    setTitle(title);
    setMessage(message);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ isModalOpen, message, title, openModal, closeModal }}>
      {children}
      <ModalBasic
        open={isModalOpen}
        onClose={closeModal}
        title={t(title)}
        actionButton={{
          label: t("CLOSE"),
          onClick: closeModal,
        }}
      >
        <>{t(message)}</>
      </ModalBasic>
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
