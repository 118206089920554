import { styled } from "@mui/material";

import { COLORS } from "@/utils/colors";
import { toCommaString } from "@/utils/formatter";

export type Props = {
  point: number;
  gutterBottom?: boolean;
};

export const TypographyPoint: React.FC<Props> = ({ point, gutterBottom }) => {
  const isMinus = point < 0;
  return (
    <StyledTypography gutterBottom={gutterBottom}>
      <Point minus={isMinus}>{toCommaString(point)}</Point>
      <Unit>P</Unit>
    </StyledTypography>
  );
};

const StyledTypography = styled("p", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "gutterBottom",
})<{ gutterBottom?: boolean }>(({ gutterBottom }) => ({
  fontSize: "2rem",
  fontWeight: 700,
  lineHeight: 1.5,
  color: COLORS.TEXT_HEADING,
  textAlign: "center",
  margin: 0,
  marginBottom: gutterBottom ? "1em" : 0,
}));

const Point = styled("span", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "minus",
})<{ minus?: boolean }>(({ minus }) => ({
  letterSpacing: "0.04em",
  color: minus ? COLORS.DEFICIT : undefined,
}));

const Unit = styled("span")({
  fontSize: "1.75rem",
  verticalAlign: "bottom",
});
