import axios, { AxiosError, AxiosResponse } from "axios";

import { API_ENDPOINT, PORTLAND_API_KEY } from "../constants";
import { isProd } from "./env";
import getPortalType from "./getPortalType";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "./localStorage";

const commonSettings = {
  baseURL: API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "X-API-Key": PORTLAND_API_KEY,
  },
  timeout: 5000,
};

const commonInterceptorsRequest = (config: any) => {
  if (!isProd()) {
    const apiEndpoint = getLocalStorageItem("endpoint");
    if (apiEndpoint) {
      config.baseURL = apiEndpoint;
    }
  }

  // X-Driver-Typeを設定 -> NOTE: `/r`アクセス時は判定できないため毎回セットし直す必要がある
  config.headers["X-Driver-Type"] = getPortalType();

  return config;
};

// axiosInstance
const axiosInstance = axios.create(commonSettings);
axiosInstance.interceptors.request.use(commonInterceptorsRequest);

// axiosAccountInstance
const axiosAccountInstance = axios.create(commonSettings);
axiosAccountInstance.interceptors.request.use(commonInterceptorsRequest);
axiosAccountInstance.interceptors.request.use((config: any) => {
  const token = getLocalStorageItem("accountToken");
  if (token) {
    axiosAccountInstance.defaults.headers.common["X-CrewPortal-Account-Authorization"] = `Bearer ${token}`;
    config.headers["X-CrewPortal-Account-Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosAccountInstance.defaults.headers.common["X-CrewPortal-Account-Authorization"];
  }
  return config;
});

axiosAccountInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<any>) => {
    if (error.response?.status === 401) {
      if (error.response.data?.error_code !== "E401-05") {
        removeLocalStorageItem("accountToken");
        delete axiosAccountInstance.defaults.headers.common["X-CrewPortal-Account-Authorization"];
        // GA4: driver_idをnullでセット
        window.dataLayer.push({
          driver_id: null,
        });
      }
    }
    return Promise.reject(error);
  }
);

// axiosAuthInstance
const axiosAuthInstance = axios.create(commonSettings);
axiosAuthInstance.interceptors.request.use(commonInterceptorsRequest);
axiosAuthInstance.interceptors.request.use((config: any) => {
  const token = getLocalStorageItem("authToken");
  if (token) {
    axiosAuthInstance.defaults.headers.common["X-CrewPortal-Auth-Authorization"] = `Bearer ${token}`;
    config.headers["X-CrewPortal-Auth-Authorization"] = `Bearer ${token}`;
  }

  return config;
});

axiosAuthInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data?.auth_token) {
      setLocalStorageItem("authToken", response.data?.auth_token);
    }
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      removeLocalStorageItem("authToken");
      delete axiosAuthInstance.defaults.headers.common["X-CrewPortal-Auth-Authorization"];
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, axiosAccountInstance, axiosAuthInstance };
