import { CacheProvider } from "@emotion/react";
import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useRoutes } from "react-router-dom";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import getPortalType from "@/utils/getPortalType";

import { AuthProvider } from "./contexts/JWTContext";
import "./i18n";
import routes from "./routes";
import { theme } from "./theme";
import createEmotionCache from "./utils/createEmotionCache";

const clientSideEmotionCache = createEmotionCache();

const App = ({ emotionCache = clientSideEmotionCache }) => {
  const content = useRoutes(routes);
  const { t } = useTranslation();
  const location = useLocation();
  const portalTypeUpper = getPortalType().toUpperCase();

  // URLが変更されたときにページの一番上にスクロールする副作用
  // biome-ignore lint/correctness/useExhaustiveDependencies: need to watch location
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate={!portalTypeUpper ? t("%s") : `${t("%s")} | ` + t(`DEFAULT_${portalTypeUpper}_TITLE`)}
          defaultTitle={t(`DEFAULT_${portalTypeUpper}_TITLE`)}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={theme}>
            <AuthProvider>{content}</AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
};

export default App;
