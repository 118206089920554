import getPortalType from "@/utils/getPortalType";

import * as Sentry from "@sentry/react";

export const getLocalStorageItem = (key: string) => {
  try {
    if (!navigator.cookieEnabled) {
      if (!window.location.pathname.endsWith("/error")) {
        const portalType = getPortalType();
        window.location.href = `/${portalType}_portal/error?status=COOKIE_DISABLED`;
      }
      return null;
    }
    return window.localStorage.getItem(key);
  } catch (error: any) {
    // localStorageが使えないブラウザの場合SentryにInfoログとしてエラーを送信し、nullを返す
    Sentry.captureMessage(
      `Failed to get item from localStorage: ${
        error?.message || JSON.stringify(error, Object.getOwnPropertyNames(error))
      }`,
      "info"
    );
    return null;
  }
};

export const setLocalStorageItem = (key: string, value: string) => {
  try {
    if (!navigator.cookieEnabled) {
      if (!window.location.pathname.endsWith("/error")) {
        const portalType = getPortalType();
        window.location.href = `/${portalType}_portal/error?status=COOKIE_DISABLED`;
      }
      return;
    }
    window.localStorage.setItem(key, value);
    // biome-ignore suppressions/unused: need not to watch no-empty
  } catch (error: any) {
    // localStorageが使えないブラウザの場合SentryにInfoログとしてエラーを送信
    Sentry.captureMessage(
      `Failed to set item in localStorage: ${
        error?.message || JSON.stringify(error, Object.getOwnPropertyNames(error))
      }`,
      "info"
    );
  }
};

export const removeLocalStorageItem = (key: string) => {
  try {
    if (!navigator.cookieEnabled) {
      if (!window.location.pathname.endsWith("/error")) {
        const portalType = getPortalType();
        window.location.href = `/${portalType}_portal/error?status=COOKIE_DISABLED`;
      }
      return;
    }
    window.localStorage.removeItem(key);
    // biome-ignore suppressions/unused: need not to watch no-empty
  } catch (error: any) {
    // localStorageが使えないブラウザの場合SentryにInfoログとしてエラーを送信
    Sentry.captureMessage(
      `Failed to remove item from localStorage: ${
        error?.message || JSON.stringify(error, Object.getOwnPropertyNames(error))
      }`,
      "info"
    );
  }
};
