import { Switch as MuiSwitch, styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

export type Props = React.ComponentProps<typeof MuiSwitch>;

export const Switch: React.FC<Props> = (props) => {
  return <StyledSwitch color="secondary" {...props} />;
};

const StyledSwitch = styled(MuiSwitch)({
  ".MuiSwitch-switchBase:not(.Mui-checked)": {
    color: COLORS.BG_02,
  },
  ".MuiSwitch-thumb": {
    boxShadow: "0 2px 1px 0 rgba(0, 0, 0, 0.2)",
  },
  ".MuiSwitch-track": {
    backgroundColor: "rgba(220, 220, 220, 1)",
    opacity: 1,
  },
});
