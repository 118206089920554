import { PaletteColor, TypeAction, TypeBackground, TypeText } from "@mui/material";

import { COLORS } from "@/utils/colors";

export const defaultVariant: VariantType = {
  palette: {
    primary: {
      main: COLORS.PRIMARY,
      contrastText: COLORS.TEXT_02,
    },
    secondary: {
      main: COLORS.SECONDARY,
      contrastText: COLORS.TEXT_02,
    },
    warning: {
      main: COLORS.WARNING,
      contrastText: COLORS.TEXT_02,
    },
    error: {
      main: COLORS.ALERT,
      contrastText: COLORS.TEXT_02,
    },
    background: {
      default: COLORS.BG_02,
      paper: COLORS.BG_01,
    },
    text: {
      primary: COLORS.TEXT_01,
      secondary: COLORS.TEXT_WEAK,
      disabled: COLORS.DISABLED,
    },
    action: {
      disabled: COLORS.TEXT_02,
      disabledBackground: COLORS.DISABLED,
      disabledOpacity: 1,
      hoverOpacity: 0,
    },
  },
};

export type VariantType = {
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
    warning: MainContrastTextType;
    error: MainContrastTextType;
    background: TypeBackground;
    text: TypeText;
    action: Partial<TypeAction>;
  };
};

type MainContrastTextType = Pick<PaletteColor, "main" | "contrastText">;
