import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Container, LayoutGeneralError } from "@/components";
import { SUPPORT_URLS } from "@/constants";
import getPortalType from "@/utils/getPortalType";

const errorTypes = ["SYSTEM", "COOKIE_DISABLED"];

export const ErrorSystem = () => {
  const { t } = useTranslation();
  const portalType = getPortalType();
  const [errorTypeFromUrl, setErrorTypeFromUrl] = useState("SYSTEM");

  const footerButtons = useMemo(() => {
    return !!portalType && errorTypeFromUrl !== "SYSTEM"
      ? [
          {
            title: t(`WHAT_IS_${errorTypeFromUrl}`),
            label: t(`WHAT_IS_${errorTypeFromUrl}`),
            to: (SUPPORT_URLS[portalType] as Record<string, string>)[`WHAT_IS_${errorTypeFromUrl}`],
          },
        ]
      : [];
  }, [t, errorTypeFromUrl, portalType]);

  useEffect(() => {
    // クエリストリングにstatusがある場合それをerrorTypeにセット
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    if (status && errorTypes.includes(status)) {
      setErrorTypeFromUrl(status);
    }
  }, []);

  return (
    <Container>
      <Helmet title={t(`ERROR_CODE_${errorTypeFromUrl}_TITLE`)} />
      <LayoutGeneralError
        title={t(`ERROR_CODE_${errorTypeFromUrl}_TITLE`)}
        message={t(`ERROR_CODE_${errorTypeFromUrl}_MESSAGE`)}
        footerButtons={footerButtons ? footerButtons : undefined}
      />
    </Container>
  );
};
