import { APP_DEEPLINK } from "@/constants";

export const getErrorCodePath = (error_code: string, path: string) => {
  const errorCodes = ["E401-02", "E401-03", "E401-04", "E401-06"];
  const appendedPath = errorCodes.includes(error_code) ? "" : path;
  return `${error_code}${appendedPath}`;
};

export const navigateToAppStore = () => {
  const isIOS = navigator.userAgent.match(/Mac OS/i);
  const isAndroid = navigator.userAgent.match(/Android/i);

  const appStoreUrl = "https://apps.apple.com/jp/app/id6476978696";
  const playStoreUrl = "https://play.google.com/store/apps/details?id=jp.goinc.godriverapp";

  try {
    // Deep Linkを開く
    window.location.href = APP_DEEPLINK;
  } catch (_e) {
    // iPhoneのSafariの場合、Deep Linkの起動に失敗したときはcatchできる
    const isiPhoneSafari =
      isIOS &&
      navigator.userAgent.match(/\(KHTML, like Gecko\) Version/i) &&
      !navigator.userAgent.match(/Edge|Smooz|Twitter|Echofon|LINE/i);
    if (isiPhoneSafari) {
      window.location.href = appStoreUrl;
    }
  }

  setTimeout(() => {
    // フォーカスが戻ってきた場合、Deep Linkの起動に失敗したと判断
    if (document.hasFocus()) {
      if (isIOS) {
        window.location.href = appStoreUrl;
      } else if (isAndroid) {
        window.location.href = playStoreUrl;
      } else {
        window.location.href = "/driver_portal/mobile_device_warning";
      }
    }
  }, 500); // 500ミリ秒後にチェック
};
