import { styled } from "@mui/material";

import { Collapse, TableCell, TableRow } from "@/components";
import { COLORS } from "@/utils/colors";

export type Props = {
  colSpan?: number;
  open: boolean;
  children: React.ReactNode;
};

export const TableExpandContent: React.FC<Props> = ({ colSpan, open, children }) => {
  return (
    <TableRow>
      <StyledTableCell colSpan={colSpan}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </StyledTableCell>
    </TableRow>
  );
};

const StyledTableCell = styled(TableCell)({
  padding: 0,
  height: 0,
  border: "none",
  backgroundColor: COLORS.BG_03,
});
