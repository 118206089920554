export const PointTransferResources = {
  en: {
    BACK: "Back",
    EXCHANGE_POINTS_AMOUNT_MIN: "Please enter 1 point or more.",
    EXCHANGE_POINTS_AMOUNT_MIN_FEES: "Please enter points that exceed the transfer fee.",
    EXCHANGE_POINTS_AMOUNT_MAX: "Please enter 100,000 points or less.",
    EXCHANGE_POINTS_AMOUNT_MAX_OWNED: "Please enter points that are less than or equal to the owned points.",
    EXCHANGE_POINTS_AMOUNT_INTEGER: "Please enter a half-width number.",
    EXCHANGE_POINTS_ALL: "Exchange all owned points",
    EXCHANGE_POINTS_AMOUNT: "Enter the points to exchange",
    CHANGE_BANK_ACCOUNT: "Change the transfer destination",
    AGREE_TO_BANK_TRANSFER_NOTICE: "Agree to the bank transfer notice",
    AGREE: "Agree",
    DISAGREE: "Disagree",
    AGREEMENT_REQUIRED_BANK_ACCOUNT:
      "Please confirm and agree to the precautions for registering a bank transfer account.",
    AGREEMENT_REQUIRED_BANK_TRANSFER: "Please confirm and agree to the precautions for bank transfers.",
    POINT_TRANSFER_CONFIRM: "Point transfer application confirmation",
    POINT_TRANSFER_CONTENTS: "Application contents",
    EXCHANGE_POINT_AMOUNT: "Exchange points",
    TRANSFER_AMOUNT: "Transfer amount",
    RESEND_AUTH_CODE_VIA_BELOW_BUTTON:
      "If you do not receive the authentication code, please resend the authentication code using the button below.",
    USER_NUMBER: "User number",
    NEED_RESEND: "Do you want to resend it?",
    ASK_RESENDING_PIN_CODE: "Resend the authentication code",
    RESEND_PIN_CODE: "Resend the authentication code",
    ASK_RESENDING_CANCEL_APPLICATION: "Do you want to cancel the point transfer application?",
    YOU_NEED_TO_RESTART: "You need to start over to reapply.",
    POINT_TRANSFER_APPLICATION_ACCEPTED: "Point transfer application accepted",
    APPLY_POINT_TRANSFER: "Apply for point transfer",
    POINT_TRANSFER_NOTICE_1: "You can exchange 1P = 1 yen.",
    POINT_TRANSFER_NOTICE_2: "The maximum number of points per day is 100,000.",
    GO_TO_BANK_ACCOUNT_REGISTRATION: "Go to bank account registration",
    PLEASE_SEND_USER_NUMBER_TO_REGISTER_BANK_ACCOUNT: "Please send the user number to register the account.",
    BANK_TRANSFER_REQUIRES_BANK_ACCOUNT_REGISTRATION:
      "Bank transfer requires registration of the transfer destination account before applying for point transfer.",
    BANK_ACCOUNT_REGISTRATION_REQUIRED_TO_CHANGE_BANK_ACCOUNT:
      "Re-registration is also required to change the transfer destination.",
    USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT_FORMER: "To register the transfer destination account, ",
    USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT_LATTER: " is required.",
    PLEASE_SEND_USER_NUMBER_TO_REGISTER_PHONE_NUMBER:
      "Please send the user number to the mobile phone number via SMS (message) using the button below.",
    PLEASE_SEND_USER_NUMBER_TO_REGISTER_EMAIL:
      "Please send the user number to the registered email address using the button below.",
    SEND_USER_NUMBER: "Send user number",
    USER_NUMBER_WILL_BE_SENT_VIA_SMS: "It will be sent via SMS (message) on your mobile phone.",
    USER_NUMBER_WILL_BE_SENT_VIA_EMAIL: "It will be sent to the registered email address.",
    USER_NUMBER_SENT: "Your user number has been sent",
    PLEASE_CHECK_USER_NUMBER_AND_GO_TO_BANK_ACCOUNT_REGISTRATION:
      "Please check the user number and proceed to register the transfer destination account.",
    NOTICE_USER_NUMBER_SENT_VIA_SMS:
      "The user number has been sent to the registered mobile phone number via SMS (message).",
    NOTICE_USER_NUMBER_SENT_VIA_EMAIL: "The user number has been sent to the registered email address.",
    WHAT_IS_USER_NUMBER: "What is a user number?",
    WHO_NEEDS_RESENDING_USER_NUMBER: "Click here if you need to resend",
    BANK_ACCOUNT_REGISTRATION_COMPLETED: "Bank account registration completed",
    BANK_ACCOUNT_REGISTRATION_COMPLETED_MESSAGE:
      "If you want to continue exchanging points, please proceed to point transfer application below.",
    BANK_ACCOUNT_REGISTRATION_FAILED: "An error has occurred",
    BANK_ACCOUNT_REGISTRATION_TITLE: "Could not register bank account",
    BANK_ACCOUNT_REGISTRATION_FAILED_MESSAGE: "Sorry, please start over again.",
    EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY_NOTICE:
      "You have reached the maximum number of points per day. Please try again tomorrow.",
    EXCHANGE_POINTS_AMOUNT_MIN_FEES_NOTICE:
      "You cannot exchange points because you have 0P or less than the transfer fee.",
    EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY_BUTTON: "You have reached the maximum number of points per day",
    EXCHANGE_POINTS_AMOUNT_MIN_FEES_BUTTON: "You do not have enough points",
    EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY:
      "The maximum number of points that can be exchanged per day is 100,000 points. Please enter % or less points.",
    EXPIRES_ON: "Expires on %",
    WITHIN_WEEK: "Within % weeks",
    WITHIN_DAY: "Within % days",
    IN_TOTAL: "Total %P",
    POINT_RETURN_NOTICE_TITLE: "Notice of point return (transfer failure)",
    REGISTERED: "Registered",
    PREPARED: "Prepared",
    EXECUTING: "Executing",
    EXECUTED: "Executed",
    CANCELED: "Canceled",
    FAILED: "Failed",
    USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT:
      "The user number is required to register the transfer destination account.",
    PIN_CODE_SENT_TO_EMAIL_EXMAPLE_1: "The pin code has been sent to the registered email address.",
    PIN_CODE_SENT_TO_EMAIL_EXMAPLE_2: "Please enter the 4-digit pin code within 30 minutes.",
  },
  jp: {
    BACK: "戻る",
    EXCHANGE_POINTS_AMOUNT_MIN: "1以上のポイントを入力してください。",
    EXCHANGE_POINTS_AMOUNT_MIN_FEES: "送金手数料を含む必要があるため221以上のポイントを入力してください。",
    EXCHANGE_POINTS_AMOUNT_MAX: "10万以下のポイントを入力してください。",
    EXCHANGE_POINTS_AMOUNT_MAX_OWNED: "保有ポイント以下のポイントを入力してください。",
    EXCHANGE_POINTS_AMOUNT_INTEGER: "半角数字で入力してください。",
    EXCHANGE_POINTS_ALL: "全保有ポイントを交換する",
    EXCHANGE_POINTS_AMOUNT: "交換するポイントを入力する",
    CHANGE_BANK_ACCOUNT: "振込先を変更する",
    AGREE_TO_BANK_ACCOUNT_NOTICE: "振込先口座登録の注意事項に同意する",
    AGREE_TO_BANK_TRANSFER_NOTICE: "銀行振込の注意事項に同意する",
    AGREE: "同意する",
    DISAGREE: "同意しない",
    AGREEMENT_REQUIRED_BANK_ACCOUNT: "振込先口座登録の注意事項を確認の上、同意してください",
    AGREEMENT_REQUIRED_BANK_TRANSFER: "銀行振込の注意事項を確認の上、同意してください",
    POINT_TRANSFER_CONFIRM: "ポイント交換申請内容確認",
    POINT_TRANSFER_CONTENTS: "申請内容",
    EXCHANGE_POINT_AMOUNT: "交換ポイント",
    TRANSFER_AMOUNT: "振込金額",
    RESEND_AUTH_CODE_VIA_BELOW_BUTTON: "認証コードが届かない場合は、下記ボタンより認証コードを再送信してください。",
    USER_NUMBER: "ユーザー番号",
    NEED_RESEND: "再送信してもよろしいですか？",
    ASK_RESENDING_PIN_CODE: "認証コードを再送信します",
    RESEND_PIN_CODE: "認証コードを再送信する",
    ASK_RESENDING_CANCEL_APPLICATION: "このままポイント交換申請をやめてもよろしいですか？",
    YOU_NEED_TO_RESTART: "再申請するには最初からやり直しが必要です。",
    POINT_TRANSFER_APPLICATION_ACCEPTED: "ポイント交換申請を受付けました",
    APPLY_POINT_TRANSFER: "ポイント交換を申請する",
    POINT_TRANSFER_NOTICE_1: "1P=1円に交換できます。",
    POINT_TRANSFER_NOTICE_2: "1日の交換上限ポイントは10万ポイントです。",
    GO_TO_BANK_ACCOUNT_REGISTRATION: "口座登録に進む",
    PLEASE_SEND_USER_NUMBER_TO_REGISTER_BANK_ACCOUNT: "口座登録のためユーザー番号を発行してください",
    BANK_TRANSFER_REQUIRES_BANK_ACCOUNT_REGISTRATION: "銀行振込にはポイント交換申請の前に振込先口座の登録が必要です",
    BANK_ACCOUNT_REGISTRATION_REQUIRED_TO_CHANGE_BANK_ACCOUNT: "振込先を変更する場合も再登録が必要です。",
    USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT_FORMER: "振込先口座の登録用に",
    USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT_LATTER: "の発行が必要です。",
    PLEASE_SEND_USER_NUMBER_TO_REGISTER_PHONE_NUMBER:
      "下記ボタンより携帯電話番号のSMS(メッセージ)宛にユーザー番号を発行してください。",
    PLEASE_SEND_USER_NUMBER_TO_REGISTER_EMAIL: "下記ボタンよりメールアドレス宛にユーザー番号を発行してください。",
    SEND_USER_NUMBER: "ユーザー番号を発行する",
    USER_NUMBER_WILL_BE_SENT_VIA_SMS: "携帯電話のSMS(メッセージ)で届きます",
    USER_NUMBER_WILL_BE_SENT_VIA_EMAIL: "登録済みのメールアドレスに届きます",
    USER_NUMBER_SENT: "ユーザー番号を送信しました",
    PLEASE_CHECK_USER_NUMBER_AND_GO_TO_BANK_ACCOUNT_REGISTRATION:
      "ユーザー番号を確認の上、振込先口座の登録へ進んでください",
    NOTICE_USER_NUMBER_SENT_VIA_SMS: "ユーザー番号は、登録済みの携帯電話番号のSMS(メッセージ)に送信しています。",
    NOTICE_USER_NUMBER_SENT_VIA_EMAIL: "ユーザー番号は、登録済みのメールアドレスに送信しています。",
    WHAT_IS_USER_NUMBER: "ユーザー番号とは何ですか?",
    WHO_NEEDS_RESENDING_USER_NUMBER: "ユーザー番号を再送信する方",
    BANK_ACCOUNT_REGISTRATION_COMPLETED: "振込先口座を登録しました",
    BANK_ACCOUNT_REGISTRATION_COMPLETED_MESSAGE:
      "引き続きポイント交換をする場合は、下記よりポイント交換申請を行ってください。",
    BANK_ACCOUNT_REGISTRATION_FAILED: "エラーが発生しました",
    BANK_ACCOUNT_REGISTRATION_TITLE: "振込先口座を登録できませんでした",
    BANK_ACCOUNT_REGISTRATION_FAILED_MESSAGE: "お手数ですが、もう一度はじめからやり直しをお願いします。",
    EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY_NOTICE:
      "1日当たりの交換上限ポイントに達しています。お手数ですが、明日以降に実施してください。",
    EXCHANGE_POINTS_AMOUNT_MIN_FEES_NOTICE: "保有ポイントが0Pまたは手数料以下のため交換できません。",
    EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY_BUTTON: "1日当たりの交換上限ポイントに達しています",
    EXCHANGE_POINTS_AMOUNT_MIN_FEES_BUTTON: "保有ポイントが不足しています",
    EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY:
      "1日に交換可能なポイントは10万ポイントまでです。%以下のポイントを入力してください。",
    EXPIRES_ON: "%に失効",
    WITHIN_WEEK: "%週間以内",
    WITHIN_DAY: "%日以内",
    IN_TOTAL: "計%P",
    POINT_RETURN_NOTICE_TITLE: "ポイント返却のお知らせ（振込失敗）",
    REGISTERED: "送金準備完了前",
    PREPARED: "認証待ち",
    EXECUTING: "手続き中",
    EXECUTED: "送金完了",
    CANCELED: "キャンセル",
    FAILED: "エラー",
    USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT:
      "ユーザー番号は次の振込先口座登録を行うGMO-PGサイトにて必要になります。",
    PIN_CODE_SENT_TO_EMAIL_EXMAPLE_1: "認証コードを登録済みのメールアドレスに送信しました。",
    PIN_CODE_SENT_TO_EMAIL_EXMAPLE_2: "認証コード4桁の数字を30分以内に入力してください。",
  },
};
