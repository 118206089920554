import { styled } from "@mui/material";

export type Props = {
  sizes: number[];
};

export const TableCols: React.FC<Props> = ({ sizes }) => {
  return (
    <colgroup>
      {sizes.map((size, index) => (
        <StyledCol key={index} size={size} />
      ))}
    </colgroup>
  );
};

const StyledCol = styled("col", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "size",
})<{ size: number }>(({ size }) => ({
  width: `${size}%`,
}));
