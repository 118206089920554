import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Container, LayoutGeneralError } from "@/components";
import { SUPPORT_URL, SUPPORT_URLS } from "@/constants";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";
import getPortalType from "@/utils/getPortalType";

const button = {
  "E409-01/api/account/mail_address_change/auth_mail": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/mail_address_change/auth_mail",
    codeType: "MAIL_ADDRESS_CHANGE",
  },
  "E409-01/api/account/phone_number_change/auth_sms": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/phone_number_change/auth_sms",
    codeType: "PHONE_NUMBER_CHANGE",
  },
  "E409-01/api/register/auth_mail": {
    message: "LOGIN",
    link: "/$portalType_portal/login",
    codeType: "ACCOUNT_REGISTRATION",
  },
};

export const Error409 = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const errorCode = location.state?.errorCode || "";

  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata(errorCode);
  const portalType = getPortalType();

  const buttonMessage = button[errorCode as keyof typeof button]?.message || "";
  const buttonLink = button[errorCode as keyof typeof button]?.link.replace("$portalType", portalType) || "";
  const codeType = button[errorCode as keyof typeof button]?.codeType;

  const primaryButton =
    buttonLink && buttonMessage
      ? {
          label: t(buttonMessage),
          to: buttonLink,
        }
      : undefined;
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  const footerButtons = useMemo(() => {
    return [
      {
        title: t(`WHAT_IS_${codeType}`),
        label: t(`WHAT_IS_${codeType}`),
        to:
          codeType === undefined || !(SUPPORT_URLS[portalType] as Record<string, string>)[`WHAT_IS_${codeType}`]
            ? SUPPORT_URL
            : (SUPPORT_URLS[portalType] as Record<string, string>)[`WHAT_IS_${codeType}`],
      },
    ];
  }, [t, codeType]);

  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError
        title={t(title)}
        message={t(message)}
        primaryButton={primaryButton}
        footerButtons={footerButtons}
      />
    </Container>
  );
};
