import { useMemo } from "react";

import { ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

export type Props = {
  active: number;
  length: number;
  gutterBottom?: boolean;
};

export const Steps: React.FC<Props> = ({ active, length, gutterBottom }) => {
  const steps = useMemo(() => [...Array(length)].map((_, i) => i + 1), [length]);
  return (
    <Wrapper gutterBottom={gutterBottom}>
      <StepList>
        {steps.map((item) => (
          <StepListItem key={`step${item}`} active={active === item}>
            <span>{item}</span>
            {item !== 1 && <Arrow />}
          </StepListItem>
        ))}
      </StepList>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ gutterBottom?: boolean }>(({ theme, gutterBottom }) => ({
  marginBottom: !gutterBottom ? 0 : theme.spacing(6),
}));

const StepList = styled("ol")(({ theme }) => ({
  listStyle: "none",
  margin: 0,
  padding: 0,
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: theme.spacing(2, 0),
}));

const StepListItem = styled("li")<{ active: boolean }>(({ active }) => ({
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.875rem",
  letterSpacing: "0.01em",
  width: "1.25rem",
  height: "1.25rem",
  borderRadius: "50%",
  color: COLORS.TEXT_02,
  backgroundColor: active ? COLORS.PRIMARY : COLORS.DISABLED,

  "&:not(:first-of-type)": {
    marginLeft: "28px",
  },
}));

const Arrow = styled(ChevronRight)({
  color: COLORS.DISABLED,
  width: "20px",
  height: "20px",
  position: "absolute",
  top: "50%",
  left: "-23px",
  marginTop: "-10px",
});
