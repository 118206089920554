export type Props = {
  color?: string;
};

export const IconCheck: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5231 22 22 17.5231 22 12C22 6.47692 17.5231 2 12 2C6.47692 2 2 6.47692 2 12C2 17.5231 6.47692 22 12 22ZM15.5706 8.42853L17 9.881L12.3302 14.3063L12.3349 14.3109L11.2835 15.3427C11.0594 15.5626 10.7005 15.5626 10.4764 15.3427L7 11.9313L8.45498 10.5035L10.875 12.8783L15.5706 8.42853Z"
        fill={color}
      />
    </svg>
  );
};
