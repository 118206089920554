import { styled } from "@mui/material";

type StyleProps = {
  align?: "left" | "center" | "right";
};

export type Props = StyleProps & {
  children: string;
};

export const TableCaption: React.FC<Props> = (props) => {
  return <Caption {...props} />;
};

const Caption = styled("caption", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "align",
})<StyleProps>(({ theme, align }) => ({
  ".MuiTable-root &": {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.4,
    letterSpacing: "0.04em",
    color: "inherit",
    textAlign: align || undefined,
    padding: theme.spacing(1, 4),
  },
}));
