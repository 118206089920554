import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Container, LayoutGeneralError } from "@/components";
import { SUPPORT_URLS } from "@/constants";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";
import getPortalType from "@/utils/getPortalType";

const button = {
  "E423-01/api/login": {
    message: "RESET_PASSWORD",
    link: "/$portalType_portal/password_reset",
  },
  "E423-01/api/driver/login": {
    message: "RESET_PASSWORD",
    link: "",
  },
  "E423-01/api/password_reset/auth_mail_code": {
    message: "RETURN_TO_LOGIN",
    link: "",
  },
  "E423-01/api/register/auth_code": {
    message: "TO_ACCOUNT_REGISTRATION",
    link: "",
  },
  "E423-01/api/account/mail_address_change/auth_sms_code": {
    message: "TO_MY_PAGE",
    link: "/$portalType_portal/account",
  },
  "E423-01/api/account/phone_number_change/auth_mail_code": {
    message: "TO_MY_PAGE",
    link: "/$portalType_portal/account",
  },
  "E423-01/api/account/password_change/auth_mail_code": {
    message: "TO_MY_PAGE",
    link: "/$portalType_portal/account",
  },
  "E423-01/api/point/transfer/auth_sms_code": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
  },
  "E423-01/api/point/transfer/auth_mail_code": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
  },
  "E423-01/api/mapping_request/auth_code": {
    message: "TO_MY_PAGE",
    link: "/$portalType_portal/account",
  },
};

export const Error423 = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const errorCode = location.state?.errorCode || "";

  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata(errorCode);
  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  const buttonLink = button[errorCode as keyof typeof button]?.link.replace("$portalType", portalType) || "";
  const buttonMessage = button[errorCode as keyof typeof button]?.message || "";

  const primaryButton =
    buttonLink && buttonMessage
      ? {
          label: t(buttonMessage),
          to: buttonLink,
        }
      : undefined;
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  const footerButtons = useMemo(() => {
    return [
      errorCode === "E423-01/api/register/auth_code"
        ? {
            title: t("WHAT_IS_ACCOUNT_REGISTRATION"),
            label: t("WHAT_IS_ACCOUNT_REGISTRATION"),
            to: supportUrls["WHAT_IS_ACCOUNT_REGISTRATION"],
          }
        : {
            title: t("WHAT_IS_PIN_CODE"),
            label: t("WHAT_IS_PIN_CODE"),
            to: supportUrls["WHAT_IS_PIN_CODE"],
          },
    ];
  }, [t]);

  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError
        title={t(title)}
        message={t(message)}
        primaryButton={primaryButton}
        footerButtons={footerButtons}
      />
    </Container>
  );
};
