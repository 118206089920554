import { yupResolver } from "@hookform/resolvers/yup";
import type { AxiosResponse } from "axios";
import { AxiosError } from "axios";
import camelcaseKeys from "camelcase-keys";
import { useEffect, useReducer, useState } from "react";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  ButtonContained,
  ButtonContainedLink,
  ButtonOutlinedLink,
  Card,
  Container,
  FormControl,
  LayoutButton,
  LayoutVertical,
  Loader,
  Radios,
  Steps,
  TextFieldWithUnit,
  Typography,
  TypographyPoint,
} from "@/components";
import { FetchReducer, FetchContext as context, initialState } from "@/contexts/FetchContext";
import { removeAuthToken } from "@/contexts/JWTContext";
import { AlertPointExpiring, CardReceiveMethod } from "@/features/common/Points/components";
import { BankTable } from "@/features/common/Points/components";
import { useNavigationError } from "@/hooks/useNavigationError";
import { ErrorResponse } from "@/types/errorResponse";
import type { PointSummary } from "@/types/pointSummary";
import { pointSummaryDefaultValues } from "@/types/pointSummary";
import type { PointTransferFee } from "@/types/pointTransferFee";
import { pointBreakdownSum as s } from "@/types/pointTransferFee";
import type { PointTransferMethod } from "@/types/pointTransferMethod";
import { pointTransferMethodDefaultValues } from "@/types/pointTransferMethod";
import { axiosAccountInstance } from "@/utils/axios";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";
import { exchangePointsAmountValidator, validationSchema } from "@/utils/validators";

type FormData = {
  exchangeOption: string;
  exchangePointsAmount?: number;
};

const defaultMinValue = 1;
const defaultMaxValue = 100000;

export const Transfer = () => {
  const [state, dispatch] = useReducer(FetchReducer, initialState);

  const [isLoading, setIsLoading] = useState(true);
  const [pointSummary, setPointSummary] = useState<PointSummary>(pointSummaryDefaultValues);
  const [pointTransferMethod, setPointTransferMethod] = useState<PointTransferMethod>(pointTransferMethodDefaultValues);
  const [callOnce, setCallOnce] = useState(true);
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [isInputtingLimited, setIsInputtingLimited] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [maxValue, setMaxValue] = useState(defaultMaxValue);
  const [maxValueMessage, setMaxValueMessage] = useState("EXCHANGE_POINTS_AMOUNT_MAX_OWNED");
  const [minValue, setMinValue] = useState(defaultMinValue);
  const [minValueMessage, setMinValueMessage] = useState("EXCHANGE_POINTS_AMOUNT_MIN");

  const cardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const portalType = getPortalType();

  const defaultItems = [
    {
      value: "1",
      label: t("EXCHANGE_POINTS_ALL"),
    },
    {
      value: "2",
      label: t("EXCHANGE_POINTS_AMOUNT"),
    },
  ];

  const methods = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        exchangeOption: Yup.string().required(),
        exchangePointsAmount: exchangePointsAmountValidator
          .max(maxValue, t(maxValueMessage))
          .min(minValue, t(minValueMessage)),
      })
    ),
    defaultValues: {
      exchangeOption: "1",
      exchangePointsAmount: 0,
    },
    mode: "onChange",
  });

  const onSubmit = () => {
    setIsLoading(true);
    let exchangePointsAmount = methods.getValues("exchangePointsAmount");
    if (methods.getValues("exchangeOption") === "1") {
      exchangePointsAmount = pointSummary.currentPoint;
    }

    axiosAccountInstance
      .get("/api/point/transfer/fee", {
        params: {
          amount: exchangePointsAmount,
        },
      })
      .then((res: AxiosResponse<PointTransferFee>) => {
        const pointTransferFee = camelcaseKeys(res.data, {
          deep: true,
        }) as PointTransferFee;

        // ここで振込金額が正でなければフォームをエラーにして返す
        if (s(pointTransferFee) <= 0) {
          methods.setError("exchangePointsAmount", {
            type: "server",
            message: t("ERROR"),
          });

          // エラーメッセージ発生箇所まで移動
          if (cardRef.current) {
            const { height } = cardRef.current.getBoundingClientRect();
            window.scrollTo({
              top: height / 2,
            });
          }
          return;
        }

        navigate(((p): string => (p ? `/${p}_portal/points/transfer/accepted` : "/"))(portalType), {
          state: {
            pointTransferMethod: pointTransferMethod,
            pointSummary: pointSummary,
            exchangeOption: methods.getValues("exchangeOption"),
            exchangePointsAmount: Number(exchangePointsAmount),
            pointTransferFee: pointTransferFee,
          },
        });
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        const endpoint = "/api/point/transfer/fee";
        if (error.response?.status === undefined || (401 <= error.response.status && error.response.status <= 503)) {
          navigateError({
            error: error,
            endpoint: endpoint,
            appName: "POINT_EXCHANGE_APPLICATION",
            path: "points",
            state: {
              steps: {
                active: 1,
                length: 4,
              },
            },
          });
        } else if (error.response.status === 400) {
          const errorCode = error.response.data.error_code ?? `${error.response.status}`;
          if (["E400-04", "E400-05"].includes(errorCode)) {
            navigateError({
              error: error,
              endpoint: endpoint,
              appName: "POINT_EXCHANGE_APPLICATION",
              path: "points",
              state: {
                steps: {
                  active: 1,
                  length: 4,
                },
              },
            });
          } else {
            methods.setError("exchangePointsAmount", {
              type: "server",
              message: t(`ERROR_CODE_${errorCode}${endpoint}_MESSAGE`),
            });
          }
        }
      });
  };

  const watchRadio = methods.watch("exchangeOption");
  const watchExchangePointsAmount = methods.watch("exchangePointsAmount");

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    removeAuthToken();

    type Result = PointSummary | PointTransferMethod;

    Promise.all([
      axiosAccountInstance
        .get("/api/point/balance/summary")
        .then((response: AxiosResponse<PointSummary>) => camelcaseKeys(response.data, { deep: true })),
      axiosAccountInstance
        .get("/api/point/transfer/method")
        .then((response: AxiosResponse<PointTransferMethod>) => camelcaseKeys(response.data, { deep: true })),
    ])
      .then((results: Result[]) => {
        const pointSummary = results[0] as PointSummary;
        const pointTransferMethod = results[1] as PointTransferMethod;
        setPointSummary(pointSummary);
        setPointTransferMethod(pointTransferMethod);

        const disabledStatus =
          !pointTransferMethod.bankAccountInfo ||
          (!pointTransferMethod.bankAccountInfo.failure &&
            (pointTransferMethod.limitAmount === 0 ||
              pointSummary.currentPoint === 0 ||
              pointSummary.currentPoint <= pointTransferMethod.transferFee));
        setDisabled(disabledStatus);

        // バリデーターの最大値を更新
        if (pointSummary.currentPoint > defaultMaxValue) {
          setMaxValue(defaultMaxValue);
          setMaxValueMessage("EXCHANGE_POINTS_AMOUNT_MAX");
        } else {
          setMaxValue(pointSummary.currentPoint);
          setMaxValueMessage("EXCHANGE_POINTS_AMOUNT_MAX_OWNED");
        }

        // バリデーターの最小値を更新
        if (pointTransferMethod.transferFee === 0) {
          setMinValue(defaultMinValue);
          setMinValueMessage("EXCHANGE_POINTS_AMOUNT_MIN");
        } else {
          setMinValue(pointTransferMethod.transferFee + 1);
          setMinValueMessage("EXCHANGE_POINTS_AMOUNT_MIN_FEES");
        }

        methods.setValue("exchangePointsAmount", pointSummary.currentPoint);

        if (!disabledStatus && pointSummary.currentPoint > pointTransferMethod.limitAmount) {
          methods.setValue("exchangeOption", "2");
          setIsOverLimit(true);
        }
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        navigate(
          `/${portalType}_portal/${error.response?.status === 401 ? "" : "points/error/"}${error.response?.status}`,
          {
            state: { errorCode: error.response?.data.error_code },
          }
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [navigate]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    if (callOnce && watchExchangePointsAmount && pointSummary.currentPoint) {
      // 明示的にバリデーションする
      methods.trigger("exchangePointsAmount");
      setCallOnce(false);

      const localIsInputtingLimited =
        pointTransferMethod.limitAmount - pointTransferMethod.currentAmount <= pointTransferMethod.transferFee;
      if (localIsInputtingLimited) {
        setIsInputtingLimited(true);
        setDisabled(true);
        methods.setValue("exchangeOption", "1");
      }
    }
  }, [watchExchangePointsAmount, pointSummary.currentPoint, callOnce]);

  return (
    <Container>
      <context.Provider value={{ state, dispatch }}>
        <Helmet title={t("POINT_EXCHANGE_APPLICATION")} />

        <Steps active={1} length={4} gutterBottom />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
              <FormProvider {...methods}>
                <LayoutVertical>
                  <Typography variant="h1">{t("POINT_EXCHANGE_APPLICATION")}</Typography>

                  <Card>
                    <Typography variant="h2" gutterBottom>
                      {t("OWNED_POINT")}
                    </Typography>
                    <LayoutVertical>
                      <TypographyPoint point={pointSummary.currentPoint} />
                      <AlertPointExpiring pointSummary={pointSummary} />
                    </LayoutVertical>
                  </Card>

                  {pointTransferMethod.bankAccountInfo && !pointTransferMethod.bankAccountInfo.failure && (
                    <>
                      <Card ref={cardRef}>
                        <Typography variant="h2" gutterBottom>
                          {t("EXCHANGE_POINT_AMOUNT")}
                        </Typography>
                        <LayoutVertical>
                          <Typography>
                            {t("POINT_TRANSFER_NOTICE_1")}
                            <br />
                            {t("POINT_TRANSFER_NOTICE_2")}
                          </Typography>
                          {disabled && (
                            <Typography style={{ color: COLORS.WARNING }}>
                              {t(
                                isInputtingLimited
                                  ? "EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY_NOTICE"
                                  : "EXCHANGE_POINTS_AMOUNT_MIN_FEES_NOTICE"
                              )}
                            </Typography>
                          )}

                          <Controller
                            name="exchangeOption"
                            defaultValue={pointSummary.currentPoint > pointTransferMethod.limitAmount ? "2" : "1"}
                            render={({ field }) => (
                              <FormControl disabled={disabled}>
                                {!disabled && isOverLimit ? (
                                  <Typography>{t("EXCHANGE_POINTS_AMOUNT")}</Typography>
                                ) : (
                                  <Radios
                                    value={field.value}
                                    items={defaultItems}
                                    onChange={(e) => {
                                      if (e.target.value === "1") {
                                        methods.setValue("exchangePointsAmount", pointSummary.currentPoint);
                                      } else if (e.target.value === "2") {
                                        methods.setValue("exchangePointsAmount", 0);
                                      }
                                      methods.trigger("exchangePointsAmount");
                                      field.onChange(e);
                                    }}
                                    onBlur={field.onBlur}
                                  />
                                )}
                              </FormControl>
                            )}
                          />
                          {watchRadio === "2" && (
                            <Controller
                              name="exchangePointsAmount"
                              control={methods.control}
                              defaultValue={0}
                              render={({ field }) => (
                                <TextFieldWithUnit
                                  unit="P"
                                  type="tel"
                                  onChange={async (e) => {
                                    const target = e.target as HTMLInputElement;
                                    const numberValue = Number(target.value);
                                    // 10万ポイントより多く保有している状態で100001ポイント以上を入力した際は固定エラー文言とする
                                    if (
                                      pointSummary.currentPoint > pointTransferMethod.limitAmount &&
                                      numberValue > pointTransferMethod.limitAmount
                                    ) {
                                      methods.setError("exchangePointsAmount", {
                                        type: "client",
                                        message: t("EXCHANGE_POINTS_AMOUNT_MAX"),
                                      });
                                      setHasError(true);
                                    } else {
                                      // 入力値がlimitAmount-currentAmountを超えている場合はエラーにする
                                      if (
                                        numberValue >
                                        pointTransferMethod.limitAmount - pointTransferMethod.currentAmount
                                      ) {
                                        methods.setError("exchangePointsAmount", {
                                          type: "client",
                                          message: t("EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY").replace(
                                            "%",
                                            (
                                              pointTransferMethod.limitAmount - pointTransferMethod.currentAmount
                                            ).toLocaleString()
                                          ),
                                        });
                                        setHasError(true);
                                      } else {
                                        field.onChange(e);
                                        setDisabled(false);
                                        setHasError(false);
                                      }
                                    }
                                  }}
                                  onBlur={field.onBlur}
                                  error={Boolean(methods.formState.errors.exchangePointsAmount)}
                                  helperText={methods.formState.errors.exchangePointsAmount?.message}
                                />
                              )}
                            />
                          )}
                        </LayoutVertical>
                      </Card>
                    </>
                  )}

                  <CardReceiveMethod isFreeTransfer={pointTransferMethod.transferFee === 0} />

                  {pointTransferMethod.bankAccountInfo && !pointTransferMethod.bankAccountInfo.failure && (
                    <>
                      <Card>
                        <Typography variant="h2" gutterBottom>
                          {t("BANK_ACCOUNT")}
                        </Typography>

                        <LayoutVertical style={{ marginBottom: "24px" }}>
                          <BankTable
                            bankName={pointTransferMethod.bankAccountInfo.bankName}
                            branchName={pointTransferMethod.bankAccountInfo.branchName}
                            accountNumber={pointTransferMethod.bankAccountInfo.maskedAccountNumber}
                            accountName={pointTransferMethod.bankAccountInfo.accountName}
                          />
                          <LayoutButton>
                            <ButtonOutlinedLink
                              to={((p): string => (p ? `/${p}_portal/points/transfer/gmo_link` : "/"))(portalType)}
                            >
                              {t("CHANGE_BANK_ACCOUNT")}
                            </ButtonOutlinedLink>
                          </LayoutButton>
                        </LayoutVertical>
                      </Card>
                    </>
                  )}

                  <LayoutButton>
                    {pointTransferMethod.bankAccountInfo && !pointTransferMethod.bankAccountInfo.failure ? (
                      <ButtonContained
                        size="large"
                        type="submit"
                        disabled={
                          disabled ||
                          (watchRadio === "2" && !watchExchangePointsAmount) ||
                          hasError ||
                          (isOverLimit && !methods.formState.isDirty) ||
                          (!hasError && !methods.formState.isValid)
                        }
                      >
                        {t("CONFIRM_APPLICATION_CONTENTS")}
                        {disabled && (
                          <Typography variant="caption">
                            {t(
                              isInputtingLimited
                                ? "EXCHANGE_POINTS_AMOUNT_MAX_PER_DAY_BUTTON"
                                : "EXCHANGE_POINTS_AMOUNT_MIN_FEES_BUTTON"
                            )}
                          </Typography>
                        )}
                      </ButtonContained>
                    ) : (
                      <ButtonContainedLink
                        to={((p): string => (p ? `/${p}_portal/points/transfer/gmo_link` : "/"))(portalType)}
                        size="large"
                      >
                        {t("GO_TO_BANK_ACCOUNT_REGISTRATION")}
                      </ButtonContainedLink>
                    )}
                  </LayoutButton>
                </LayoutVertical>
              </FormProvider>
            </form>
          </>
        )}
      </context.Provider>
    </Container>
  );
};
