import {
  Box,
  ButtonContained,
  Checkbox,
  FormBox,
  FormControlLabel,
  TextField,
  TextFieldPhoneNumber,
} from "@/components";
import { EmptyAuthTokenError } from "@/contexts/JWTContext";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import type { MaskedMailAddress } from "@/types/user";
import { axiosAuthInstance } from "@/utils/axios";
import {
  codeLength,
  crewNumberValidator,
  passwordConfirmValidator,
  passwordValidator,
  phoneNumberValidatorDelimited,
  pinCodeValidator,
  validationSchema,
} from "@/utils/validators";
import { yupResolver } from "@hookform/resolvers/yup";
import type { AxiosResponse } from "axios";
import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const PasswordResetForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyCrewNumberAndPhoneNumber } = useAuth();
  const { t } = useTranslation();

  type FormData = {
    crewNumber: string;
    phoneNumber: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        crewNumber: crewNumberValidator,
        phoneNumber: phoneNumberValidatorDelimited,
      })
    ),
    defaultValues: {
      crewNumber: "",
      phoneNumber: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyCrewNumberAndPhoneNumber(
        data.crewNumber,
        data.phoneNumber.replaceAll(" ", "") // NOTE: TextFieldPhoneNumber使用するためtrim()ではなくreplaceAll()でスペース除去
      );

      axiosAuthInstance
        .get("/api/password_reset/masked_mail_address")
        .then((response: AxiosResponse<MaskedMailAddress>) => {
          response.data = camelcaseKeys(response.data, { deep: true });
          navigate("/crew_portal/password_reset/auth_mail", {
            state: { maskedEmail: response.data.maskedMailAddress },
          });
        })
        .catch(() =>
          navigate("/crew_portal/password_reset/auth_mail_code", {
            state: { failed: true },
          })
        );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/password_reset",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="passwordResetForm">
        <Controller
          name="crewNumber"
          control={control}
          rules={{ required: t("CREW_NUMBER_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("CREW_NUMBER_NOTE")}>
              <TextField
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type="tel"
                label={t("CREW_NUMBER")}
                fullWidth
                error={Boolean(errors.crewNumber)}
                helperText={errors.crewNumber?.message}
                style={{ marginTop: "24px" }}
              />
            </FormBox>
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          rules={{ required: t("PHONE_NUMBER_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PHONE_NUMBER_NOTE")}>
              <TextFieldPhoneNumber
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type="tel"
                label={t("PHONE_NUMBER")}
                fullWidth
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
                style={{ marginTop: "24px" }}
              />
            </FormBox>
          )}
        />
        <Box textAlign="center" style={{ marginTop: "24px" }}>
          <ButtonContained type="submit" color="primary" size="large" disabled={!isDirty || isSubmitting}>
            {t("NEXT")}
          </ButtonContained>
        </Box>
      </form>
    </>
  );
};

export const PasswordResetMailCodeForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyPinCode } = useAuth();
  const { t } = useTranslation();

  type FormData = {
    pinCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        pinCode: pinCodeValidator,
      })
    ),
    defaultValues: {
      pinCode: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyPinCode(data.pinCode);
      navigate("/crew_portal/password_reset/password");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/password_reset/auth_mail_code",
        });
      } else if (error instanceof EmptyAuthTokenError) {
        // 確認コード送信 ダミートークン返却後の認証失敗はリクエストせずにエラー表示
        navigate("/crew_portal/401", {
          state: {
            errorCode: "E401-05/api/password_reset/auth_mail_code/failed",
          },
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="passwordResetMailCodeForm">
        <Controller
          name="pinCode"
          control={control}
          rules={{ required: t("PIN_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
                if (e.target.value.length === codeLength) {
                  handleSubmit(onSubmit)();
                }
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("PIN_CODE")}
              fullWidth
              error={Boolean(errors.pinCode)}
              helperText={errors.pinCode?.message}
              inputProps={{ maxLength: 4 }}
              style={{ marginTop: "24px" }}
            />
          )}
        />
      </form>
    </>
  );
};

export const PasswordResetPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(true);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { resetPassword } = useAuth();
  const { t } = useTranslation();

  type FormData = {
    password: string;
    passwordConfirmation: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        password: passwordValidator,
        passwordConfirmation: passwordConfirmValidator,
      })
    ),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await resetPassword(data.password);
      navigate("/crew_portal/top", {
        state: { status: "PASSWORD_RESET_SUCCESS" },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/password_reset/password",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="passwordResetPasswordForm">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: t("PASSWORD_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PASSWORD_NOTE")}>
              <TextField
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type={showPassword ? "text" : "password"}
                label={t("NEW_PASSWORD")}
                fullWidth
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                inputProps={{ maxLength: 64, inputMode: "url" }} // NOTE: パスワード設定時のみurlキーボードを利用する(Crew向け)
              />
            </FormBox>
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          defaultValue=""
          rules={{ required: t("PASSWORD_CONFIRM_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
              value={value}
              type={showPassword ? "text" : "password"}
              label={t("NEW_PASSWORD_CONFIRM")}
              fullWidth
              error={Boolean(errors.passwordConfirmation)}
              helperText={errors.passwordConfirmation?.message}
              style={{ marginTop: "24px" }}
              inputProps={{ maxLength: 64, inputMode: "url" }} // NOTE: パスワード設定時のみurlキーボードを利用する(Crew向け)
            />
          )}
        />
        <FormControlLabel
          control={<Checkbox checked={showPassword} onChange={handleShowPassword} name="show_password" />}
          label={t("SHOW_PASSWORD")}
          style={{ marginTop: "24px" }}
        />
        <Box textAlign="center">
          <ButtonContained
            type="submit"
            color="primary"
            disabled={!isValid || isSubmitting}
            style={{ marginTop: "24px" }}
            size="large"
          >
            {t("REGISTER")}
          </ButtonContained>
        </Box>
      </form>
    </>
  );
};
