import {
  AlertError,
  Box,
  ButtonOutlinedLink,
  ButtonTextLink,
  Card,
  type ChipTransferStatusProps,
  Container,
  InfoMessage,
  LayoutInfo,
  LayoutVertical,
  ListDescription,
  ListDescriptionDetail,
  ListDescriptionItem,
  ListDescriptionTerm,
  Loader,
  Typography,
  TypographyNote,
} from "@/components";
import { CardTransfer } from "@/components/CardTransfer";
import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "@/components/Loader/Loader";
import { SUPPORT_URLS } from "@/constants";
import { FetchContext as context } from "@/contexts/FetchContext";
import { removeAuthToken } from "@/contexts/JWTContext";
import { AlertMessagePointTransfer } from "@/features/common/Points/components";
import { BankTable, CardReceiveMethod } from "@/features/common/Points/components";
import { CardPointTransfer } from "@/features/common/Points/components";
import { useNavigationError } from "@/hooks/useNavigationError";
import type { PointTransferFee } from "@/types/pointTransferFee";
import type { PointTransferHistoryDetail } from "@/types/pointTransferHistory";
import { pointTransferHistoryDetailDefaultValues } from "@/types/pointTransferHistory";
import { axiosAccountInstance } from "@/utils/axios";
import { formatDisplayDate } from "@/utils/formatter";
import getPortalType from "@/utils/getPortalType";

export const TransferHistory = () => {
  const { state } = useContext(context);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { navigateError } = useNavigationError();
  const [pointTransferHistoryDetail, setPointTransferHistoryDetail] = useState<PointTransferHistoryDetail>(
    pointTransferHistoryDetailDefaultValues
  );
  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  // 初回読み込み時
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        removeAuthToken();
        const response = await axiosAccountInstance.get(`/api/point/transfer/history/detail/${id}`);
        setPointTransferHistoryDetail(camelcaseKeys(response.data, { deep: true }));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          navigateError({
            error: error,
            endpoint: "/api/point/transfer/history/detail",
            appName: "POINT_EXCHANGE_APPLICATION_HISTORY",
            path: "points",
          });
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, []);

  return (
    <Container>
      {state.loading || isLoading || _.isEqual(pointTransferHistoryDetail, pointTransferHistoryDetailDefaultValues) ? (
        <Loader />
      ) : (
        <>
          <Helmet title={t("POINT_EXCHANGE_APPLICATION_HISTORY")} />

          <LayoutVertical>
            <Typography variant="h1">{t("POINT_EXCHANGE_APPLICATION_HISTORY")}</Typography>
          </LayoutVertical>

          <ButtonOutlinedLink
            to={((p): string => (p ? `/${p}_portal/points/transfer/histories` : "/"))(portalType)}
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {t("BACK_TO_LIST")}
          </ButtonOutlinedLink>

          <LayoutVertical>
            <CardTransfer
              id={pointTransferHistoryDetail.transferId}
              status={pointTransferHistoryDetail.status as ChipTransferStatusProps["status"]}
              date={formatDisplayDate(pointTransferHistoryDetail.createdAt)}
            >
              {pointTransferHistoryDetail.status === "FAILED" && (
                <LayoutInfo>
                  <InfoMessage variant="error">
                    {pointTransferHistoryDetail.fee.errorFee
                      ? t("POINT_RETURN_EXAMPLE_1").replace("%", pointTransferHistoryDetail.fee.errorFee.toString())
                      : t("POINT_RETURN_EXAMPLE_3")}
                  </InfoMessage>
                  <AlertError>
                    <ListDescription>
                      <ListDescriptionItem>
                        <ListDescriptionTerm bold>{t("RETURNED_POINT")}</ListDescriptionTerm>
                        <ListDescriptionDetail bold align="right">
                          {(
                            pointTransferHistoryDetail.amount + pointTransferHistoryDetail.fee.transferFee
                          ).toLocaleString()}
                          P
                        </ListDescriptionDetail>
                      </ListDescriptionItem>
                      <ListDescriptionItem>
                        <ListDescriptionTerm>{t("RETURNED_DATE")}</ListDescriptionTerm>
                        <ListDescriptionDetail align="right">
                          {new Date(pointTransferHistoryDetail.updatedAt).toLocaleDateString()}
                        </ListDescriptionDetail>
                      </ListDescriptionItem>
                      <ListDescriptionItem>
                        <ListDescriptionTerm>{t("REASON")}</ListDescriptionTerm>
                        <ListDescriptionDetail align="right">
                          {t(`POINT_TRANSFER_HISTORY_${pointTransferHistoryDetail.failedInfo?.failedReason}`)}
                        </ListDescriptionDetail>
                      </ListDescriptionItem>
                    </ListDescription>
                  </AlertError>
                  <TypographyNote color="alert">{t("POINT_RETURN_EXAMPLE_2")}</TypographyNote>
                  <Box textAlign="center">
                    <ButtonTextLink to={supportUrls["ERROR_HANDLING_FEE"]} target="_blank">
                      {t("ERROR_HANDLING_FEE")}
                    </ButtonTextLink>
                  </Box>
                </LayoutInfo>
              )}
            </CardTransfer>

            <CardPointTransfer
              exchangePointsAmount={pointTransferHistoryDetail.amount + pointTransferHistoryDetail.fee.transferFee}
              pointTransferFee={
                {
                  transferFee: pointTransferHistoryDetail.fee.transferFee,
                  pointBreakdown: pointTransferHistoryDetail.pointBreakdown,
                } as PointTransferFee
              }
              status={pointTransferHistoryDetail.status}
            />

            <CardReceiveMethod isFreeTransfer={!pointTransferHistoryDetail.fee.transferFee} isTransferHistory={true} />

            <Card>
              <Typography variant="h2" gutterBottom>
                {t("BANK_ACCOUNT")}
              </Typography>

              <LayoutVertical style={{ marginBottom: "24px" }}>
                <BankTable
                  bankName={pointTransferHistoryDetail.bankAccountInfo.bankName}
                  branchName={pointTransferHistoryDetail.bankAccountInfo.branchName}
                  accountNumber={
                    pointTransferHistoryDetail.status === "FAILED"
                      ? pointTransferHistoryDetail.bankAccountInfo.accountNumber
                      : pointTransferHistoryDetail.bankAccountInfo.maskedAccountNumber
                  }
                  accountName={pointTransferHistoryDetail.bankAccountInfo.accountName}
                  accountType={
                    pointTransferHistoryDetail.status === "FAILED"
                      ? pointTransferHistoryDetail.bankAccountInfo.accountType
                      : undefined
                  }
                />
              </LayoutVertical>

              <AlertMessagePointTransfer />
            </Card>
          </LayoutVertical>

          <ButtonOutlinedLink
            to={((p): string => (p ? `/${p}_portal/points/transfer/histories` : "/"))(portalType)}
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {t("BACK_TO_LIST")}
          </ButtonOutlinedLink>
        </>
      )}
    </Container>
  );
};
