import React from "react";
import { useTranslation } from "react-i18next";

import { Table, TableBody, TableCell, TableCellHead, TableCols, TableRow } from "@/components";

export interface BankTableProps {
  bankName: string;
  branchName: string;
  accountNumber: string;
  accountName: string;
  accountType?: string;
}

export const BankTable: React.FC<BankTableProps> = ({
  bankName,
  branchName,
  accountNumber,
  accountName,
  accountType,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Table>
        <TableCols sizes={[35, 65]} />
        <TableBody>
          <TableRow>
            <TableCellHead>{t("BANK_NAME")}</TableCellHead>
            <TableCell>{bankName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCellHead>{t("BRANCH_NAME")}</TableCellHead>
            <TableCell>{branchName}</TableCell>
          </TableRow>
          {accountType && (
            <TableRow>
              <TableCellHead>{t("ACCOUNT_TYPE")}</TableCellHead>
              <TableCell>{t(accountType)}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCellHead>{t("ACCOUNT_NUMBER")}</TableCellHead>
            <TableCell>{accountNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCellHead>{t("ACCOUNT_NAME")}</TableCellHead>
            <TableCell>{accountName}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
