import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Props, TextField } from "@/components/TextField/TextField";

export const delimiter = " "; // スペース区切り(例: 090 1234 5678)

export const TextFieldPhoneNumber: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.value);
  const [maxLength, setMaxLength] = useState<number>();
  const [isKeyDownBackcpace, setIsKeyDownBackcpace] = useState(false);

  // value変換(自動区切り文字入力)
  useEffect(() => {
    setMaxLength(13); // 区切り文字入力時は13文字まで
    const v = String(props.value);
    if (isKeyDownBackcpace) {
      setValue(v);
    } else {
      if (v.match(`^[0-9]{3}$`)) {
        setValue(v + delimiter);
      } else if (v.match(`^[0-9]{4}$`)) {
        setValue(v.slice(0, 3) + delimiter + v.slice(3, 4));
      } else if (v.match(`^[0-9]{3}${delimiter}[0-9]{4}$`)) {
        setValue(v + delimiter);
      } else if (v.match(`^[0-9]{3}${delimiter}[0-9]{5}$`)) {
        setValue(v.slice(0, 8) + delimiter + v.slice(8, 9));
      } else if (v.match(`^[0-9]{11}$`)) {
        setMaxLength(13);
        const areaCode = v.slice(0, 3); // 市外局番
        const localCode = v.slice(3, 7); // 市内局番
        const subscriberCode = v.slice(7, 11); // 加入者番号
        let number = areaCode;
        number = localCode ? number + delimiter + localCode : number;
        number = subscriberCode ? number + delimiter + subscriberCode : number;
        setValue(number);
      } else {
        setValue(v);
      }
    }
  }, [isKeyDownBackcpace, props.value]);

  const _onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const v = e.clipboardData.getData("text/plain");
    if (v.match(`^[0-9]+$`)) {
      setMaxLength(11); // paste時の数値入力は11文字まで
    }
  };

  // value変換(スペース自動削除: backspaceのKeyUp時に末尾の区切り文字を削除)
  const _onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const v = String(value);
    if (e.key === "Backspace") {
      if (v.match(`${delimiter}$`)) {
        setValue(v.slice(0, -1));
      }
    }
  };

  // value変換(backspaceのKeyDown時は変換しない)
  const _onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setIsKeyDownBackcpace(e.key === "Backspace");
  };

  return (
    <TextField
      {...props}
      onPaste={_onPaste}
      onKeyUp={_onKeyUp}
      onKeyDown={_onKeyDown}
      value={value}
      placeholder={t("PHONE_NUMBER_EXAMPLE")}
      inputProps={{ maxLength: maxLength }}
    />
  );
};
