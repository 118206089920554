import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Container, LayoutGeneralError } from "@/components";
import { SUPPORT_URL, SUPPORT_URLS } from "@/constants";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";
import getPortalType from "@/utils/getPortalType";

const button = {
  "E400-02/api/register/auth_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/register",
    codeType: "ACCOUNT_REGISTRATION",
  },
  "E400-02/api/account/phone_number_change/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/phone_number_change/auth_mail_code",
    codeType: "PIN_CODE",
  },
  "E400-02/api/account/phone_number_change/auth_sms": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/phone_number_change/auth_sms",
    codeType: "PIN_CODE",
  },
  "E400-02/api/account/phone_number_change/auth_sms_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/phone_number_change/auth_sms_code",
    codeType: "CONFIRM_CODE",
  },
  "E400-03/api/register/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/register/auth_mail",
    codeType: "CONFIRM_CODE",
  },
  "E400-02/api/password_reset/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/password_reset/auth_mail_code",
    codeType: "PIN_CODE",
  },
  "E400-02/api/password_reset/auth_mail_code/failed": {
    message: "START_OVER",
    link: "/$portalType_portal/password_reset",
    codeType: "PIN_CODE",
  },
  "E400-02/api/account/mail_address_change/auth_sms_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/mail_address_change/auth_sms_code",
    codeType: "PIN_CODE",
  },
  "E400-02/api/account/mail_address_change/auth_mail": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/mail_address_change/auth_mail",
    codeType: "PIN_CODE",
  },
  "E400-02/api/account/mail_address_change/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/mail_address_change/auth_mail_code",
    codeType: "CONFIRM_CODE",
  },
  "E400-02/api/account/password_change/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/password_change/auth_mail_code",
    codeType: "PIN_CODE",
  },
  "E400-03/api/register/auth_code": {
    message: "",
    link: "",
    codeType: "ACCOUNT_REGISTRATION",
  },
  "E400-02/api/register/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/register/auth_mail_code",
    codeType: "CONFIRM_CODE",
  },
  "E400-04/api/login": {
    message: "",
    link: "",
    codeType: null,
  },
  "E400-04/api/driver/login": {
    message: "",
    link: "",
    codeType: null,
  },
  "E400-05/api/login": {
    message: "RESET_PASSWORD",
    link: "/$portalType_portal/password_reset",
    codeType: "ACCOUNT_LOCKED",
  },
  "E400-05/api/driver/login": {
    message: "RESET_PASSWORD",
    link: "",
    codeType: "ACCOUNT_LOCKED",
  },
  "E400-06/api/point/transfer/auth_sms": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: null,
  },
  "E400-06/api/point/transfer/auth_mail": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: null,
  },
  "E400-02/api/point/transfer/auth_sms_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/points/transfer/auth_sms_code",
    codeType: "PIN_CODE",
  },
  "E400-02/api/point/transfer/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/points/transfer/auth_mail_code",
    codeType: "PIN_CODE",
  },
  "E400-07/api/point/transfer/auth_sms_code": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: null,
  },
  "E400-07/api/point/transfer/auth_mail_code": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: null,
  },
  "E400-06/api/point/transfer/skip_auth": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: null,
  },
  "E400-07/api/point/transfer/skip_auth": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: null,
  },
  "E400-06/api/point/transfer/auth_sms_code": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: null,
  },
  "E400-06/api/point/transfer/auth_mail_code": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: null,
  },
  "E400-10/api/point/transfer/auth_sms": {
    message: "TO_TOP",
    link: "/$portalType_portal/top",
    codeType: null,
  },
  "E400-10/api/point/transfer/auth_mail": {
    message: "TO_TOP",
    link: "/$portalType_portal/top",
    codeType: null,
  },
  "E400-01/api/account/phone_number_change/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/phone_number_change/auth_mail_code",
    codeType: "PIN_CODE",
  },
  "E400-01/api/account/password_change/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/password_change/auth_mail_code",
    codeType: "PIN_CODE",
  },
  "E400-02/api/mapping_request/auth_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/mapping_request/auth_sms_code",
    codeType: "PIN_CODE",
  },
  "E400-03/api/mapping_request/auth_code": {
    message: "",
    link: "",
    codeType: "PIN_CODE",
  },
  "E400-09/api/register/auth_mail": {
    message: "MAKE_CONTACT",
    link: "/crew_portal/contact?status=CONFIRMATION_EMAIL_NOT_ARRIVED",
    codeType: "CONFIRMATION_EMAIL_NOT_ARRIVED",
  },
  "E400-09/api/account/mail_address_change/auth_mail": {
    message: "MAKE_CONTACT",
    link: "/crew_portal/contact_account?status=CONFIRMATION_EMAIL_NOT_ARRIVED",
    codeType: "CONFIRMATION_EMAIL_NOT_ARRIVED",
  },
};

export const Error400 = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const errorCode = location.state?.errorCode || "";

  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata(errorCode);
  const portalType = getPortalType();

  const buttonMessage = button[errorCode as keyof typeof button]?.message || "";
  const buttonLink = button[errorCode as keyof typeof button]?.link.replace("$portalType", portalType) || "";
  const codeType = button[errorCode as keyof typeof button]?.codeType;

  const primaryButton =
    buttonLink && buttonMessage
      ? {
          label: t(buttonMessage),
          to: buttonLink,
        }
      : undefined;
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  const footerButtons = useMemo(() => {
    return codeType === null
      ? []
      : [
          codeType !== ""
            ? {
                title: t(`WHAT_IS_${codeType}`),
                label: t(`WHAT_IS_${codeType}`),
                to:
                  codeType === undefined || !(SUPPORT_URLS[portalType] as Record<string, string>)[`WHAT_IS_${codeType}`]
                    ? SUPPORT_URL
                    : (SUPPORT_URLS[portalType] as Record<string, string>)[`WHAT_IS_${codeType}`],
              }
            : {
                title: t("WHAT_IS_ACCOUNT_REGISTRATION"),
                label: t("ACCOUNT_REGISTRATION"),
                to: "/$portalType_portal/register",
              },
        ];
  }, [t, codeType]);

  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError
        title={t(title)}
        message={t(message)}
        primaryButton={primaryButton}
        footerButtons={footerButtons}
      />
    </Container>
  );
};
