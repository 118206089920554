import { ListItemProps, ListItem as MuiListItem, styled } from "@mui/material";

export type Props = ListItemProps;

export const ListItem: React.FC<Props> = (props) => {
  return <StyledListItem {...props} />;
};
const StyledListItem = styled(MuiListItem)({
  padding: 0,
});
