import { LinkProps, styled } from "@mui/material";

import { Link } from "@/components";
import { COLORS } from "@/utils/colors";

export type Props = LinkProps;

export const LinkText: React.FC<Props> = (props) => {
  return <Container {...props} />;
};

const Container = styled(Link)({
  color: COLORS.PRIMARY_ACTIVE,
  outline: "none",
  textDecorationColor: "currentColor",
});
