import type { AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, ButtonContained, ButtonTextLink, Container, LayoutVertical, StepCard, Typography } from "@/components";
import { SUPPORT_URLS } from "@/constants";
import type { MaskedMailAddress } from "@/types/user";
import { axiosAuthInstance } from "@/utils/axios";

import { PasswordResetMailCodeForm } from "./components/Forms";

export const PasswordResetAuthMailCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [maskedEmail, setMaskedEmail] = useState("");

  useEffect(() => {
    axiosAuthInstance
      .get("/api/password_reset/masked_mail_address")
      .then((response: AxiosResponse<MaskedMailAddress>) => {
        response.data = camelcaseKeys(response.data, { deep: true });
        setMaskedEmail(response.data.maskedMailAddress);
      })
      .catch(() => setMaskedEmail(""));
  }, []);

  const onSubmit = async () => {
    try {
      await axiosAuthInstance
        .get("/api/password_reset/masked_mail_address")
        .then((response: AxiosResponse<MaskedMailAddress>) => {
          response.data = camelcaseKeys(response.data, { deep: true });
          setMaskedEmail(response.data.maskedMailAddress);
        })
        .catch(() => setMaskedEmail(""));
      if (maskedEmail) {
        navigate("/crew_portal/password_reset/auth_mail", {
          state: { maskedEmail: maskedEmail },
        });
      } else {
        navigate("/crew_portal/password_reset");
      }
    } catch (_error: any) {
      navigate("/crew_portal/password_reset");
    }
  };

  return (
    <Container>
      <Helmet title={t("RESET_PASSWORD")} />
      <LayoutVertical>
        <StepCard activestep={2} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_INPUT_PIN_CODE_FROM_EMAIL")}
          </Typography>
          <Typography component="h3" variant="body1">
            {t("PIN_CODE_SENT_EXAMPLE")}
          </Typography>
          <PasswordResetMailCodeForm />
        </StepCard>

        <ButtonTextLink to={SUPPORT_URLS["crew"]["WHO_DO_NOT_RECEIVE_PIN_CODE"]} target="_blank">
          {t("WHO_DO_NOT_RECEIVE_PIN_CODE")}
        </ButtonTextLink>
        <ButtonTextLink to={SUPPORT_URLS["crew"]["WHAT_IS_PIN_CODE"]} target="_blank">
          {t("WHAT_IS_PIN_CODE")}
        </ButtonTextLink>

        <Typography component="h3" align="center">
          {t("WHO_DO_NOT_RECEIVE_PIN_CODE_OR_CHALLENGE_RESENDING")}
        </Typography>
        <Box textAlign="center">
          <ButtonContained type="button" color="primary" size="medium" onClick={onSubmit}>
            {t("TRY_AGAIN")}
          </ButtonContained>
        </Box>
      </LayoutVertical>
    </Container>
  );
};
