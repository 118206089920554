import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Container, LayoutGeneralError } from "@/components";

export const MobileDeviceWarning = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Helmet title={t("REQUIRES_OPERATION_WITH_DRIVER_APP")} />
      <LayoutGeneralError
        title={t("REQUIRES_OPERATION_WITH_DRIVER_APP")}
        message={t("OPEN_THE_DRIVER_APP_FROM_YOUR_SMART_PHONE")}
      />
    </Container>
  );
};
