import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CircularProgress as MuiCircularProgress, circularProgressClasses, styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

const size = 40;
const thickness = 3;
const waitTime = 5000;

interface LoaderProps {
  url?: string;
}

export const Loader: React.FC<LoaderProps> = ({ url }) => {
  const navigate = useNavigate();

  // 5秒経っても読み込みが終わらない場合はurlへ遷移
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (url) {
        navigate(url);
      }
    }, waitTime);

    return () => clearTimeout(timeoutId);
  }, [navigate, url]);

  return (
    <Root>
      <Wrap>
        <Background variant="determinate" size={size} thickness={thickness} value={100} />
        <CircularProgress variant="indeterminate" disableShrink size={size} thickness={thickness} />
      </Wrap>
    </Root>
  );
};

const Root = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100%",
});

const Wrap = styled("div")({
  position: "relative",
  height: `${size}px`,
  width: `${size}px`,
});

const Background = styled(MuiCircularProgress)({
  position: "absolute",
  top: 0,
  left: 0,
  color: COLORS.PRIMARY,
  opacity: 0.1,
});

const CircularProgress = styled(MuiCircularProgress)({
  position: "absolute",
  top: 0,
  left: 0,
  color: COLORS.PRIMARY,
  animationDuration: "800ms",

  [`& .${circularProgressClasses.circle}`]: {
    strokeLinecap: "round",
  },
});
