import React from "react";
import { useTranslation } from "react-i18next";

import {
  AlertWarning,
  Box,
  ButtonTextLink,
  InfoMessage,
  LayoutVertical,
  ListBullet,
  ListBulletItem,
  ListItem,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import getPortalType from "@/utils/getPortalType";

export interface AlertMessagePointTransferProps {
  bankAccountRegistration?: boolean;
}

export const AlertMessagePointTransfer: React.FC<AlertMessagePointTransferProps> = ({
  bankAccountRegistration = false,
}) => {
  const { t } = useTranslation();
  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  return (
    <>
      <LayoutVertical>
        <InfoMessage variant="warning">{t("NOTICE")}</InfoMessage>
      </LayoutVertical>
      <LayoutVertical>
        <AlertWarning>
          {bankAccountRegistration && (
            <Typography style={{ whiteSpace: "pre-line" }}>{t("POINT_TRANSFER_HISTORY_NOTICE_0")}</Typography>
          )}
          <ListBullet>
            <ListBulletItem>{t("POINT_TRANSFER_HISTORY_NOTICE_1")}</ListBulletItem>
            <ListItem style={{ flexDirection: "column", alignItems: "flex-start" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  paddingLeft: "1.5rem",
                }}
              >
                {t("POINT_TRANSFER_HISTORY_NOTICE_2")}
              </Typography>
            </ListItem>
            <ListBulletItem>{t("POINT_TRANSFER_HISTORY_NOTICE_3")}</ListBulletItem>
          </ListBullet>
          <Typography>{t("POINT_TRANSFER_HISTORY_NOTICE_4")}</Typography>
        </AlertWarning>
        <Box textAlign="center">
          <ButtonTextLink to={supportUrls["ABOUT_BANK_ACCOUNT"]} target="_blank">
            {t("ABOUT_BANK_ACCOUNT")}
          </ButtonTextLink>
        </Box>
      </LayoutVertical>
    </>
  );
};
