import { styled } from "@mui/material";

const StyledContainer = styled("div")({
  width: "800px",
  height: "100%",
  maxWidth: "100%",
  margin: "0 auto",
});

export type Props = {
  children: React.ReactNode;
};

export const Container: React.FC<Props> = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};
