import i18n from "i18next";
import * as Yup from "yup";

import { delimiter as phoneNumberDelimiter } from "@/components/TextFieldPhoneNumber/TextFieldPhoneNumber";

export const validationSchema = Yup.object();
export const codeLength = 4;

export const emailValidator = Yup.string()
  .required(i18n.t("EMAIL_REQUIRED"))
  .max(254, i18n.t("EMAIL_TOO_LONG"))
  .email(i18n.t("EMAIL_INVALID")) // Yup標準Emailバリデーション
  // BE側でのバリデーションと合わせるため、以下のバリデーションを追加
  .matches(/^(?!\.).+$/, i18n.t("EMAIL_INVALID")) // 先頭がドット
  .matches(/^(?!.*\.\.).+$/, i18n.t("EMAIL_INVALID")) // ドットが連続
  .matches(/^(?!.*\.@).+$/, i18n.t("EMAIL_INVALID")) // アットマークの前がドット
  .matches(/^(?!.*@[^.]+$).+$/, i18n.t("EMAIL_INVALID")); // ドットレスドメイン

export const passwordValidatorForLogin = Yup.string().required(i18n.t("PASSWORD_REQUIRED"));

export const passwordValidator = Yup.string()
  .required(i18n.t("PASSWORD_REQUIRED"))
  .matches(/^[!-~]+$/, i18n.t("PASSWORD_INVALID"))
  .min(8, i18n.t("PASSWORD_TOO_SHORT"))
  .max(64, i18n.t("PASSWORD_TOO_LONG"))
  .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).+$/, i18n.t("PASSWORD_INVALID_MIXED"));

export const passwordConfirmValidator = Yup.string()
  .required(i18n.t("PASSWORD_CONFIRM_REQUIRED"))
  .oneOf([Yup.ref("password"), ""], i18n.t("PASSWORD_CONFIRM_VALID"));

export const phoneNumberValidatorDelimited = Yup.string()
  .required(i18n.t("PHONE_NUMBER_REQUIRED"))
  .matches(RegExp(`^[0-9${phoneNumberDelimiter}]+$`), i18n.t("VALID_HALF_NUM"))
  .matches(RegExp(`[0-9]{11}|[0-9${phoneNumberDelimiter}]{13}`), i18n.t("VALID_NUM_LENGTH"))
  .matches(RegExp(`^0[789]0`), i18n.t("VALID_PHONE_NUMBER_AREA_CODE"))
  .matches(
    RegExp(`^[0-9]{3}${phoneNumberDelimiter}?[0-9]{4}${phoneNumberDelimiter}?[0-9]{4}$`),
    i18n.t("PHONE_NUMBER_VALID")
  );

export const pinCodeValidator = Yup.string()
  .required(i18n.t("PIN_CODE_REQUIRED"))
  .matches(/^[0-9]+$/, i18n.t("VALID_HALF_NUM"));

export const confirmCodeValidator = Yup.string()
  .required(i18n.t("CONFIRM_CODE_REQUIRED"))
  .matches(/^[0-9]+$/, i18n.t("VALID_HALF_NUM"));

export const crewNumberValidator = Yup.string()
  .required(i18n.t("CREW_NUMBER_REQUIRED"))
  .matches(/^[0-9]+$/, i18n.t("VALID_HALF_NUM"));

export const exchangePointsAmountValidator = Yup.number()
  .typeError(i18n.t("EXCHANGE_POINTS_AMOUNT_INTEGER"))
  .min(1, i18n.t("EXCHANGE_POINTS_AMOUNT_MIN"))
  .max(100000, i18n.t("EXCHANGE_POINTS_AMOUNT_MAX"))
  .integer(i18n.t("EXCHANGE_POINTS_AMOUNT_INTEGER"));

export const telephoneNumberValidator = Yup.string()
  .required(i18n.t("TELEPHONE_NUMBER") + i18n.t("_REQUIRED"))
  .matches(/^[0-9]+$/, i18n.t("VALID_HALF_NUM"))
  .min(10, i18n.t("VALID_NUM_LENGTH"));

export const nameValidator = Yup.string().required(i18n.t("NAME") + i18n.t("_REQUIRED"));
export const companyNameValidator = Yup.string().required(i18n.t("COMPANY_NAME") + i18n.t("_REQUIRED"));
export const officeNameValidator = Yup.string().required(i18n.t("OFFICE_NAME") + i18n.t("_REQUIRED"));
export const contactContentsValidator = Yup.string()
  .required(i18n.t("CONTACT_CONTENTS") + i18n.t("_REQUIRED"))
  .max(2000, i18n.t("VALID_TOO_LONG").replace("%", String(2000)));

export const contactTypeValidator = Yup.string()
  .required(i18n.t("CONTACT_TYPE") + i18n.t("_REQUIRED"))
  .oneOf(["CONTACT", "ACCOUNT_RESET_REQUEST", "CONFIRMATION_EMAIL_NOT_ARRIVED"], i18n.t("INVALID_CONTACT_TYPE"));
