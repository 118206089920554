import type { AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";
import { useContext } from "react";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Card,
  ChipNotificationStatusProps,
  Container,
  LayoutVertical,
  ListNotification,
  ListNotificationItem,
  Loader,
  Typography,
} from "@/components";
import "@/components/Loader/Loader";
import { FetchContext as context } from "@/contexts/FetchContext";
import { removeAuthToken } from "@/contexts/JWTContext";
import { useNavigationError } from "@/hooks/useNavigationError";
import type { Notification, Notifications as NotificationList } from "@/types/notification";
import { axiosAccountInstance } from "@/utils/axios";
import { formatNotificationDate } from "@/utils/formatter";
import getPortalType from "@/utils/getPortalType";
import axios from "axios";

export const Notifications = () => {
  const { state } = useContext(context);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [offset, setOffset] = useState(0);
  const [prevOffset, setPrevOffset] = useState(0);
  const { t } = useTranslation();
  const { navigateError } = useNavigationError();
  const bottomRef = useRef<HTMLDivElement>(null);
  const portalType = getPortalType();

  const handleErrors = (error: any) => {
    if (axios.isAxiosError(error)) {
      navigateError({
        error: error,
        endpoint: "/api/notifications",
        path: "notifications",
        state: {
          status: "RELOAD",
        },
      });
    }
  };

  // 初回読み込み時
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    const fetch = async () => {
      try {
        removeAuthToken();
        const response: AxiosResponse<NotificationList> = await axiosAccountInstance.get("/api/notifications", {
          params: {
            offset: offset,
            count: 10,
          },
        });
        if (response.data.length === 0) {
          return;
        }
        setNotifications(camelcaseKeys(response.data, { deep: true }));
        setOffset(offset + 5);
      } catch (error) {
        handleErrors(error);
      }
    };
    fetch();
  }, []);

  // スクロール時
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    const fetch = async (currentOffset: number) => {
      try {
        const response: AxiosResponse<NotificationList> = await axiosAccountInstance.get("/api/notifications", {
          params: {
            offset: currentOffset,
            count: 5,
          },
        });
        if (response.data.length === 0) {
          return;
        }
        setNotifications((prevNotifications) => {
          const newNotifications = camelcaseKeys(response.data, { deep: true });
          const combined = [...prevNotifications, ...newNotifications];

          // 重複を削除
          const unique = combined.reduce((acc: Notification[], current: Notification) => {
            const x = acc.find((item) => item.id === current.id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          return unique;
        });
        setOffset((prevOffset) => prevOffset + 5);
      } catch (error) {
        handleErrors(error);
      }
    };

    // Intersection Observerのインスタンスを作成
    const observer = new IntersectionObserver(
      (entries) => {
        // 画面の一番下の要素が交差したかどうかを判定
        if (entries[0].isIntersecting) {
          // 交差したらfetchを呼び出す
          const currentOffset = offset;
          if (currentOffset - prevOffset === 5) {
            fetch(currentOffset);
            setPrevOffset(currentOffset);
          }
        }
      },
      {
        // 交差判定の閾値を0に設定
        threshold: 0,
      }
    );

    // 画面の一番下の要素を監視対象に登録
    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    // コンポーネントがアンマウントされたら監視を解除
    return () => observer.disconnect();
  }, [offset]);

  return (
    <Container>
      {state.loading ? (
        <Loader />
      ) : (
        <>
          <Helmet title={t("NOTIFICATION")} />

          <LayoutVertical>
            <Typography variant="h1">{t("NOTIFICATION")}</Typography>

            <Card>
              {Object.entries(
                notifications
                // biome-ignore suppressions/unused: need not to watch @typescript-eslint/no-unused-vars
              ).map(([_, value], index) => {
                return (
                  <React.Fragment key={index}>
                    <ListNotification>
                      <ListNotificationItem
                        status={value.flagType as ChipNotificationStatusProps["status"]}
                        date={formatNotificationDate(value.postedAt)}
                        label={value.title}
                        to={((p): string => (p ? `/${p}_portal/notification/${value.id}` : "/"))(portalType)}
                        notification={value}
                      />
                    </ListNotification>
                  </React.Fragment>
                );
              })}
            </Card>
          </LayoutVertical>
        </>
      )}
    </Container>
  );
};
