import { styled } from "@mui/material";

import { Typography } from "@/components";
import { COLORS } from "@/utils/colors";

export type Props = {
  color?: "warning" | "error";
  children: React.ReactNode;
};

export const TypographyEmphasis: React.FC<Props> = (props) => {
  return <Container fontWeight={700} {...props} />;
};

const Container = styled(Typography, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "color",
})<{ color?: Props["color"] }>(({ color }) => ({
  color: color === "error" ? COLORS.ALERT : color === "warning" ? COLORS.WARNING : undefined,
}));
