import axios from "axios";
import { createContext, useEffect, useState } from "react";

import { Alert, Snackbar } from "@mui/material";

import { API_ENDPOINT, PORTLAND_API_KEY } from "@/constants";
import { getLocalStorageItem } from "@/utils/localStorage";

// エラーメッセージを管理するためのコンテクストを作成
const ErrorContext = createContext({
  errorMessage: "",
  setErrorMessage: (_message: string) => {
    // biome-ignore suppressions/unused: need not to watch
  },
});

export const UseAxiosAccountInstance = () => {
  return axios;
};

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const axiosInstance = UseAxiosAccountInstance();

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    axiosInstance.interceptors.request.use((config: any) => {
      const apiEndpoint = getLocalStorageItem("endpoint") || API_ENDPOINT;
      config.baseURL = apiEndpoint;
      config.headers = {
        "Content-Type": "application/json",
        "X-API-Key": PORTLAND_API_KEY,
      };

      return config;
    });
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.message === "Network Error" && !error.response) {
          setErrorMessage("リクエストに失敗しました。エンドポイントが正しいか確認してください");
        }
        return Promise.reject(error);
      }
    );
    // biome-ignore suppressions/unused: need not to watch
  }, []);

  const handleClose = () => {
    setErrorMessage("");
  };

  return (
    <ErrorContext.Provider value={{ errorMessage, setErrorMessage }}>
      {children}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </ErrorContext.Provider>
  );
};
