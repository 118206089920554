import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Box, ButtonOutlinedLink, ButtonTextLink, Container, LayoutVertical, StepCard, Typography } from "@/components";
import { SUPPORT_URLS } from "@/constants";

import { RegisterForm } from "./components/Forms";

export const Register = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Helmet title={t("REGISTER_ACCOUNT")} />
      <LayoutVertical>
        <StepCard activestep={0} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_AUTHENTICATE")}
          </Typography>
          <Typography component="h3" variant="body1">
            {t("PLEASE_INPUT_EXAMPLE_AUTH")}
          </Typography>
          <RegisterForm />
        </StepCard>
        <ButtonTextLink to={SUPPORT_URLS["crew"]["WHAT_IS_ACCOUNT_REGISTRATION"]} target="_blank">
          {t("WHAT_IS_ACCOUNT_REGISTRATION")}
        </ButtonTextLink>

        <Typography component="h3" align="center">
          {t("ALREADY_HAVE_ACCOUNT")}
        </Typography>
        <Box textAlign="center">
          <ButtonOutlinedLink to="/crew_portal/login" size="large">
            {t("LOGIN")}
          </ButtonOutlinedLink>
        </Box>
      </LayoutVertical>
    </Container>
  );
};
