import { AppBar as MuiAppBar, styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

export type Props = {
  position?: "fixed" | "absolute" | "sticky" | "static" | "relative";
  elevation?: number;
  children: React.ReactNode;
};

export const AppBar: React.FC<Props> = (props) => {
  return (
    <StyledAppBar position={props.position} elevation={props.elevation}>
      <Inner>{props.children}</Inner>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(MuiAppBar)({
  backgroundColor: COLORS.BG_01,
  boxShadow: "none",
});

const Inner = styled("div")({
  width: "100%",
  maxWidth: "800px",
  textAlign: "center",
  margin: "0 auto",
  color: COLORS.TEXT_01,
  backgroundColor: COLORS.BG_01,
  boxShadow: "0 0 14px 0 rgba(0, 0, 0, 0.05)",
  position: "relative",
});
