import {
  ButtonContained,
  ButtonOutlinedLink,
  Card,
  Container,
  LayoutButton,
  LayoutVertical,
  Loader,
  Steps,
  Typography,
  TypographyRounded,
} from "@/components";
import { FetchContext as context } from "@/contexts/FetchContext";
import { useNavigationError } from "@/hooks/useNavigationError";
import { axiosAccountInstance } from "@/utils/axios";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
export const TransferGmoLink = () => {
  const { state } = useContext(context);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { navigateError } = useNavigationError();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const portalType = getPortalType();

  const handleErrors = (error: any, endpoint: string) => {
    if (axios.isAxiosError(error)) {
      navigateError({
        error: error,
        endpoint: endpoint,
        appName: "POINT_EXCHANGE_APPLICATION_ERROR",
        path: "points",
        state: {
          steps: {
            active: 2,
            length: 4,
          },
        },
      });
    }
  };

  const onSubmit = async () => {
    const endpoint = "/api/point/transfer/gmo_link";
    try {
      const response = await axiosAccountInstance.post(endpoint);
      const { gmo_registration_url } = response.data;
      navigate(
        ((p): string => (p ? `/${p}_portal/points/transfer/gmo_link/${p === "crew" ? "auth_sms" : "auth_mail"}` : "/"))(
          portalType
        ),
        {
          state: {
            ...location.state,
            gmoRegistrationUrl: gmo_registration_url,
          },
        }
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleErrors(error, endpoint);
      }
    }
  };

  return (
    <Container>
      <Helmet title={t("POINT_EXCHANGE_APPLICATION")} />

      <Steps active={2} length={4} gutterBottom />

      <LayoutVertical>
        <Typography variant="h1">{t("PLEASE_SEND_USER_NUMBER_TO_REGISTER_BANK_ACCOUNT")}</Typography>
        <Card>
          <Typography variant="h2" gutterBottom>
            {t("BANK_TRANSFER_REQUIRES_BANK_ACCOUNT_REGISTRATION")}
          </Typography>

          <LayoutVertical>
            <Typography>{t("BANK_ACCOUNT_REGISTRATION_REQUIRED_TO_CHANGE_BANK_ACCOUNT")}</Typography>

            <Typography>
              {t("USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT_FORMER")}
              <span style={{ color: COLORS.WARNING, fontWeight: "bold" }}>{t("USER_NUMBER")}</span>
              {t("USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT_LATTER")}
            </Typography>

            <Typography>
              {t(
                portalType === "crew"
                  ? "PLEASE_SEND_USER_NUMBER_TO_REGISTER_PHONE_NUMBER"
                  : "PLEASE_SEND_USER_NUMBER_TO_REGISTER_EMAIL"
              )}
            </Typography>

            {state.loading || !state.data?.user ? (
              <Loader />
            ) : (
              <TypographyRounded style={portalType === "crew" ? undefined : { minWidth: "100%" }}>
                {portalType === "crew" ? state.data.user.maskedPhoneNumber : state.data.user.mailAddress}
              </TypographyRounded>
            )}
          </LayoutVertical>
        </Card>

        {isLoading ? (
          <Loader />
        ) : (
          <LayoutButton>
            <ButtonContained
              onClick={async () => {
                {
                  setIsLoading(true);
                  try {
                    await onSubmit();
                  } catch (_err: any) {
                    // biome-ignore suppressions/unused: need not to watch no-empty
                  } finally {
                    setIsLoading(false);
                  }
                }
              }}
              size="large"
            >
              {t("SEND_USER_NUMBER")}
              <Typography variant="caption">
                {t(portalType === "crew" ? "USER_NUMBER_WILL_BE_SENT_VIA_SMS" : "USER_NUMBER_WILL_BE_SENT_VIA_EMAIL")}
              </Typography>
            </ButtonContained>
          </LayoutButton>
        )}

        <LayoutButton
          justifyContent="flex-start"
          sx={{
            display: { xs: "block", sm: "undefined" },
          }}
        >
          <ButtonOutlinedLink to={((p): string => (p ? `/${p}_portal/points/transfer` : "/"))(portalType)}>
            {t("BACK")}
          </ButtonOutlinedLink>
        </LayoutButton>
      </LayoutVertical>
    </Container>
  );
};
