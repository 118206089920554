import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  ButtonLogout,
  ButtonOutlined,
  ButtonOutlinedLink,
  ButtonTextLink,
  Card,
  CardAccount,
  Container,
  Divider,
  FormControlLabel,
  LayoutButton,
  LayoutButtonWithTypography,
  LayoutVertical,
  Loader,
  RatingStars,
  SnackbarAlert,
  Switch,
  Typography,
} from "@/components";
import { AlertType } from "@/components/SnackbarAlert/SnackbarAlert";
import { FETCH_SUCCESS } from "@/constants";
import { SUPPORT_URLS } from "@/constants";
import { FetchContext as context } from "@/contexts/FetchContext";
import { useNavigationError } from "@/hooks/useNavigationError";
import { MappingRequest } from "@/types/user";
import { axiosAccountInstance } from "@/utils/axios";
import { navigateToAppStore } from "@/utils/errorHandling";
import getPortalType from "@/utils/getPortalType";
import { AxiosError } from "axios";

import { ErrorResponse } from "@/types/errorResponse";
import { CrewTable } from "../../crew/Account/components/MappingRequest/CrewTable";

export const Account = () => {
  const { state, dispatch } = useContext(context);
  const location = useLocation();
  const navigate = useNavigate();
  const portalType = getPortalType();
  const portalTypeUpper = portalType.toUpperCase();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  const [isPinCodeSkipped, setIsPinCodeSkipped] = useState(false);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState<{
    message: string;
    type: string;
  }>({ message: "", type: "info" });

  const { navigateError } = useNavigationError();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  const handlePinCodeSkipChange = async () => {
    const reversed = !isPinCodeSkipped;
    const body = { is_point_transfer_pin_code_skipped: reversed };

    await axiosAccountInstance
      .patch("/api/account", body)
      .then(() => {
        // biome-ignore suppressions/unused: need not to watch
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        navigateError({
          error: error,
          endpoint: "/api/account",
        });
      })
      .finally(() => {
        const updatedUser = {
          ...state.data.user,
          isPointTransferPinCodeSkipped: reversed,
        };

        dispatch({
          type: FETCH_SUCCESS,
          data: {
            ...state.data,
            user: updatedUser,
          },
        });

        setIsPinCodeSkipped(reversed);
      });
  };

  useEffect(() => {
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    if (!hasRunOnce && state.data && state.data.user) {
      setIsPinCodeSkipped(state.data.user.isPointTransferPinCodeSkipped);
      setHasRunOnce(true);
    }
  }, [state.data, state.data ? state.data.user : null, hasRunOnce]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    if (location.state?.snackbar) {
      setOpenSnackbar(true);
      setSnackbarContent(location.state.snackbar);
      navigate(((p): string => (p ? `/${p}_portal/account` : "/"))(portalType), { state: {} });
    }
  }, [location.state, navigate]);

  return (
    <Container>
      <Helmet title={t("MY_PAGE")} />
      <LayoutVertical>
        <Typography variant="h1">{t("MY_PAGE")}</Typography>
        {state.loading || !state.data?.user.driverCode ? (
          <Loader url={`/${portalType}_portal/account/error/500`} />
        ) : (
          <>
            <SnackbarAlert
              type={snackbarContent.type as AlertType}
              message={snackbarContent.message}
              onClose={() => {
                setOpenSnackbar(false);
              }}
              open={openSnackbar}
            />
            <CardAccount
              customerName={state.data.user.driverName}
              infoMessage={
                state.data.user.mappingRequests.some(
                  (mappingRequest: MappingRequest) => mappingRequest.state === "unapplied"
                ) && t("AVAILABLE_DRIVER_NUMBER_NOTICE")
              }
              enableWelcomeBack={false}
            >
              <Box textAlign="left" style={{ marginTop: "24px" }}>
                <CrewTable
                  crewNumber={state.data.user.driverCode}
                  companyName={state.data.user.companyName}
                  officeName={state.data.user.officeName}
                  portalType={portalType}
                />
              </Box>

              {state.data.user.mappingRequests.map(
                (mappingRequest: MappingRequest, index: number) =>
                  !["cancelled", "revoked", "rejected"].includes(mappingRequest.state) && (
                    <Box key={index} textAlign="left" style={{ marginTop: "24px" }}>
                      <CrewTable
                        crewNumber={mappingRequest.subDriverCode}
                        companyName={mappingRequest.subCompanyName}
                        officeName={mappingRequest.subOfficeName}
                        state={mappingRequest.state}
                        driverId={mappingRequest.subDriverId}
                        appliedAt={mappingRequest.appliedAt}
                        portalType={portalType}
                      />
                    </Box>
                  )
              )}
            </CardAccount>
            {!!state.data.user.driverScore && (
              <Card>
                <Typography variant="h2" gutterBottom>
                  {t("DRIVER_SCORE")}
                </Typography>

                <Box textAlign="center">
                  <Typography
                    style={{
                      fontSize: "32px",
                      fontWeight: "bold",
                      marginBottom: "8px",
                    }}
                  >
                    {state.data.user.driverScore}
                  </Typography>
                  <RatingStars rating={state.data.user.driverScore} />
                </Box>

                <Box textAlign="center" style={{ marginTop: "24px" }}>
                  <ButtonTextLink to={supportUrls.WHAT_IS_DRIVER_SCORE} target="_blank">
                    {t("WHAT_IS_DRIVER_SCORE")}
                  </ButtonTextLink>
                </Box>
              </Card>
            )}
            <Card>
              <Typography variant="h2" gutterBottom>
                {t("ACCOUNT")}
              </Typography>

              {/* 乗務員のみ */}
              {portalType === "crew" && (
                <>
                  <Typography variant="h3" gutterBottom>
                    {t("PHONE_NUMBER")}
                  </Typography>
                  <Typography>{state.data.user.maskedPhoneNumber}</Typography>
                  <LayoutButton sx={{ justifyContent: "flex-end" }}>
                    <ButtonOutlinedLink to={"/crew_portal/account/phone_number_change/auth_mail"}>
                      {t("EDIT")}
                    </ButtonOutlinedLink>
                  </LayoutButton>

                  <Divider />
                </>
              )}

              <Typography variant="h3" gutterBottom>
                {t("EMAIL")}
              </Typography>
              <Typography>{state.data.user.mailAddress}</Typography>
              <LayoutButton sx={{ justifyContent: "flex-end" }}>
                {portalType === "crew" ? (
                  <ButtonOutlinedLink to="/crew_portal/account/mail_address_change/auth_sms">
                    {t("EDIT")}
                  </ButtonOutlinedLink>
                ) : isMobile ? (
                  <ButtonOutlined
                    onClick={() => {
                      navigateToAppStore();
                    }}
                  >
                    {t("EDIT")}
                  </ButtonOutlined>
                ) : (
                  <ButtonOutlinedLink to="/driver_portal/mobile_device_warning">{t("EDIT")}</ButtonOutlinedLink>
                )}
              </LayoutButton>

              <Divider />

              <Typography variant="h3" gutterBottom>
                {t("PASSWORD")}
              </Typography>
              <LayoutButton sx={{ justifyContent: "flex-end" }}>
                {portalType === "crew" ? (
                  <ButtonOutlinedLink to="/crew_portal/account/password_change/auth_mail">
                    {t("EDIT")}
                  </ButtonOutlinedLink>
                ) : isMobile ? (
                  <ButtonOutlined
                    onClick={() => {
                      navigateToAppStore();
                    }}
                  >
                    {t("EDIT")}
                  </ButtonOutlined>
                ) : (
                  <ButtonOutlinedLink to="/driver_portal/mobile_device_warning">{t("EDIT")}</ButtonOutlinedLink>
                )}
              </LayoutButton>
            </Card>

            <Card>
              <Typography variant="h2" gutterBottom>
                {t("SETTINGS")}
              </Typography>
              <Typography variant="h3" gutterBottom>
                {t("POINT_EXCHANGE_APPLICATION")}
              </Typography>
              <FormControlLabel
                fullWidth
                checked={isPinCodeSkipped}
                control={<Switch onChange={handlePinCodeSkipChange} name="isPinCodeSkipped" />}
                label={t("SKIP_PIN_CODE_INPUT_NEXT_TIME")}
                labelPlacement="start"
              />
            </Card>
          </>
        )}

        <Box pt={4} pb={4}>
          <LayoutButtonWithTypography>
            <Typography>{((p): string => (p ? t(`QUIT_GO_${p}_PORTAL`) : ""))(portalTypeUpper)}</Typography>
            <ButtonLogout />
          </LayoutButtonWithTypography>
        </Box>
      </LayoutVertical>
    </Container>
  );
};
