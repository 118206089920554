import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Box, Chip, Container, LayoutVertical, Loader, StepCard, Typography } from "@/components";
import { FetchContext as context } from "@/contexts/FetchContext";
import getPortalType from "@/utils/getPortalType";

import { PhoneNumberChangeAuthMailForm } from "./components/PhoneNumberChange/Forms";

export const PhoneNumberChangeAuthMail = () => {
  const { t } = useTranslation();
  const { state } = useContext(context);
  const mailAddress = state.data?.user?.mailAddress || "";

  return (
    <Container>
      <Helmet title={t("CHANGE_PHONE_NUMBER")} />
      <LayoutVertical>
        {state.loading || !state.data?.user.driverCode ? (
          <Loader url={((p): string => (p ? `/${p}_portal/account/error/500` : "/"))(getPortalType())} />
        ) : (
          <>
            <Typography variant="h1">{t("CHANGE_PHONE_NUMBER")}</Typography>
            <StepCard activestep={0} steplength={5}>
              <Typography variant="h2" gutterBottom>
                {t("PLEASE_SEND_PIN_CODE")}
              </Typography>
              <Typography>{t("PLEASE_SEND_PIN_CODE_BY_EMAIL")}</Typography>
              {mailAddress && (
                <Box textAlign="center" style={{ marginTop: "24px" }}>
                  <Chip label={state.data.user.mailAddress} size="medium" />
                  <PhoneNumberChangeAuthMailForm />
                </Box>
              )}
            </StepCard>
          </>
        )}
      </LayoutVertical>
    </Container>
  );
};
