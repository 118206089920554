import { styled } from "@mui/material";

import { AlertBox, AlertBoxProps } from "@/components";
import { COLORS } from "@/utils/colors";

type StyleProps = {
  strong?: boolean;
};

export type Props = Omit<AlertBoxProps, "severity" | "icon"> & StyleProps;

export const AlertInfo: React.FC<Props> = (props) => {
  return <StyledAlert {...props} severity="info" />;
};

const StyledAlert = styled(AlertBox, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "strong",
})<StyleProps>(({ strong }) => ({
  backgroundColor: COLORS.BG_03,
  color: strong ? COLORS.PRIMARY : COLORS.TEXT_01,
}));
