import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, ButtonContained, Chip, Container, LayoutVertical, ModalBasic, StepCard, Typography } from "@/components";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { COLORS } from "@/utils/colors";
import axios from "axios";
export const PasswordResetAuthMail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { sendPinCode } = useAuth();
  const location = useLocation();
  const maskedEmail = location.state?.maskedEmail || "";

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = async () => {
    try {
      await sendPinCode();
      setIsModalOpen(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/password_reset/auth_mail",
        });
      }
    }
  };

  const handleClose = async () => {
    navigate("/crew_portal/password_reset/auth_mail_code");
  };

  return (
    <Container>
      <Helmet title={t("RESET_PASSWORD")} />
      <ModalBasic
        open={isModalOpen}
        onClose={() => {
          // biome-ignore suppressions/unused: need to keep empty block
        }}
        title={t("EMAIL_SENT_FOR_AUTHENTICATION")}
        actionButton={{
          label: t("NEXT"),
          onClick: handleClose,
        }}
      >
        <span style={{ color: COLORS.WARNING, fontWeight: "bold" }}>{t("PLEASE_CHECK_YOUR_EMAIL")}</span>
      </ModalBasic>
      <LayoutVertical>
        <StepCard activestep={1} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_SEND_PIN_CODE")}
          </Typography>
          <Typography component="h3" variant="body1">
            {t("PLEASE_SEND_PIN_CODE_BY_EMAIL")}
          </Typography>
          <Box textAlign="center" style={{ marginTop: "24px" }}>
            <Chip label={maskedEmail} size="medium" />
          </Box>
          <Box textAlign="center" style={{ marginTop: "24px" }}>
            <ButtonContained type="button" color="primary" size="large" onClick={onSubmit}>
              {t("SEND_PIN_CODE")}
            </ButtonContained>
          </Box>
        </StepCard>
      </LayoutVertical>
    </Container>
  );
};
