import { DEVELOPMENT_ENV, ENVIRONMENT, LOCAL_ENV, PROD_ENV, QA_ENV } from "../constants";

export const isProd = (): boolean => {
  return ENVIRONMENT === PROD_ENV;
};

export const isQa = (): boolean => {
  return ENVIRONMENT === QA_ENV;
};

export const isDevelopment = (): boolean => {
  return ENVIRONMENT === DEVELOPMENT_ENV;
};

export const isLocal = (): boolean => {
  return ENVIRONMENT === LOCAL_ENV;
};

export const isProdOrQa = (): boolean => {
  return isProd() || isQa();
};

export const ignoreProdOrQaPermission = (permission: string): string => {
  if (isProdOrQa()) {
    return `${permission}:ignore`;
  }
  return permission;
};
