import { Navigate } from "react-router-dom";

import {
  Account,
  MailAddressChangeAuthMail,
  MailAddressChangeAuthMailCode,
  MailAddressChangeAuthSms,
  MailAddressChangeAuthSmsCode,
  PasswordChangeAuthMail,
  PasswordChangeAuthMailCode,
  PasswordChangePassword,
  PhoneNumberChangeAuthMail,
  PhoneNumberChangeAuthMailCode,
  PhoneNumberChangeAuthSms,
  PhoneNumberChangeAuthSmsCode,
} from "@/features/common/Account";
import { Auth as AuthLayout } from "@/features/common/Auth";
import { Contact, ContactReceived } from "@/features/common/Contact";
import { Dashboard } from "@/features/common/Dashboard";
import {
  DebugAccountEncrypted,
  DebugExpiredToken,
  DebugLayout,
  DebugMappingRequestState,
  DebugResetIssueCode,
  DebugTop,
  DebugUpdateGmoBankRemittanceStatus,
} from "@/features/common/Debug";
import { DebugTransferFreeFee } from "@/features/common/Debug/components/DebugTransferFreeFee";
import Error400 from "@/features/common/Error400";
import Error401 from "@/features/common/Error401";
import Error404 from "@/features/common/Error404";
import Error409 from "@/features/common/Error409";
import Error423 from "@/features/common/Error423";
import Error429 from "@/features/common/Error429";
import Error500 from "@/features/common/Error500";
import Error503 from "@/features/common/Error503";
import ErrorLoggedIn from "@/features/common/ErrorLoggedIn";
import ErrorSystem from "@/features/common/ErrorSystem";
import { Notification, Notifications } from "@/features/common/Notifications";
import {
  GmoBankAccountRegistrationResult,
  History,
  Points,
  PointsExpire,
  Transfer,
  TransferAccepted,
  TransferConfirm,
  TransferGmoLink,
  TransferHistories,
  TransferHistory,
} from "@/features/common/Points";
import { Top } from "@/features/common/Top";
import { MappingRequestAuthSmsCode, MappingRequestConfirm } from "@/features/crew/Account";
import { Login as CrewLogin } from "@/features/crew/Login/Login";
import {
  PasswordReset,
  PasswordResetAuthMail,
  PasswordResetAuthMailCode,
  PasswordResetPassword,
} from "@/features/crew/PasswordReset";
import { TransferAuthSmsCode, TransferGmoLinkAuthSms } from "@/features/crew/Points";
import { AuthMail, AuthMailCode, Password, Register } from "@/features/crew/Register";
import { Login as DriverLogin } from "@/features/driver/Login/Login";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import { ModalProvider } from "./contexts/ModalContext";
import Error403 from "./features/common/Error403";
import { MobileDeviceWarning, TermsOfUse } from "./features/driver/Login";
import { TransferAuthMailCode, TransferGmoLinkAuthMail } from "./features/driver/Points";
import { isProd } from "./utils/env";

// FE側メンテナンスモード (例: portlandDBメンテナンス時)
const isCrewMaintenanceMode = false;
const isDriverMaintenanceMode = false;
const maintenancePeriod = null; // 例: { startAt: "2024-01-01T00:00:00Z", endAt: "2024-01-01T01:00:00Z" }

const routes = [
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        // 乗務員ポータルログイン画面へリダイレクト
        element: <Navigate to="/crew_portal/login" />,
      },
    ],
  },
  {
    path: "r",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/crew_portal/register" />,
      },
    ],
  },
  // 乗務員ポータル
  ...(isCrewMaintenanceMode
    ? [
        {
          path: "crew_portal/*",
          index: true,
          element: <Navigate to="/crew_portal/503" state={maintenancePeriod} />,
        },
      ]
    : [
        {
          path: "crew_portal",
          element: (
            <AuthGuard>
              <ModalProvider>
                <Dashboard />
              </ModalProvider>
            </AuthGuard>
          ),
          children: [
            {
              path: "",
              element: <Navigate to="/crew_portal/top" />,
            },
            {
              path: "top",
              element: <Top />,
            },
            { path: "top/error/:id", element: <ErrorLoggedIn /> },
            { path: "points", element: <Points /> },
            { path: "points/expire/:id", element: <PointsExpire /> },
            { path: "points/exchange", element: <Points /> },
            { path: "points/exchange/accepted", element: <Points /> },
            { path: "points/exchange/auth_sms_code", element: <Points /> },
            { path: "points/exchange/confirm", element: <Points /> },
            { path: "points/exchange/transfer", element: <Points /> },
            { path: "points/history", element: <History /> },
            { path: "points/transfer", element: <Transfer /> },
            { path: "points/transfer/detail", element: <Points /> },
            {
              path: "points/transfer/histories",
              element: <TransferHistories />,
            },
            {
              path: "points/transfer/history/:id",
              element: <TransferHistory />,
            },
            { path: "points/error/:id", element: <ErrorLoggedIn /> },
            { path: "notifications", element: <Notifications /> },
            { path: "notification/:id", element: <Notification /> },
            { path: "notifications/error/:id", element: <ErrorLoggedIn /> },
            { path: "account", element: <Account /> },
            {
              path: "account/password_change/auth_mail",
              element: <PasswordChangeAuthMail />,
            },
            {
              path: "account/password_change/auth_mail_code",
              element: <PasswordChangeAuthMailCode />,
            },
            {
              path: "account/password_change/password",
              element: <PasswordChangePassword />,
            },
            {
              path: "account/phone_number_change/auth_mail",
              element: <PhoneNumberChangeAuthMail />,
            },
            {
              path: "account/phone_number_change/auth_mail_code",
              element: <PhoneNumberChangeAuthMailCode />,
            },
            {
              path: "account/phone_number_change/auth_sms",
              element: <PhoneNumberChangeAuthSms />,
            },
            {
              path: "account/phone_number_change/auth_sms_code",
              element: <PhoneNumberChangeAuthSmsCode />,
            },
            {
              path: "account/mail_address_change/auth_sms",
              element: <MailAddressChangeAuthSms />,
            },
            {
              path: "account/mail_address_change/auth_sms_code",
              element: <MailAddressChangeAuthSmsCode />,
            },
            {
              path: "account/mail_address_change/auth_mail",
              element: <MailAddressChangeAuthMail />,
            },
            {
              path: "account/mail_address_change/auth_mail_code",
              element: <MailAddressChangeAuthMailCode />,
            },
            {
              path: "account/mapping_request/auth_sms_code",
              element: <MappingRequestAuthSmsCode />,
            },
            {
              path: "account/mapping_request/confirm",
              element: <MappingRequestConfirm />,
            },
            { path: "account/error/:id", element: <ErrorLoggedIn /> },
            { path: "contact_account", element: <Contact isLoggedin /> },
            { path: "contact_account/received", element: <ContactReceived /> },
          ],
        },
        {
          path: "crew_portal",
          element: (
            <AuthGuard>
              <ModalProvider>
                <Dashboard hideNavigation />
              </ModalProvider>
            </AuthGuard>
          ),
          children: [
            { path: "points/transfer/accepted", element: <TransferAccepted /> },
            {
              path: "points/transfer/auth_sms_code",
              element: <TransferAuthSmsCode />,
            },
            { path: "points/transfer/confirm", element: <TransferConfirm /> },
            { path: "points/transfer/gmo_link", element: <TransferGmoLink /> },
            {
              path: "points/transfer/gmo_link/auth_sms",
              element: <TransferGmoLinkAuthSms />,
            },
            {
              path: "points/gmo_bank_account_registration_result",
              element: <GmoBankAccountRegistrationResult />,
            },
            { path: "points/transfer/error/:id", element: <ErrorLoggedIn /> },
          ],
        },
        {
          path: "crew_portal",
          element: (
            <ModalProvider>
              <AuthLayout />
            </ModalProvider>
          ),
          children: [
            { path: "login", element: <CrewLogin /> },
            { path: "register", element: <Register /> },
            { path: "register/auth_mail", element: <AuthMail /> },
            { path: "register/auth_mail_code", element: <AuthMailCode /> },
            { path: "register/password", element: <Password /> },
            { path: "password_reset", element: <PasswordReset /> },
            {
              path: "password_reset/auth_mail",
              element: <PasswordResetAuthMail />,
            },
            {
              path: "password_reset/auth_mail_code",
              element: <PasswordResetAuthMailCode />,
            },
            {
              path: "password_reset/password",
              element: <PasswordResetPassword />,
            },
            { path: "contact", element: <Contact /> },
            { path: "contact/received", element: <ContactReceived /> },
          ],
        },
      ]),
  {
    path: "crew_portal",
    element: <AuthLayout isError={true} />,
    children: [
      {
        path: "400",
        element: <Error400 />,
      },
      {
        path: "401",
        element: <Error401 />,
      },
      {
        path: "403",
        element: <Error403 />,
      },
      {
        path: "404",
        element: <Error404 />,
      },
      {
        path: "409",
        element: <Error409 />,
      },
      {
        path: "423",
        element: <Error423 />,
      },
      {
        path: "429",
        element: <Error429 />,
      },
      {
        path: "500",
        element: <Error500 />,
      },
      {
        path: "503",
        element: <Error503 />,
      },
      {
        path: "error",
        element: <ErrorSystem />,
      },
    ],
  },
  // ドライバーポータル
  ...(isDriverMaintenanceMode
    ? [
        {
          path: "driver_portal/*",
          index: true,
          element: <Navigate to="/driver_portal/503" state={maintenancePeriod} />,
        },
      ]
    : [
        {
          path: "driver_portal",
          element: (
            <AuthGuard>
              <ModalProvider>
                <Dashboard />
              </ModalProvider>
            </AuthGuard>
          ),
          children: [
            {
              path: "",
              element: <Navigate to="/driver_portal/top" />,
            },
            {
              path: "top",
              element: <Top />,
            },
            { path: "top/error/:id", element: <ErrorLoggedIn /> },
            { path: "points", element: <Points /> },
            { path: "points/expire/:id", element: <PointsExpire /> },
            { path: "points/exchange", element: <Points /> },
            { path: "points/exchange/accepted", element: <Points /> },
            { path: "points/exchange/auth_mail_code", element: <Points /> },
            { path: "points/exchange/confirm", element: <Points /> },
            { path: "points/exchange/transfer", element: <Points /> },
            { path: "points/history", element: <History /> },
            { path: "points/transfer", element: <Transfer /> },
            { path: "points/transfer/detail", element: <Points /> },
            {
              path: "points/transfer/histories",
              element: <TransferHistories />,
            },
            {
              path: "points/transfer/history/:id",
              element: <TransferHistory />,
            },
            { path: "points/error/:id", element: <ErrorLoggedIn /> },
            { path: "notifications", element: <Notifications /> },
            { path: "notification/:id", element: <Notification /> },
            { path: "notifications/error/:id", element: <ErrorLoggedIn /> },
            { path: "account", element: <Account /> },
            { path: "account/error/:id", element: <ErrorLoggedIn /> },
            { path: "contact_account", element: <Contact isLoggedin /> },
            { path: "contact_account/received", element: <ContactReceived /> },
          ],
        },
        {
          path: "driver_portal",
          element: (
            <AuthGuard>
              <ModalProvider>
                <Dashboard hideNavigation />
              </ModalProvider>
            </AuthGuard>
          ),
          children: [
            { path: "points/transfer/accepted", element: <TransferAccepted /> },
            {
              path: "points/transfer/auth_mail_code",
              element: <TransferAuthMailCode />,
            },
            { path: "points/transfer/confirm", element: <TransferConfirm /> },
            { path: "points/transfer/gmo_link", element: <TransferGmoLink /> },
            {
              path: "points/transfer/gmo_link/auth_mail",
              element: <TransferGmoLinkAuthMail />,
            },
            {
              path: "points/gmo_bank_account_registration_result",
              element: <GmoBankAccountRegistrationResult />,
            },
            { path: "points/transfer/error/:id", element: <ErrorLoggedIn /> },
          ],
        },
        {
          path: "driver_portal",
          element: (
            <ModalProvider>
              <AuthLayout />
            </ModalProvider>
          ),
          children: [
            { path: "login", element: <DriverLogin /> },
            { path: "contact", element: <Contact /> },
            { path: "contact/received", element: <ContactReceived /> },
          ],
        },
      ]),
  {
    path: "driver_portal",
    element: <AuthLayout isError={true} />,
    children: [
      {
        path: "400",
        element: <Error400 />,
      },
      {
        path: "401",
        element: <Error401 />,
      },
      {
        path: "403",
        element: <Error403 />,
      },
      {
        path: "404",
        element: <Error404 />,
      },
      {
        path: "409",
        element: <Error409 />,
      },
      {
        path: "423",
        element: <Error423 />,
      },
      {
        path: "429",
        element: <Error429 />,
      },
      {
        path: "500",
        element: <Error500 />,
      },
      {
        path: "503",
        element: <Error503 />,
      },
      {
        path: "error",
        element: <ErrorSystem />,
      },
      {
        path: "mobile_device_warning",
        element: <MobileDeviceWarning />,
      },
      // TODO: ドライバーアプリ対応後に消す
      {
        path: "support/delete_account",
        element: <TermsOfUse />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout isError={true} />,
    children: [
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
  ...(!isProd()
    ? [
        {
          path: "debug",
          element: <DebugLayout />,
          children: [
            {
              path: "transfer_free_fee",
              element: <DebugTransferFreeFee />,
            },
            {
              path: "reset_issue_code",
              element: <DebugResetIssueCode />,
            },
            {
              path: "expired_token",
              element: <DebugExpiredToken />,
            },
            {
              path: "account/encrypted",
              element: <DebugAccountEncrypted />,
            },
            {
              path: "update_gmo_bank_remittance_status",
              element: <DebugUpdateGmoBankRemittanceStatus />,
            },
            {
              path: "mapping_request_state",
              element: <DebugMappingRequestState />,
            },
            {
              path: "*",
              element: <DebugTop />,
            },
          ],
        },
      ]
    : []),
];

export default routes;
