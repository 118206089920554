import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  ButtonContained,
  Checkbox,
  FormBox,
  FormControlLabel,
  ModalBasic,
  TextField,
  Typography,
} from "@/components";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";
import {
  codeLength,
  passwordConfirmValidator,
  passwordValidator,
  pinCodeValidator,
  validationSchema,
} from "@/utils/validators";
import axios from "axios";
export const PasswordChangeAuthMailForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyAccountEmail } = useAuth();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const portalType = getPortalType();

  const onSubmit = async () => {
    try {
      await verifyAccountEmail("password");
      setIsModalOpen(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/password_change/auth_mail",
          appName: "CHANGE_PASSWORD",
          path: "account",
        });
      }
    }
  };

  const handleClose = async () => {
    navigate(((p): string => (p ? `/${p}_portal/account/password_change/auth_mail_code` : "/"))(portalType));
  };

  return (
    <>
      <ModalBasic
        open={isModalOpen}
        onClose={() => {
          // biome-ignore suppressions/unused: need not to use
        }}
        title={t("EMAIL_SENT_FOR_AUTHENTICATION")}
        actionButton={{
          label: t("NEXT"),
          onClick: handleClose,
        }}
      >
        <span style={{ color: COLORS.WARNING, fontWeight: "bold" }}>{t("PLEASE_CHECK_YOUR_EMAIL")}</span>
      </ModalBasic>
      <Typography variant="h2" align="center" />
      <ButtonContained type="submit" color="primary" onClick={onSubmit} style={{ marginTop: "24px" }} size="large">
        {t("SEND_PIN_CODE")}
      </ButtonContained>
    </>
  );
};

export const PasswordChangeAuthMailCodeForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyAccountPinCode } = useAuth();
  const { t } = useTranslation();
  const portalType = getPortalType();
  const [submitError] = useState("");
  type FormData = {
    pinCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        pinCode: pinCodeValidator,
      })
    ),
    defaultValues: {
      pinCode: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyAccountPinCode("password", data.pinCode);
      navigate(((p): string => (p ? `/${p}_portal/account/password_change/password` : "/"))(portalType));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/password_change/auth_mail_code",
          appName: "CHANGE_PASSWORD",
          path: "account",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="pinCodeForm">
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="pinCode"
          control={control}
          defaultValue=""
          rules={{ required: t("PIN_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
                if (e.target.value.length === codeLength) {
                  handleSubmit(onSubmit)();
                }
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("PIN_CODE")}
              fullWidth
              error={Boolean(errors.pinCode)}
              helperText={errors.pinCode?.message}
              inputProps={{ maxLength: 4 }}
              style={{ marginTop: "24px" }}
            />
          )}
        />
      </form>
    </>
  );
};

export const PasswordChangePasswordForm = () => {
  const [showPassword, setShowPassword] = useState(true);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { changePassword } = useAuth();
  const { t } = useTranslation();
  const portalType = getPortalType();
  type FormData = {
    password: string;
    passwordConfirmation: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        password: passwordValidator,
        passwordConfirmation: passwordConfirmValidator,
      })
    ),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await changePassword(data.password);
      navigate(((p): string => (p ? `/${p}_portal/account` : "/"))(portalType), {
        state: { status: "PASSWORD_SUCCESS" },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/password_change/password",
          appName: "CHANGE_PASSWORD",
          path: "account",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="passwordForm">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: t("PASSWORD_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PASSWORD_NOTE")}>
              <TextField
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type={showPassword ? "text" : "password"}
                label={t("NEW_PASSWORD")}
                fullWidth
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                style={{ marginTop: "24px" }}
                inputProps={{ maxLength: 64, inputMode: "url" }} // NOTE: パスワード設定時のみurlキーボードを利用する(Crew向け)
              />
            </FormBox>
          )}
        />
        <Controller
          name="passwordConfirmation"
          control={control}
          defaultValue=""
          rules={{ required: t("PASSWORD_CONFIRM_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PASSWORD_CONFIRM_NOTE")}>
              <TextField
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type={showPassword ? "text" : "password"}
                label={t("NEW_PASSWORD_CONFIRM")}
                fullWidth
                error={Boolean(errors.passwordConfirmation)}
                helperText={errors.passwordConfirmation?.message}
                style={{ marginTop: "24px" }}
                inputProps={{ maxLength: 64, inputMode: "url" }} // NOTE: パスワード設定時のみurlキーボードを利用する(Crew向け)
              />
            </FormBox>
          )}
        />
        <FormControlLabel
          control={<Checkbox checked={showPassword} onChange={handleShowPassword} name="show_password" />}
          label={t("SHOW_PASSWORD")}
          style={{ marginTop: "24px" }}
        />
        <Box textAlign="center">
          <ButtonContained
            type="submit"
            color="primary"
            disabled={!isValid || isSubmitting}
            size="large"
            style={{ marginTop: "24px" }}
          >
            {t("SET")}
          </ButtonContained>
        </Box>
      </form>
    </>
  );
};
