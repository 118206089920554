export type Props = {
  size?: string;
  rate: number;
  style?: React.CSSProperties;
};

type Variant = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

type StarMap = {
  [key in Variant]: React.ReactNode;
};

const starMap: StarMap = {
  0: (
    <>
      <path
        d="M12 19.7798L5.3511 23.8987C4.83806 24.2165 4.21072 23.7387 4.3405 23.1289L6.0224 15.2266L0.231253 9.86996C-0.215599 9.45663 0.0240222 8.68347 0.617266 8.62444L8.30564 7.85948L11.3754 0.429953C11.6123 -0.143318 12.3877 -0.143318 12.6246 0.429954L15.6944 7.85948L23.3827 8.62444C23.976 8.68347 24.2156 9.45663 23.7687 9.86996L17.9776 15.2266L19.6595 23.1289C19.7893 23.7387 19.1619 24.2165 18.6489 23.8987L12 19.7798Z"
        fill="#DDDDDD"
      />
    </>
  ),
  1: (
    <>
      <path
        d="M12.3342 19.7798L5.68533 23.8987C5.17229 24.2165 4.54495 23.7387 4.67473 23.1289L6.35663 15.2266L0.565481 9.86996C0.11863 9.45663 0.358251 8.68347 0.951495 8.62444L8.63987 7.85948L11.7096 0.429953C11.9465 -0.143318 12.7219 -0.143318 12.9588 0.429954L16.0286 7.85948L23.717 8.62444C24.3102 8.68347 24.5498 9.45663 24.103 9.86996L18.3118 15.2266L19.9937 23.1289C20.1235 23.7387 19.4962 24.2165 18.9831 23.8987L12.3342 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.73423 11.8764L0.565481 9.8704C0.406297 9.72316 0.334229 9.53026 0.334229 9.34024C0.334229 8.99685 0.569585 8.66288 0.951495 8.62488L2.73423 8.44751V11.8764Z"
        fill="#E2CE32"
      />
    </>
  ),
  2: (
    <>
      <path
        d="M12.6686 19.7798L6.01968 23.8987C5.50664 24.2165 4.8793 23.7387 5.00908 23.1289L6.69098 15.2266L0.899832 9.86996C0.45298 9.45663 0.692601 8.68347 1.28585 8.62444L8.97422 7.85948L12.044 0.429953C12.2809 -0.143318 13.0563 -0.143318 13.2932 0.429954L16.3629 7.85948L24.0513 8.62444C24.6446 8.68347 24.8842 9.45663 24.4373 9.86996L18.6462 15.2266L20.3281 23.1289C20.4579 23.7387 19.8305 24.2165 19.3175 23.8987L12.6686 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46859 8.20825L1.28585 8.62442C0.903936 8.66242 0.668579 8.99638 0.668579 9.33977C0.668579 9.52979 0.740648 9.72269 0.899832 9.86994L5.46859 14.0959V8.20825ZM5.46859 20.9699V23.9659C5.15641 23.8603 4.92542 23.522 5.00908 23.1289L5.46859 20.9699Z"
        fill="#E2CE32"
      />
    </>
  ),
  3: (
    <>
      <path
        d="M12.0029 19.7798L5.35403 23.8987C4.84099 24.2165 4.21365 23.7387 4.34343 23.1289L6.02533 15.2266L0.234183 9.86996C-0.212669 9.45663 0.0269519 8.68347 0.620196 8.62444L8.30857 7.85948L11.3783 0.429953C11.6152 -0.143318 12.3906 -0.143318 12.6275 0.429954L15.6973 7.85948L23.3857 8.62444C23.9789 8.68347 24.2185 9.45663 23.7717 9.86996L17.9805 15.2266L19.6624 23.1289C19.7922 23.7387 19.1649 24.2165 18.6518 23.8987L12.0029 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00292969 9.33906C0.00292969 8.99567 0.238286 8.6617 0.620196 8.62371L7.20295 7.96875V22.7526L5.35403 23.898C4.84099 24.2158 4.21365 23.7379 4.34343 23.1282L6.02533 15.2259L0.234183 9.86922C0.0749982 9.72198 0.00292969 9.52908 0.00292969 9.33906Z"
        fill="#E2CE32"
      />
    </>
  ),
  4: (
    <>
      <path
        d="M12.3372 19.7798L5.68826 23.8987C5.17522 24.2165 4.54788 23.7387 4.67766 23.1289L6.35956 15.2266L0.568411 9.86996C0.121559 9.45663 0.36118 8.68347 0.954425 8.62444L8.6428 7.85948L11.7126 0.429953C11.9494 -0.143318 12.7249 -0.143318 12.9617 0.429954L16.0315 7.85948L23.7199 8.62444C24.3131 8.68347 24.5528 9.45663 24.1059 9.86996L18.3148 15.2266L19.9967 23.1289C20.1264 23.7387 19.4991 24.2165 18.9861 23.8987L12.3372 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.337158 9.33907C0.337158 8.99568 0.572515 8.66172 0.954425 8.62372L8.6428 7.85876L9.93718 4.72607V21.2658L5.68826 23.898C5.17522 24.2158 4.54788 23.738 4.67766 23.1282L6.35956 15.2259L0.568411 9.86924C0.409227 9.722 0.337158 9.52909 0.337158 9.33907Z"
        fill="#E2CE32"
      />
    </>
  ),
  5: (
    <>
      <path
        d="M12.6714 19.7798L6.02248 23.8987C5.50945 24.2165 4.88211 23.7387 5.01189 23.1289L6.69378 15.2266L0.90264 9.86996C0.455788 9.45663 0.695409 8.68347 1.28865 8.62444L8.97703 7.85948L12.0468 0.429953C12.2837 -0.143318 13.0591 -0.143318 13.296 0.429954L16.3657 7.85948L24.0541 8.62444C24.6474 8.68347 24.887 9.45663 24.4401 9.86996L18.649 15.2266L20.3309 23.1289C20.4607 23.7387 19.8333 24.2165 19.3203 23.8987L12.6714 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.02248 23.898L12.6714 19.779L12.6714 19.7791V4.97316e-08L12.6404 0C12.3979 0.0112322 12.1604 0.154311 12.0468 0.429236L8.97703 7.85876L1.28865 8.62372C0.906743 8.66172 0.671387 8.99569 0.671387 9.33908C0.671387 9.5291 0.743455 9.722 0.90264 9.86924L6.69378 15.2259L5.01189 23.1282C4.88211 23.738 5.50945 24.2158 6.02248 23.898Z"
        fill="#E2CE32"
      />
    </>
  ),
  6: (
    <>
      <path
        d="M12.0057 19.7798L5.35683 23.8987C4.8438 24.2165 4.21646 23.7387 4.34624 23.1289L6.02813 15.2266L0.23699 9.86996C-0.209862 9.45663 0.0297595 8.68347 0.623004 8.62444L8.31138 7.85948L11.3812 0.429953C11.618 -0.143318 12.3935 -0.143318 12.6303 0.429954L15.7001 7.85948L23.3885 8.62444C23.9817 8.68347 24.2213 9.45663 23.7745 9.86996L17.9833 15.2266L19.6652 23.1289C19.795 23.7387 19.1677 24.2165 18.6546 23.8987L12.0057 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.35683 23.898L12.0057 19.779L14.4058 21.2658V4.72621L12.6303 0.429236C12.5167 0.154311 12.2793 0.0112322 12.0367 6.74845e-10L11.9747 0C11.7322 0.0112322 11.4948 0.154311 11.3812 0.429236L8.31138 7.85876L0.623004 8.62372C0.241094 8.66172 0.0057373 8.99569 0.0057373 9.33908C0.0057373 9.5291 0.0778058 9.722 0.23699 9.86924L6.02813 15.2259L4.34624 23.1282C4.21646 23.738 4.8438 24.2158 5.35683 23.898Z"
        fill="#E2CE32"
      />
    </>
  ),
  7: (
    <>
      <path
        d="M12.3401 19.7798L5.69119 23.8987C5.17815 24.2165 4.55081 23.7387 4.68059 23.1289L6.36249 15.2266L0.571341 9.86996C0.124489 9.45663 0.36411 8.68347 0.957354 8.62444L8.64573 7.85948L11.7155 0.429953C11.9524 -0.143318 12.7278 -0.143318 12.9647 0.429954L16.0344 7.85948L23.7228 8.62444C24.3161 8.68347 24.5557 9.45663 24.1088 9.86996L18.3177 15.2266L19.9996 23.1289C20.1294 23.7387 19.502 24.2165 18.989 23.8987L12.3401 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.69119 23.898L12.3401 19.779L17.1401 22.7526V7.96877L16.0344 7.85876L12.9647 0.429236C12.8511 0.154311 12.6136 0.0112322 12.3711 6.74845e-10L12.3091 0C12.0666 0.0112322 11.8291 0.154311 11.7155 0.429236L8.64573 7.85876L0.957354 8.62372C0.575445 8.66172 0.340088 8.99569 0.340088 9.33908C0.340088 9.5291 0.412156 9.722 0.571341 9.86924L6.36249 15.2259L4.68059 23.1282C4.55081 23.738 5.17815 24.2158 5.69119 23.898Z"
        fill="#E2CE32"
      />
    </>
  ),
  8: (
    <>
      <path
        d="M12.6743 19.7798L6.02541 23.8987C5.51238 24.2165 4.88504 23.7387 5.01482 23.1289L6.69671 15.2266L0.905569 9.86996C0.458717 9.45663 0.698339 8.68347 1.29158 8.62444L8.97995 7.85948L12.0497 0.429953C12.2866 -0.143318 13.062 -0.143318 13.2989 0.429954L16.3687 7.85948L24.057 8.62444C24.6503 8.68347 24.8899 9.45663 24.4431 9.86996L18.6519 15.2266L20.3338 23.1289C20.4636 23.7387 19.8363 24.2165 19.3232 23.8987L12.6743 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.02541 23.898L12.6743 19.779L19.3232 23.898C19.5055 24.0109 19.7023 24.0234 19.8744 23.9652V20.9695L18.6519 15.2259L19.8744 14.0952V8.20756L16.3687 7.85876L13.2989 0.429236C13.1853 0.154311 12.9478 0.0112322 12.7053 6.74845e-10L12.6433 0C12.4008 0.0112322 12.1633 0.154311 12.0497 0.429236L8.97995 7.85876L1.29158 8.62372C0.909673 8.66172 0.674316 8.99569 0.674316 9.33908C0.674316 9.5291 0.746385 9.722 0.905569 9.86924L6.69671 15.2259L5.01482 23.1282C4.88504 23.738 5.51238 24.2158 6.02541 23.898Z"
        fill="#E2CE32"
      />
    </>
  ),
  9: (
    <>
      <path
        d="M12.0085 19.7798L5.35964 23.8987C4.8466 24.2165 4.21927 23.7387 4.34905 23.1289L6.03094 15.2266L0.239798 9.86996C-0.207054 9.45663 0.0325671 8.68347 0.625811 8.62444L8.31418 7.85948L11.384 0.429953C11.6208 -0.143318 12.3963 -0.143318 12.6331 0.429954L15.7029 7.85948L23.3913 8.62444C23.9845 8.68347 24.2241 9.45663 23.7773 9.86996L17.9861 15.2266L19.668 23.1289C19.7978 23.7387 19.1705 24.2165 18.6574 23.8987L12.0085 19.7798Z"
        fill="#DDDDDD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.35964 23.898L12.0085 19.779L18.6574 23.898C19.1705 24.2158 19.7978 23.738 19.668 23.1282L17.9861 15.2259L21.6086 11.8752V8.44635L15.7029 7.85876L12.6331 0.429236C12.5195 0.154311 12.2821 0.0112322 12.0396 6.74845e-10L11.9775 0C11.735 0.0112322 11.4976 0.154311 11.384 0.429236L8.31418 7.85876L0.625811 8.62372C0.243902 8.66172 0.00854492 8.99569 0.00854492 9.33908C0.00854492 9.5291 0.0806134 9.722 0.239798 9.86924L6.03094 15.2259L4.34905 23.1282C4.21927 23.738 4.8466 24.2158 5.35964 23.898Z"
        fill="#E2CE32"
      />
    </>
  ),
  10: (
    <path
      d="M12.3429 19.7798L5.69399 23.8987C5.18096 24.2165 4.55362 23.7387 4.6834 23.1289L6.36529 15.2266L0.574148 9.86996C0.127297 9.45663 0.366918 8.68347 0.960162 8.62444L8.64853 7.85948L11.7183 0.429953C11.9552 -0.143318 12.7306 -0.143318 12.9675 0.429954L16.0373 7.85948L23.7256 8.62444C24.3189 8.68347 24.5585 9.45663 24.1116 9.86996L18.3205 15.2266L20.0024 23.1289C20.1322 23.7387 19.5048 24.2165 18.9918 23.8987L12.3429 19.7798Z"
      fill="#E2CE32"
    />
  ),
};

export const IconStar: React.FC<Props> = ({ size = "24", ...props }) => {
  const star = starMap[props.rate as Variant];
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.style}
    >
      {star}
    </svg>
  );
};
