import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import {
  AlertInfo,
  ButtonContainedLink,
  ButtonOutlinedLink,
  ButtonTextLink,
  Card,
  Container,
  LayoutButton,
  LayoutButtonWithTypography,
  LayoutVertical,
  Loader,
  Steps,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import { formatId } from "@/utils/formatter";
import getPortalType from "@/utils/getPortalType";

export const TransferConfirm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    // 直リンク対策
    if (!location.state || !location.state.transferId) {
      navigate(((p): string => (p ? `/${p}_portal/points/transfer` : "/"))(portalType));
    } else {
      setIsLoading(false);
    }
  }, [location.state, navigate]);

  return (
    <Container>
      <Helmet title={t("POINT_EXCHANGE_APPLICATION")} />

      <Steps active={4} length={4} gutterBottom />

      <LayoutVertical>
        <Typography variant="h1">{t("POINT_TRANSFER_APPLICATION_ACCEPTED")}</Typography>

        {isLoading ? (
          <Loader />
        ) : (
          <Card>
            <Typography variant="h2" gutterBottom>
              {t("APPLICATION_ID")} {formatId(location.state.transferId)}
            </Typography>

            <LayoutVertical>
              <AlertInfo>
                <LayoutButtonWithTypography>
                  <Typography width="100%">{t("BANK_TRANSFER_EXAMPLE_2")}</Typography>
                  <ButtonTextLink to={supportUrls["ABOUT_BANK_ACCOUNT"]} target="_blank">
                    {t("ABOUT_BANK_ACCOUNT")}
                  </ButtonTextLink>
                </LayoutButtonWithTypography>
              </AlertInfo>

              <Typography>{t("POINT_EXCHANGE_APPLICATION_HISTORY_EXAMPLE")}</Typography>

              <LayoutButton>
                <ButtonContainedLink
                  size="large"
                  to={`/${portalType}_portal/points/transfer/history/${location.state.transferId}`}
                >
                  {t("SEE_APPLICATION_HISTORY")}
                </ButtonContainedLink>
              </LayoutButton>
            </LayoutVertical>
          </Card>
        )}

        <LayoutButton>
          <ButtonOutlinedLink to={((p): string => (p ? `/${p}_portal/top` : "/"))(portalType)}>
            {t("BACK_TO_TOP")}
          </ButtonOutlinedLink>
        </LayoutButton>
      </LayoutVertical>
    </Container>
  );
};
