import { useState } from "react";

const colSpan = 1000;

export const useTableExpand = () => {
  const [expanded, setExpanded] = useState("");
  const open = (id: string) => id === expanded;
  const toggle = (id: string) => {
    if (open(id)) {
      setExpanded("");
    } else {
      setExpanded(id);
    }
  };
  const triggerProps = (id: string) => {
    return {
      colSpan,
      open: open(id),
      onClick: () => toggle(id),
    };
  };
  const contentProps = (id: string) => {
    return {
      colSpan,
      open: open(id),
    };
  };

  return {
    toggle,
    open,
    triggerProps,
    contentProps,
  };
};
