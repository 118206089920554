import { useEffect } from "react";

import { Loader } from "@/components";

export const TermsOfUse = () => {
  // TODO: ドライバーアプリ対応後にファイルごと消す
  useEffect(() => {
    window.location.href = "https://support-driver.taxigo.jp/hc/ja/articles/30933690717849";
  }, []);

  return <Loader />;
};
