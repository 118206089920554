import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  Box,
  ButtonContainedLink,
  ButtonTextLink,
  Chip,
  Container,
  LayoutVertical,
  StepCard,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";

import { AuthMailCodeForm } from "./components/Forms";

export const AuthMailCode = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const newEmail = location.state?.newEmail || "";

  return (
    <Container>
      <Helmet title={t("REGISTER_ACCOUNT")} />
      <LayoutVertical>
        <StepCard activestep={2} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_INPUT_CONFIRM_CODE_BY_EMAIL")}
          </Typography>
          {newEmail && (
            <Box textAlign="center" style={{ marginTop: "24px", marginBottom: "24px" }}>
              <Chip label={newEmail} size="medium" />
            </Box>
          )}
          <Typography component="h3" variant="body1">
            {t("CONFIRM_CODE_SENT_BY_EMAIL")}
          </Typography>
          <AuthMailCodeForm />
        </StepCard>

        <ButtonTextLink to={SUPPORT_URLS["crew"]["WHO_DO_NOT_RECEIVE_CONFIRM_CODE"]} target="_blank">
          {t("WHO_DO_NOT_RECEIVE_CONFIRM_CODE")}
        </ButtonTextLink>
        <ButtonTextLink to={SUPPORT_URLS["crew"]["WHAT_IS_CONFIRM_CODE"]} target="_blank">
          {t("WHAT_IS_CONFIRM_CODE")}
        </ButtonTextLink>

        <Typography component="h3" align="center">
          {t("WHO_DO_NOT_RECEIVE_CONFIRM_CODE_OR_CHALLENGE_RESENDING")}
        </Typography>
        <Box textAlign="center">
          <ButtonContainedLink type="button" color="primary" size="medium" to="/crew_portal/register/auth_mail">
            {t("TRY_AGAIN")}
          </ButtonContainedLink>
        </Box>
      </LayoutVertical>
    </Container>
  );
};
