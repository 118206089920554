import camelcaseKeys from "camelcase-keys";
import { useContext } from "react";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Card,
  type ChipTransferStatusProps,
  Container,
  LayoutVertical,
  ListTransfer,
  ListTransferItem,
  Loader,
  Typography,
} from "@/components";
import "@/components/Loader/Loader";
import { FetchContext as context } from "@/contexts/FetchContext";
import { removeAuthToken } from "@/contexts/JWTContext";
import { useNavigationError } from "@/hooks/useNavigationError";
import type { PointTransferHistories, PointTransferHistory } from "@/types/pointTransferHistory";
import { pointTransferHistoriesDefaultValues } from "@/types/pointTransferHistory";
import { axiosAccountInstance } from "@/utils/axios";
import { formatDisplayDate } from "@/utils/formatter";
import getPortalType from "@/utils/getPortalType";

import axios from "axios";
export const TransferHistories = () => {
  const { state } = useContext(context);
  const [pointTransferHistories, setPointTransferHistories] = useState<PointTransferHistories>(
    pointTransferHistoriesDefaultValues
  );
  const [offset, setOffset] = useState(0);
  const [prevOffset, setPrevOffset] = useState(0);
  const { t } = useTranslation();
  const { navigateError } = useNavigationError();
  const bottomRef = useRef<HTMLDivElement>(null);

  const handleErrors = (error: any) => {
    if (axios.isAxiosError(error)) {
      navigateError({
        error: error,
        endpoint: "/api/point/transfer/history",
        appName: "POINT_EXCHANGE_APPLICATION_HISTORY",
        path: "points",
      });
    }
  };

  // 初回読み込み時
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    const fetch = async () => {
      try {
        removeAuthToken();
        const response = await axiosAccountInstance.get("/api/point/transfer/history", {
          params: {
            offset: offset,
            count: 5,
          },
        });
        if (response.data.length === 0) {
          return;
        }
        setPointTransferHistories(camelcaseKeys(response.data, { deep: true }));
        setOffset(offset + 5);
      } catch (error) {
        handleErrors(error);
      }
    };
    fetch();
  }, []);

  // スクロール時
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    const fetch = async (currentOffset: number) => {
      try {
        removeAuthToken();
        const response = await axiosAccountInstance.get("/api/point/transfer/history", {
          params: {
            offset: currentOffset,
            count: 5,
          },
        });
        if (response.data.length === 0) {
          return;
        }
        setPointTransferHistories((prevPointTransferHistories) => {
          const newPointTransferHistories = camelcaseKeys(response.data, {
            deep: true,
          });
          const combined = [...prevPointTransferHistories, ...newPointTransferHistories];

          // 重複を削除
          const unique = combined.reduce((acc: PointTransferHistory[], current: PointTransferHistory) => {
            const x = acc.find((item) => item.transferId === current.transferId);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          return unique;
        });
        setOffset((prevOffset) => prevOffset + 5);
      } catch (error) {
        handleErrors(error);
      }
    };

    // Intersection Observerのインスタンスを作成
    const observer = new IntersectionObserver(
      (entries) => {
        // 画面の一番下の要素が交差したかどうかを判定
        if (entries[0].isIntersecting) {
          // 交差したらfetchを呼び出す
          const currentOffset = offset;
          if (currentOffset - prevOffset === 5) {
            fetch(currentOffset);
            setPrevOffset(currentOffset);
          }
        }
      },
      {
        // 交差判定の閾値を0に設定
        threshold: 0,
      }
    );

    // 画面の一番下の要素を監視対象に登録
    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    // コンポーネントがアンマウントされたら監視を解除
    return () => observer.disconnect();
  }, [offset]);

  return (
    <Container>
      {state.loading ? (
        <Loader />
      ) : (
        <>
          <Helmet title={t("POINT_EXCHANGE_APPLICATION_HISTORY")} />

          <LayoutVertical>
            <Typography variant="h1">{t("POINT_EXCHANGE_APPLICATION_HISTORY")}</Typography>

            <Card>
              {Object.entries(
                pointTransferHistories
                // biome-ignore suppressions/unused: need not to watch @typescript-eslint/no-unused-vars
              ).map(([_, value], index) => {
                return (
                  <React.Fragment key={index}>
                    <ListTransfer>
                      <ListTransferItem
                        status={value.status as ChipTransferStatusProps["status"]}
                        date={formatDisplayDate(value.createdAt)}
                        label={t(`POINT_TRANSFER_HISTORY_${value.receiveMethod}`)}
                        amount={`${value.amount.toLocaleString()} ${t("YEN")}`}
                        to={`/${getPortalType()}_portal/points/transfer/history/${value.transferId}`}
                      />
                    </ListTransfer>
                  </React.Fragment>
                );
              })}
            </Card>
          </LayoutVertical>
        </>
      )}
      <div ref={bottomRef}></div>
    </Container>
  );
};
