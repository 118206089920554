import React from "react";

import { styled } from "@mui/material";

import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from "@/components";
import { COLORS } from "@/utils/colors";

export type RadioItem = {
  value?: unknown;
  label: string;
};

export type Props = RadioGroupProps & {
  items: RadioItem[];
};

export const Radios: React.FC<Props> = ({ items, ...props }) => {
  return (
    <StyledRadioGroup {...props}>
      {items.map((item) => (
        <StyledFormControlLabel
          key={item.label}
          value={item.value}
          control={<StyledRadio size="small" />}
          label={item.label}
        />
      ))}
    </StyledRadioGroup>
  );
};

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  margin: theme.spacing(0, -4, -4, 0),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const StyledRadio = styled(Radio)(() => ({
  "&.Mui-disabled": {
    color: COLORS.DISABLED,
  },
}));
