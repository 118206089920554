import { useTranslation } from "react-i18next";

import {
  AlertInfo,
  AlertTitle,
  Box,
  ButtonTextLink,
  Card,
  Divider,
  Grid,
  IconInfo,
  IconStar,
  LayoutVertical,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";

export type Props = {
  customerName: string;
  customerDriverScore?: string;
  infoMessage?: string;
  enableWelcomeBack?: boolean;
  children?: React.ReactNode;
};

export const CardAccount: React.FC<Props> = ({
  customerName,
  customerDriverScore,
  infoMessage,
  enableWelcomeBack,
  children,
}) => {
  const { t } = useTranslation();
  const portalType = getPortalType();
  const supportUrls: { [key: string]: string } = SUPPORT_URLS[portalType];

  return (
    <Card>
      <Grid container direction="column" justifyContent="space-between" alignItems="flex-start">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {enableWelcomeBack && (
            <Typography variant="h3" component="span">
              {t("WELCOME_BACK")}
            </Typography>
          )}
          {!!customerDriverScore && (
            <Typography component="span" fontSize="16px" fontWeight="bold" display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <IconStar style={{ marginRight: "4px" }} rate={10} size="20px" />
                {customerDriverScore}
              </Box>
            </Typography>
          )}
        </div>
        <Typography variant="h2" component="span" sx={{ width: "100%" }}>
          {t("CUSTOMER_NAME").replace("%", customerName)}
        </Typography>
      </Grid>
      {infoMessage && (
        <>
          <LayoutVertical>
            <AlertInfo strong sx={{ width: "100%", mt: "24px" }}>
              <AlertTitle icon={<IconInfo />} sx={{ marginBottom: "0px" }}>
                {infoMessage || t("ACCOUNT_INFO")}
              </AlertTitle>
            </AlertInfo>
          </LayoutVertical>
          <LayoutVertical>
            <Typography variant="caption" color={COLORS.PRIMARY} sx={{ mt: "12px" }}>
              {t("APPROVAL_NOTICE")}
            </Typography>
          </LayoutVertical>
          {!enableWelcomeBack && (
            <>
              <Box textAlign="center" style={{ marginTop: "24px" }}>
                <ButtonTextLink to={supportUrls["WHAT_IS_POINT_COMBINATION"]} target="_blank">
                  {t("ABOUT_POINT_COMBINED_APPLICATION")}
                </ButtonTextLink>
              </Box>
              <Divider />
            </>
          )}
        </>
      )}
      {children}
    </Card>
  );
};
