import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Container, LayoutVertical, StepCard, Typography } from "@/components";

import { PasswordForm } from "./components/Forms";

export const Password = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Helmet title={t("REGISTER_ACCOUNT")} />
      <LayoutVertical>
        <StepCard activestep={3} steplength={5}>
          <Typography variant="h2">{t("PLEASE_INPUT_PASSWORD")}</Typography>
          <PasswordForm />
        </StepCard>
      </LayoutVertical>
    </Container>
  );
};
