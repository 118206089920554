import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

import { isLocal } from "@/utils/env";

type ErrorMetadata = {
  getErrorMetadata: (errorCode: string) => [string, string];
};

type ErrorType = "TITLE" | "MESSAGE";

export const useErrorMetadata = (): ErrorMetadata => {
  const { t } = useTranslation();

  const captureAndTranslate = (errorCode: string, errorType: ErrorType, sendError: boolean = true): string => {
    const key = `ERROR_CODE_${errorCode}_${errorType}`;
    const translation = t(key);
    if (key === translation) {
      if (sendError) {
        if (isLocal()) {
          console.info(`Unknown error code encountered: ${errorCode}`);
        } else {
          Sentry.captureMessage(`Unknown error code encountered: ${errorCode}`, "info");
        }
      }
      return `ERROR_CODE__${errorType}`;
    }
    return translation;
  };

  const getErrorMetadata = (errorCode: string): [string, string] => {
    const title = captureAndTranslate(errorCode, "TITLE", true);
    const message = captureAndTranslate(errorCode, "MESSAGE", false);

    return [title, message];
  };

  return { getErrorMetadata };
};
