export const COLORS = {
  BG_01: "#FFFFFF",
  BG_02: "#F7F7F7",
  BG_03: "#F0F5FB",
  TEXT_01: "#222222",
  TEXT_02: "#FFFFFF",
  TEXT_WEAK: "#777777",
  TEXT_HEADING: "#222222",
  TEXT_LOGO: "#000000",
  TEXT_PLACEHOLDER: "#999999",
  PRIMARY: "#1F4878",
  PRIMARY_ACTIVE: "#2D68AE",
  SECONDARY: "#58A7CC",
  WARNING: "#ED5C74",
  WARNING_BG: "#FEF5F7",
  ALERT: "#DE5454",
  ALERT_BG: "#FCF1F1",
  ACCENT: "#E2CE32",
  DISABLED: "#DDDDDD",
  NEGATIVE: "#999999",
  DEFICIT: "#ED5C74",
  BORDER: "#DDDDDD",
} as const;
