import { useTranslation } from "react-i18next";

import { styled } from "@mui/material";

import { Link, Typography } from "@/components";
import { FOOTER_URLS } from "@/constants";
import { COLORS } from "@/utils/colors";

type Props = {
  isLoggedin?: boolean;
  portalType: string;
};

export const Footer: React.FC<Props> = ({ portalType, isLoggedin = false }) => {
  const { t } = useTranslation<"translation">("translation");
  const portalTypeUpper = portalType.toUpperCase();
  const footerUrls: { [key: string]: string } = FOOTER_URLS[portalType as "driver" | "crew" | ""];

  return (
    <StyledFooter>
      <FooterInner>
        {!!portalType && (
          <>
            <FooterList>
              <FooterListItem>
                <FooterLink href={footerUrls[isLoggedin ? "OPERATION_MANUAL" : "LOGIN_MANUAL"]}>
                  {t("MANUAL")}
                </FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink href={footerUrls["FAQ"]}>{t("FAQ")}</FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink href={footerUrls[isLoggedin ? "LOGIN_CONTACT" : "CONTACT"]}>{t("CONTACT")}</FooterLink>
              </FooterListItem>
            </FooterList>
            <FooterList>
              <FooterListItem>
                <FooterLink href={footerUrls["TERMS_OF_USE"]}>{t(`TERMS_OF_${portalTypeUpper}_USE`)}</FooterLink>
              </FooterListItem>
              <FooterListItem>
                <FooterLink href={footerUrls["PRIVACY_POLICY"]}>{t("PRIVACY_POLICY")}</FooterLink>
              </FooterListItem>
            </FooterList>
          </>
        )}
        <FooterTypography>© GO Inc.</FooterTypography>
      </FooterInner>
    </StyledFooter>
  );
};

const StyledFooter = styled("footer")({
  fontSize: "0.875rem",
  color: COLORS.TEXT_WEAK,
  background: COLORS.BG_02,
  textAlign: "center",
});

const FooterInner = styled("div")(({ theme }) => ({
  maxWidth: "800px",
  margin: "0 auto",
  padding: theme.spacing(6),

  "& > *:last-child": {
    marginBottom: "0",
  },
}));

const FooterList = styled("ul")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(0, 6),
  listStyle: "none",
  margin: theme.spacing(0, 0, 2),
  padding: "0",
}));

const FooterListItem = styled("li")({
  margin: "0",
  display: "block",
  whiteSpace: "nowrap",
});

const FooterLink = styled(Link)({
  letterSpacing: "0.02em",
  outline: "none",
  textDecorationColor: "currentColor",
});

const FooterTypography = styled(Typography)(({ theme }) => ({
  fontSize: "inherit",
  color: "inherit",
  lineHeight: "inherit",
  marginTop: theme.spacing(6),
}));
