import { FormControlLabelProps, FormControlLabel as MuiFormControlLabel, styled } from "@mui/material";

export type Props = FormControlLabelProps & {
  control: React.ReactElement<any, any>;
  fullWidth?: boolean;
};

export const FormControlLabel: React.FC<Props> = (props) => {
  const { control, ...rest } = props;
  return <StyledFormControlLabel control={control} {...rest} />;
};

const StyledFormControlLabel = styled(MuiFormControlLabel, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "fullWidth",
})<{ fullWidth?: Props["fullWidth"] }>(({ theme, fullWidth }) => ({
  width: fullWidth ? "100%" : undefined,
  justifyContent: fullWidth ? "space-between" : undefined,

  "&.MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
  },

  "&.MuiFormControlLabel-labelPlacementEnd": {
    marginRight: 0,
  },

  ".MuiFormControlLabel-label": {
    lineHeight: 1.4,
    padding: `${theme.spacing(2)} 0`,
  },
}));
