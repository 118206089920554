import { styled } from "@mui/material";

type Align = "flex-start" | "center" | "flex-end";

type StyleProps = {
  alignItems?: Align;
};

export type Props = StyleProps & {
  children: React.ReactNode;
};

export const LayoutButtonWithTypography: React.FC<Props> = ({ alignItems, children }) => {
  return <Container alignItems={alignItems}>{children}</Container>;
};

const Container = styled("div", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "alignItems",
})<StyleProps>(({ alignItems = "center", theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: alignItems,
  gap: theme.spacing(2),
  width: "100%",
}));
