import { getLocalStorageItem } from "@/utils/localStorage";
import { parseJwt } from "@/utils/parser";

export const getPortalType = (): "crew" | "driver" | "" => {
  let portalType: "crew" | "driver" | "" = "";
  const url = window.location.pathname;
  if (url.startsWith("/crew_portal") || url.startsWith("/r")) {
    portalType = "crew";
  } else if (url.startsWith("/driver_portal")) {
    portalType = "driver";
  } else {
    const accountToken = getLocalStorageItem("accountToken");
    if (accountToken) {
      const jwtPayload = parseJwt(accountToken);
      const aud = jwtPayload?.aud;
      portalType = aud as "crew" | "driver";
    }
  }

  return portalType;
};

export default getPortalType;
