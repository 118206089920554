export type Props = {
  color?: string;
};

export const IconExclamation: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.47692 22 2 17.5231 2 12C2 6.47692 6.47692 2 12 2C17.5231 2 22 6.47692 22 12C22 17.5231 17.5231 22 12 22ZM10.8462 6.94615C10.8462 6.76347 10.9942 6.61538 11.1769 6.61538H12.8231C13.0058 6.61538 13.1538 6.76347 13.1538 6.94615V13.2077C13.1538 13.3904 13.0058 13.5385 12.8231 13.5385H11.1769C10.9942 13.5385 10.8462 13.3904 10.8462 13.2077V6.94615ZM12 15.8461C11.1503 15.8461 10.4615 16.5349 10.4615 17.3846C10.4615 18.2343 11.1503 18.9231 12 18.9231C12.8497 18.9231 13.5385 18.2343 13.5385 17.3846C13.5385 16.5349 12.8497 15.8461 12 15.8461Z"
        fill={color}
      />
    </svg>
  );
};
