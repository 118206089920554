import { styled } from "@mui/material";

export type Props = {
  children: React.ReactNode;
  bold?: boolean;
};

export const ListDescriptionTerm: React.FC<Props> = (props) => {
  return <Container {...props} />;
};

const Container = styled("dt", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "bold",
})<{ bold?: boolean }>(({ bold }) => ({
  fontWeight: bold ? 700 : undefined,
  width: "50%",
}));
