import { To } from "history";

import { ButtonProps, Button as MuiButton, styled } from "@mui/material";
import { SpacingProps } from "@mui/system";

export type Props = ButtonProps &
  SpacingProps & {
    to?: To;
    target?: "_blank" | "_top" | "_parent" | "_self";
  };

export const Button: React.FC<Props> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

const StyledButton = styled(MuiButton)({
  display: "inline-block",
  textAlign: "center",
});
