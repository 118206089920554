import React from "react";
import { useTranslation } from "react-i18next";

import { ModalBasic, ModalBasicProps, Typography } from "@/components";

type Props = Pick<ModalBasicProps, "open" | "onClose"> & {
  type: string;
  onConfirm: VoidFunction;
};

export const ModalResendConfirm: React.FC<Props> = ({ open, onClose, type, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <ModalBasic
      open={open}
      onClose={onClose}
      title={t(`ASK_RESENDING_${type}`)}
      actionButton={{
        label: t(`RESEND_${type}`),
        onClick: onConfirm,
      }}
      weakActionButton={{
        label: t("CANCEL"),
        onClick: onClose,
      }}
    >
      <Typography>{t("NEED_RESEND")}</Typography>
    </ModalBasic>
  );
};
