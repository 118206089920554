// ENVIRONMENT
export const ENVIRONMENT = import.meta.env.VITE_ENV || "";
export const LOCAL_ENV = "local";
export const DEVELOPMENT_ENV = "development";
export const QA_ENV = "qa";
export const PROD_ENV = "production";

// APP
export const APP_TITLE = import.meta.env.VITE_APP_TITLE || "";
export const APP_DEEPLINK = import.meta.env.VITE_APP_DEEPLINK || "";

// API
export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT || "";
export const PORTLAND_API_KEY = import.meta.env.VITE_PORTLAND_API_KEY || "";

// SENTRY
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || "";

// STATES
export const INITIALIZE = "INITIALIZE";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP = "SIGN_UP";

export const FETCH_START = "FETCH_START";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";

// URL
export const SUPPORT_URL = "https://goinc.jp/contact/";

export const FOOTER_URLS = {
  crew: {
    LOGIN_MANUAL: "https://support.taxigo.jp/hc/ja/articles/26158262650777",
    OPERATION_MANUAL: "https://support.taxigo.jp/hc/ja/articles/26158316329497",
    CONTACT: "/crew_portal/contact",
    LOGIN_CONTACT: "/crew_portal/contact_account",
    TERMS_OF_USE: "https://go.goinc.jp/taxi-point-agreement",
    PRIVACY_POLICY: "https://go.goinc.jp/taxi-point-privacy",
    FAQ: "https://support.taxigo.jp/",
  },
  driver: {
    LOGIN_MANUAL: "https://support-driver.taxigo.jp/hc/ja/articles/29946511469593",
    OPERATION_MANUAL: "https://support-driver.taxigo.jp/hc/ja/articles/29946511469593",
    CONTACT: "/driver_portal/contact",
    LOGIN_CONTACT: "/driver_portal/contact_account",
    TERMS_OF_USE: "https://go.goinc.jp/driver/agreement",
    PRIVACY_POLICY: "https://go.goinc.jp/driver/privacy",
    FAQ: "https://support-driver.taxigo.jp/",
  },
  "": {},
};

export const SUPPORT_URLS = {
  crew: {
    WHO_DO_NOT_RECEIVE_PIN_CODE: "https://support.taxigo.jp/hc/ja/articles/26157117301145",
    WHO_DO_NOT_RECEIVE_CONFIRM_CODE: "https://support.taxigo.jp/hc/ja/articles/26157117301145",
    WHO_DO_NOT_RECEIVE_SMS: "https://support.taxigo.jp/hc/ja/articles/26158230138265",
    WHAT_IS_PIN_CODE: "https://support.taxigo.jp/hc/ja/articles/26158351744793",
    WHAT_IS_CONFIRM_CODE: "https://support.taxigo.jp/hc/ja/articles/26158351744793",
    WHO_CHANGED_PHONE_NUMBER: "https://support.taxigo.jp/hc/ja/articles/26158312120089",
    WHAT_IS_ACCOUNT_LOCKED: "https://support.taxigo.jp/hc/ja/articles/26158345604121",
    WHAT_IS_ACCOUNT_REGISTRATION: "https://support.taxigo.jp/hc/ja/articles/27429232982425",
    WHAT_IS_PHONE_NUMBER_CHANGE: "https://support.taxigo.jp/hc/ja/articles/26238774817689",
    WHAT_IS_MAIL_ADDRESS_CHANGE: "https://support.taxigo.jp/hc/ja/articles/26238774817689",
    ABOUT_BANK_ACCOUNT: "https://support.taxigo.jp/hc/ja/articles/26158368409497",
    WHAT_IS_USER_NUMBER: "https://support.taxigo.jp/hc/ja/articles/26158377693977",
    ERROR_HANDLING_FEE: "https://support.taxigo.jp/hc/ja/articles/26158368409497",
    WHAT_IS_POINT_COMBINATION: "https://support.taxigo.jp/hc/ja/articles/28216534754457",
    WHAT_IS_REVOKE_POINT_COMBINATION: "https://support.taxigo.jp/hc/ja/articles/28216554522393",
    NOTE_ON_ACCOUNT_RESET: "https://support.taxigo.jp/hc/ja/articles/29051632223257",
    WHAT_IS_CONFIRMATION_EMAIL_NOT_ARRIVED: "https://support.taxigo.jp/hc/ja/articles/26157117301145",
    ABOUT_NON_ARRIVAL_OF_CREW_PORTAL_CONFIRMATION_EMAIL: "https://support.taxigo.jp/hc/ja/articles/26157117301145",
    WHAT_IS_DRIVER_SCORE: "",

    // crew
    WHO_DO_NOT_RECEIVE_EMAIL: "https://support.taxigo.jp/hc/ja/articles/26158230138265",

    WHAT_IS_COOKIE_DISABLED: "https://support.taxigo.jp/hc/ja/articles/32688536222105",
  },
  driver: {
    WHO_DO_NOT_RECEIVE_PIN_CODE: "https://support-driver.taxigo.jp/hc/ja/articles/29946753161881",
    WHO_DO_NOT_RECEIVE_CONFIRM_CODE: "https://support-driver.taxigo.jp/hc/ja/articles/29946753161881",
    WHO_DO_NOT_RECEIVE_SMS: "",
    WHAT_IS_PIN_CODE: "https://support-driver.taxigo.jp/hc/ja/articles/29946753161881",
    WHAT_IS_CONFIRM_CODE: "https://support-driver.taxigo.jp/hc/ja/articles/29946753161881",
    WHO_CHANGED_PHONE_NUMBER: "",
    WHAT_IS_ACCOUNT_LOCKED: "https://support-driver.taxigo.jp/hc/ja/articles/29946563933593",
    WHAT_IS_ACCOUNT_REGISTRATION: "",
    WHAT_IS_PHONE_NUMBER_CHANGE: "",
    WHAT_IS_MAIL_ADDRESS_CHANGE: "",
    ABOUT_BANK_ACCOUNT: "https://support-driver.taxigo.jp/hc/ja/articles/29946624605209",
    WHAT_IS_USER_NUMBER: "https://support-driver.taxigo.jp/hc/ja/articles/29946685441049",
    ERROR_HANDLING_FEE: "https://support-driver.taxigo.jp/hc/ja/articles/29946624605209",
    WHAT_IS_POINT_COMBINATION: "",
    WHAT_IS_REVOKE_POINT_COMBINATION: "",
    NOTE_ON_ACCOUNT_RESET: "",
    ABOUT_NON_ARRIVAL_OF_CREW_PORTAL_CONFIRMATION_EMAIL: "",
    WHAT_IS_DRIVER_SCORE: "https://support.go.goinc.jp/hc/ja/articles/29208467518489",

    // driver
    WHO_DO_NOT_RECEIVE_EMAIL: "https://support-driver.taxigo.jp/hc/ja/articles/29946735177113",

    WHAT_IS_COOKIE_DISABLED: "https://support-driver.taxigo.jp/hc/ja/articles/32688821980185",
  },
  "": {},
};

export const TAB_URLS = {
  crew: ["/crew_portal/top", "/crew_portal/points", "/crew_portal/notifications", "/crew_portal/account"],
  driver: ["/driver_portal/top", "/driver_portal/points", "/driver_portal/notifications", "/driver_portal/account"],
  "": [],
};
