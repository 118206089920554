import { ListProps, List as MuiList, styled } from "@mui/material";

export type Props = ListProps;

export const List: React.FC<Props> = ({ children, ...props }) => {
  return <StyledList {...props}>{children}</StyledList>;
};

const StyledList = styled(MuiList)({
  padding: 0,
});
