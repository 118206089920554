export type Props = {
  color?: string;
};

export const IconTimeFill: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        fillRule="evenodd"
        d="M13 23.184c5.822 0 10.542-4.72 10.542-10.542S18.822 2.101 13 2.101 2.458 6.82 2.458 12.642c0 5.823 4.72 10.542 10.542 10.542ZM11.378 7.777h2.433v5.676h4.054v2.433H11.978a.598.598 0 0 1-.6-.6V7.777Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
