import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Container, LayoutGeneralError } from "@/components";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";
import getPortalType from "@/utils/getPortalType";

export const Error429 = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const errorCode = location.state?.errorCode || "";
  const defaultErrorCode = "E429-01";

  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata(defaultErrorCode);
  const portalType = getPortalType();

  let buttonLink = `${portalType}_portal/account`;
  let buttonMessage = errorCode;
  if (errorCode.includes("/api/account/")) {
    buttonLink = `/${portalType}_portal/account`;
    buttonMessage = "TO_MY_PAGE";
  } else if (errorCode.includes("/api/points")) {
    buttonLink = `/${portalType}_portal/points/transfer`;
    buttonMessage = "TO_POINT_EXCHANGE_APPLICATION";
  } else if (errorCode.includes("/api/password_reset")) {
    buttonLink = `/${portalType}_portal/password_reset`;
    buttonMessage = "TO_RESET_PASSWORD";
  } else if (errorCode.includes("/api/point/transfer")) {
    buttonLink = `/${portalType}_portal/points/transfer`;
    buttonMessage = "TO_POINT_EXCHANGE_APPLICATION";
  } else {
    buttonLink = `/${portalType}_portal/register`;
    buttonMessage = "TO_ACCOUNT_REGISTRATION";
  }

  const primaryButton =
    buttonLink && buttonMessage
      ? {
          label: t(buttonMessage),
          to: buttonLink,
        }
      : undefined;

  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError title={t(title)} message={t(message)} primaryButton={primaryButton} />
    </Container>
  );
};
