import { styled } from "@mui/material";

export type Props = {
  children: React.ReactNode;
};

export const ListDescriptionItem: React.FC<Props> = (props) => {
  return <Container {...props} />;
};

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
}));
