import type { AxiosResponse } from "axios";
import { AxiosError } from "axios";
import camelcaseKeys from "camelcase-keys";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { ErrorResponse } from "@/types/errorResponse";
import { CssBaseline } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Footer,
  Header,
  HeaderNavigation,
  HeaderUtility,
  LayoutBasicPage,
  LayoutBasicPageContent,
  LayoutBasicPageMain,
  ModalBasic,
} from "@/components";
import GlobalStyle from "@/components/GlobalStyle";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, TAB_URLS } from "@/constants";
import { FetchReducer, FetchContext as context, initialState } from "@/contexts/FetchContext";
import { useNavigation } from "@/hooks/useNavigation";
import { useNavigationError } from "@/hooks/useNavigationError";
import { notificationsDefaultValues, type Notifications } from "@/types/notification";
import { pointHistoriesDefaultValues, type PointHistories } from "@/types/pointHistory";
import type { PointSummary } from "@/types/pointSummary";
import { pointSummaryDefaultValues } from "@/types/pointSummary";
import { pointTransferMethodDefaultValues, type PointTransferMethod } from "@/types/pointTransferMethod";
import type { User } from "@/types/user";
import { userDefaultValues } from "@/types/user";
import { axiosAccountInstance } from "@/utils/axios";
import getPortalType from "@/utils/getPortalType";
import { getLocalStorageItem } from "@/utils/localStorage";
import { parseJwt } from "@/utils/parser";

type Props = {
  hideNavigation?: boolean;
};

export const Dashboard: React.FC<Props> = ({ hideNavigation }) => {
  const { navigateError } = useNavigationError();
  const [state, dispatch] = useReducer(FetchReducer, initialState);
  const { t } = useTranslation();
  const { navigations } = useNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const portalType = getPortalType();

  const tabUrls = [
    ...TAB_URLS[portalType],
    `/${portalType}_portal/points/transfer`,
    `/${portalType}_portal/points/transfer/histories`,
    `/${portalType}_portal/points/history`,
    `/${portalType}_portal/account/phone_number_change/auth_mail`,
    `/${portalType}_portal/account/mail_address_change/auth_sms`,
    `/${portalType}_portal/account/password_change/auth_mail`,
  ];

  const checkUrl = (url: string): string => {
    const matchingUrl = tabUrls.find((tabUrl: string) => url.startsWith(tabUrl));
    return matchingUrl ? matchingUrl : url;
  };

  const userName = useMemo(
    () => state.data?.user?.driverName && t("CUSTOMER_NAME").replace("%", state.data.user.driverName),
    [state, t]
  );
  const status = location.state?.status;
  const handleErrors = (
    error: any,
    defaultValues: User | PointSummary | PointHistories | Notifications | PointTransferMethod
  ) => {
    if (error && 400 <= error.response?.status && error.response?.status <= 503) {
      if (error.response?.status === 503 && error.response?.data.error_code === "E503-02") {
        return defaultValues;
      }
      throw error;
    } else if (error.response?.status === undefined) {
      throw error;
    }
    return defaultValues;
  };
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [reload, setReload] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    dispatch({ type: FETCH_START, loading: true });

    // GTMにdriver_idを送信
    window.dataLayer = window.dataLayer || [];
    if (window.dataLayer.some((value) => !value.driver_id)) {
      const accountToken = getLocalStorageItem("accountToken");
      if (accountToken) {
        const jwtPayload = parseJwt(accountToken);
        const driverId = jwtPayload?.jti; // JWTのペイロードからjtiを取得
        window.dataLayer.push({
          driver_id: driverId,
        });
        // jwtのaudとサブディレクトリが異なる場合はaud側にリダイレクト
        const aud = jwtPayload?.aud;
        if ((!portalType && !!aud) || portalType !== aud) {
          navigate(`/${aud}_portal/top`);
        }
      } else if ([`/${portalType}_portal/`, `/${portalType}_portal`].includes(location.pathname)) {
        // トークンがない場合はログイン画面にリダイレクト
        navigate(`/${portalType}_portal/login`);
        return;
      }
    }

    // リロード時は再取得しない
    if (location.state?.status === undefined && reload) {
      return;
    }

    type Result = User | PointSummary | PointHistories | Notifications | PointTransferMethod;

    Promise.all([
      axiosAccountInstance
        .get("/api/account/profile")
        .then((response: AxiosResponse<User>) => camelcaseKeys(response.data, { deep: true }))
        .catch((error: AxiosError<ErrorResponse>) => handleErrors(error, userDefaultValues)),
      axiosAccountInstance
        .get("/api/point/balance/summary")
        .then((response: AxiosResponse<PointSummary>) => camelcaseKeys(response.data, { deep: true }))
        .catch((error: AxiosError<ErrorResponse>) => handleErrors(error, pointSummaryDefaultValues)),
      axiosAccountInstance
        .get("/api/point/history", {
          params: {
            offset: 0,
            count: 3,
          },
        })
        .then((response: AxiosResponse<PointHistories>) => camelcaseKeys(response.data, { deep: true }))
        .catch((error: AxiosError<ErrorResponse>) => handleErrors(error, pointHistoriesDefaultValues)),
      axiosAccountInstance
        .get("/api/notifications", {
          params: {
            offset: 0,
            count: 10,
          },
        })
        .then((response: AxiosResponse<Notifications>) => camelcaseKeys(response.data, { deep: true }))
        .catch((error: AxiosError<ErrorResponse>) => handleErrors(error, notificationsDefaultValues)),
      axiosAccountInstance
        .get("/api/point/transfer/method")
        .then((response: AxiosResponse<PointTransferMethod>) => camelcaseKeys(response.data, { deep: true }))
        .catch((error: AxiosError<ErrorResponse>) => handleErrors(error, pointTransferMethodDefaultValues)),
    ])
      .then((results: Result[]) => {
        const user = results[0] as User;
        const pointSummary = results[1] as PointSummary;
        const pointHistories = results[2] as PointHistories;
        const notifications = results[3] as Notifications;
        const pointTransferMethod = results[4] as PointTransferMethod;
        dispatch({
          type: FETCH_SUCCESS,
          loading: false,
          data: {
            user,
            pointSummary,
            pointHistories,
            notifications,
            pointTransferMethod,
          },
        });
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        dispatch({ type: FETCH_ERROR, loading: false, error: error });
        if (error.response?.status) {
          const currentPath = location.pathname;
          const isExpiredOrMaintained = [401, 503].includes(error.response?.status);

          if (tabUrls.includes(currentPath)) {
            navigate(
              isExpiredOrMaintained
                ? `/${portalType}_portal/${error.response?.status}`
                : `${checkUrl(currentPath)}/error/${error.response?.status}`,
              {
                state: {
                  errorCode: error.response?.data.error_code,
                  status: isExpiredOrMaintained ? undefined : "RELOAD",
                  previousPath: currentPath,
                },
              }
            );
          } else {
            if (!currentPath.includes("/error/")) {
              navigate(`/${portalType}_portal/${isExpiredOrMaintained ? "" : "top/error/"}${error.response?.status}`, {
                state: {
                  errorCode: error.response?.data.error_code,
                  status: "RELOAD",
                  previousPath: currentPath,
                },
              });
            }
          }
        } else {
          // 通信エラー
          navigateError({
            error: error,
            endpoint: "/api/account/profile",
          });
        }
      });
  }, [reload]);

  // モーダル初期化
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    setReload(false);
    if (!location.state) {
      setIsModalOpen(true);
    } else {
      if (location.state?.status === "RELOAD") {
        setReload(true);
        // 再発火を防ぐためにステータスを削除
        navigate(location.pathname, {
          state: { ...location.state, status: undefined },
        });
      }
    }
  }, [location.state]);

  const handleClose = async () => {
    setIsModalOpen(false);
    setReload(true);
    // モーダルの意図しない再描画を停止
    navigate(((p): string => (p ? `/${p}_portal/account` : "/"))(portalType));
  };

  return (
    <LayoutBasicPage>
      <CssBaseline />
      <GlobalStyle />
      <context.Provider value={{ state, dispatch }}>
        <LayoutBasicPageContent>
          <Header>
            {matches && <HeaderUtility name={userName} />}
            {!hideNavigation && <HeaderNavigation navigations={navigations} />}
          </Header>
          <LayoutBasicPageMain>
            <Outlet />
          </LayoutBasicPageMain>
          {status && !["REGISTRATION_SUCCESS", "RELOAD"].includes(status) && (
            <ModalBasic
              open={isModalOpen}
              onClose={() => {
                // biome-ignore suppressions/unused: need not to use
              }}
              title={t(status)}
              actionButton={{
                label: t("OK"),
                onClick: handleClose,
              }}
            />
          )}
          <Footer portalType={portalType} isLoggedin={true} />
        </LayoutBasicPageContent>
      </context.Provider>
    </LayoutBasicPage>
  );
};
