import { useTranslation } from "react-i18next";

import { Chip, ChipProps } from "@/components";

type Status = "REGISTERED" | "PREPARED" | "EXECUTING" | "EXECUTED" | "CANCELED" | "FAILED";

type StatusMap = {
  [key in Status]: {
    label: string;
    color: ChipProps["color"];
  };
};

export type Props = {
  status: Status;
};

export const ChipTransferStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const statusTypeMap: StatusMap = {
    REGISTERED: {
      label: t("REGISTERED"),
      color: "secondary",
    },
    PREPARED: {
      label: t("PREPARED"),
      color: "secondary",
    },
    EXECUTING: {
      label: t("EXECUTING"),
      color: "primary",
    },
    EXECUTED: {
      label: t("EXECUTED"),
      color: "primary",
    },
    CANCELED: {
      label: t("CANCELED"),
      color: "error",
    },
    FAILED: {
      label: t("FAILED"),
      color: "error",
    },
  };

  return (
    <Chip
      color={statusTypeMap[status].color}
      label={statusTypeMap[status].label}
      size="small"
      component="span"
      wraptext="nowrap"
    />
  );
};
