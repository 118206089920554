import type { AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";
import { useContext, useEffect, useState } from "react";
import type React from "react";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Container, Loader, Typography } from "@/components";
import { FetchContext as context } from "@/contexts/FetchContext";
import { removeAuthToken } from "@/contexts/JWTContext";
import { useNavigationError } from "@/hooks/useNavigationError";
import type { PointHistories, PointHistory } from "@/types/pointHistory";
import { pointHistoriesDefaultValues } from "@/types/pointHistory";
import { axiosAccountInstance } from "@/utils/axios";
import axios from "axios";

import { CardsPointHistory } from "./components";

export type PointActionCardProps = {
  label: string;
  caption?: string;
  icon: React.ReactNode;
  date: string;
  point: number;
};

export const History = () => {
  const { state } = useContext(context);
  const [pointHistories, setPointHistories] = useState<PointHistories>(pointHistoriesDefaultValues);
  const [offset, setOffset] = useState(0);
  const [prevOffset, setPrevOffset] = useState(0);
  const { t } = useTranslation();
  const { navigateError } = useNavigationError();
  const bottomRef = useRef<HTMLDivElement>(null);

  const handleErrors = (error: any) => {
    if (axios.isAxiosError(error)) {
      navigateError({
        error: error,
        endpoint: "/api/point/history",
        appName: "POINT_HISTORY",
        path: "points",
      });
    }
  };

  // 初回読み込み時
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    const fetch = async () => {
      try {
        removeAuthToken();
        const response: AxiosResponse<PointHistories> = await axiosAccountInstance.get("/api/point/history", {
          params: {
            offset: offset,
            count: 20,
          },
        });
        if (response.data.length === 0) {
          return;
        }
        setPointHistories(camelcaseKeys(response.data, { deep: true }));
        setOffset(offset + 20);
      } catch (error) {
        handleErrors(error);
      }
    };
    fetch();
  }, []);

  // スクロール時
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    const fetch = async (currentOffset: number) => {
      try {
        const response: AxiosResponse<PointHistories> = await axiosAccountInstance.get("/api/point/history", {
          params: {
            offset: currentOffset,
            count: 20,
          },
        });
        if (response.data.length === 0) {
          return;
        }
        setPointHistories((prevPointHistories) => {
          const newPointHistories = camelcaseKeys(response.data, {
            deep: true,
          });
          const combined = [...prevPointHistories, ...newPointHistories];

          // 重複を削除
          const unique = combined.reduce((acc: PointHistory[], current: PointHistory) => {
            const x = acc.find((item) => item.historyId === current.historyId);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          return unique;
        });
        setOffset((prevOffset) => prevOffset + 20);
      } catch (error) {
        handleErrors(error);
      }
    };

    // Intersection Observerのインスタンスを作成
    const observer = new IntersectionObserver(
      (entries) => {
        // 画面の一番下の要素が交差したかどうかを判定
        if (entries[0].isIntersecting) {
          // 交差したらfetchを呼び出す
          // offsetの値をローカル変数に保存
          const currentOffset = offset;
          // 前のoffsetとの差が20であることを確認
          if (currentOffset - prevOffset === 20) {
            fetch(currentOffset);
            // 前のoffsetを更新
            setPrevOffset(currentOffset);
          }
        }
      },
      {
        // 交差判定の閾値を0に設定
        threshold: 0,
      }
    );

    // 画面の一番下の要素を監視対象に登録
    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    // コンポーネントがアンマウントされたら監視を解除
    return () => observer.disconnect();
  }, [offset]);

  return (
    <Container>
      {state.loading ? (
        <Loader />
      ) : (
        <>
          <Helmet title={t("POINT_HISTORY")} />

          <Typography variant="h1" style={{ marginBottom: "24px" }}>
            {t("POINT_HISTORY")}
          </Typography>

          <CardsPointHistory pointHistories={pointHistories} />
        </>
      )}
      <div ref={bottomRef}></div>
    </Container>
  );
};
