import { CheckboxProps, Checkbox as MuiCheckbox, styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

export type Props = Omit<CheckboxProps, "size" | "color">;

export const Checkbox: React.FC<Props> = (props) => {
  return <StyledCheckbox size="small" {...props} />;
};

const StyledCheckbox = styled(MuiCheckbox)(({ theme }) => ({
  alignSelf: "flex-start",
  padding: theme.spacing(2),

  "&:not(.Mui-disabled, .Mui-error)": {
    color: COLORS.PRIMARY,
  },
}));
