export type Props = {
  color?: string;
};

export const IconPriorityPath: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        d="M10.16 18.924c.355-.143.64-.303.874-.482H9.305c.233.187.527.348.856.482ZM8.908 16.672v-.313h.803v.447c0 .188.052.215.286.215h.76c.173 0 .216-.045.25-.304.13.071.364.152.563.188a6.045 6.045 0 0 0-.242-.242l.63-.33c.061.053.121.107.182.17v-.287h-1.495c.138.125.25.25.328.358l-.622.384c-.138-.232-.44-.527-.717-.742H8.147v.43c.078-.09.156-.188.216-.278l.545.304ZM11.37 15.332H8.978v.276h2.394v-.277ZM11.37 14.188v-.269H8.978v.268h2.394ZM8.977 14.902h2.394v-.285H8.977v.285Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.707 6.01C3.405 6.01 2.35 7.1 2.35 8.447V20.76c0 1.346 1.055 2.437 2.357 2.437h17.286c1.302 0 2.357-1.091 2.357-2.437V8.447c0-1.346-1.055-2.437-2.357-2.437h-6.286v1.219h1.572a.8.8 0 0 1 .785.812v.813a.8.8 0 0 1-.785.812H9.42a.8.8 0 0 1-.785-.812V8.04a.8.8 0 0 1 .785-.812h1.572v-1.22H4.707Zm.121 9.42c.692-.831 1.331-2.136 1.711-3.45l.951.295a17.734 17.734 0 0 1-.657 1.734v6.3h-.976v-4.468a7.117 7.117 0 0 1-.536.733c-.078-.25-.329-.867-.493-1.144Zm6.81 2.413.174-.036.613.358c-.268.42-.657.786-1.167 1.09.58.116 1.219.197 1.902.232-.19.215-.415.59-.536.85-.942-.099-1.79-.269-2.515-.555-.7.25-1.53.438-2.463.572a3.202 3.202 0 0 0-.493-.768 14.849 14.849 0 0 0 1.945-.322 3.967 3.967 0 0 1-.597-.483c-.242.126-.51.242-.795.358a2.646 2.646 0 0 0-.562-.644c.9-.268 1.582-.634 2.014-1.027-.181-.108-.242-.295-.25-.608-.225.313-.51.643-.83.876l-.605-.5c.155-.09.311-.215.45-.35h-.649V15.61h.787V13.4h1.426c.014-.054.025-.11.037-.168.01-.048.02-.096.032-.145h-1.98v-.76h5.299v.76h-2.256l-.139.313h1.85v2.207h.726v1.279h-.544c.138.16.268.321.345.455l-.665.367c-.113-.206-.329-.474-.553-.724-.078.456-.277.599-.77.599h-.726c-.077.09-.164.178-.259.259h1.755Zm7.624 1c0 .296.043.34.31.34h.753c.259 0 .31-.17.346-1.224.207.179.656.358.933.43-.104 1.411-.38 1.796-1.184 1.796h-1.003c-.968 0-1.193-.331-1.193-1.341v-2.172h-1.261c-.19 1.591-.631 2.923-2.62 3.7a3.379 3.379 0 0 0-.639-.938c1.72-.581 2.049-1.582 2.178-2.762h-2.04v-1.028h3.31v-1.448h-1.486c-.199.456-.423.885-.665 1.216a6.732 6.732 0 0 0-.908-.465c.562-.733.968-1.94 1.15-2.85l1.054.214c-.078.277-.164.563-.26.867h1.116V11.97h1.046v1.207h2.653v1.018h-2.653v1.448h3.327v1.028h-2.264v2.172Z"
        clipRule="evenodd"
      />
      <path fill={color} d="M11.975 3.947h2.75V8.76h-2.75z" />
    </svg>
  );
};
