import React from "react";
import { useTranslation } from "react-i18next";

import {
  AlertWarning,
  Box,
  ButtonTextLink,
  InfoMessage,
  LayoutVertical,
  ListBullet,
  ListBulletItem,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";

export const AlertMessageRevokeCrew: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <LayoutVertical>
        <InfoMessage variant="warning">{t("REVOKE_CREW_NOTICE_0")}</InfoMessage>
      </LayoutVertical>
      <LayoutVertical>
        <AlertWarning>
          <ListBullet>
            <ListBulletItem>{t("REVOKE_CREW_NOTICE_1")}</ListBulletItem>
            <ListBulletItem>{t("REVOKE_CREW_NOTICE_2")}</ListBulletItem>
          </ListBullet>
          <Typography>{t("REVOKE_CREW_NOTICE_3")}</Typography>
        </AlertWarning>
        <Box textAlign="center">
          <ButtonTextLink to={SUPPORT_URLS["crew"]["WHAT_IS_REVOKE_POINT_COMBINATION"]} target="_blank">
            {t("WHAT_IS_REVOKE_POINT_COMBINATION")}
          </ButtonTextLink>
        </Box>
      </LayoutVertical>
    </>
  );
};
