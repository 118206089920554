import { To } from "history";

import { styled } from "@mui/material";

import { ChipTransferStatus, ChipTransferStatusProps, ListLinkItem } from "@/components";

export type Props = {
  status: ChipTransferStatusProps["status"];
  date: string;
  label: string;
  amount: string;
  to: To;
};

export const ListTransferItem: React.FC<Props> = ({ status, date, label, amount, to }) => (
  <ListLinkItem to={to} header={<Header status={status} date={date} />}>
    <Wrap>
      {label}
      <Amount>{amount}</Amount>
    </Wrap>
  </ListLinkItem>
);

type HeaderProps = {
  status: Props["status"];
  date: string;
};
const Header: React.FC<HeaderProps> = ({ status, date }) => {
  return (
    <>
      <ChipTransferStatus status={status} />
      <span>{date}</span>
    </>
  );
};

const Wrap = styled("span")({
  display: "flex",
  justifyContent: "space-between",
});

const Amount = styled("span")({
  textAlign: "right",
  fontWeight: 700,
});
