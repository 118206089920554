import { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Container, LayoutGeneralError } from "@/components";
import { SUPPORT_URL, SUPPORT_URLS } from "@/constants";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";
import getPortalType from "@/utils/getPortalType";

const button = {
  "E401-01": {
    message: "LOGIN",
    link: "/$portalType_portal/login",
    codeType: "",
  },
  "E401-01/api/password_reset/auth_mail_code": {
    message: "START_OVER",
    link: "/$portalType_portal/password_reset",
    codeType: "PIN_CODE",
  },
  "E401-01/api/password_reset/auth_mail_code/failed": {
    message: "START_OVER",
    link: "/$portalType_portal/password_reset",
    codeType: "PIN_CODE",
  },
  "E401-02": {
    message: "LOGIN",
    link: "/$portalType_portal/login",
    codeType: "",
  },
  "E401-02/session_expired_on_point_exchange": {
    message: "LOGIN",
    link: "/$portalType_portal/login",
    codeType: "",
  },
  "E401-03": {
    message: "LOGIN",
    link: "/$portalType_portal/login",
    codeType: "",
  },
  "E401-04": {
    message: "",
    link: "",
    codeType: "",
  },
  "E401-05/api/register/auth_mail": {
    message: "TO_ACCOUNT_REGISTRATION",
    link: "/$portalType_portal/register",
    codeType: "",
  },
  "E401-05/api/register/auth_mail_code": {
    message: "START_OVER",
    link: "/$portalType_portal/register",
    codeType: "CONFIRM_CODE",
  },
  "E401-05/api/account/mail_address_change/auth_sms_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/mail_address_change/auth_sms",
    codeType: "PIN_CODE",
  },
  "E401-05/api/account/password_change/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/password_change/auth_mail",
    codeType: "PIN_CODE",
  },
  "E401-05/api/account/phone_number_change/auth_mail_code": {
    message: "TRY_AGAIN",
    link: "/$portalType_portal/account/phone_number_change/auth_mail",
    codeType: "PIN_CODE",
  },
  "E401-05/api/account/phone_number_change/auth_sms_code": {
    message: "START_OVER",
    link: "/$portalType_portal/account/phone_number_change/auth_mail",
    codeType: "CONFIRM_CODE",
  },
  "E401-05/api/password_reset/auth_mail_code": {
    message: "START_OVER",
    link: "/$portalType_portal/password_reset",
    codeType: "PIN_CODE",
  },
  "E401-05/api/password_reset/auth_mail_code/failed": {
    message: "START_OVER",
    link: "/$portalType_portal/password_reset",
    codeType: "PIN_CODE",
  },
  "E401-05/api/account/mail_address_change/auth_mail_code": {
    message: "START_OVER",
    link: "/$portalType_portal/account/mail_address_change/auth_sms",
    codeType: "CONFIRM_CODE",
  },
  "E401-06/crew": {
    message: "RESET_PASSWORD",
    link: "/$portalType_portal/password_reset",
    codeType: "ACCOUNT_LOCKED",
  },
  "E401-06/driver": {
    message: "RESET_PASSWORD",
    link: "",
    codeType: "ACCOUNT_LOCKED",
  },
  "E401-05/api/point/transfer/auth_sms_code": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: "PIN_CODE",
  },
  "E401-05/api/point/transfer/auth_mail_code": {
    message: "TO_POINT_EXCHANGE_APPLICATION",
    link: "/$portalType_portal/points/transfer",
    codeType: "PIN_CODE",
  },
  "E401-05/api/register/password": {
    message: "START_OVER",
    link: "/$portalType_portal/register",
    codeType: "",
  },
  "E401-05/api/password_reset/auth_mail": {
    message: "START_OVER",
    link: "/$portalType_portal/password_reset",
    codeType: "",
  },
  "E401-05/api/password_reset/password": {
    message: "START_OVER",
    link: "/$portalType_portal/password_reset",
    codeType: "",
  },
  "E401-05/api/account/password_change/password": {
    message: "START_OVER",
    link: "/$portalType_portal/account/password_change/auth_mail",
    codeType: "",
  },
  "E401-05/api/account/phone_number_change/auth_sms": {
    message: "START_OVER",
    link: "/$portalType_portal/account/phone_number_change/auth_mail",
    codeType: "CONFIRM_CODE",
  },
  "E401-05/api/account/mail_address_change/auth_mail": {
    message: "START_OVER",
    link: "/$portalType_portal/account/mail_address_change/auth_sms",
    codeType: "CONFIRM_CODE",
  },
};

export const Error401 = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const errorCode = location.state?.errorCode || "";
  const previousPath = location.state?.previousPath || "";
  const appName = location.state?.appName || "";

  const portalType = getPortalType();

  let modifiedErrorCode = errorCode;
  if (
    (previousPath.includes("/points/transfer") || appName.includes("POINT_EXCHANGE_APPLICATION")) &&
    errorCode === "E401-02"
  ) {
    modifiedErrorCode = "E401-02/session_expired_on_point_exchange";
  } else if (errorCode === "E401-06") {
    modifiedErrorCode = `E401-06/${portalType}`;
  }

  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata(modifiedErrorCode);

  const buttonMessage = button[modifiedErrorCode as keyof typeof button]?.message || "";
  const buttonLink = button[modifiedErrorCode as keyof typeof button]?.link.replace("$portalType", portalType) || "";
  const codeType = button[modifiedErrorCode as keyof typeof button]?.codeType;

  const primaryButton =
    buttonLink && buttonMessage
      ? {
          label: t(buttonMessage),
          to: buttonLink,
        }
      : undefined;
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  const footerButtons = useMemo(() => {
    if (codeType !== "") {
      return [
        {
          title: t(`WHAT_IS_${codeType}`),
          label: t(`WHAT_IS_${codeType}`),
          to:
            codeType === undefined || !(SUPPORT_URLS[portalType] as Record<string, string>)[`WHAT_IS_${codeType}`]
              ? SUPPORT_URL
              : (SUPPORT_URLS[portalType] as Record<string, string>)[`WHAT_IS_${codeType}`],
        },
      ];
    }
    return [];
    // biome-ignore suppressions/unused: need not to watch
  }, [t, codeType]);

  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError
        title={t(title)}
        message={t(message)}
        primaryButton={primaryButton}
        footerButtons={footerButtons}
      />
    </Container>
  );
};
