import { styled } from "@mui/material";

import { AlertBox, AlertBoxProps } from "@/components";
import { COLORS } from "@/utils/colors";

export type Props = Omit<AlertBoxProps, "severity" | "icon">;

export const AlertError: React.FC<Props> = (props) => {
  return <StyledAlert {...props} severity="error" />;
};

const StyledAlert = styled(AlertBox)({
  backgroundColor: COLORS.ALERT_BG,
  color: COLORS.ALERT,
  border: `1px solid ${COLORS.ALERT}`,
});
