import { TextField } from "@/components";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { codeLength, pinCodeValidator, validationSchema } from "@/utils/validators";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export const MappingRequestAuthSmsCodeForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyPinCodeOnMappingRequest } = useAuth();
  const { t } = useTranslation();

  type FormData = {
    pinCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        pinCode: pinCodeValidator,
      })
    ),
    defaultValues: {
      pinCode: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyPinCodeOnMappingRequest(location.state.driverId, data.pinCode);
      navigate("/crew_portal/account/mapping_request/confirm", {
        state: {
          crewNumber: location.state.crewNumber,
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/mapping_request/auth_code",
          appName: "APPLICATION_FOR_POINT_COMBINATION",
          path: "account",
          state: {
            driverId: location.state.driverId,
            crewNumber: location.state.crewNumber,
          },
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="pinCodeForm">
        <Controller
          name="pinCode"
          control={control}
          defaultValue=""
          rules={{ required: t("PIN_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
                if (e.target.value.length === codeLength) {
                  handleSubmit(onSubmit)();
                }
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("PIN_CODE")}
              fullWidth
              error={Boolean(errors.pinCode)}
              helperText={errors.pinCode?.message}
              inputProps={{ maxLength: 4 }}
              style={{ marginTop: "24px" }}
            />
          )}
        />
      </form>
    </>
  );
};
