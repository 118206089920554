import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  Container,
  LayoutVertical, // Steps,
  Typography,
} from "@/components";

import Error400 from "../Error400";
import Error401 from "../Error401";
import Error404 from "../Error404";
import Error409 from "../Error409";
import Error423 from "../Error423";
import Error429 from "../Error429";
import Error500 from "../Error500";
import Error503 from "../Error503";

const pages = {
  "400": Error400,
  "401": Error401,
  "404": Error404,
  "409": Error409,
  "423": Error423,
  "429": Error429,
  "500": Error500,
  "503": Error503,
};

const ErrorLoggedIn = () => {
  const { id } = useParams<{ id?: keyof typeof pages }>();
  const { t } = useTranslation();
  const location = useLocation();
  const appName = location.state?.appName || "";

  // const activeStep = location.state?.steps?.active || 0;
  // const stepsLength = location.state?.steps?.length || 0;

  const PageComponent = id && pages[id] ? pages[id] : Error404;

  return (
    <Container>
      <LayoutVertical>
        {/* ph.2 */}
        {/* {activeStep || stepsLength ? (
          <Steps active={activeStep} length={stepsLength} />
        ) : null} */}
        {appName && <Typography variant="h1">{t(appName)}</Typography>}
        <PageComponent />
      </LayoutVertical>
    </Container>
  );
};

export { ErrorLoggedIn };
