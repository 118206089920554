import { To } from "history";

import { styled } from "@mui/material";

import { ListLinkItem } from "@/components";
import { COLORS } from "@/utils/colors";
import { toCommaString } from "@/utils/formatter";

export type Props = {
  to?: To;
  label: string;
  point: number;
};

export const ListExpiredItem: React.FC<Props> = ({ to, label, point }) => {
  return (
    <ListLinkItem to={to}>
      <Wrap>
        <Label>{label}</Label>
        <Point minus={point < 0}>
          <span>{toCommaString(point)}</span>P
        </Point>
      </Wrap>
    </ListLinkItem>
  );
};

const Point = styled("span", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "minus",
})<{ minus?: boolean }>(({ minus }) => ({
  fontWeight: 700,

  "> span": {
    color: minus ? COLORS.DEFICIT : undefined,
  },
}));

const Label = styled("span")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const Wrap = styled("span")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
}));
