export const MappingRequestResources = {
  en: {
    COMBINED_APPLICATION: "Please apply for combined application from My Page.",
    COMBINED_APPLICATION_MORE: "Please apply for additional crew numbers from your personal page.",
    APPROVAL_NOTICE:
      "- It takes about 3 business days to approve the combined application. If you have points that are close to the expiration date, please apply as soon as possible.",
    AVAILABLE_DRIVER_NUMBER_NOTICE:
      "You have a driver number that can be combined. If you apply, you can combine your points.",
    ABOUT_POINT_COMBINED_APPLICATION: "About point combined application",
    BUSINESS_NAME: "Business Name",
    SALES_OFFICE_NAME: "Sales Office Name",
    APPLY_FOR_COMBINED_APPLICATION: "Apply for combined application",
    UNAPPLIED: "Unapplied",
    REVIEWING: "Reviewing",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    REVOKE: "Revoke",
    REVOKE_CREW_NOTICE_TITLE: "Are you sure you want to revoke this driver number?",
    REVOKE_CREW_NOTICE_0: "Please be sure to check before revoking",
    REVOKE_CREW_NOTICE_1:
      "If you revoke, the points earned by dispatching with this driver number will not be combined.",
    REVOKE_CREW_NOTICE_2: "To reapply for combined application, you will need to start over from the driver app.",
    REVOKE_CREW_NOTICE_3: 'For details, please check "About point combined revocation".',
    DO_REVOKE: "Revoke",
    SUCCESSFULLY_REVOKED: "Successfully revoked.",
    FAILED_TO_REVOKE: "Failed to revoke.",
    APPLICATION_FOR_POINT_COMBINATION: "Application for point combination",
    PIN_CODE_SENT_TO_SMS_EXMAPLE_FOR_POINT_COMBINED_APPLICATION:
      "Please enter the 4-digit authentication code sent to your mobile phone number via SMS (message) from the driver app within 24 hours.",
    WHAT_IS_POINT_COMBINATION: "What is point combination?",
    WHAT_IS_REVOKE_POINT_COMBINATION: "What is point combination revocation?",
    POINT_COMBINED_APPLICATION_ACCEPTED: "Point combined application has been received",
    POINT_COMBINED_APPLICATION_ACCEPTED_MESSAGE:
      "Please wait for the confirmation from GO Inc.\nWe will contact you at the registered email address.\nPlease check your email.",
    POINT_COMBINED_APPLICATION_EXECUTED: "Point combination executed",
    POINT_COMBINED_APPLICATION_REVERTED: "Point combination reverted",
    POINT_COMBINED_APPLICATION_REVERTED_FROM: "Point combination reverted from driver number [%s]",
    POINT_COMBINED_APPLICATION_EXECUTED_FROM: "Point combination executed from driver number [%s]",
    POINT_COMBINED_APPLICATION_EXECUTED_TO: "Point combination executed to driver number [%s]",
    POINT_COMBINED_APPLICATION_REVERTED_TO: "Point combination reverted to driver number [%s]",
    MOVE: "Moved",
  },
  jp: {
    COMBINED_APPLICATION: "ポイントの合算申請はマイページから行ってください。",
    COMBINED_APPLICATION_MORE: "乗務員番号の追加申請はマイページから行ってください。",
    APPROVAL_NOTICE:
      "※合算申請の承認は3営業日ほど要します。有効期限の近いポイントがある場合は早めの申請をお願いします。",
    AVAILABLE_DRIVER_NUMBER_NOTICE: "合算申請可能な乗務員番号があります。申請を行うと保有ポイントを合算できます。",
    ABOUT_POINT_COMBINED_APPLICATION: "ポイントの合算申請について",
    BUSINESS_NAME: "事業者名",
    SALES_OFFICE_NAME: "営業所名",
    APPLY_FOR_COMBINED_APPLICATION: "合算申請する",
    UNAPPLIED: "未申請",
    REVIEWING: "審査中",
    APPROVED: "合算済み",
    REJECTED: "却下",
    REVOKE: "解除",
    REVOKE_CREW_NOTICE_TITLE: "この乗務員番号を解除してもよろしいですか？",
    REVOKE_CREW_NOTICE_0: "解除前に必ずご確認ください",
    REVOKE_CREW_NOTICE_1: "解除すると、この乗務員番号での配車での獲得ポイントが合算されなくなります。",
    REVOKE_CREW_NOTICE_2: "再度、合算申請するには乗務員アプリからやり直しが必要です。",
    REVOKE_CREW_NOTICE_3: "詳細については「ポイントの合算解除について」をご確認ください。",
    DO_REVOKE: "解除する",
    SUCCESSFULLY_REVOKED: "解除しました。",
    FAILED_TO_REVOKE: "解除できませんでした。",
    APPLICATION_FOR_POINT_COMBINATION: "ポイントの合算申請",
    PIN_CODE_SENT_TO_SMS_EXMAPLE_FOR_POINT_COMBINED_APPLICATION:
      "乗務員アプリから携帯電話番号のSMS(メッセージ)へ送信された認証コード4桁の数字を24時間以内に入力してください。",
    WHAT_IS_POINT_COMBINATION: "ポイントの合算申請とは何ですか?",
    WHAT_IS_REVOKE_POINT_COMBINATION: "ポイントの合算解除について",
    POINT_COMBINED_APPLICATION_ACCEPTED: "ポイントの合算申請を受け付けました",
    POINT_COMBINED_APPLICATION_ACCEPTED_MESSAGE:
      "GO株式会社にて内容確認を行いますので完了するまでお待ちください。\n申請状況は登録のメールアドレス宛てに連絡いたします。",
    POINT_COMBINED_APPLICATION_EXECUTED: "ポイント合算",
    POINT_COMBINED_APPLICATION_REVERTED: "ポイント合算取消",
    POINT_COMBINED_APPLICATION_REVERTED_FROM: "乗務員番号【%s】からの合算取消",
    POINT_COMBINED_APPLICATION_EXECUTED_FROM: "乗務員番号【%s】より合算",
    POINT_COMBINED_APPLICATION_EXECUTED_TO: "乗務員番号【%s】への合算取消",
    POINT_COMBINED_APPLICATION_REVERTED_TO: "乗務員番号【%s】へ合算",
    MOVE: "移行",
  },
};
