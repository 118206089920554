interface JwtPayload {
  aud: string;
  iat: number;
  iss: string;
  jti: number;
  sub: string;
}

export const parseJwt = (token: string): JwtPayload | null => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (_e) {
    return null;
  }
};
