import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import {
  ButtonContained,
  Card,
  Container,
  LayoutButton,
  LayoutVertical,
  Loader,
  Steps,
  Typography,
} from "@/components";

export const MappingRequestConfirm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // 直リンク対策
    if (!location.state || !location.state.crewNumber) {
      navigate("/crew_portal/account");
    } else {
      setIsLoading(false);
    }
  }, [location.state, navigate]);

  return (
    <Container>
      <Helmet title={t("APPLICATION_FOR_POINT_COMBINATION")} />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Steps active={2} length={2} gutterBottom />

          <LayoutVertical>
            <Typography variant="h1">{t("POINT_COMBINED_APPLICATION_ACCEPTED")}</Typography>

            <Card>
              <Typography variant="h2" gutterBottom>
                {t("REVIEWING")}
              </Typography>

              <Typography variant="h3" gutterBottom>
                {`${t("CREW_NUMBER")}:${location.state.crewNumber}`}
              </Typography>

              <LayoutVertical>
                <Typography sx={{ whiteSpace: "pre-line" }}>
                  {t("POINT_COMBINED_APPLICATION_ACCEPTED_MESSAGE")}
                </Typography>

                <LayoutButton>
                  <ButtonContained
                    size="large"
                    onClick={() => {
                      navigate("/crew_portal/account", {
                        state: {
                          status: "RELOAD",
                        },
                      });
                    }}
                  >
                    {t("TO_MY_PAGE")}
                  </ButtonContained>
                </LayoutButton>
              </LayoutVertical>
            </Card>
          </LayoutVertical>
        </>
      )}
    </Container>
  );
};
