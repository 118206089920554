import { useContext } from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { Box, ButtonTextLink, Chip, Container, LayoutVertical, Loader, StepCard, Typography } from "@/components";
import { SUPPORT_URLS } from "@/constants";
import { FetchContext as context } from "@/contexts/FetchContext";

import { MappingRequestAuthSmsCodeForm } from "./components/MappingRequest/Forms";

export const MappingRequestAuthSmsCode = () => {
  const { t } = useTranslation();
  const { state } = useContext(context);
  const location = useLocation();
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isDisabled) {
      const timer = setTimeout(() => setIsDisabled(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [isDisabled]);

  useEffect(() => {
    // 直リンク対策
    if (!location.state || !location.state.driverId) {
      navigate("/crew_portal/account");
    } else {
      setIsLoading(false);
    }
  }, [location.state, navigate]);

  return (
    <Container>
      <Helmet title={t("APPLICATION_FOR_POINT_COMBINATION")} />
      <LayoutVertical>
        {state.loading || isLoading ? (
          <Loader />
        ) : (
          <>
            <Typography variant="h1">{t("APPLICATION_FOR_POINT_COMBINATION")}</Typography>
            <StepCard activestep={0} steplength={2}>
              <Typography variant="h2" gutterBottom>
                {t("PLEASE_INPUT_PIN_CODE_FROM_SMS")}
              </Typography>
              <Box textAlign="center" style={{ marginTop: "24px", marginBottom: "24px" }}>
                <Chip label={state.data?.user?.maskedPhoneNumber || ""} size="medium" />
              </Box>
              <Typography component="h3" variant="body1">
                {t("PIN_CODE_SENT_TO_SMS_EXMAPLE_FOR_POINT_COMBINED_APPLICATION")}
              </Typography>
              <Box textAlign="center">
                <MappingRequestAuthSmsCodeForm />
              </Box>
            </StepCard>
            <Box textAlign="center">
              <ButtonTextLink to={SUPPORT_URLS["crew"]["WHO_DO_NOT_RECEIVE_SMS"]} target="_blank">
                {t("WHO_DO_NOT_RECEIVE_SMS")}
              </ButtonTextLink>
            </Box>
            <Box textAlign="center">
              <ButtonTextLink to={SUPPORT_URLS["crew"]["WHAT_IS_PIN_CODE"]} target="_blank">
                {t("WHAT_IS_PIN_CODE")}
              </ButtonTextLink>
            </Box>
            <Box textAlign="center">
              <ButtonTextLink to={SUPPORT_URLS["crew"]["WHAT_IS_POINT_COMBINATION"]} target="_blank">
                {t("WHAT_IS_POINT_COMBINATION")}
              </ButtonTextLink>
            </Box>
          </>
        )}
      </LayoutVertical>
    </Container>
  );
};
