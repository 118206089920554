import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ModalBasic } from "@/components";
import useAuth from "@/hooks/useAuth";
import getPortalType from "@/utils/getPortalType";

export type Props = {
  open: boolean;
  onClose: VoidFunction;
};

export const ModalLogout: React.FC<Props> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { t } = useTranslation();

  const handleLogout = async () => {
    const portalType = getPortalType();
    await signOut();
    navigate(((p): string => (p ? `/${p}_portal/login` : "/"))(portalType));
  };

  return (
    <ModalBasic
      open={open}
      onClose={onClose}
      title={t("ASK_LOGOUT")}
      actionButton={{
        label: t("EXECUTE_LOGOUT"),
        onClick: handleLogout,
      }}
      weakActionButton={{
        label: t("CANCEL"),
        onClick: onClose,
      }}
    />
  );
};
