import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ButtonContainedLink,
  ButtonOutlinedLink,
  ButtonTextLink,
  Card,
  Checkbox,
  Container,
  FormBox,
  FormControlLabel,
  LayoutButton,
  LayoutButtonWithTypography,
  LayoutVertical,
  Loader,
  ModalBasic,
  Steps,
  Typography,
  TypographyRounded,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import { FetchContext as context } from "@/contexts/FetchContext";
import { AlertMessagePointTransfer } from "@/features/common/Points/components";
import { COLORS } from "@/utils/colors";

export const TransferGmoLinkAuthMail = () => {
  const { state } = useContext(context);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const location = useLocation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate("/driver_portal/points/transfer");
    } else {
      setIsLoading(false);
    }
  }, [location.state, navigate]);

  return (
    <Container>
      <Helmet title={t("POINT_EXCHANGE_APPLICATION")} />

      <Steps active={3} length={4} gutterBottom />

      <ModalBasic
        open={isModalOpen}
        onClose={() => {
          // biome-ignore suppressions/unused: need to keep empty block
        }}
        title={t("AGREEMENT_REQUIRED_BANK_ACCOUNT")}
        actionButton={{
          label: t("AGREE"),
          onClick: () => {
            setAgreeToTerms(true);
            setIsCheckboxChecked(true);
            setIsModalOpen(false);
          },
        }}
        weakActionButton={{
          label: t("DISAGREE"),
          onClick: () => {
            setAgreeToTerms(false);
            setIsCheckboxChecked(false);
            setIsModalOpen(false);
          },
        }}
      >
        <AlertMessagePointTransfer bankAccountRegistration={true} />
      </ModalBasic>

      <LayoutVertical>
        <Typography variant="h1">{t("USER_NUMBER_SENT")}</Typography>
        <Card>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_CHECK_USER_NUMBER_AND_GO_TO_BANK_ACCOUNT_REGISTRATION")}
          </Typography>

          <LayoutVertical>
            <Typography style={{ color: COLORS.WARNING, fontWeight: "bold" }}>
              {t("USER_NUMBER_REQUIRED_TO_REGISTER_BANK_ACCOUNT")}
            </Typography>

            <Typography>{t("NOTICE_USER_NUMBER_SENT_VIA_EMAIL")}</Typography>

            {state.loading || !state.data?.user ? (
              <Loader />
            ) : (
              <TypographyRounded style={{ minWidth: "100%" }}>{state.data.user.mailAddress}</TypographyRounded>
            )}

            {isLoading ? (
              <Loader />
            ) : (
              <>
                <FormBox>
                  <FormControlLabel
                    name="AGREE_TO_BANK_ACCOUNT_NOTICE"
                    control={<Checkbox />}
                    label={
                      <span
                        style={{
                          color: COLORS.SECONDARY,
                          textDecoration: "underline",
                        }}
                      >
                        {t("AGREE_TO_BANK_ACCOUNT_NOTICE")}
                      </span>
                    }
                    checked={isCheckboxChecked}
                    onChange={async (e) => {
                      const target = e.target as HTMLInputElement;
                      if (target.checked) {
                        setIsModalOpen(true);
                      } else {
                        setAgreeToTerms(false);
                        setIsCheckboxChecked(false);
                      }
                    }}
                  />
                </FormBox>

                <LayoutButton>
                  <ButtonContainedLink to={location.state.gmoRegistrationUrl} disabled={!agreeToTerms}>
                    {t("REGISTER_BANK_ACCOUNT")}
                    <Typography variant="caption">{t("MOVE_TO_GMO_PG")}</Typography>
                  </ButtonContainedLink>
                </LayoutButton>
              </>
            )}
          </LayoutVertical>
        </Card>

        <LayoutButton>
          <ButtonTextLink to={SUPPORT_URLS["driver"]["WHO_DO_NOT_RECEIVE_EMAIL"]} target="_blank">
            {t("WHO_DO_NOT_RECEIVE_EMAIL")}
          </ButtonTextLink>
        </LayoutButton>

        <LayoutButton>
          <ButtonTextLink to={SUPPORT_URLS["driver"]["WHAT_IS_USER_NUMBER"]} target="_blank">
            {t("WHAT_IS_USER_NUMBER")}
          </ButtonTextLink>
        </LayoutButton>

        <LayoutButtonWithTypography>
          <Typography>{t("WHO_NEEDS_RESENDING_USER_NUMBER")}</Typography>
          <ButtonContainedLink to="/driver_portal/points/transfer/gmo_link">{t("TRY_AGAIN")}</ButtonContainedLink>
        </LayoutButtonWithTypography>

        <LayoutButton
          justifyContent="flex-start"
          sx={{
            display: { xs: "block", sm: "undefined" },
          }}
        >
          <ButtonOutlinedLink to="/driver_portal/points/transfer/gmo_link">{t("BACK")}</ButtonOutlinedLink>
        </LayoutButton>
      </LayoutVertical>
    </Container>
  );
};
