import { Dispatch, createContext } from "react";

import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from "@/constants";

export const initialState = {
  data: null,
  loading: true,
  error: null,
};

export const FetchReducer = (state: any, action: any) => {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_ERROR:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    default:
      return state;
  }
};

export type ActionType = {
  type: string;
  data?: any;
};

export type ValueType = {
  dispatch: Dispatch<ActionType>;
  state: {
    data: any;
    loading: boolean;
    error: any;
  };
};

export const FetchContext = createContext<ValueType>(undefined as never);
