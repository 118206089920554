import { useTranslation } from "react-i18next";

import { styled } from "@mui/material";

import { Card, ChipTransferStatus, ChipTransferStatusProps, Typography } from "@/components";
import { COLORS } from "@/utils/colors";
import { formatId } from "@/utils/formatter";

export type Props = {
  id: number;
  status: ChipTransferStatusProps["status"];
  date: string;
  children?: React.ReactNode;
};

export const CardTransfer: React.FC<Props> = ({ id, status, date, children }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <StyledTypography variant="h2">
        {t("APPLICATION_ID")} {formatId(id)}
      </StyledTypography>
      <Layout>
        <Caption>
          <ChipTransferStatus status={status} />
          <span>{date}</span>
        </Caption>
      </Layout>
      {children}
    </Card>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Caption = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  fontSize: "0.875rem",
  lineHeight: 1.4,
  letterSpacing: "0.04em",
  color: COLORS.TEXT_WEAK,
}));

const Layout = styled("div")(({ theme }) => ({
  "&:not(:last-of-type)": {
    marginBottom: theme.spacing(6),
  },
}));
