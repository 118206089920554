import { useTranslation } from "react-i18next";

import { ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

import { Button } from "../../components/Button/Button";
import { Chip } from "../../components/Chip/Chip";

export type Props = {
  children: React.ReactNode;
  onClick: VoidFunction;
};

export const Information: React.FC<Props> = ({ children, onClick }) => {
  return (
    <StyledInformationWrap>
      <StyledInformation fullWidth variant="outlined" endIcon={<ChevronRight />} onClick={onClick}>
        <UnreadChip />
        <span>{children}</span>
      </StyledInformation>
    </StyledInformationWrap>
  );
};

const UnreadChip: React.FC = () => {
  const { t } = useTranslation();
  return <Chip label={t("UNREAD")} color="error" size="small" component="span" wraptext="nowrap" />;
};

const StyledInformationWrap = styled("div")({});

const StyledInformation = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(0, 2),
  borderRadius: "8px",
  padding: theme.spacing(2, 4),
  textAlign: "left",
  letterSpacing: "0.04em",
  color: COLORS.ALERT,
  borderColor: COLORS.ALERT,
  background: COLORS.ALERT_BG,

  ".MuiChip-root": {
    marginRight: theme.spacing(2),
  },

  ".MuiButton-endIcon": {
    marginLeft: "auto",
  },

  "&:hover": {
    color: COLORS.ALERT,
    borderColor: COLORS.ALERT,
    background: COLORS.ALERT_BG,
  },
}));
