import axios, { AxiosResponse } from "axios";
import { ReactNode, createContext, useEffect, useReducer } from "react";

import { INITIALIZE, SIGN_IN, SIGN_OUT, SIGN_UP } from "@/constants";
import { User } from "@/types/user";
import { userDefaultValues } from "@/types/user";
import { axiosAccountInstance, axiosAuthInstance } from "@/utils/axios";

import { ActionMap, AuthState, AuthUser, JWTContextType } from "../types/auth";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../utils/localStorage";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
  };
};

// authTokenがない場合にリクエストしたときの事前エラー
export class EmptyAuthTokenError extends Error {
  data: object;
  status: number;

  constructor(message: string, data: object, status: number) {
    super(message);
    this.data = data;
    this.status = status;
  }
}

const setSession = (accountToken: string | null) => {
  if (accountToken) {
    setLocalStorageItem("accountToken", accountToken);
    axiosAccountInstance.defaults.headers.common["X-CrewPortal-Account-Authorization"] = `Bearer ${accountToken}`;
  } else {
    removeLocalStorageItem("accountToken");
    delete axiosAccountInstance.defaults.headers.common["X-CrewPortal-Account-Authorization"];
  }
};

const checkAuthorizationHeader = () => {
  const authToken = getLocalStorageItem("authToken");
  if (authToken) {
    if (!axiosAuthInstance.defaults.headers.common["X-CrewPortal-Auth-Authorization"]) {
      axiosAuthInstance.defaults.headers.common["X-CrewPortal-Auth-Authorization"] = `Bearer ${authToken}`;
    }
  } else {
    throw new EmptyAuthTokenError("EMPTY_AUTH_TOKEN", { error_code: "E401-05" }, 401);
  }
};

export const removeAuthToken = () => {
  removeLocalStorageItem("authToken");
  delete axios.defaults.headers.common["X-CrewPortal-Auth-Authorization"];
};

const setTmpSession = (authToken: string) => {
  setLocalStorageItem("authToken", authToken);
  axiosAuthInstance.defaults.headers.common["X-CrewPortal-Auth-Authorization"] = `Bearer ${authToken}`;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accountToken = getLocalStorageItem("accountToken");
        const user: AuthUser = { accountToken };
        if (user.accountToken) {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (_err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const isSignedIn = () => {
    const accountToken = getLocalStorageItem("accountToken");
    return Boolean(accountToken);
  };

  const logInByPhoneNumber = async (phoneNumber: string, password: string) => {
    const response = await axiosAuthInstance.post("/api/login", {
      phone_number: phoneNumber,
      password: password,
    });
    const { account_token } = response.data;
    const user: User = { ...userDefaultValues, accountToken: account_token };

    setSession(account_token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const logInByEmail = async (email: string, password: string) => {
    const response = await axiosAuthInstance.post("/api/driver/login", {
      mail_address: email,
      password: password,
    });
    const { account_token } = response.data;
    const user: User = { ...userDefaultValues, accountToken: account_token };

    setSession(account_token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signOut = async () => {
    // リクエストに失敗してもログアウト処理は実行する
    try {
      await axiosAccountInstance.post("/api/logout");
    } catch (_err: any) {
      // biome-ignore suppressions/unused: need not to use
    } finally {
      // GA4: driver_idをnullでセット
      window.dataLayer.push({
        driver_id: null,
      });

      setSession(null);
      dispatch({ type: SIGN_OUT });
    }
  };

  const signUp = async (phoneNumber: string, pinCode: string) => {
    const response = await axiosAuthInstance.post("/api/register/auth_code", {
      phone_number: phoneNumber,
      pin_code: Number(pinCode),
    });
    const { auth_token } = response.data;
    setTmpSession(auth_token);
  };

  const verifyEmail = async (email: string) => {
    checkAuthorizationHeader();
    await axiosAuthInstance.post("/api/register/auth_mail", {
      mail_address: email,
    });
  };

  const verifySigningUp = async (_email: string, confirmCode: string) => {
    checkAuthorizationHeader();
    await axiosAuthInstance.post("/api/register/auth_mail_code", {
      confirm_code: Number(confirmCode),
    });
  };

  const verifyCrewNumberAndPhoneNumber = async (crewNumber: string, phoneNumber: string) => {
    const response = await axiosAuthInstance.post("/api/password_reset", {
      driver_code: crewNumber,
      phone_number: phoneNumber,
    });
    const { auth_token } = response.data;
    setTmpSession(auth_token);
  };

  const sendPinCode = async () => {
    await axiosAuthInstance.post("/api/password_reset/auth_mail");
  };

  const verifyPinCode = async (pinCode: string) => {
    checkAuthorizationHeader();
    await axiosAuthInstance.post("/api/password_reset/auth_mail_code", {
      pin_code: Number(pinCode),
    });
  };

  const resetPassword = async (password: string) => {
    const response = await axiosAuthInstance.post("/api/password_reset/password", {
      new_password: password,
    });
    const { account_token } = response.data;

    setLocalStorageItem("accountToken", account_token);

    const user: AuthUser = { accountToken: account_token };

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const registerPassword = async (password: string) => {
    const response = await axiosAuthInstance.post("/api/register/password", {
      password: password,
    });
    const { account_token } = response.data;

    setLocalStorageItem("accountToken", account_token);

    const user: AuthUser = { accountToken: account_token };

    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const verifyAccountPhoneNumber = async () => {
    const response = await axiosAccountInstance.post("/api/account/mail_address_change/auth_sms");
    const { auth_token } = response.data;
    setTmpSession(auth_token);
  };

  const verifyAccountPinCode = async (target: string, pinCode: string) => {
    await axiosAuthInstance.post(
      `/api/account/${target}_change/auth_${target === "mail_address" ? "sms" : "mail"}_code`,
      {
        pin_code: Number(pinCode),
      }
    );
  };

  const registerAccountEmail = async (email: string) => {
    await axiosAuthInstance.post("/api/account/mail_address_change/auth_mail", {
      new_mail_address: email,
    });
  };

  const verifyAccountConfirmCode = async (target: string, confirmCode: string) => {
    await axiosAuthInstance.post(
      `/api/account/${target}_change/auth_${target === "mail_address" ? "mail" : "sms"}_code`,
      {
        confirm_code: Number(confirmCode),
      }
    );
  };

  const verifyAccountEmail = async (target: string) => {
    const response = await axiosAccountInstance.post(`/api/account/${target}_change/auth_mail`);
    const { auth_token } = response.data;
    setTmpSession(auth_token);
  };

  const registerAccountPhoneNumber = async (phoneNumber: string) => {
    checkAuthorizationHeader();
    await axiosAuthInstance.post("/api/account/phone_number_change/auth_sms", {
      new_phone_number: phoneNumber,
    });
  };

  const changePassword = async (password: string) => {
    checkAuthorizationHeader();
    await axiosAuthInstance.post("/api/account/password_change/password", {
      new_password: password,
    });
  };

  const verifyPinCodeOnPointTransfer = async (pinCode: string, transferId: string, portalType: "crew" | "driver") => {
    checkAuthorizationHeader();
    await axiosAuthInstance.post(`/api/point/transfer/auth_${portalType === "crew" ? "sms" : "mail"}_code`, {
      pin_code: Number(pinCode),
      transfer_id: transferId,
    });
  };

  const verifyExchangePointAmount = (
    amount: number,
    transferFee: number,
    isPinCodeSkipped: boolean,
    portalType: "crew" | "driver"
  ): Promise<string> => {
    return axiosAccountInstance
      .post(
        `/api/point/transfer/${isPinCodeSkipped ? "skip_auth" : portalType === "crew" ? "auth_sms" : "auth_mail"}`,
        {
          amount: amount - transferFee,
          transfer_fee: transferFee,
        }
      )
      .then((response: AxiosResponse<{ auth_token: string; transfer_id: string }>) => {
        const { auth_token, transfer_id } = response.data;
        setTmpSession(auth_token);
        return transfer_id;
      });
  };

  const verifyExchangePointAmountRetry = (
    amount: number,
    transferFee: number,
    transferId: string,
    portalType: "crew" | "driver"
  ): Promise<string> => {
    return axiosAccountInstance
      .post(`/api/point/transfer/auth_${portalType === "crew" ? "sms" : "mail"}/retry`, {
        amount: amount - transferFee,
        transfer_fee: transferFee,
        transfer_id: transferId,
      })
      .then((response: AxiosResponse<{ transfer_id: string }>) => {
        const { transfer_id } = response.data;
        return transfer_id;
      });
  };

  const cancelExchangePoint = async (transferId: string) => {
    await axiosAccountInstance.post("/api/point/transfer/cancellation", {
      transfer_id: transferId,
    });
  };

  const verifyPinCodeOnMappingRequest = async (subDriverId: number, pinCode: string) => {
    await axiosAccountInstance.post("/api/mapping_request/auth_code", {
      sub_driver_id: subDriverId,
      pin_code: Number(pinCode),
    });
  };

  const revokeMappingRequest = async (subDriverId: number) => {
    await axiosAccountInstance.post("/api/mapping_request/revoke", {
      sub_driver_id: subDriverId,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        isSignedIn,
        logInByPhoneNumber,
        logInByEmail,
        signOut,
        signUp,
        verifyEmail,
        verifySigningUp,
        sendPinCode,
        verifyPinCode,
        registerPassword,
        verifyAccountPhoneNumber,
        verifyAccountPinCode,
        registerAccountEmail,
        verifyAccountEmail,
        registerAccountPhoneNumber,
        verifyAccountConfirmCode,
        changePassword,
        verifyCrewNumberAndPhoneNumber,
        resetPassword,
        verifyPinCodeOnPointTransfer,
        verifyExchangePointAmount,
        verifyExchangePointAmountRetry,
        cancelExchangePoint,
        verifyPinCodeOnMappingRequest,
        revokeMappingRequest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
