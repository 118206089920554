import {
  Alert,
  AlertInfo,
  ButtonContained,
  ButtonTextLink,
  InfoMessage,
  ModalBasic,
  TextField,
  TextFieldEmail,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";
import {
  codeLength,
  confirmCodeValidator,
  emailValidator,
  pinCodeValidator,
  validationSchema,
} from "@/utils/validators";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const MailAddressChangeAuthSmsForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyAccountPhoneNumber } = useAuth();
  const { t } = useTranslation();
  const portalType = getPortalType();

  return (
    <>
      <Typography variant="h2" align="center" />
      <ButtonContained
        type="submit"
        color="primary"
        onClick={async () => {
          try {
            await verifyAccountPhoneNumber();
            navigate(((p): string => (p ? `/${p}_portal/account/mail_address_change/auth_sms_code` : "/"))(portalType));
          } catch (error) {
            if (axios.isAxiosError(error)) {
              navigateError({
                error: error,
                endpoint: "/api/account/mail_address_change/auth_sms",
                appName: "CHANGE_EMAIL",
                path: "account",
              });
            }
          }
        }}
        style={{ marginTop: "24px" }}
        size="large"
      >
        {t("SEND_PIN_CODE")}
      </ButtonContained>
    </>
  );
};

export const MailAddressChangeAuthSmsCodeForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyAccountPinCode } = useAuth();
  const { t } = useTranslation();
  const portalType = getPortalType();
  const [submitError] = useState("");
  type FormData = {
    pinCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        pinCode: pinCodeValidator,
      })
    ),
    defaultValues: {
      pinCode: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyAccountPinCode("mail_address", data.pinCode);
      navigate(((p): string => (p ? `/${p}_portal/account/mail_address_change/auth_mail` : "/"))(portalType));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/mail_address_change/auth_sms_code",
          appName: "CHANGE_EMAIL",
          path: "account",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="pinCodeForm">
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="pinCode"
          control={control}
          defaultValue=""
          rules={{ required: t("PIN_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
                if (e.target.value.length === codeLength) {
                  handleSubmit(onSubmit)();
                }
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("PIN_CODE")}
              fullWidth
              error={Boolean(errors.pinCode)}
              helperText={errors.pinCode?.message}
              inputProps={{ maxLength: 4 }}
              style={{ marginTop: "24px" }}
            />
          )}
        />
      </form>
    </>
  );
};

export const MailAddressChangeAuthMailForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { registerAccountEmail } = useAuth();
  const { t } = useTranslation();
  const [submitError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const portalType = getPortalType();

  type FormData = {
    email: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        email: emailValidator,
      })
    ),
    defaultValues: {
      email: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await registerAccountEmail(data.email);
      setEmail(data.email);
      setIsModalOpen(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/mail_address_change/auth_mail",
          appName: "CHANGE_EMAIL",
          path: "account",
          state: { newEmail: email },
        });
      }
    }
  };

  const handleClose = async () => {
    navigate(((p): string => (p ? `/${p}_portal/account/mail_address_change/auth_mail_code` : "/"))(portalType), {
      state: { newEmail: email },
    });
  };

  return (
    <>
      <ModalBasic
        open={isModalOpen}
        onClose={() => {
          // biome-ignore suppressions/unused: need not to use
        }}
        title={t("EMAIL_SENT_FOR_CONFIRMATION")}
        actionButton={{
          label: t("NEXT"),
          onClick: handleClose,
        }}
      >
        <>
          <span style={{ color: COLORS.WARNING, fontWeight: "bold" }}>{t("PLEASE_CHECK_YOUR_EMAIL")}</span>
          <InfoMessage variant="primary" sx={{ marginTop: "24px" }}>
            {t("IF_YOU_DO_NOT_RECEIVE_CONFIRMATION_EMAIL")}
          </InfoMessage>
          <AlertInfo style={{ marginBottom: "20px" }}>
            <Typography>{t("CHECK_BELOW_FAQ")}</Typography>
          </AlertInfo>
          <ButtonTextLink
            to={SUPPORT_URLS.crew.WHAT_IS_CONFIRMATION_EMAIL_NOT_ARRIVED}
            target="_blank"
            sx={{ textAlign: "left" }}
            isTargetBlankFixed
          >
            {t("WHAT_IS_CONFIRMATION_EMAIL_NOT_ARRIVED")}
          </ButtonTextLink>
        </>
      </ModalBasic>
      <form onSubmit={handleSubmit(onSubmit)} name="emailForm" noValidate>
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: t("EMAIL_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldEmail
              onChange={(e) => {
                onChange(e);
              }}
              onBlur={onBlur}
              value={value}
              type="email"
              label={t("EMAIL")}
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              style={{ marginTop: "24px", marginBottom: "24px" }}
            />
          )}
        />
        <ButtonContained type="submit" color="primary" disabled={!isDirty || isSubmitting} size="large">
          {t("SEND")}
        </ButtonContained>
      </form>
    </>
  );
};

export const MailAddressChangeAuthMailCodeForm = ({
  newEmail,
}: {
  newEmail: string;
}) => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyAccountConfirmCode } = useAuth();
  const { t } = useTranslation();
  const portalType = getPortalType();
  const [submitError] = useState("");
  type FormData = {
    confirmCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        confirmCode: confirmCodeValidator,
      })
    ),
    defaultValues: {
      confirmCode: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyAccountConfirmCode("mail_address", data.confirmCode);
      navigate(((p): string => (p ? `/${p}_portal/account` : "/"))(portalType), {
        state: { status: "EMAIL_SUCCESS" },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/mail_address_change/auth_mail_code",
          appName: "CHANGE_EMAIL",
          path: "account",
          state: { newEmail },
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="confirmCodeForm">
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="confirmCode"
          control={control}
          defaultValue=""
          rules={{ required: t("CONFIRM_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
                if (e.target.value.length === codeLength) {
                  handleSubmit(onSubmit)();
                }
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("CONFIRM_CODE")}
              fullWidth
              error={Boolean(errors.confirmCode)}
              helperText={errors.confirmCode?.message}
              inputProps={{ maxLength: 4 }}
              style={{ marginTop: "24px" }}
            />
          )}
        />
      </form>
    </>
  );
};
