import { TableCell as MuiTableCell, TableCellProps, styled } from "@mui/material";

import { COLORS } from "@/utils/colors";

type StyleProps = {
  fontStyle?: "weak" | "strong";
  bold?: boolean;
};

export type Props = TableCellProps & StyleProps;

export const TableCell = styled(MuiTableCell, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "bold" && propName !== "total",
})<StyleProps>(({ theme, fontStyle, bold }) => ({
  padding: theme.spacing(1, 2),
  height: "56px",
  borderBottom: `1px solid ${COLORS.BORDER}`,
  fontSize: fontStyle === "strong" ? "1.25rem" : fontStyle === "weak" ? "1rem" : undefined,
  fontWeight: fontStyle === "strong" || bold ? 700 : undefined,
  lineHeight: 1.4,
  letterSpacing: "0.04em",
}));
