import React from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableCellHead,
  TableCols,
  TableExpandContent,
  TableExpandTrigger,
  TableRow,
  Typography,
} from "@/components";
import { useTableExpand } from "@/hooks/useTableExpand";
import type { PointTransferFee } from "@/types/pointTransferFee";
import { COLORS } from "@/utils/colors";

export interface CardPointTransferProps {
  exchangePointsAmount: number;
  pointTransferFee: PointTransferFee;
  status?: string;
}

export const CardPointTransfer: React.FC<CardPointTransferProps> = ({
  exchangePointsAmount,
  pointTransferFee,
  status,
}) => {
  const { t } = useTranslation();
  const { triggerProps, contentProps } = useTableExpand();

  return (
    <Card>
      <Typography variant="h2" gutterBottom>
        {t("POINT_TRANSFER_CONTENTS")}
      </Typography>
      <Table>
        <TableCaption align="right">{t("POINT_TO_YEN")}</TableCaption>
        <TableCols sizes={[50, 50]} />
        <TableBody>
          <TableRow>
            <TableCellHead bold>{t("EXCHANGE_POINT_AMOUNT")}</TableCellHead>
            <TableCell bold align="right">
              {exchangePointsAmount.toLocaleString() || 0}P
            </TableCell>
          </TableRow>
          {status !== "FAILED" && <TableExpandTrigger {...triggerProps("breakdown")} label={t("BREAKDOWN")} />}
          <TableExpandContent {...contentProps("breakdown")}>
            <Table>
              <TableCols sizes={[50, 50]} />
              <TableBody>
                <TableRow>
                  <TableCellHead>{t("TIP")}</TableCellHead>
                  <TableCell fontStyle="weak" align="right">
                    {pointTransferFee?.pointBreakdown.userTip.toLocaleString() || 0}P
                  </TableCell>
                </TableRow>
                {pointTransferFee?.pointBreakdown.other > 0 && (
                  <TableRow>
                    <TableCellHead>{t("OTHER")}</TableCellHead>
                    <TableCell fontStyle="weak" align="right">
                      {pointTransferFee.pointBreakdown.other.toLocaleString()}P
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableExpandContent>
          <TableRow>
            <TableCellHead>{t("REMITTANCE_FEE")}</TableCellHead>
            <TableCell align="right">
              {pointTransferFee?.transferFee > 0 ? `-${pointTransferFee.transferFee.toLocaleString()}` : "0"}
              P
              <br />
              {pointTransferFee?.transferFee === 0 && (
                <Typography style={{ fontSize: "14px", color: COLORS.SECONDARY }}>{t("ONCE_FREE")}</Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCellHead bold>{t("TRANSFER_AMOUNT")}</TableCellHead>
            <TableCell fontStyle="strong" align="right">
              {(exchangePointsAmount - pointTransferFee.transferFee).toLocaleString() || 0}
              {t("YEN")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};
