import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Container, LayoutVertical, StepCard, Typography } from "@/components";

import { PasswordResetPasswordForm } from "./components/Forms";

export const PasswordResetPassword = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Helmet title={t("RESET_PASSWORD")} />
      <LayoutVertical>
        <StepCard activestep={3} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_REGISTER_NEW_PASSWORD")}
          </Typography>
          <PasswordResetPasswordForm />
        </StepCard>
      </LayoutVertical>
    </Container>
  );
};
