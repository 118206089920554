import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ButtonContained,
  ButtonOutlinedLink,
  Card,
  Checkbox,
  Container,
  FormBox,
  FormControlLabel,
  LayoutButton,
  LayoutVertical,
  Loader,
  ModalBasic,
  Steps,
  Typography,
} from "@/components";
import { FetchContext as context } from "@/contexts/FetchContext";
import {
  AlertMessagePointTransfer,
  BankTable,
  CardPointTransfer,
  CardReceiveMethod,
} from "@/features/common/Points/components";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { ErrorResponse } from "@/types/errorResponse";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";

export const TransferAccepted = () => {
  const { state } = useContext(context);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const portalType = getPortalType();
  const location = useLocation();
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();

  const { verifyExchangePointAmount } = useAuth();

  useEffect(() => {
    if (!location.state) {
      navigate(`/${portalType}_portal/points/transfer`);
    } else if (!state.loading && state.data?.user) {
      setIsLoading(false);
    }
  }, [location.state, navigate, portalType, state.loading, state.data?.user]);

  return (
    <Container>
      <Helmet title={t("POINT_EXCHANGE_APPLICATION")} />

      <Steps active={2} length={4} gutterBottom />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ModalBasic
            open={isModalOpen}
            onClose={() => {
              // biome-ignore suppressions/unused: need not to use
            }}
            title={t("AGREEMENT_REQUIRED_BANK_TRANSFER")}
            actionButton={{
              label: t("AGREE"),
              onClick: () => {
                setAgreeToTerms(true);
                setIsCheckboxChecked(true);
                setIsModalOpen(false);
              },
            }}
            weakActionButton={{
              label: t("DISAGREE"),
              onClick: () => {
                setAgreeToTerms(false);
                setIsCheckboxChecked(false);
                setIsModalOpen(false);
              },
            }}
          >
            <AlertMessagePointTransfer />
          </ModalBasic>

          <LayoutVertical>
            <Typography variant="h1">{t("POINT_TRANSFER_CONFIRM")}</Typography>

            <LayoutButton
              justifyContent="flex-start"
              sx={{
                display: { xs: "block", sm: "undefined" },
              }}
            >
              <ButtonOutlinedLink to={((p): string => (p ? `/${p}_portal/points/transfer` : "/"))(portalType)}>
                {t("BACK")}
              </ButtonOutlinedLink>
            </LayoutButton>

            <CardPointTransfer
              exchangePointsAmount={location.state.exchangePointsAmount}
              pointTransferFee={location.state.pointTransferFee}
            />
            <CardReceiveMethod isFreeTransfer={location.state.pointTransferFee?.transferFee === 0} />

            <Card>
              <Typography variant="h2" gutterBottom>
                {t("BANK_ACCOUNT")}
              </Typography>

              <LayoutVertical style={{ marginBottom: "24px" }}>
                <BankTable
                  bankName={location.state.pointTransferMethod.bankAccountInfo.bankName}
                  branchName={location.state.pointTransferMethod.bankAccountInfo.branchName}
                  accountNumber={location.state.pointTransferMethod.bankAccountInfo.maskedAccountNumber}
                  accountName={location.state.pointTransferMethod.bankAccountInfo.accountName}
                />
              </LayoutVertical>
            </Card>

            <FormBox>
              <FormControlLabel
                name="AGREE_TO_BANK_TRANSFER_NOTICE"
                control={<Checkbox />}
                label={
                  <span
                    style={{
                      color: COLORS.SECONDARY,
                      textDecoration: "underline",
                    }}
                  >
                    {t("AGREE_TO_BANK_TRANSFER_NOTICE")}
                  </span>
                }
                checked={isCheckboxChecked}
                onChange={async (e) => {
                  const target = e.target as HTMLInputElement;
                  if (target.checked) {
                    setIsModalOpen(true);
                  } else {
                    setAgreeToTerms(false);
                    setIsCheckboxChecked(false);
                  }
                }}
              />
            </FormBox>

            <LayoutButton>
              <ButtonContained
                type="submit"
                onClick={async () => {
                  setIsLoading(true);
                  await verifyExchangePointAmount(
                    location.state.exchangePointsAmount,
                    location.state.pointTransferFee?.transferFee,
                    state.data.user.isPointTransferPinCodeSkipped,
                    portalType ? portalType : "crew"
                  )
                    .then((transferId) => {
                      setIsLoading(false);
                      navigate(
                        ((p): string =>
                          p
                            ? `/${portalType}_portal/points/transfer/${
                                state.data.user.isPointTransferPinCodeSkipped
                                  ? "confirm"
                                  : p === "crew"
                                    ? "auth_sms_code"
                                    : "auth_mail_code"
                              }`
                            : "/")(portalType),

                        {
                          state: {
                            ...location.state,
                            pointTransferFee: location.state.pointTransferFee,
                            exchangePointsAmount: location.state.exchangePointsAmount,
                            transferId: transferId,
                            status: state.data.user.isPointTransferPinCodeSkipped ? "RELOAD" : undefined,
                          },
                        }
                      );
                    })
                    .catch((error: AxiosError<ErrorResponse>) => {
                      navigateError({
                        error: error,
                        endpoint: `/api/point/transfer/${
                          state.data.user.isPointTransferPinCodeSkipped
                            ? "skip_auth"
                            : portalType === "crew"
                              ? "auth_sms"
                              : "auth_mail"
                        }`,
                        appName: "POINT_EXCHANGE_APPLICATION_ERROR",
                        path: "points",
                        state: {
                          steps: {
                            active: 2,
                            length: 4,
                          },
                        },
                      });
                    });
                }}
                size="large"
                disabled={!agreeToTerms}
              >
                {t("APPLY_POINT_TRANSFER")}
              </ButtonContained>
              <ButtonOutlinedLink
                to={((p): string => (p ? `/${p}_portal/points/transfer` : "/"))(portalType)}
                size="large"
              >
                {t("BACK_AND_CHANGE_CONTENTS")}
              </ButtonOutlinedLink>
            </LayoutButton>
          </LayoutVertical>
        </>
      )}
    </Container>
  );
};
