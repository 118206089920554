export type PointTransferHistory = {
  transferId: number;
  status: string;
  amount: number;
  receiveMethod: string;
  createdAt: string;
};

export type PointTransferHistories = PointTransferHistory[];

export const pointTransferHistoriesDefaultValues: PointTransferHistories = [];

export type PointTransferHistoryDetail = {
  transferId: number;
  status: string;
  amount: number;
  fee: {
    transferFee: number;
    errorFee: number;
  };
  receiveMethod: string;
  updatedAt: string;
  createdAt: string;
  executedAt: string;
  historyId: number;
  failedInfo: {
    failedReason: string;
  };
  pointBreakdown: {
    userTip: number;
    other: number;
  };
  bankAccountInfo: {
    bankName: string;
    branchName: string;
    accountName: string;
    maskedAccountNumber: string;
    accountNumber: string;
    accountType: string;
  };
};

export const pointTransferHistoryDetailDefaultValues: PointTransferHistoryDetail = {
  transferId: 0,
  status: "",
  amount: 0,
  fee: {
    transferFee: 0,
    errorFee: 0,
  },
  receiveMethod: "",
  updatedAt: "",
  createdAt: "",
  executedAt: "",
  historyId: 0,
  failedInfo: {
    failedReason: "",
  },
  pointBreakdown: {
    userTip: 0,
    other: 0,
  },
  bankAccountInfo: {
    bankName: "",
    branchName: "",
    accountName: "",
    maskedAccountNumber: "",
    accountNumber: "",
    accountType: "",
  },
};
