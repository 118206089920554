export type MappingRequest = {
  subDriverId: number;
  subDriverCode: string;
  subCompanyName: string;
  subOfficeName: string;
  state:
    | "unapplied"
    | "reviewing"
    | "reviewing_manually"
    | "approved_manually"
    | "approved_automatically"
    | "rejected"
    | "rejected_hidden"
    | "cancelled"
    | "revoked";
  appliedAt: string;
  reviewedAt: string;
};

export type User = {
  accountToken: string;
  driverName: string;
  driverId: number;
  driverType: string;
  driverCode: number;
  companyName: string;
  officeName: string;
  maskedPhoneNumber: string;
  mailAddress: string;
  isPointTransferPinCodeSkipped: boolean;
  mappingRequests: MappingRequest[];
  driverScore: string;
};

export const userDefaultValues: User = {
  accountToken: "",
  driverName: "",
  driverId: 0,
  driverType: "",
  driverCode: 0,
  companyName: "",
  officeName: "",
  maskedPhoneNumber: "",
  mailAddress: "",
  isPointTransferPinCodeSkipped: false,
  mappingRequests: [],
  driverScore: "",
};

export type MaskedMailAddress = {
  maskedMailAddress: string;
};
