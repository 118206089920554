import { styled } from "@mui/material";

import { List, ListProps } from "@/components";
import { COLORS } from "@/utils/colors";

export type Props = ListProps & {
  variant?: "card";
};

export const ListPoint: React.FC<Props> = ({ children, ...props }) => {
  return <StyledList {...props}>{children}</StyledList>;
};

const StyledList = styled(List, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "variant",
})<{ variant?: Props["variant"] }>(({ theme, variant }) => {
  if (variant === "card") {
    return {
      ".listPointItem": {
        boxShadow: " 0px 0px 14px 0px rgba(0, 0, 0, 0.05)",
        padding: theme.spacing(6),
        borderRadius: "8px",
        borderBottom: "none",
        backgroundColor: COLORS.BG_01,
        marginBottom: theme.spacing(6),
      },
    };
  }
  return {
    marginTop: theme.spacing(-4),

    ".listPointItem": {
      borderBottom: `1px solid ${COLORS.BORDER}`,
      padding: `${theme.spacing(4)} 0`,
    },
  };
});
