import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "@/hooks/useAuth";
import getPortalType from "@/utils/getPortalType";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
const AuthGuard: React.FC<AuthGuardType> = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const portalType = getPortalType();

  if (!isInitialized) {
    return <></>;
  }

  if (!isAuthenticated) {
    return <Navigate to={((p): string => (p ? `/${p}_portal/login` : "/"))(portalType)} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
