import {
  Alert,
  ButtonContained,
  ButtonOutlined,
  ButtonTextLink,
  Card,
  Checkbox,
  Container,
  FormBox,
  FormControlLabel,
  LayoutButton,
  LayoutButtonWithTypography,
  LayoutVertical,
  Loader,
  Steps,
  TextField,
  Typography,
  TypographyRounded,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";
import { FETCH_SUCCESS } from "@/constants";
import { FetchContext as context } from "@/contexts/FetchContext";
import { ModalCancelConfirm, ModalResendConfirm } from "@/features/common/Points/components";
import useAuth from "@/hooks/useAuth";
import { useModal } from "@/hooks/useModal";
import { useNavigationError } from "@/hooks/useNavigationError";
import { ErrorResponse } from "@/types/errorResponse";
import { PointTransferFee, pointTransferFeeDefaultValues } from "@/types/pointTransferFee";
import { axiosAccountInstance } from "@/utils/axios";
import { COLORS } from "@/utils/colors";
import { codeLength, pinCodeValidator, validationSchema } from "@/utils/validators";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

type FormData = {
  pinCode: string;
};

export const TransferAuthMailCode = () => {
  const { state, dispatch } = useContext(context);

  const location = useLocation();

  const [submitError] = useState("");
  const [isPinCodeSkipped, setIsPinCodeSkipped] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [transferId, setTransferId] = useState<string>("");
  const [pointTransferFee, setPointTransferFee] = useState<PointTransferFee>(pointTransferFeeDefaultValues);
  const [exchangePointsAmount, setExchangePointsAmount] = useState<number>(0);

  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useModal();
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { cancelExchangePoint, verifyPinCodeOnPointTransfer, verifyExchangePointAmountRetry } = useAuth();

  const handleErrors = (error: AxiosError<ErrorResponse>, endpoint: string) => {
    navigateError({
      error: error,
      endpoint: endpoint,
      appName: "POINT_EXCHANGE_APPLICATION_ERROR",
      path: "points",
      state: {
        transferId: transferId,
        pointTransferFee: pointTransferFee,
        exchangePointsAmount: exchangePointsAmount,
        steps: {
          active: 3,
          length: 4,
        },
      },
    });
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        pinCode: pinCodeValidator,
      })
    ),
    defaultValues: {
      pinCode: "",
    },
    mode: "onChange",
  });

  const handlePinCodeSkipChange = async () => {
    const reversed = !isPinCodeSkipped;
    const body = { is_point_transfer_pin_code_skipped: reversed };

    await axiosAccountInstance
      .patch("/api/account", body)
      .then(() => {
        // biome-ignore suppressions/unused: need not to watch no-console
      })
      .catch((error: AxiosError<ErrorResponse>) => {
        navigateError({
          error: error,
          endpoint: "/api/account",
        });
      })
      .finally(() => {
        const updatedUser = {
          ...state.data.user,
          isPointTransferPinCodeSkipped: reversed,
        };

        dispatch({
          type: FETCH_SUCCESS,
          data: {
            ...state.data,
            user: updatedUser,
          },
        });

        setIsPinCodeSkipped(reversed);
      });
  };

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      await verifyPinCodeOnPointTransfer(data.pinCode, transferId, "driver");
      navigate("/driver_portal/points/transfer/confirm", {
        state: {
          ...location.state,
          transferId: transferId,
          status: "RELOAD",
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleErrors(error, "/api/point/transfer/auth_mail_code");
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      setTransferId(location.state.transferId);
      setPointTransferFee(location.state.pointTransferFee);
      setExchangePointsAmount(location.state.exchangePointsAmount);
      setIsLoading(false);
    }
  }, [location.state]);

  useEffect(() => {
    if (state.data?.user) {
      setIsPinCodeSkipped(state.data.user.isPointTransferPinCodeSkipped);
    }
  }, [state.data]);

  return (
    <Container>
      <Helmet title={t("POINT_EXCHANGE_APPLICATION")} />

      <Steps active={3} length={4} gutterBottom />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <LayoutVertical>
            <Typography variant="h1">{t("APPLICATION_NOT_COMPLETED_YET")}</Typography>
            <Card>
              <Typography variant="h2" gutterBottom>
                {t("PLEASE_INPUT_PIN_CODE")}
              </Typography>

              <LayoutVertical>
                {state.loading || !state.data?.user ? (
                  <Loader />
                ) : (
                  <TypographyRounded style={{ minWidth: "100%" }}>{state.data.user.mailAddress}</TypographyRounded>
                )}

                <Typography>
                  {t("PIN_CODE_SENT_TO_EMAIL_EXMAPLE_1")}
                  <br />
                  {t("PIN_CODE_SENT_TO_EMAIL_EXMAPLE_2")}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)} name="pinCodeForm">
                  {submitError && (
                    <Alert mt={2} mb={1} severity="warning">
                      {submitError}
                    </Alert>
                  )}
                  <Controller
                    name="pinCode"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("PIN_CODE_REQUIRED") }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e);
                          if (e.target.value.length === codeLength) {
                            await handleSubmit(onSubmit)();
                          }
                        }}
                        onBlur={onBlur}
                        value={value}
                        type="tel"
                        label={t("PIN_CODE")}
                        fullWidth
                        error={Boolean(errors.pinCode)}
                        helperText={errors.pinCode?.message}
                      />
                    )}
                  />
                </form>

                <FormBox>
                  <FormControlLabel
                    name="SKIP_PIN_CODE_INPUT_NEXT_TIME"
                    control={<Checkbox />}
                    label={t("SKIP_PIN_CODE_INPUT_NEXT_TIME")}
                    onChange={async () => {
                      await handlePinCodeSkipChange();
                    }}
                    checked={isPinCodeSkipped}
                  />
                </FormBox>
              </LayoutVertical>
            </Card>

            <LayoutButton>
              <ButtonTextLink to={SUPPORT_URLS["driver"]["WHO_DO_NOT_RECEIVE_EMAIL"]} target="_blank">
                {t("WHO_DO_NOT_RECEIVE_EMAIL")}
              </ButtonTextLink>
            </LayoutButton>

            <LayoutButton>
              <ButtonTextLink to={SUPPORT_URLS["driver"]["WHAT_IS_PIN_CODE"]} target="_blank">
                {t("WHAT_IS_PIN_CODE")}
              </ButtonTextLink>
            </LayoutButton>

            <LayoutButtonWithTypography>
              <Typography width="100%">{t("RESEND_AUTH_CODE_VIA_BELOW_BUTTON")}</Typography>
              <ButtonContained onClick={() => handleOpen("resendPinCode")}>{t("SEND_PIN_CODE_AGAIN")}</ButtonContained>
            </LayoutButtonWithTypography>
            <LayoutButton>
              <ButtonOutlined
                onClick={() => handleOpen("cancelApplication")}
                style={{ color: COLORS.WARNING, borderColor: COLORS.WARNING }}
              >
                {t("CANCEL_APPLICATION")}
              </ButtonOutlined>
            </LayoutButton>
          </LayoutVertical>
          <ModalResendConfirm
            type="PIN_CODE"
            open={open("resendPinCode")}
            onConfirm={async () => {
              await verifyExchangePointAmountRetry(
                exchangePointsAmount,
                pointTransferFee.transferFee,
                transferId,
                "driver"
              )
                .then((transferId) => {
                  setTransferId(transferId);
                })
                .catch((error: AxiosError<ErrorResponse>) => {
                  handleErrors(error, "/api/point/transfer/auth_mail/retry");
                })
                .finally(() => {
                  handleClose();
                });
            }}
            onClose={handleClose}
          />
          <ModalCancelConfirm
            type="CANCEL_APPLICATION"
            open={open("cancelApplication")}
            onConfirm={async () => {
              try {
                await cancelExchangePoint(transferId);
                navigate("/driver_portal/points/transfer");
              } catch (error) {
                if (axios.isAxiosError(error)) {
                  handleErrors(error, "/api/point/transfer/cancellation");
                }
                handleClose();
              }
            }}
            onClose={handleClose}
          />
        </>
      )}
    </Container>
  );
};
