import styled from "@emotion/styled";
import { AnimationEventHandler, ChangeEventHandler, Dispatch, FocusEventHandler, useCallback, useState } from "react";

import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { SpacingProps, spacing } from "@mui/system";

import { COLORS } from "@/utils/colors";

export type Props = Omit<TextFieldProps, "color" | "variant"> &
  SpacingProps & {
    maxLength?: number;
  };

const SpacedTextField = styled(MuiTextField)(spacing);

export const TextField: React.FC<Props> = ({
  inputProps,
  InputLabelProps,
  onChange,
  onBlur,
  onFocus,
  maxLength,
  ...props
}) => {
  const [fieldHasValue, setFieldHasValue] = useState(false);
  const [fieldIsFocus, setFieldIsFocus] = useState(false);
  const _onChange = useCallback<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (e) => {
      // 入力値がmaxLengthを超えている場合、それ以上の入力を許可しない
      if (maxLength && e.target.value.length > maxLength) {
        e.target.value = e.target.value.slice(0, maxLength);
      }

      if (onChange) {
        onChange(e);
      }
      setFieldHasValue(e.target.value !== "");
    },
    [onChange, maxLength]
  );
  const _onFocus = useCallback<FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setFieldIsFocus(true);
    },
    [onFocus]
  );
  const _onBlur = useCallback<FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setFieldIsFocus(false);
      setFieldHasValue(e.target.value !== "");
    },
    [onBlur]
  );

  return (
    <StyledTextField
      color="secondary"
      variant="standard"
      inputProps={{
        onAnimationStart: makeAnimationStartHandler(setFieldHasValue),
        ...inputProps,
      }}
      InputLabelProps={{
        shrink: fieldHasValue || fieldIsFocus,
        ...InputLabelProps,
      }}
      onChange={_onChange}
      onFocus={_onFocus}
      onBlur={_onBlur}
      {...props}
    />
  );
};

const StyledTextField = styled(SpacedTextField)({
  ".MuiInput-root:not(.Mui-disabled, .Mui-error)": {
    "&:before": {
      borderBottomColor: COLORS.PRIMARY,
    },

    "&:hover": {
      "&:before": {
        borderBottomColor: COLORS.PRIMARY,
      },
    },
  },

  ".MuiInput-input": {
    padding: "6px 0 8px",
  },

  ".MuiInputLabel-root": {
    transform: "translate(0, 16px) scale(1)",
    zIndex: 1,

    "&:not(.MuiInputLabel-shrink, .Mui-disabled, .Mui-error)": {
      color: COLORS.TEXT_PLACEHOLDER,
    },

    "&.MuiInputLabel-shrink": {
      transform: "translate(0, -4px) scale(0.88)",
    },
  },

  ".MuiFormHelperText-root": {
    fontSize: "1rem",
    lineHeight: 1.4,
  },
});

// fix bug: input label is not shrink
// https://github.com/mui/material-ui/issues/36448
const makeAnimationStartHandler =
  (stateSetter: Dispatch<boolean>): AnimationEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
  (e) => {
    const autofilled = !!(e.target as HTMLElement)?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill") {
      stateSetter(autofilled);
    }
    if (e.animationName === "mui-auto-fill-cancel") {
      stateSetter(autofilled);
    }
  };
