import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { CssBaseline, styled } from "@mui/material";

import { Footer, LogoVertical } from "@/components";
import useAuth from "@/hooks/useAuth";
import getPortalType from "@/utils/getPortalType";

import GlobalStyle from "../../../components/GlobalStyle";

interface AuthProps {
  isError?: boolean;
}

export const Auth: React.FC<AuthProps> = ({ isError = false }) => {
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();
  const portalType = getPortalType();

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    if (!isError && isSignedIn()) {
      navigate(((p): string => (p ? `/${p}_portal/top` : "/"))(portalType));
    }
  }, [isError, isSignedIn, navigate]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <AuthHeader>
          <LogoVertical component="h1" />
        </AuthHeader>
        <MainContent>
          <Outlet />
        </MainContent>
        <Footer portalType={portalType} />
      </AppContent>
    </Root>
  );
};

const Root = styled("div")({
  display: "flex",
  width: "800px",
  maxWidth: "100%",
  minHeight: "100vh",
  margin: "0 auto",
});

const AppContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(6, 0),
  padding: theme.spacing(10, 6, 6),
}));

const MainContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

const AuthHeader = styled("header")({});
