import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { styled } from "@mui/material";

import { Button, Menu, MenuItem } from "@/components";
import { TAB_URLS } from "@/constants";
import { COLORS } from "@/utils/colors";
import { navigateToAppStore } from "@/utils/errorHandling";
import getPortalType from "@/utils/getPortalType";

type NavigationItem = {
  path: string;
  label: string;
};

export type Navigation = NavigationItem & {
  children?: Required<NavigationItem>[];
};

export type Props = {
  navigations: Navigation[];
};

export const HeaderNavigation: React.FC<Props> = ({ navigations }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const isActive = useCallback((path: string) => location.pathname.startsWith(path), [location]);
  const portalType = getPortalType();
  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  const to = useCallback(
    (path: string) => {
      handleClose();
      // トップページへの遷移時はリロードする
      navigate(path, {
        state: {
          status: path === TAB_URLS[portalType][0] ? "RELOAD" : undefined,
        },
      });
    },
    [navigate]
  );
  const onClickNavigation = (event: React.MouseEvent<HTMLButtonElement>, index: number, path?: string) => {
    if (path) return to(path);
    setAnchorEl(event.currentTarget);
    setCurrentIndex(index);
  };
  const isOpen = (index: number) => index === currentIndex;
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentIndex(null);
  };
  return (
    <Navigation>
      <NavigationList>
        {navigations.map((nav, navIndex) => (
          <NavigationListItem key={`header-nav-${navIndex}`}>
            <NavigationButton
              active={isActive(nav.path || "")}
              id={`header-nav-${navIndex}`}
              aria-controls={isOpen(navIndex) ? `header-nav-child-${navIndex}` : undefined}
              aria-haspopup={nav.children ? "true" : undefined}
              aria-expanded={isOpen(navIndex) ? "true" : undefined}
              onClick={(e) => onClickNavigation(e, navIndex, !nav.children ? nav.path : undefined)}
            >
              {nav.label}
            </NavigationButton>
            {!!nav.children && (
              <Menu
                id={`header-nav-child-${navIndex}`}
                anchorEl={anchorEl}
                open={isOpen(navIndex)}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": `header-nav-${navIndex}`,
                }}
              >
                {nav.children.map((child, childIndex) => (
                  <MenuItem
                    key={`header-nav-child-${childIndex}`}
                    onClick={(e) => {
                      // pathがネイティブアプリのdeeplinkの場合はストアへ遷移
                      if (child.path.includes("://")) {
                        e.preventDefault();
                        navigateToAppStore();
                      } else {
                        to(child.path);
                      }
                    }}
                  >
                    {child.label}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </NavigationListItem>
        ))}
      </NavigationList>
    </Navigation>
  );
};

const Navigation = styled("div")({
  position: "relative",
  width: "100%",
  backgroundColor: COLORS.PRIMARY,
  color: COLORS.TEXT_02,

  "&::after": {
    content: "''",
    display: "block",
    width: "100vw",
    height: "100%",
    backgroundColor: COLORS.PRIMARY,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "-1",
  },
});

const NavigationList = styled("ul")({
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "800px",
  margin: "0 auto",
  padding: "0",
  listStyle: "none",
});

const NavigationListItem = styled("li")({
  width: "100%",
});

const NavigationButton = styled(Button, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "active",
})<{ active: boolean }>(({ active }) => ({
  width: "100%",
  height: "100%",
  minHeight: "48px",
  padding: 0,
  borderRadius: "0",
  wordBreak: "break-word",
  backgroundColor: active ? COLORS.PRIMARY_ACTIVE : "inherit",
  fontSize: "1rem",
  fontWeight: active ? 700 : 400,
  lineHeight: 1.2,
  letterSpacing: "0.02em",
  color: "inherit",

  "&:hover": {
    backgroundColor: active ? COLORS.PRIMARY_ACTIVE : "inherit",
  },
}));
