import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { Container, LayoutGeneralError } from "@/components";
import { useErrorMetadata } from "@/hooks/useErrorMetadata";
import getPortalType from "@/utils/getPortalType";

export const Error403 = () => {
  const { t } = useTranslation();

  const { getErrorMetadata } = useErrorMetadata();
  const [title, message] = getErrorMetadata("E403-01");
  const portalType = getPortalType();

  const primaryButton =
    portalType === "crew"
      ? {
          title: t("LOGIN_HERE_CREW"),
          label: t("LOGIN"),
          to: "/crew_portal/login",
        }
      : portalType === "driver"
        ? {
            title: t("LOGIN_HERE_DRIVER"),
            label: t("LOGIN"),
            to: "/driver_portal/login",
          }
        : undefined;

  return (
    <Container>
      <Helmet title={t(title)} />
      <LayoutGeneralError title={t(title)} message={t(message)} primaryButton={primaryButton} />
    </Container>
  );
};
