export type Props = {
  color?: string;
};

export const IconWarning: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9317 3.32979C12.183 2.89007 12.817 2.89007 13.0683 3.32979L22.4128 19.6827C22.6621 20.119 22.3471 20.6619 21.8445 20.6619H3.15549C2.65293 20.6619 2.33787 20.119 2.58721 19.6827L11.9317 3.32979ZM11.5182 10.4711C11.5182 10.3157 11.6442 10.1897 11.7997 10.1897H13.2004C13.3558 10.1897 13.4818 10.3157 13.4818 10.4711V13.8353C13.4818 13.9908 13.3558 14.1168 13.2004 14.1168H11.7997C11.6442 14.1168 11.5182 13.9908 11.5182 13.8353V10.4711ZM12.5 16.0803C11.777 16.0803 11.191 16.6663 11.191 17.3893C11.191 18.1123 11.777 18.6984 12.5 18.6984C13.223 18.6984 13.8091 18.1123 13.8091 17.3893C13.8091 16.6663 13.223 16.0803 12.5 16.0803Z"
        fill={color}
      />
    </svg>
  );
};
