const c = (key: string, prefix: string) => {
  return `ERROR_CODE_${key}_${prefix}`;
};
const t = "TITLE";
const m = "MESSAGE";

export const ErrorTitleResources = {
  en: {
    [c("", t)]: "Error occurred",
    [c("E400-01", t)]: "Communication error",
    [c("E400-01/api/account/phone_number_change/auth_mail_code", t)]: "Authentication failed",
    [c("E400-01/api/account/password_change/auth_mail_code", t)]: "Authentication failed",
    [c("E400-02/api/account/mail_address_change/auth_mail_code", t)]: "Authentication failed",
    [c("E400-02/api/account/mail_address_change/auth_sms_code", t)]: "Authentication failed",
    [c("E400-02/api/account/password_change/auth_mail_code", t)]: "Authentication failed",
    [c("E400-02/api/account/phone_number_change/auth_mail_code", t)]: "Authentication failed",
    [c("E400-02/api/account/phone_number_change/auth_sms_code", t)]: "Authentication failed",
    [c("E400-02/api/account/phone_number_change/auth_sms", t)]:
      "This phone number is already registered with a GO driver portal account",
    [c("E400-02/api/login", t)]: "Invalid phone number or password",
    [c("E400-02/api/driver/login", t)]: "Invalid mail address or password",
    [c("E400-02/api/password_reset/auth_mail_code", t)]: "Authentication failed",
    [c("E400-02/api/password_reset/auth_mail_code/failed", t)]: "Authentication failed",
    [c("E400-02/api/point/transfer/auth_sms_code", t)]: "Authentication failed",
    [c("E400-02/api/point/transfer/auth_mail_code", t)]: "Authentication failed",
    [c("E400-02/api/register/auth_code", t)]: "Authentication failed",
    [c("E400-02/api/register/auth_mail_code", t)]: "Authentication failed",
    [c("E400-02/api/mapping_request/auth_code", t)]: "Authentication failed",
    [c("E400-03/api/register/auth_code", t)]: "Authentication code expired",
    [c("E400-03/api/mapping_request/auth_code", t)]: "Authentication code expired",
    [c("E400-04/api/login", t)]: "Account suspended",
    [c("E400-04/api/driver/login", t)]: "Account suspended",
    [c("E400-05/api/login", t)]: "Account locked",
    [c("E400-05/api/driver/login", t)]: "Account locked",
    [c("E400-06/api/point/transfer/auth_sms_code", t)]: "You do not have enough points",
    [c("E400-06/api/point/transfer/auth_sms", t)]: "You do not have enough points",
    [c("E400-06/api/point/transfer/auth_mail_code", t)]: "You do not have enough points",
    [c("E400-06/api/point/transfer/auth_mail", t)]: "You do not have enough points",
    [c("E400-06/api/point/transfer/skip_auth", t)]: "You do not have enough points",
    [c("E400-07/api/point/transfer/auth_sms_code", t)]: "Failed to apply for point exchange",
    [c("E400-07/api/point/transfer/auth_mail_code", t)]: "Failed to apply for point exchange",
    [c("E400-07/api/point/transfer/skip_auth", t)]: "Failed to apply for point exchange",
    [c("E400-10/api/point/transfer/auth_sms", t)]: "Up to 100,000 points can be exchanged per day.",
    [c("E400-10/api/point/transfer/auth_mail", t)]: "Up to 100,000 points can be exchanged per day.",
    [c("E400-09/api/register/auth_mail", t)]: "Failed to send the email for identity verification",
    [c("E400-09/api/account/mail_address_change/auth_mail", t)]: "Failed to send the email for identity verification",
    [c("E401-01", t)]: "Session expired",
    [c("E401-01/api/password_reset/auth_mail_code", t)]: "Authentication failed",
    [c("E401-01/api/password_reset/auth_mail_code/failed", t)]: "Authentication failed",
    [c("E401-02", t)]: "Session expired",
    [c("E401-02/session_expired_on_point_exchange", t)]: "Session expired",
    [c("E401-03", t)]: "Logged out due to login from another browser",
    [c("E401-04", t)]: "Your account has been suspended.",
    [c("E401-05/api/account/mail_address_change/auth_mail_code", t)]: "Authentication code expired",
    [c("E401-05/api/account/mail_address_change/auth_mail", t)]: "Confirmation code expired",
    [c("E401-05/api/account/mail_address_change/auth_sms_code", t)]: "Authentication code expired",
    [c("E401-05/api/account/password_change/auth_mail_code", t)]: "Authentication code expired",
    [c("E401-05/api/account/password_change/password", t)]: "Timeout",
    [c("E401-05/api/account/phone_number_change/auth_mail_code", t)]: "Authentication code expired",
    [c("E401-05/api/account/phone_number_change/auth_sms_code", t)]: "Confirmation code expired",
    [c("E401-05/api/account/phone_number_change/auth_sms", t)]: "Confirmation code expired",
    [c("E401-05/api/password_reset/auth_mail_code", t)]: "Authentication code expired",
    [c("E401-05/api/password_reset/auth_mail_code/failed", t)]: "Authentication code expired",
    [c("E401-05/api/password_reset/auth_mail", t)]: "Timeout",
    [c("E401-05/api/password_reset/password", t)]: "Timeout",
    [c("E401-05/api/point/transfer/auth_sms_code", t)]: "Authentication code expired",
    [c("E401-05/api/point/transfer/auth_mail_code", t)]: "Authentication code expired",
    [c("E401-05/api/register/auth_mail_code", t)]: "Confirmation failed",
    [c("E401-05/api/register/auth_mail", t)]: "Session expired",
    [c("E401-05/api/register/password", t)]: "Timeout",
    [c("E401-06/crew", t)]: "Account locked",
    [c("E401-06/driver", t)]: "Account locked",
    [c("E403-01", t)]: "Logged out due to login from another browser",
    [c("E404-01", t)]: "The requested URL page was not found.",
    [c("E409-01/api/account/mail_address_change/auth_mail", t)]:
      "This email address is already registered with a GO driver portal account",
    [c("E409-01/api/account/phone_number_change/auth_sms", t)]:
      "This phone number is already registered with a GO driver portal account",
    [c("E409-01/api/register/auth_mail", t)]:
      "This email address is already registered with a GO driver portal account",
    [c("E423-01/api/account/mail_address_change/auth_sms_code", t)]: "Authentication code invalidated",
    [c("E423-01/api/account/password_change/auth_mail_code", t)]: "Authentication code invalidated",
    [c("E423-01/api/account/phone_number_change/auth_mail_code", t)]: "Authentication code invalidated",
    [c("E423-01/api/login", t)]: "Account locked",
    [c("E423-01/api/driver/login", t)]: "Account locked",
    [c("E423-01/api/password_reset/auth_mail_code", t)]: "Authentication code invalidated",
    [c("E423-01/api/point/transfer/auth_sms_code", t)]: "Authentication code invalidated",
    [c("E423-01/api/point/transfer/auth_mail_code", t)]: "Authentication code invalidated",
    [c("E423-01/api/register/auth_code", t)]: "Authentication code invalidated",
    [c("E423-01/api/mapping_request/auth_code", t)]: "Authentication code invalidated",
    [c("E429-01", t)]: "Authentication code (confirmation code) cannot be sent",
    [c("E500-01", t)]: "Error occurred",
    [c("E503-01", t)]: "Under maintenance",
    [c("E503-02", m)]: "Under maintenance",
    [c("NETWORK", t)]: "Communication error",
    [c("SYSTEM", t)]: "Error occurred",
    [c("COOKIE_DISABLED", t)]: "Error occurred",
  },
  jp: {
    [c("", t)]: "エラーが発生しました",
    [c("E400-01", t)]: "通信エラーが発生しました",
    [c("E400-01/api/account/phone_number_change/auth_mail_code", t)]: "認証ができません",
    [c("E400-01/api/account/password_change/auth_mail_code", t)]: "認証ができません",
    [c("E400-02/api/account/mail_address_change/auth_mail_code", t)]: "確認ができません",
    [c("E400-02/api/account/mail_address_change/auth_sms_code", t)]: "認証ができません",
    [c("E400-02/api/account/password_change/auth_mail_code", t)]: "認証ができません",
    [c("E400-02/api/account/phone_number_change/auth_mail_code", t)]: "認証ができません",
    [c("E400-02/api/account/phone_number_change/auth_sms_code", t)]: "確認ができません",
    [c("E400-02/api/account/phone_number_change/auth_sms", t)]:
      "この携帯電話番号は、既にGO乗務員ポータルのアカウントに登録されています",
    [c("E400-02/api/login", t)]: "携帯電話番号またはパスワードが正しくありません",
    [c("E400-02/api/driver/login", t)]: "メールアドレスまたはパスワードが正しくありません",
    [c("E400-02/api/password_reset/auth_mail_code", t)]: "認証ができません",
    [c("E400-02/api/password_reset/auth_mail_code/failed", t)]: "認証ができません",
    [c("E400-02/api/point/transfer/auth_sms_code", t)]: "認証ができません",
    [c("E400-02/api/point/transfer/auth_mail_code", t)]: "認証ができません",
    [c("E400-02/api/register/auth_code", t)]: "認証ができません",
    [c("E400-02/api/register/auth_mail_code", t)]: "確認ができません",
    [c("E400-02/api/mapping_request/auth_code", t)]: "認証ができません",
    [c("E400-03/api/register/auth_code", t)]: "認証コードの有効期限が切れています",
    [c("E400-03/api/mapping_request/auth_code", t)]: "認証コードの有効期限が切れています",
    [c("E400-04/api/login", t)]: "アカウントが停止されています",
    [c("E400-04/api/driver/login", t)]: "アカウントが停止されています",
    [c("E400-05/api/login", t)]: "アカウントがロックされました",
    [c("E400-05/api/driver/login", t)]: "アカウントがロックされました",
    [c("E400-06/api/point/transfer/auth_sms_code", t)]: "保有ポイントが足りていません",
    [c("E400-06/api/point/transfer/auth_sms", t)]: "保有ポイントが足りていません",
    [c("E400-06/api/point/transfer/auth_mail_code", t)]: "保有ポイントが足りていません",
    [c("E400-06/api/point/transfer/auth_mail", t)]: "保有ポイントが足りていません",
    [c("E400-06/api/point/transfer/skip_auth", t)]: "保有ポイントが足りていません",
    [c("E400-07/api/point/transfer/auth_sms_code", t)]: "ポイント交換申請ができませんでした",
    [c("E400-07/api/point/transfer/auth_mail_code", t)]: "ポイント交換申請ができませんでした",
    [c("E400-07/api/point/transfer/skip_auth", t)]: "ポイント交換申請ができませんでした",
    [c("E400-10/api/point/transfer/auth_sms", t)]: "1日に交換可能なポイントは10万ポイントまでです。",
    [c("E400-10/api/point/transfer/auth_mail", t)]: "1日に交換可能なポイントは10万ポイントまでです。",
    [c("E400-09/api/register/auth_mail", t)]: "本人確認用のメールを送信できませんでした",
    [c("E400-09/api/account/mail_address_change/auth_mail", t)]: "本人確認用のメールを送信できませんでした",
    [c("E401-01", t)]: "セッションが切れました",
    [c("E401-01/api/password_reset/auth_mail_code", t)]: "確認ができません",
    [c("E401-01/api/password_reset/auth_mail_code/failed", t)]: "認証ができません",
    [c("E401-02", t)]: "セッションが切れました",
    [c("E401-02/session_expired_on_point_exchange", t)]: "セッションが切れました",
    [c("E401-03", t)]: "他のブラウザからログインされたためログアウトしました",
    [c("E401-04", t)]: "アカウントが停止されています",
    [c("E401-05/api/account/mail_address_change/auth_mail_code", t)]: "確認コードの有効期限が切れています",
    [c("E401-05/api/account/mail_address_change/auth_mail", t)]: "確認コードの有効期限が切れています",
    [c("E401-05/api/account/mail_address_change/auth_sms_code", t)]: "認証コードの有効期限が切れています",
    [c("E401-05/api/account/password_change/auth_mail_code", t)]: "認証コードの有効期限が切れています",
    [c("E401-05/api/account/password_change/password", t)]: "タイムアウトしました",
    [c("E401-05/api/account/phone_number_change/auth_mail_code", t)]: "認証コードの有効期限が切れています",
    [c("E401-05/api/account/phone_number_change/auth_sms_code", t)]: "確認コードの有効期限が切れています",
    [c("E401-05/api/account/phone_number_change/auth_sms", t)]: "確認コードの有効期限が切れています",
    [c("E401-05/api/password_reset/auth_mail_code", t)]: "認証コードの有効期限が切れています",
    [c("E401-05/api/password_reset/auth_mail_code/failed", t)]: "認証ができません",
    [c("E401-05/api/password_reset/auth_mail", t)]: "タイムアウトしました",
    [c("E401-05/api/password_reset/password", t)]: "タイムアウトしました",
    [c("E401-05/api/point/transfer/auth_sms_code", t)]: "認証コードの有効期限が切れています",
    [c("E401-05/api/point/transfer/auth_mail_code", t)]: "認証コードの有効期限が切れています",
    [c("E401-05/api/register/auth_mail_code", t)]: "確認コードの有効期限が切れています",
    [c("E401-05/api/register/auth_mail", t)]: "セッションが切れました",
    [c("E401-05/api/register/password", t)]: "タイムアウトしました",
    [c("E401-06/crew", t)]: "アカウントがロックされました",
    [c("E401-06/driver", t)]: "アカウントがロックされました",
    [c("E403-01", t)]: "他のブラウザからログインされたためログアウトしました",
    [c("E404-01", t)]: "指定されたURLのページが見つかりません",
    [c("E409-01/api/account/mail_address_change/auth_mail", t)]:
      "このメールアドレスは、既にGO乗務員ポータルのアカウントに登録されています",
    [c("E409-01/api/account/phone_number_change/auth_sms", t)]:
      "この携帯電話番号は、既にGO乗務員ポータルのアカウントに登録されています",
    [c("E409-01/api/register/auth_mail", t)]:
      "このメールアドレスは、既にGO乗務員ポータルのアカウントに登録されています",
    [c("E423-01/api/account/mail_address_change/auth_sms_code", t)]: "認証コードが無効化されました",
    [c("E423-01/api/account/password_change/auth_mail_code", t)]: "認証コードが無効化されました",
    [c("E423-01/api/account/phone_number_change/auth_mail_code", t)]: "認証コードが無効化されました",
    [c("E423-01/api/login", t)]: "アカウントがロックされました",
    [c("E423-01/api/driver/login", t)]: "アカウントがロックされました",
    [c("E423-01/api/password_reset/auth_mail_code", t)]: "認証コードが無効化されました",
    [c("E423-01/api/point/transfer/auth_sms_code", t)]: "認証コードが無効化されました",
    [c("E423-01/api/point/transfer/auth_mail_code", t)]: "認証コードが無効化されました",
    [c("E423-01/api/register/auth_code", t)]: "認証コードが無効化されました",
    [c("E423-01/api/mapping_request/auth_code", t)]: "認証コードが無効化されました",
    [c("E429-01", t)]: "認証コード(確認コード)が送信できません",
    [c("E500-01", t)]: "エラーが発生しました",
    [c("E503-01", t)]: "メンテナンス中",
    [c("E503-02", t)]: "メンテナンス中",
    [c("NETWORK", t)]: "通信エラーが発生しました",
    [c("SYSTEM", t)]: "エラーが発生しました",
    [c("COOKIE_DISABLED", t)]: "エラーが発生しました",
  },
};

export const ErrorMessageResources = {
  en: {
    WHAT_IS_undefined: "Contact",
    [c("", m)]: "Sorry, please try again later.",
    [c("E400-01", m)]: "Invalid phone number or password",
    [c("E400-01/api/account/phone_number_change/auth_mail_code", m)]: "Please try again",
    [c("E400-01/api/account/password_change/auth_mail_code", m)]: "Please try again",
    [c("E400-02/api/account/mail_address_change/auth_mail_code", m)]: "Please try again",
    [c("E400-02/api/account/mail_address_change/auth_sms_code", m)]: "Please try again",
    [c("E400-02/api/account/password_change/auth_mail_code", m)]: "Please try again",
    [c("E400-02/api/account/phone_number_change/auth_mail_code", m)]: "Please try again",
    [c("E400-02/api/account/phone_number_change/auth_sms_code", m)]: "Please try again",
    [c("E400-02/api/account/phone_number_change/auth_sms", m)]: "Please check your input and try again",
    [c("E400-02/api/login", m)]: "Invalid phone number or password",
    [c("E400-02/api/driver/login", m)]: "Invalid mail address or password",
    [c("E400-02/api/password_reset/auth_mail_code", m)]: "Please try again",
    [c("E400-02/api/password_reset/auth_mail_code/failed", m)]: "Please check your input and try again",
    [c("E400-02/api/point/transfer/auth_sms_code", m)]: "Please try again",
    [c("E400-02/api/point/transfer/auth_mail_code", m)]: "Please try again",
    [c("E400-02/api/register/auth_code", m)]: "Please try again",
    [c("E400-02/api/register/auth_mail_code", m)]: "Please try again",
    [c("E400-02/api/mapping_request/auth_code", m)]: "Please try again",
    [c("E400-03/api/register/auth_code", m)]: "Please input the code again from the driver app",
    [c("E400-03/api/mapping_request/auth_code", m)]: "Please input the code again from the driver app",
    [c("E400-04/api/login", m)]: "Your account has been suspended. We will contact you when your account is available.",
    [c("E400-04/api/driver/login", m)]:
      "Your account has been suspended. We will contact you when your account is available.",
    [c("E400-05/api/login", m)]:
      "Due to exceeding the maximum number of failed attempts, your account has been locked.\nPlease reset your password to unlock the account.",
    [c("E400-05/api/driver/login", m)]:
      "Due to exceeding the maximum number of failed attempts, your account has been locked.\nPlease reset your password to unlock the account.",
    [c("E400-06/api/point/transfer/auth_sms_code", m)]: "Please try again after some time.",
    [c("E400-06/api/point/transfer/auth_sms", m)]: "Please try again",
    [c("E400-06/api/point/transfer/auth_mail_code", m)]: "Please try again after some time.",
    [c("E400-06/api/point/transfer/auth_mail", m)]: "Please try again",
    [c("E400-06/api/point/transfer/skip_auth", m)]: "Please try again after some time.",
    [c("E400-07/api/point/transfer/auth_sms_code", t)]: "ポイント交換申請ができませんでした",
    [c("E400-07/api/point/transfer/auth_mail_code", t)]: "ポイント交換申請ができませんでした",
    [c("E400-07/api/point/transfer/skip_auth", m)]: "Please try again after some time.",
    [c("E400-10/api/point/transfer/auth_sms", m)]:
      "Since you have already applied for 100,000 point redemption, you cannot apply today. \nPlease apply for point redemption after 24 hours.",
    [c("E400-10/api/point/transfer/auth_mail", m)]:
      "Since you have already applied for 100,000 point redemption, you cannot apply today. \nPlease apply for point redemption after 24 hours.",
    [c("E400-09/api/register/auth_mail", m)]:
      "Please check your spam folder.\nFurther details are available in the below FAQ.",
    [c("E400-09/api/account/mail_address_change/auth_mail", m)]:
      "Please check your spam folder.\nFurther details are available in the below FAQ.",
    [c("E401-01", m)]: "Please log in again",
    [c("E401-01/api/password_reset/auth_mail_code", m)]: "Please check your input and try again from the beginning",
    [c("E401-01/api/password_reset/auth_mail_code/failed", m)]:
      "Please check your input and try again from the beginning",
    [c("E401-02", m)]: "Please log in again",
    [c("E401-02/session_expired_on_point_exchange", m)]:
      'Please log in again and check the status of the point exchange application from "Check application status".',
    [c("E401-03", m)]: "You have been logged out because you logged in from another browser",
    [c("E401-04", m)]: "When your account becomes available, you will be contacted by GO Corporation.",
    [c("E401-05/api/account/mail_address_change/auth_mail_code", m)]: "Please try again",
    [c("E401-05/api/account/mail_address_change/auth_mail", m)]: "Please start over",
    [c("E401-05/api/account/mail_address_change/auth_sms_code", m)]: "Please try again",
    [c("E401-05/api/account/password_change/auth_mail_code", m)]: "Please try again",
    [c("E401-05/api/account/password_change/password", m)]: "Please start over",
    [c("E401-05/api/account/phone_number_change/auth_mail_code", m)]: "Please try again",
    [c("E401-05/api/account/phone_number_change/auth_sms_code", m)]: "Please try again",
    [c("E401-05/api/account/phone_number_change/auth_sms", m)]: "Please start over",
    [c("E401-05/api/password_reset/auth_mail_code", m)]: "Please start over from the beginning.",
    [c("E401-05/api/password_reset/auth_mail_code/failed", m)]: "Please try again",
    [c("E401-05/api/password_reset/auth_mail", m)]: "Please start over from the beginning.",
    [c("E401-05/api/password_reset/password", m)]: "Please start over from the beginning.",
    [c("E401-05/api/point/transfer/auth_sms_code", m)]: "Please start over",
    [c("E401-05/api/point/transfer/auth_mail_code", m)]: "Please start over",
    [c("E401-05/api/register/auth_mail_code", m)]: "Please start over from the beginning.",
    [c("E401-05/api/register/auth_mail", m)]: "Please start over",
    [c("E401-05/api/register/password", m)]: "Please start over from the beginning.",
    [c("E401-06/crew", m)]:
      "You have exceeded the maximum number of failed attempts, and your account has been locked.\nPlease reset your password to unlock the account.",
    [c("E401-06/driver", m)]:
      "You have exceeded the maximum number of failed attempts, and your account has been locked.\nPlease try again later.",
    [c("E403-01", m)]: "You have been logged out because you logged in from another browser",
    [c("E404-01", m)]: "",
    [c("E409-01/api/account/mail_address_change/auth_mail", m)]: "Please check your input and try again",
    [c("E409-01/api/account/phone_number_change/auth_sms", m)]: "Please check your input and try again",
    [c("E409-01/api/register/auth_mail", m)]: "Please log in from the button below",
    [c("E423-01/api/account/mail_address_change/auth_sms_code", m)]:
      "Authentication code entry has failed the specified number of times, and the code has been invalidated.\n\nYou can generate a new authentication code 1 hour after the issuance of the previous one.\nPlease try again after some time.",
    [c("E423-01/api/account/password_change/auth_mail_code", m)]:
      "Authentication code entry has failed the specified number of times, and the code has been invalidated.\n\nYou can generate a new authentication code 1 hour after the issuance of the previous one.\nPlease try again after some time.",
    [c("E423-01/api/account/phone_number_change/auth_mail_code", m)]:
      "Authentication code entry has failed the specified number of times, and the code has been invalidated.\n\nYou can generate a new authentication code 1 hour after the issuance of the previous one.\nPlease try again after some time.",
    [c("E423-01/api/login", m)]:
      "Due to exceeding the maximum number of failed attempts, your account has been locked.\nPlease reset your password to unlock the account.",
    [c("E423-01/api/driver/login", m)]:
      "Due to exceeding the maximum number of failed attempts, your account has been locked.\nPlease reset your password to unlock the account.",
    [c("E423-01/api/password_reset/auth_mail_code", m)]:
      "The authentication code has been invalidated due to multiple failed attempts.\nNew authentication code cannot be issued due to lock.\nPlease try again later.",
    [c("E423-01/api/point/transfer/auth_sms_code", m)]:
      "Authentication code entry has failed the specified number of times, and the code has been invalidated.\n\nYou can generate a new authentication code 1 hour after the issuance of the previous one.\nPlease try again after some time.",
    [c("E423-01/api/point/transfer/auth_mail_code", m)]:
      "Authentication code entry has failed the specified number of times, and the code has been invalidated.\n\nYou can generate a new authentication code 1 hour after the issuance of the previous one.\nPlease try again after some time.",
    [c("E423-01/api/register/auth_code", m)]:
      "The authentication code has been invalidated due to multiple failed attempts.\nNew authentication code cannot be issued due to lock.\nPlease try again later.",
    [c("E423-01/api/mapping_request/auth_code", m)]:
      "The authentication code has been invalidated due to multiple failed attempts.\nNew authentication code cannot be issued due to lock.\nPlease try again later.",
    [c("E429-01", m)]:
      "The number of times the authentication code (confirmation code) can be issued has been exceeded.\n\nYou can issue a new authentication code (confirmation code) after 24 hours.\nPlease try again later.",
    [c("E500-01", m)]: "Sorry, please try again later.",
    [c("E503-01", m)]:
      'Thank you for always using "%s".\nWe apologize, but we are currently under maintenance. Please try again after it\'s finished.',
    [c("E503-02", m)]:
      'Thank you for always using "%s". We apologize, but the point withdrawal service is currently suspended due to maintenance. Please try again after the maintenance has ended.',
    [c("NETWORK", m)]: "Please check your communication environment, reload the screen and try again.",
    [c("SYSTEM", m)]: "Sorry, please try again later.",
    [c("COOKIE_DISABLED", m)]:
      "The page cannot be displayed because your cookie settings are disabled.\nPlease enable cookie settings in your browser.",
  },
  jp: {
    WHAT_IS_undefined: "お問い合わせ",
    [c("", m)]: "申し訳ありませんが、しばらく経ってからアクセスしてください。",
    [c("E400-01", m)]: "携帯電話番号またはパスワードが正しくありません。",
    [c("E400-01/api/account/phone_number_change/auth_mail_code", m)]:
      "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-01/api/account/password_change/auth_mail_code", m)]: "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/account/mail_address_change/auth_mail_code", m)]:
      "確認コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/account/mail_address_change/auth_sms_code", m)]:
      "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/account/password_change/auth_mail_code", m)]: "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/account/phone_number_change/auth_mail_code", m)]:
      "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/account/phone_number_change/auth_sms_code", m)]:
      "確認コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/account/phone_number_change/auth_sms", m)]: "入力をご確認の上、もう一度やり直してください。",
    [c("E400-02/api/login", m)]: "携帯電話番号またはパスワードが正しくありません。",
    [c("E400-02/api/driver/login", m)]: "メールアドレスまたはパスワードが正しくありません。",
    [c("E400-02/api/password_reset/auth_mail_code", m)]: "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/password_reset/auth_mail_code/failed", m)]:
      "入力をご確認の上、もう一度最初からやり直してください。",
    [c("E400-02/api/point/transfer/auth_sms_code", m)]: "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/point/transfer/auth_mail_code", m)]: "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/register/auth_code", m)]: "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/register/auth_mail_code", m)]: "確認コードを確認の上、もう一度やり直してください。",
    [c("E400-02/api/mapping_request/auth_code", m)]: "認証コードを確認の上、もう一度やり直してください。",
    [c("E400-03/api/register/auth_code", m)]: "乗務員アプリより、再度認証コードを送信してください。",
    [c("E400-03/api/mapping_request/auth_code", m)]: "乗務員アプリより、再度認証コードを送信してください。",
    [c("E400-04/api/login", m)]: "アカウントが利用可能になった場合は、GO株式会社より連絡いたします。",
    [c("E400-04/api/driver/login", m)]: "アカウントが利用可能になった場合は、GO株式会社より連絡いたします。",
    [c("E400-05/api/login", m)]:
      "入力に規定回数失敗したため、アカウントがロックされました。\nパスワードを再設定し、ロックを解除してください。",
    [c("E400-05/api/driver/login", m)]:
      "入力に規定回数失敗したため、アカウントがロックされました。\nしばらく経ってからログインしてください。",
    [c("E400-06/api/point/transfer/auth_sms_code", m)]: "はじめからやり直してください。",
    [c("E400-06/api/point/transfer/auth_sms", m)]: "はじめからやり直してください。",
    [c("E400-06/api/point/transfer/auth_mail_code", m)]: "はじめからやり直してください。",
    [c("E400-06/api/point/transfer/auth_mail", m)]: "はじめからやり直してください。",
    [c("E400-06/api/point/transfer/skip_auth", m)]: "はじめからやり直してください。",
    [c("E400-07/api/point/transfer/auth_sms_code", m)]: "お手数ですが、もう一度はじめからやり直しをお願いします。",
    [c("E400-07/api/point/transfer/auth_mail_code", m)]: "お手数ですが、もう一度はじめからやり直しをお願いします。",
    [c("E400-07/api/point/transfer/skip_auth", m)]: "お手数ですが、もう一度はじめからやり直しをお願いします。",
    [c("E400-10/api/point/transfer/auth_sms", m)]:
      "既に10万ポイントの交換申請をしているため、本日は申請できません。\n24時間以降にポイント交換申請をしてください。",
    [c("E400-10/api/point/transfer/auth_mail", m)]:
      "既に10万ポイントの交換申請をしているため、本日は申請できません。\n24時間以降にポイント交換申請をしてください。",
    [c("E400-09/api/register/auth_mail", m)]:
      "メールの受信拒否設定(迷惑メール設定)により、GO乗務員ポータルからのメールを受信できない可能性があります。\n\nお手数ですが下記より問い合わせをお願いします。",
    [c("E400-09/api/account/mail_address_change/auth_mail", m)]:
      "メールの受信拒否設定(迷惑メール設定)により、GO乗務員ポータルからのメールを受信できない可能性があります。\n\nお手数ですが下記より問い合わせをお願いします。",
    [c("E401-01", m)]: "お手数ですが再度ログインをお願いします。",
    [c("E401-01/api/password_reset/auth_mail_code", m)]: "入力をご確認の上、もう一度最初からやり直してください。",
    [c("E401-01/api/password_reset/auth_mail_code/failed", m)]:
      "入力をご確認の上、もう一度最初からやり直してください。",
    [c("E401-02", m)]: "お手数ですが再度ログインをお願いします。",
    [c("E401-02/session_expired_on_point_exchange", m)]:
      "お手数ですが、再度ログインし、「申請状況を確認する」からポイント交換申請のステータスをご確認ください。",
    [c("E401-03", m)]: "複数のブラウザからログインすることはできません。再度ログインしてください。",
    [c("E401-04", m)]: "アカウントが利用可能になった場合は、GO株式会社より連絡いたします。",
    [c("E401-04", m)]: "アカウントが利用可能になった場合は、GO株式会社より連絡いたします。",
    [c("E401-05/api/account/mail_address_change/auth_mail_code", m)]:
      "お手数ですが、メールアドレス変更を最初からやり直してください。",
    [c("E401-05/api/account/mail_address_change/auth_mail", m)]:
      "お手数ですが、メールアドレス変更を最初からやり直してください。",
    [c("E401-05/api/account/mail_address_change/auth_sms_code", m)]: "再度、認証コードを送信してください。",
    [c("E401-05/api/account/password_change/auth_mail_code", m)]: "再度、認証コードを送信してください。",
    [c("E401-05/api/account/password_change/password", m)]:
      "お手数ですが、パスワード変更を最初からやり直してください。",
    [c("E401-05/api/account/phone_number_change/auth_mail_code", m)]: "再度、認証コードを送信してください。",
    [c("E401-05/api/account/phone_number_change/auth_sms_code", m)]:
      "お手数ですが、携帯電話番号変更を最初からやり直してください。",
    [c("E401-05/api/account/phone_number_change/auth_sms", m)]:
      "お手数ですが、携帯電話番号変更を最初からやり直してください。",
    [c("E401-05/api/password_reset/auth_mail_code", m)]:
      "お手数ですが、パスワードの再設定を最初からやり直してください。",
    [c("E401-05/api/password_reset/auth_mail_code/failed", m)]:
      "入力をご確認の上、もう一度最初からやり直してください。",
    [c("E401-05/api/password_reset/auth_mail", m)]: "お手数ですが、パスワードの再設定を最初からやり直してください。",
    [c("E401-05/api/password_reset/password", m)]: "お手数ですが、パスワードの再設定を最初からやり直してください。",
    [c("E401-05/api/point/transfer/auth_sms_code", m)]: "はじめからやり直してください。",
    [c("E401-05/api/point/transfer/auth_mail_code", m)]: "はじめからやり直してください。",
    [c("E401-05/api/register/auth_mail_code", m)]: "お手数ですが、アカウント登録を最初からやり直してください。",
    [c("E401-05/api/register/auth_mail", m)]: "最初からやり直しをお願いします。",
    [c("E401-05/api/register/password", m)]: "お手数ですが、アカウント登録を最初からやり直してください。",
    [c("E401-06/crew", m)]:
      "入力に規定回数失敗したため、アカウントがロックされました。\nパスワードを再設定し、ロックを解除してください。",
    [c("E401-06/driver", m)]:
      "入力に規定回数失敗したため、アカウントがロックされました。\nしばらく経ってからログインしてください。",
    [c("E403-01", m)]: "複数のブラウザからログインすることはできません。再度ログインしてください。",
    [c("E404-01", m)]: "",
    [c("E409-01/api/account/mail_address_change/auth_mail", m)]: "入力をご確認の上、もう一度やり直してください。",
    [c("E409-01/api/account/phone_number_change/auth_sms", m)]: "入力をご確認の上、もう一度やり直してください。",
    [c("E409-01/api/register/auth_mail", m)]: "下記ボタンよりログインしてください。",
    [c("E423-01/api/account/mail_address_change/auth_sms_code", m)]:
      "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。\nまた、新しい認証コード発行にもロックがかかりました。\n時間をおいてやり直してください。",
    [c("E423-01/api/account/password_change/auth_mail_code", m)]:
      "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。\nまた、新しい認証コード発行にもロックがかかりました。\n時間をおいてやり直してください。",
    [c("E423-01/api/account/phone_number_change/auth_mail_code", m)]:
      "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。\nまた、新しい認証コード発行にもロックがかかりました。\n時間をおいてやり直してください。",
    [c("E423-01/api/login", m)]:
      "入力に規定回数失敗したため、アカウントがロックされました。\nパスワードを再設定し、ロックを解除してください。",
    [c("E423-01/api/driver/login", m)]:
      "入力に規定回数失敗したため、アカウントがロックされました。\nしばらく経ってからログインしてください。",
    [c("E423-01/api/password_reset/auth_mail_code", m)]:
      "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。\nまた、新しい認証コード発行にもロックがかかりました。\n時間をおいてやり直してください。",
    [c("E423-01/api/point/transfer/auth_sms_code", m)]:
      "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。\nまた、新しい認証コード発行にもロックがかかりました。\n時間をおいてやり直してください。",
    [c("E423-01/api/point/transfer/auth_mail_code", m)]:
      "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。\nまた、新しい認証コード発行にもロックがかかりました。\n時間をおいてやり直してください。",
    [c("E423-01/api/register/auth_code", m)]:
      "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。\nまた、新しい認証コード発行にもロックがかかりました。\n時間をおいてやり直してください。",
    [c("E423-01/api/mapping_request/auth_code", m)]:
      "認証コードの入力に規定回数失敗したため、認証コードが無効化されました。\nまた、新しい認証コード発行にもロックがかかりました。\n時間をおいてやり直してください。",
    [c("E429-01", m)]:
      "認証コード(確認コード)の発行回数の制限を超えています。\n\nセキュリティ保持のため、一定期間あけてやり直してください。",
    [c("E500-01", m)]: "申し訳ありませんが、しばらく経ってからやり直してください。",
    [c("E503-01", m)]:
      "いつも『%s』をご利用くださいましてありがとうございます。\n申し訳ありませんが、現在メンテナンス中の為、終了後に再度やり直してください。",
    [c("E503-02", m)]:
      "いつも『%s』をご利用くださいましてありがとうございます。\n申し訳ありませんが、現在メンテナンス中の為、ポイント引出しのサービスを停止しています。終了後に再度やり直してください。",
    [c("NETWORK", m)]: "通信環境をご確認の上、画面を再読み込みして再度お試しください。",
    [c("SYSTEM", m)]: "申し訳ありませんが、しばらく経ってからアクセスしてください。",
    [c("COOKIE_DISABLED", m)]:
      "cookieの設定が無効のため、ページを表示することができません。\nお使いのブラウザのcookieの設定を有効にしてください。",
  },
};
