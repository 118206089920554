import React from "react";
import { useTranslation } from "react-i18next";

import { AlertTitle, AlertWarning, IconWarning, List, ListItem } from "@/components";
import { PointBalance, PointSummary } from "@/types/pointSummary";
import { formatExpiringDate } from "@/utils/formatter";

export interface AlertPointExpiringProps {
  pointSummary: PointSummary;
}

export const AlertPointExpiring: React.FC<AlertPointExpiringProps> = ({ pointSummary }) => {
  const { t } = useTranslation();

  const now = new Date();
  const twoWeeksFromNow = new Date();
  twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);

  const balancesExpiringInTwoWeeks: PointBalance[] = pointSummary.expirePointBalances.filter((balance) => {
    const expiryDate = new Date(balance.expiredAt);
    return expiryDate <= twoWeeksFromNow && expiryDate >= now;
  });
  const totalBalanceExpiringInTwoWeeks: number = pointSummary.expirePointBalances
    ? balancesExpiringInTwoWeeks.reduce((total, balance) => {
        return total + balance.pointBalance;
      }, 0)
    : 0;

  const hidden =
    pointSummary.expirePointBalances.length === 0 &&
    totalBalanceExpiringInTwoWeeks === 0 &&
    pointSummary.expirePointAmounts.expirePointAmount30Days === 0;

  return hidden ? null : (
    <AlertWarning strong sx={{ maxWidth: "280px" }}>
      <AlertTitle icon={<IconWarning />}>{t("POINTS_EXPIRING_SOON")}</AlertTitle>
      <List>
        {pointSummary.expirePointBalances.length > 0 && (
          <ListItem key={0}>
            {t("EXPIRES_ON").replace("%", formatExpiringDate(new Date(pointSummary.expirePointBalances[0].expiredAt)))}
            【{t("IN_TOTAL").replace("%", pointSummary.expirePointBalances[0].pointBalance.toLocaleString())}】
          </ListItem>
        )}
        {totalBalanceExpiringInTwoWeeks > 0 && (
          <ListItem key={1}>
            {t("WITHIN_WEEK").replace("%", "2")}【
            {t("IN_TOTAL").replace("%", totalBalanceExpiringInTwoWeeks.toLocaleString())}】
          </ListItem>
        )}
        {pointSummary.expirePointAmounts.expirePointAmount30Days > 0 && (
          <ListItem key={2}>
            {t("WITHIN_DAY").replace("%", "30")}【
            {t("IN_TOTAL").replace("%", pointSummary.expirePointAmounts.expirePointAmount30Days.toLocaleString())}】
          </ListItem>
        )}
      </List>
    </AlertWarning>
  );
};
