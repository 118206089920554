import { useEffect, useState } from "react";

import { ButtonProps, styled, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Button } from "@/components";
import { COLORS } from "@/utils/colors";

const disabledMilliseconds = 5000;

export type Props = Omit<ButtonProps, "variant"> & {
  beforeIcon?: React.ReactNode;
  afterIcon?: React.ReactNode;
  disableOnClick?: boolean;
};

export const ButtonOutlined: React.FC<Props> = ({
  children,
  beforeIcon,
  afterIcon,
  disableOnClick = true,
  ...props
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (disableOnClick && disabled) {
      // disabledMilliseconds秒後にdisabledをfalseにする
      const timer = setTimeout(() => {
        setDisabled(false);
      }, disabledMilliseconds);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [disableOnClick, disabled]);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disableOnClick) {
      setDisabled(true);
    }
    props.onClick?.(event);
  };

  return (
    <StyledButton isBreak={!matches} variant="outlined" disabled={disabled} {...props} onClick={handleClick}>
      {beforeIcon}
      <span>{children}</span>
      {afterIcon}
    </StyledButton>
  );
};

const StyledButton = styled(Button, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "isBreak",
})<{ isBreak: boolean }>(({ theme, fullWidth, isBreak }) => ({
  display: "inline-flex",
  alignItems: "center",
  padding: `6px ${theme.spacing(4)}`,
  borderRadius: "1000px",
  fontWeight: 400,
  lineHeight: 1.2,
  letterSpacing: "0.02em",
  borderColor: "inherit",
  backgroundColor: COLORS.BG_01,

  "&:hover": {
    backgroundColor: COLORS.BG_01,
  },

  "&.Mui-disabled": {
    color: theme.palette.action.disabledBackground,
  },

  "&.MuiButton-sizeSmall": {
    fontSize: "1rem",
    padding: theme.spacing(1, 2),
  },

  "&.MuiButton-sizeLarge": {
    fontSize: "1.25rem",
    fontWeight: 700,
    width: fullWidth || isBreak ? "100%" : "280px",
    maxWidth: "100%",
    minHeight: "56px",
  },

  "& > span > .MuiTypography-caption": {
    letterSpacing: "0.02em",
    display: "block",
    marginTop: theme.spacing(1),
  },
}));
