import { Grid, GridProps, styled } from "@mui/material";

export type Props = GridProps & {
  children: React.ReactElement | React.ReactElement[];
};

export const LayoutButton: React.FC<Props> = ({ justifyContent = "center", children, ...props }) => {
  const buttons = Array.isArray(children) ? children : [children];
  return (
    <StyledGrid
      container
      spacing={4}
      justifyContent={justifyContent}
      sx={{
        display: { xs: "block", sm: "flex" },
        flexDirection: { sm: "row-reverse" },
      }}
      className="layoutButton"
      {...props}
    >
      {buttons.map((button, index) => (
        <Grid item key={index}>
          {button}
        </Grid>
      ))}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)(({ justifyContent }) => ({
  marginTop: 0,
  textAlign: justifyContent === "flex-end" ? "right" : justifyContent === "center" ? "center" : "left",

  ".MuiButtonBase-root": {
    height: "100%",
  },
}));
