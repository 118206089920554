import React from "react";

import { styled } from "@mui/material";

import { InputAdornment, TextField, TextFieldProps } from "@/components";

export type Props = TextFieldProps & {
  unit: string;
  align?: "left" | "center" | "right";
};

export const TextFieldWithUnit: React.FC<Props> = ({ unit, InputProps, ...props }) => {
  return (
    <StyledTextField
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
        ...InputProps,
      }}
      {...props}
    />
  );
};

const StyledTextField = styled(TextField, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "align",
})<Pick<Props, "align">>(({ align = "right" }) => ({
  ".MuiInput-input": {
    textAlign: align,
  },
}));
