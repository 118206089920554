import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";

import {
  ButtonTextLink,
  Card,
  Container,
  InfoMessageExpandTrigger,
  Link,
  ListBullet,
  ListBulletItem,
  Typography,
} from "@/components";
import { FOOTER_URLS, SUPPORT_URLS } from "@/constants";
import getPortalType from "@/utils/getPortalType";

import { ContactAccountForm, ContactFormCrew, ContactFormDriver } from "./components/Forms";

type Props = {
  isLoggedin?: boolean;
};

export const Contact: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const portalType = getPortalType();
  const footerUrls: { [key: string]: string } = FOOTER_URLS[portalType];

  return (
    <Container>
      <Helmet title={t("CONTACT")} />
      {props.isLoggedin && (
        <Typography variant="h1" gutterBottom>
          {t("CONTACT")}
        </Typography>
      )}
      <Card>
        <Typography variant="h2" gutterBottom>
          {t("CONTACT")}
        </Typography>

        <Typography sx={{ my: 4 }}>{t("CONFIRM_BEFORE_CONTACT")}</Typography>

        <ListBullet>
          <ListBulletItem>
            <Trans
              i18nKey="CONFIRM_BEFORE_CONTACT_1"
              components={{
                Link: <Link href={footerUrls["FAQ"]} target="_blank"></Link>,
              }}
            />
          </ListBulletItem>
          <ListBulletItem>{t("CONFIRM_BEFORE_CONTACT_2")}</ListBulletItem>
          <ListBulletItem>
            {((p): string => (p ? t(`CONFIRM_BEFORE_${p}_CONTACT`) : ""))(portalType.toUpperCase())}
          </ListBulletItem>
        </ListBullet>

        {/* 乗務員のみ */}
        {portalType === "crew" && (
          <>
            <InfoMessageExpandTrigger label="CONTACT_FORM_NOTICE">
              <Typography sx={{ whiteSpace: "pre-line" }}>{t("CONTACT_COMMON_FORM_NOTICE_MESSAGE_0")}</Typography>
              <ol>
                <li>{t("CONTACT_COMMON_FORM_NOTICE_MESSAGE_1")}</li>
                <li>{t("CONTACT_COMMON_FORM_NOTICE_MESSAGE_2")}</li>
              </ol>
              <Typography sx={{ whiteSpace: "pre-line" }}>
                {t(props.isLoggedin ? "CONTACT_ACCOUNT_FORM_NOTICE_MESSAGE" : "CONTACT_FORM_NOTICE_MESSAGE")}
              </Typography>
            </InfoMessageExpandTrigger>
            <InfoMessageExpandTrigger label="CHECK_EMAIL_NOTICE">
              <Typography sx={{ whiteSpace: "pre-line" }}>{t("INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_0")}</Typography>
              <ol style={{ paddingLeft: "16px" }}>
                <li>{t("INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_1")}</li>
                <li>{t("INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_2")}</li>
              </ol>
              <Typography sx={{ whiteSpace: "pre-line", mb: "16px" }}>
                {t("INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_3")}
              </Typography>
              <ButtonTextLink
                to={SUPPORT_URLS.crew.ABOUT_NON_ARRIVAL_OF_CREW_PORTAL_CONFIRMATION_EMAIL}
                target="_blank"
                sx={{ textAlign: "left", textDecoration: "underline" }}
                isTargetBlankFixed
              >
                {t("ABOUT_NON_ARRIVAL_OF_CREW_PORTAL_CONFIRMATION_EMAIL")}
              </ButtonTextLink>
            </InfoMessageExpandTrigger>
          </>
        )}

        {props.isLoggedin ? (
          <ContactAccountForm />
        ) : portalType === "crew" ? (
          <ContactFormCrew />
        ) : (
          <ContactFormDriver />
        )}

        <Typography color="text.secondary" sx={{ mt: 6, fontSize: 16, whiteSpace: "pre-wrap" }}>
          {t("CONTACT_SUPPORT_HOURS_NOTICE")}
        </Typography>
      </Card>
    </Container>
  );
};
