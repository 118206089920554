import { styled } from "@mui/material";

export type Props = {
  children: React.ReactNode;
};

export const LayoutInfo: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2, 0),
}));
