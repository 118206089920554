export type PointTransferFee = {
  transferFee: number;
  pointBreakdown: {
    userTip: number;
    other: number;
  };
};

export const pointTransferFeeDefaultValues: PointTransferFee = {
  transferFee: 0,
  pointBreakdown: {
    userTip: 0,
    other: 0,
  },
};

export const pointBreakdownSum = (pointTransferFee: PointTransferFee | undefined) => {
  if (pointTransferFee) {
    return (
      pointTransferFee.pointBreakdown.userTip + pointTransferFee.pointBreakdown.other - pointTransferFee.transferFee
    );
  }
  return 0;
};
