import { ErrorResponse } from "@/types/errorResponse";
import { getErrorCodePath as gecp } from "@/utils/errorHandling";
import getPortalType from "@/utils/getPortalType";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import useErrorModal from "./useErrorModal";

export const useNavigationError = () => {
  const navigate = useNavigate();
  const { openModal } = useErrorModal();

  type props = {
    error: AxiosError<ErrorResponse>;
    endpoint: string;
    appName?: string;
    path?: string;
    state?: object;
  };

  const navigateError = ({ error, endpoint, appName, path, state }: props) => {
    const portalType = getPortalType();
    if (error.response && 400 <= error.response.status && error.response.status <= 503) {
      // 403なら画面直遷移
      if (error.response.status === 403) {
        navigate(`/${portalType}_portal/403`);
        return;
      }
      const appPath =
        path && error.response.status !== 503
          ? path +
            (endpoint.includes("/point/transfer") && error.response.status < 500 ? "/transfer/error/" : "/error/")
          : "";
      const errorCode = error.response.data.error_code ?? `${error.response.status}`;
      const isPinConfirmCodeExpired = errorCode === "E401-05";
      const noHeaders = ["account", "points", "notifications"].includes(path ?? "")
        ? // 認証・確認コード有効期限切れエラーはヘッダー表示する
          isPinConfirmCodeExpired
          ? []
          : [401]
        : // 基本的に 401 と 429 はヘッダー表示しない
          [401, 429];
      navigate(
        `/${portalType}_portal/${noHeaders.includes(error.response.status) ? "" : appPath}${error.response.status}`,
        {
          state: {
            errorCode: ["E401-01", "E401-02", "E401-03", "E401-04", "E401-06"].includes(errorCode)
              ? // 確認コード送信 ダミートークン返却後の認証失敗はE401-01のため例外
                endpoint.startsWith("/api/password_reset/auth_mail_code")
                ? gecp(errorCode, endpoint)
                : errorCode
              : gecp(errorCode, endpoint),
            appName: error.response.status < 500 ? appName : undefined,
            ...state,
          },
        }
      );
    } else if (error?.status === undefined) {
      openModal("ERROR_CODE_NETWORK_TITLE", "ERROR_CODE_NETWORK_MESSAGE");
    }
    return;
  };
  return { navigateError };
};
