export type PointHistory = {
  historyId: number;
  category: string;
  note: string;
  addPoint: number;
  description: {
    transferId?: number;
    fromUserType?: number;
    fromUserId?: number;
    fromPointId?: number;
    fromDriverCode?: string;
    toUserType?: number;
    toUserId?: number;
    toPointId?: number;
    toDriverCode?: string;
    comment?: string;
  } | null;
  enabledAt: string;
  expiredAt: string;
  updatedAt: string;
};

export type PointHistories = PointHistory[];

export const pointHistoriesDefaultValues: PointHistories = [];

export const getTransferedParams = (
  pointHistory: PointHistory,
  driverId: number
): {
  userType: number;
  driverCode: string;
  pointId: number;
  caption: string;
} | null => {
  if (!pointHistory.description) {
    return null;
  }

  const isMinus = pointHistory.addPoint < 0;

  if (pointHistory.description.fromUserId === driverId) {
    return {
      userType: Number(pointHistory.description.toUserType),
      driverCode: pointHistory.description.toDriverCode || "",
      pointId: Number(pointHistory.description.toPointId),
      caption: isMinus ? "POINT_COMBINED_APPLICATION_REVERTED_TO" : "POINT_COMBINED_APPLICATION_EXECUTED_TO",
    };
  } else {
    return {
      userType: Number(pointHistory.description.fromUserType),
      driverCode: pointHistory.description.fromDriverCode || "",
      pointId: Number(pointHistory.description.fromPointId),
      caption: isMinus ? "POINT_COMBINED_APPLICATION_REVERTED_FROM" : "POINT_COMBINED_APPLICATION_EXECUTED_FROM",
    };
  }
};
