import { AxiosError } from "axios";
import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ButtonContained,
  ButtonOutlined,
  Chip,
  ChipProps,
  LayoutButton,
  ModalBasic,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableCols,
  TableRow,
} from "@/components";
import { DateText } from "@/components/ListPointItem/ListPointItem";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { ErrorResponse } from "@/types/errorResponse";
import type { MappingRequest } from "@/types/user";
import { COLORS } from "@/utils/colors";
import { formatDisplayDate } from "@/utils/formatter";

import { AlertMessageRevokeCrew } from "./AlertMessageRevokeCrew";

type Status = MappingRequest["state"];

export interface CrewTableProps {
  crewNumber: string;
  companyName: string;
  officeName: string;
  state?: Status;
  driverId?: number;
  appliedAt?: string;
  portalType: "crew" | "driver" | "";
}

type StatusMap = {
  [key in Status]: {
    label: string;
    color: ChipProps["color"];
  };
};

export const CrewTable: React.FC<CrewTableProps> = ({
  crewNumber,
  companyName,
  officeName,
  state,
  driverId,
  appliedAt,
  portalType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { revokeMappingRequest } = useAuth();
  const { navigateError } = useNavigationError();

  const statusTypeMap: StatusMap = {
    unapplied: {
      label: t("UNAPPLIED"),
      color: "default",
    },
    reviewing: {
      label: t("REVIEWING"),
      color: "secondary",
    },
    reviewing_manually: {
      label: t("REVIEWING"),
      color: "secondary",
    },
    approved_manually: {
      label: t("APPROVED"),
      color: "primary",
    },
    approved_automatically: {
      label: t("APPROVED"),
      color: "primary",
    },
    rejected: {
      label: t("REJECTED"),
      color: "error",
    },
    rejected_hidden: {
      label: "NONE",
      color: "error",
    },
    cancelled: {
      label: t("CANCELLED"),
      color: "error",
    },
    revoked: {
      label: t("REVOKED"),
      color: "error",
    },
  };

  return (
    <>
      {state && statusTypeMap[state].label !== "NONE" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Chip
            color={statusTypeMap[state].color}
            label={statusTypeMap[state].label}
            size="small"
            component="span"
            charColor={COLORS.TEXT_02}
            bgColor={COLORS.DISABLED}
            style={{ marginRight: "8px" }}
          />
          {appliedAt && ["reviewing", "reviewing_manually"].includes(state) && (
            <DateText>{formatDisplayDate(appliedAt)}</DateText>
          )}
        </div>
      )}
      <ModalBasic
        color={COLORS.WARNING}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={t("REVOKE_CREW_NOTICE_TITLE")}
        actionButton={{
          label: t("DO_REVOKE"),
          onClick: async () => {
            setIsModalOpen(false);
            if (!driverId) return;
            await revokeMappingRequest(driverId)
              .then(() => {
                navigate("/crew_portal/account", {
                  state: {
                    snackbar: {
                      message: t("SUCCESSFULLY_REVOKED"),
                      type: "success",
                    },
                    status: "RELOAD",
                  },
                });
              })
              .catch((error: AxiosError<ErrorResponse>) => {
                if (error.response?.status === undefined || error.response.status >= 500) {
                  navigateError({
                    error: error,
                    endpoint: "/api/mapping_request/revoke",
                    appName: "APPLICATION_FOR_POINT_COMBINATION",
                    path: "account",
                  });
                } else {
                  navigate("/crew_portal/account", {
                    state: {
                      snackbar: {
                        message: t("FAILED_TO_REVOKE"),
                        type: "danger",
                      },
                      status: "RELOAD",
                    },
                  });
                }
              });
          },
        }}
        weakActionButton={{
          label: t("CANCEL"),
          onClick: () => {
            setIsModalOpen(false);
          },
        }}
      >
        <AlertMessageRevokeCrew />
      </ModalBasic>
      <Table>
        <TableCols sizes={[36]} />
        <TableBody>
          <TableRow>
            <TableCellHead
              sx={{
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              {t(`${portalType.toUpperCase()}_NUMBER`)}
            </TableCellHead>
            <TableCell
              sx={{
                fontWeight: "bold",
              }}
            >
              {crewNumber}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCellHead
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {t("BUSINESS_NAME")}
            </TableCellHead>
            <TableCell>{companyName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCellHead
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {t("SALES_OFFICE_NAME")}
            </TableCellHead>
            <TableCell>{officeName}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {state &&
        (state === "unapplied" && driverId ? (
          <LayoutButton>
            <ButtonContained
              type="submit"
              color="primary"
              onClick={async () => {
                navigate("/crew_portal/account/mapping_request/auth_sms_code", {
                  state: {
                    driverId,
                    crewNumber,
                  },
                });
              }}
              size="medium"
            >
              {t("APPLY_FOR_COMBINED_APPLICATION")}
            </ButtonContained>
          </LayoutButton>
        ) : (
          !["reviewing", "reviewing_manually"].includes(state) && (
            <LayoutButton
              justifyContent="flex-end"
              sx={{
                flexDirection: { sm: "row" },
              }}
            >
              <ButtonOutlined
                type="submit"
                color="warning"
                onClick={async () => {
                  setIsModalOpen(true);
                }}
                size="medium"
                disableOnClick={false}
              >
                {t("REVOKE")}
              </ButtonOutlined>
            </LayoutButton>
          )
        ))}
    </>
  );
};
