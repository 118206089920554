export type Props = {
  color?: string;
};

export const IconPoint: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23.3C17.9647 23.3 22.8 18.4647 22.8 12.5C22.8 6.53534 17.9647 1.70001 12 1.70001C6.03532 1.70001 1.2 6.53534 1.2 12.5C1.2 18.4647 6.03532 23.3 12 23.3ZM20.9649 15.4888C21.0639 15.1918 20.8828 14.8802 20.5803 14.7991C20.2778 14.7181 19.9688 14.8984 19.8673 15.1946C19.4573 16.3917 18.7786 17.482 17.8803 18.3803C16.982 19.2786 15.8917 19.9573 14.6946 20.3674C14.3984 20.4688 14.218 20.7779 14.2991 21.0803C14.3801 21.3828 14.6917 21.564 14.9888 21.4649C16.3779 21.0018 17.6431 20.2212 18.6822 19.1822C19.7212 18.1431 20.5018 16.878 20.9649 15.4888ZM3.41968 10.2009C3.1172 10.1199 2.93605 9.80827 3.03509 9.5112C3.49821 8.12207 4.27877 6.85692 5.31784 5.81785C6.35691 4.77878 7.62205 3.99822 9.01119 3.53511C9.30826 3.43607 9.61986 3.61722 9.70091 3.91969C9.78196 4.22217 9.60164 4.53121 9.30539 4.63268C8.10827 5.04269 7.01798 5.72143 6.1197 6.61971C5.22141 7.518 4.54267 8.60829 4.13266 9.80541C4.03119 10.1017 3.72215 10.282 3.41968 10.2009ZM12.2939 7.09923C13.0948 7.09923 13.7879 7.19093 14.3734 7.37434C14.9589 7.55774 15.4421 7.81396 15.8233 8.14301C16.2044 8.47205 16.486 8.86583 16.6683 9.32434C16.8561 9.78284 16.95 10.2845 16.95 10.8293C16.95 11.3957 16.8533 11.9162 16.66 12.3909C16.4667 12.8602 16.1767 13.2648 15.7901 13.6046C15.4035 13.9445 14.9174 14.2088 14.332 14.3976C13.752 14.5864 13.0727 14.6808 12.2939 14.6808H10.6286V17.6849C10.6286 18.3003 10.1297 18.7992 9.51431 18.7992C8.89889 18.7992 8.4 18.3003 8.4 17.6849V8.7659C8.4 7.84542 9.14619 7.09923 10.0667 7.09923H12.2939ZM12.2939 12.9816C12.7026 12.9816 13.0589 12.933 13.3626 12.836C13.6664 12.7335 13.9177 12.5905 14.1166 12.4071C14.3209 12.2183 14.4728 11.9918 14.5722 11.7274C14.6717 11.4577 14.7214 11.1584 14.7214 10.8293C14.7214 10.5165 14.6717 10.2333 14.5722 9.97973C14.4728 9.7262 14.3237 9.51043 14.1249 9.33243C13.926 9.15442 13.6747 9.01956 13.3709 8.92786C13.0671 8.83077 12.7081 8.78222 12.2939 8.78222H10.6286V12.9816H12.2939Z"
        fill={color}
      />
    </svg>
  );
};
