export type Props = {
  color?: string;
};

export const IconExchange: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0857 24.5L16.9714 19.6571L12.0857 14.8143L10.2857 16.6143L12.1286 18.4571C11.3286 18.4857 10.55 18.3571 9.79286 18.0714C9.03572 17.7857 8.35714 17.3429 7.75714 16.7429C7.18572 16.1714 6.75 15.5143 6.45 14.7714C6.15 14.0286 6 13.2857 6 12.5429C6 12.0571 6.06429 11.5714 6.19286 11.0857C6.32143 10.6 6.5 10.1286 6.72857 9.67143L4.84286 7.78571C4.35715 8.5 4 9.25714 3.77143 10.0571C3.54286 10.8571 3.42857 11.6714 3.42857 12.5C3.42857 13.5857 3.64286 14.6571 4.07143 15.7143C4.5 16.7714 5.12857 17.7143 5.95714 18.5429C6.78572 19.3714 7.71429 19.9929 8.74286 20.4071C9.77143 20.8214 10.8286 21.0429 11.9143 21.0714L10.2857 22.7L12.0857 24.5ZM19.1571 17.2143C19.6429 16.5 20 15.7429 20.2286 14.9429C20.4571 14.1429 20.5714 13.3286 20.5714 12.5C20.5714 11.4143 20.3643 10.3357 19.95 9.26429C19.5357 8.19286 18.9143 7.24286 18.0857 6.41429C17.2571 5.58571 16.3214 4.97143 15.2786 4.57143C14.2357 4.17143 13.1714 3.97143 12.0857 3.97143L13.7143 2.3L11.9143 0.5L7.02857 5.34286L11.9143 10.1857L13.7143 8.38572L11.8286 6.5C12.6 6.5 13.3857 6.65 14.1857 6.95C14.9857 7.25 15.6714 7.68572 16.2429 8.25714C16.8143 8.82857 17.25 9.48571 17.55 10.2286C17.85 10.9714 18 11.7143 18 12.4571C18 12.9429 17.9357 13.4286 17.8071 13.9143C17.6786 14.4 17.5 14.8714 17.2714 15.3286L19.1571 17.2143Z"
        fill={color}
      />
    </svg>
  );
};
