import { styled } from "@mui/material";

export type Props = {
  children: React.ReactNode;
  bold?: boolean;
  align?: "left" | "center" | "right";
};

export const ListDescriptionDetail: React.FC<Props> = (props) => {
  return <Container {...props} />;
};

const Container = styled("dd", {
  shouldForwardProp: (propName: PropertyKey) => propName !== "bold" && propName !== "align",
})<Pick<Props, "bold" | "align">>(({ bold, align }) => ({
  fontWeight: bold ? 700 : undefined,
  textAlign: align,
  width: "50%",
}));
