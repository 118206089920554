import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  ButtonTextLink,
  Container,
  InfoMessageExpandTrigger,
  LayoutVertical,
  StepCard,
  Typography,
} from "@/components";
import { SUPPORT_URLS } from "@/constants";

import { AuthMailForm } from "./components/Forms";

export const AuthMail = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Helmet title={t("REGISTER_ACCOUNT")} />
      <LayoutVertical>
        <StepCard activestep={1} steplength={5}>
          <Typography variant="h2" gutterBottom>
            {t("PLEASE_INPUT_EMAIL")}
          </Typography>
          <Typography component="h3" variant="body1">
            {t("PLEASE_INPUT_EMAIL_EXAMPLE")}
          </Typography>
          <InfoMessageExpandTrigger label="CHECK_EMAIL_NOTICE">
            <Typography sx={{ whiteSpace: "pre-line" }}>{t("INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_0")}</Typography>
            <ol style={{ paddingLeft: "16px" }}>
              <li>{t("INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_1")}</li>
              <li>{t("INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_2")}</li>
            </ol>
            <Typography sx={{ whiteSpace: "pre-line", mb: "16px" }}>
              {t("INVALID_EMAIL_ADDRESS_NOTICE_MESSAGE_3")}
            </Typography>
            <ButtonTextLink
              to={SUPPORT_URLS.crew.ABOUT_NON_ARRIVAL_OF_CREW_PORTAL_CONFIRMATION_EMAIL}
              target="_blank"
              sx={{ textAlign: "left", textDecoration: "underline" }}
              isTargetBlankFixed
            >
              {t("ABOUT_NON_ARRIVAL_OF_CREW_PORTAL_CONFIRMATION_EMAIL")}
            </ButtonTextLink>
          </InfoMessageExpandTrigger>
          <AuthMailForm />
        </StepCard>
      </LayoutVertical>
    </Container>
  );
};
