import React from "react";

export const ModalRegisteredImage: React.FC = () => {
  return (
    <svg width="96" height="79" viewBox="0 0 96 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8041 50.7382L36.056 70.6342L39.9462 69.0418L27.8781 45.625L25.8041 50.7382Z"
        fill="#D7B25C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.885 60.4027L28.7089 73.6459L32.599 72.0523L23.9602 55.2871L21.885 60.4027Z"
        fill="#D7B25C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9678 70.066L21.3648 76.6552L25.2525 75.0616L20.0418 64.9492L17.9678 70.066Z"
        fill="#D7B25C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.4847 61.4485L39.2489 42.0781L50.8429 64.5781L58.4847 61.4485Z"
        fill="#D7B25C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8476 35.8379L29.7234 41.0771L43.4022 67.6283L47.3855 65.9951L31.8476 35.8379Z"
        fill="#D7B25C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0414 64.9479L25.2521 75.0603L28.7088 73.6455L21.885 60.4023L20.0414 64.9479Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9604 55.2875L32.5981 72.0527L36.056 70.6343L25.804 50.7383L23.9604 55.2875Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8784 45.6254L39.9453 69.0422L43.402 67.6274L29.7232 41.0762L27.8784 45.6254Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8431 64.5794L39.2491 42.0794L32.1971 34.9766L31.8473 35.8382L47.3852 65.9954L50.8431 64.5794Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.4906 61.4195C57.0494 62.8679 49.9951 58.1279 42.7354 50.8307C35.4745 43.5335 30.758 36.4427 32.198 34.9943C33.6404 33.5471 40.6924 38.2895 47.9545 45.5843C55.2142 52.8803 59.9318 59.9711 58.4906 61.4195Z"
        fill="#6B592E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.0304 50.6014C77.059 49.1362 61.3754 54.0574 52.3175 59.0758C51.4399 58.507 52.0536 58.933 50.548 57.8314C60.0954 51.7714 79.8471 46.1098 88.6089 48.289C88.7486 49.0594 88.8895 49.8298 89.0304 50.6014Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.9553 14.8164C54.2908 26.4972 43.769 36.4644 40.1057 48.1428C40.649 48.7344 41.0621 49.146 41.5183 49.5672C45.129 38.5524 55.0132 27.8748 59.4956 16.9572C58.9833 16.2432 58.4699 15.5316 57.9553 14.8164Z"
        fill="#76C5EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1746 2.37695C43.6696 14.1898 34.4708 26.9506 35.9645 38.7634C36.6749 38.8738 37.3854 38.989 38.097 39.1018C36.6033 27.2914 45.8021 14.5282 44.3084 2.71895C43.5979 2.60255 42.8875 2.49215 42.1746 2.37695Z"
        fill="#BACE8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.4058 57.0125C85.0177 57.6953 84.6285 58.3757 84.2404 59.0609C82.7753 58.7273 81.2088 57.8249 80.1831 56.7221C80.5724 56.0393 80.9604 55.3601 81.3497 54.6797C82.3742 55.7813 83.9407 56.6837 85.4058 57.0125Z"
        fill="#BACE8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8827 18.8526C35.6212 17.9802 35.3621 17.109 35.0994 16.2402C33.3466 16.4274 31.3251 17.2806 29.8672 18.4506C30.1263 19.3206 30.389 20.1882 30.6493 21.0606C32.1084 19.8918 34.1311 19.0398 35.8827 18.8526Z"
        fill="#BACE8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2867 9.54276C46.6801 8.81076 46.0748 8.08356 45.4682 7.35156C43.6748 7.94676 41.8443 9.21756 40.7327 10.6396C41.3392 11.368 41.9458 12.1 42.55 12.832C43.6616 11.41 45.4909 10.1404 47.2867 9.54276Z"
        fill="#F28A9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.1451 31.5801C61.2162 31.2429 62.2884 30.9057 63.3595 30.5697C63.9648 28.6029 63.9648 26.3649 63.3607 24.7773C62.2896 25.1121 61.2174 25.4505 60.1463 25.7853C60.7493 27.3741 60.7517 29.6109 60.1451 31.5801Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1951 49.2093C57.4172 46.8945 70.6566 31.3689 78.1671 32.1369C78.5229 32.8233 78.4608 33.0909 78.8142 33.7821C68.7175 35.7537 56.5659 49.8537 46.3892 51.2937C47.0518 50.1345 47.0805 49.9317 47.1951 49.2093Z"
        fill="#F28A9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.367 22.4941C43.2249 23.8189 43.0817 25.1545 42.9396 26.4805C43.8829 27.2917 45.1712 27.3685 46.2745 26.6809C46.4166 25.3525 46.5611 24.0229 46.702 22.6969C45.5987 23.3833 44.3103 23.3053 43.367 22.4941Z"
        fill="#F28A9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.8362 11.0938C68.1521 11.8113 67.4715 12.5253 66.7897 13.2418C67.2315 14.4729 68.2739 15.5157 69.4846 15.9381C70.1676 15.2229 70.8506 14.5065 71.5324 13.7913C70.3216 13.3677 69.2792 12.3249 68.8362 11.0938Z"
        fill="#BACE8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.9491 9.174C57.7968 7.878 58.6458 6.5844 59.4959 5.2884C58.7735 3.3972 57.2619 1.9344 55.5819 1.5C54.7329 2.7972 53.884 4.0908 53.035 5.3868C54.715 5.8176 56.2279 7.2804 56.9491 9.174Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.8259 41.6592C73.1865 42.3756 73.5483 43.0872 73.9089 43.806C75.2844 42.9132 76.7591 41.2788 77.7262 39.5784C77.3644 38.8632 77.005 38.1468 76.6432 37.4316C75.6773 39.1308 74.2026 40.7652 72.8259 41.6592Z"
        fill="#BACE8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.8838 45.1016C80.9418 45.3896 80.0009 45.6728 79.0612 45.9596C78.0618 47.3192 77.4719 48.83 77.5316 49.8704C78.4725 49.5824 79.4122 49.3004 80.3531 49.0136C80.2922 47.9744 80.8832 46.4636 81.8838 45.1016Z"
        fill="#BACE8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.769 37.9622C59.5308 36.971 58.2962 35.9714 57.0604 34.9766C55.1284 35.213 53.5738 36.2174 53.0353 37.5806C54.2735 38.5742 55.5081 39.5714 56.7451 40.5662C57.2824 39.209 58.8359 38.201 60.769 37.9622Z"
        fill="#76C5EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.621 36.7503C41.009 37.5327 41.3923 38.3187 41.778 39.1023C42.929 38.5179 44.104 37.2255 44.8216 35.7531C44.4347 34.9695 44.0514 34.1847 43.6646 33.4023C42.9481 34.8711 41.7732 36.1635 40.621 36.7503Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9947 29.6075C74.9255 30.2591 74.855 30.9059 74.7858 31.5539C73.3709 31.7723 71.6395 31.5827 70.3058 31.0643C70.3762 30.4151 70.4431 29.7683 70.5147 29.1191C71.8509 29.6351 73.5822 29.8235 74.9947 29.6075Z"
        fill="#BACE8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.6119 34.4305C82.6203 35.0761 82.6287 35.7205 82.6406 36.3649C84.44 36.3973 86.7027 35.9761 88.4913 35.2813C88.483 34.6369 88.4734 33.9913 88.4615 33.3457C86.6728 34.0429 84.4113 34.4605 82.6119 34.4305Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.8952 44.4108C90.6899 44.1984 92.4845 43.9896 94.2791 43.7808C94.6361 42.4248 93.8134 40.8696 92.1478 39.75C90.3531 39.96 88.5585 40.1712 86.7627 40.3824C88.4308 41.4972 89.2534 43.0536 88.8952 44.4108Z"
        fill="#F28A9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9657 13.2891C48.7986 14.5071 48.6326 15.7263 48.4642 16.9443C49.6762 17.7771 51.3215 17.9619 52.7209 17.4207C52.8893 16.2015 53.0553 14.9835 53.2224 13.7655C51.8218 14.3067 50.1777 14.1219 48.9657 13.2891Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.8068 14.9263C78.2241 15.3619 79.6402 15.8011 81.0551 16.2391C82.3184 14.9239 82.9011 12.9559 82.5668 11.1499C81.1518 10.7107 79.7345 10.2727 78.3184 9.83594C78.6527 11.6455 78.07 13.6123 76.8068 14.9263Z"
        fill="#F28A9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.7787 21.0234C72.9607 21.1878 72.1416 21.351 71.3237 21.5154C71.3548 22.6218 71.9733 23.8986 72.9285 24.8226C73.7476 24.6558 74.5655 24.4962 75.3834 24.3294C74.4294 23.4078 73.8109 22.1286 73.7787 21.0234Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.2697 23.7949L82.6375 25.1965L83.8351 26.0005L82.6244 26.7853L82.2315 28.1797L81.1199 27.2617L79.6775 27.3205L80.1981 25.9693L79.699 24.6145L81.1378 24.6913L82.2697 23.7949Z"
        fill="#BACE8E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.7175 57.3704L71.5969 58.9688L72.5605 60.2456L71.0107 60.6248L70.0984 61.94L69.2602 60.5768L67.7343 60.1088L68.7659 58.8872L68.7348 57.2852L70.2107 57.8948L71.7175 57.3704Z"
        fill="#F28A9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.9851 47.4958L72.2818 48.937L72.7057 50.4802L71.126 50.2546L69.7934 51.1378L69.5212 49.5586L68.2734 48.559L69.6836 47.8078L70.246 46.3066L71.391 47.4226L72.9851 47.4958Z"
        fill="#76C5EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9014 26.5531L30.4023 28.0771L31.0351 29.5495L29.4387 29.5459L28.2411 30.6019L27.7527 29.0755L26.3832 28.2571L27.6751 27.3187L28.0274 25.7539L29.3133 26.7007L30.9014 26.5531Z"
        fill="#EBC670"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9682 70.0664L16.25 74.3552C16.6058 74.9756 17.0237 75.5432 17.5216 76.0436C18.0852 76.61 18.7312 77.0672 19.4369 77.444L21.364 76.6544L17.9682 70.0664Z"
        fill="#EBC670"
      />
    </svg>
  );
};
