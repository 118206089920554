import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import { Box, Chip, CssBaseline, Drawer, IconButton, List, ListItemButton, TextField, styled } from "@mui/material";

import { Header } from "@/components";
import { getLocalStorageItem, setLocalStorageItem } from "@/utils/localStorage";

import { ErrorProvider } from "./DebugUtils";

export const DebugLayout = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [apiEndpoint, setApiEndpoint] = useState("");

  const toggleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const getEnvFromApiEndpoint = () => {
    // https://portland-{ブランチ名}-main.kenos-dynamic.internal.{環境}.mo-t.com となっている場合、`dynamic-{環境}/{ブランチ名}` を返す、なっていなかった場合は空文字を返す
    const matches = apiEndpoint.match(/^https:\/\/portland-(.*)-main.kenos-dynamic.internal.(.*).mo-t.com$/);
    if (matches) {
      return `dynamic-${matches[2]}/${matches[1]}`;
    }
    return "";
  };

  useEffect(() => {
    const initialApiEndpoint = getLocalStorageItem("endpoint") || "";
    setApiEndpoint(initialApiEndpoint);
  }, []);

  const handleApiEndpointChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newApiEndpoint = event.target.value;
    setApiEndpoint(newApiEndpoint);
    setLocalStorageItem("endpoint", newApiEndpoint);
  };

  return (
    <>
      <CssBaseline />
      {getEnvFromApiEndpoint() && <Chip label={getEnvFromApiEndpoint()} color="primary" />}
      <Header>
        <HeaderIconButton color="inherit" onClick={toggleOpenDrawer}>
          <MenuIcon />
        </HeaderIconButton>
        <HeaderSpan>Debugツール</HeaderSpan>
      </Header>
      <Drawer anchor="left" open={openDrawer} onClose={toggleOpenDrawer}>
        <List>
          <ListItemButton component={Link} to="">
            DebugツールTOP
          </ListItemButton>
          <ListItemButton component={Link} to="transfer_free_fee">
            ポイント交換 手数料無料権
          </ListItemButton>
          <ListItemButton component={Link} to="reset_issue_code">
            1日のSMS/メール送信上限数リセット
          </ListItemButton>
          <ListItemButton component={Link} to="expired_token">
            トークン有効期限切れ更新
          </ListItemButton>
          <ListItemButton component={Link} to="account/encrypted">
            アカウント情報取得
          </ListItemButton>
          <ListItemButton component={Link} to="update_gmo_bank_remittance_status">
            GMO送金結果の更新
          </ListItemButton>
          <ListItemButton component={Link} to="mapping_request_state">
            合算申請の審査の操作
          </ListItemButton>
        </List>
      </Drawer>
      <Box mx={2} mt={4}>
        <TextField
          label="apiEndpoint"
          name="apiEndpoint"
          value={apiEndpoint}
          onChange={handleApiEndpointChange}
          fullWidth
        />
      </Box>
      <ErrorProvider>
        <Main>
          <Outlet />
        </Main>
      </ErrorProvider>
    </>
  );
};
const HeaderIconButton = styled(IconButton)(() => ({
  position: "fixed",
  top: 10,
  left: 10,
}));

const HeaderSpan = styled("p")(() => ({
  fontSize: 14,
  fontWeight: 700,
  marginTop: -16,
  marginBottom: 2,
}));

const Main = styled("main")(() => ({
  padding: 16,
}));
