export type Props = {
  color?: string;
};

export const IconWindow: React.FC<Props> = ({ color = "currentColor" }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0714 2.5H2.76189C2.43315 2.5 2.16666 2.7665 2.16666 3.09524V13.2143C2.16666 13.543 2.43315 13.8095 2.76189 13.8095H6.33333V17.381C6.33333 17.7097 6.59982 17.9762 6.92857 17.9762H18.2381C18.5668 17.9762 18.8333 17.7097 18.8333 17.381V7.26188C18.8333 6.93314 18.5668 6.66665 18.2381 6.66665H14.6667V3.09524C14.6667 2.7665 14.4002 2.5 14.0714 2.5ZM12.8809 6.66665V4.2857H3.95236V12.0238H6.33333V7.26188C6.33333 6.93314 6.59982 6.66665 6.92857 6.66665H12.8809ZM8.11903 8.45232V16.1904H17.0476V8.45232H8.11903Z"
        fill={color}
      />
    </svg>
  );
};
