import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  ButtonContained,
  FormBox,
  ModalBasic,
  TextField,
  TextFieldPhoneNumber,
  Typography,
} from "@/components";
import useAuth from "@/hooks/useAuth";
import { useNavigationError } from "@/hooks/useNavigationError";
import { COLORS } from "@/utils/colors";
import getPortalType from "@/utils/getPortalType";
import {
  codeLength,
  confirmCodeValidator,
  phoneNumberValidatorDelimited,
  pinCodeValidator,
  validationSchema,
} from "@/utils/validators";
import axios from "axios";
export const PhoneNumberChangeAuthMailForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyAccountEmail } = useAuth();
  const { t } = useTranslation();
  const portalType = getPortalType();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = async () => {
    try {
      await verifyAccountEmail("phone_number");
      setIsModalOpen(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/phone_number_change/auth_mail",
          appName: "CHANGE_PHONE_NUMBER",
          path: "account",
        });
      }
    }
  };

  const handleClose = async () => {
    navigate(((p): string => (p ? `/${p}_portal/account/phone_number_change/auth_mail_code` : "/"))(portalType));
  };

  return (
    <>
      <ModalBasic
        open={isModalOpen}
        onClose={() => {
          // biome-ignore suppressions/unused: need not to use
        }}
        title={t("EMAIL_SENT_FOR_AUTHENTICATION")}
        actionButton={{
          label: t("NEXT"),
          onClick: handleClose,
        }}
      >
        <span style={{ color: COLORS.WARNING, fontWeight: "bold" }}>{t("PLEASE_CHECK_YOUR_EMAIL")}</span>
      </ModalBasic>
      <Typography variant="h2" align="center" />
      <ButtonContained type="submit" color="primary" onClick={onSubmit} style={{ marginTop: "24px" }} size="large">
        {t("SEND_PIN_CODE")}
      </ButtonContained>
    </>
  );
};

export const PhoneNumberChangeAuthMailCodeForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyAccountPinCode } = useAuth();
  const { t } = useTranslation();
  const portalType = getPortalType();
  const [submitError] = useState("");
  type FormData = {
    pinCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        pinCode: pinCodeValidator,
      })
    ),
    defaultValues: {
      pinCode: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyAccountPinCode("phone_number", data.pinCode);
      navigate(((p): string => (p ? `/${p}_portal/account/phone_number_change/auth_sms` : "/"))(portalType));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/phone_number_change/auth_mail_code",
          appName: "CHANGE_PHONE_NUMBER",
          path: "account",
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="pinCodeForm">
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="pinCode"
          control={control}
          defaultValue=""
          rules={{ required: t("PIN_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
                if (e.target.value.length === codeLength) {
                  handleSubmit(onSubmit)();
                }
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("PIN_CODE")}
              fullWidth
              error={Boolean(errors.pinCode)}
              helperText={errors.pinCode?.message}
              inputProps={{ maxLength: 4 }}
              style={{ marginTop: "24px" }}
            />
          )}
        />
      </form>
    </>
  );
};

export const PhoneNumberChangeAuthSmsForm = () => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { registerAccountPhoneNumber } = useAuth();
  const { t } = useTranslation();
  const portalType = getPortalType();
  const [submitError] = useState("");
  type FormData = {
    phoneNumber: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        phoneNumber: phoneNumberValidatorDelimited,
      })
    ),
    defaultValues: {
      phoneNumber: "",
    },
    mode: "onBlur",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await registerAccountPhoneNumber(data.phoneNumber.replaceAll(" ", "")); // NOTE: TextFieldPhoneNumber使用するためtrim()ではなくreplaceAll()でスペース除去
      navigate(((p): string => (p ? `/${p}_portal/account/phone_number_change/auth_sms_code` : "/"))(portalType), {
        state: { newPhoneNumber: data.phoneNumber },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/phone_number_change/auth_sms",
          appName: "CHANGE_PHONE_NUMBER",
          path: "account",
          state: { newPhoneNumber: data.phoneNumber },
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="phoneNumberForm">
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          rules={{ required: t("PHONE_NUMBER_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormBox helperText={t("PHONE_NUMBER_NOTE")}>
              <TextFieldPhoneNumber
                onChange={(e) => {
                  onChange(e);
                }}
                onBlur={onBlur}
                value={value}
                type="tel"
                label={t("PHONE_NUMBER")}
                fullWidth
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
                style={{ marginTop: "24px" }}
              />
            </FormBox>
          )}
        />

        <Box textAlign="center">
          <ButtonContained
            type="submit"
            color="primary"
            disabled={!isDirty || isSubmitting}
            size="large"
            style={{ marginTop: "24px" }}
          >
            {t("SEND")}
          </ButtonContained>
        </Box>
      </form>
    </>
  );
};

export const PhoneNumberChangeAuthSmsCodeForm = ({
  newPhoneNumber,
}: {
  newPhoneNumber: string;
}) => {
  const navigate = useNavigate();
  const { navigateError } = useNavigationError();
  const { verifyAccountConfirmCode } = useAuth();
  const { t } = useTranslation();
  const [submitError] = useState("");
  type FormData = {
    confirmCode: string;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver<FormData>(
      validationSchema.shape({
        confirmCode: confirmCodeValidator,
      })
    ),
    defaultValues: {
      confirmCode: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    try {
      await verifyAccountConfirmCode("phone_number", data.confirmCode);
      navigate(((p): string => (p ? `/${p}_portal/account` : "/"))(getPortalType()), {
        state: { status: "PHONE_NUMBER_SUCCESS" },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        navigateError({
          error: error,
          endpoint: "/api/account/phone_number_change/auth_sms_code",
          appName: "CHANGE_PHONE_NUMBER",
          path: "account",
          state: { newPhoneNumber },
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} name="confirmCodeForm">
        {submitError && (
          <Alert mt={2} mb={1} severity="warning">
            {submitError}
          </Alert>
        )}
        <Controller
          name="confirmCode"
          control={control}
          defaultValue=""
          rules={{ required: t("CONFIRM_CODE_REQUIRED") }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              onChange={(e) => {
                onChange(e);
                if (e.target.value.length === codeLength) {
                  handleSubmit(onSubmit)();
                }
              }}
              onBlur={onBlur}
              value={value}
              type="tel"
              label={t("CONFIRM_CODE")}
              fullWidth
              error={Boolean(errors.confirmCode)}
              helperText={errors.confirmCode?.message}
              inputProps={{ maxLength: 4 }}
              style={{ marginTop: "24px" }}
            />
          )}
        />
      </form>
    </>
  );
};
