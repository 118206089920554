import React from "react";
import { useState } from "react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/material";

// ref: https://github.com/mui/material-ui/issues/35922#issuecomment-1482941075
import { AlertInfo } from "@/components/AlertInfo/AlertInfo";
import { InfoMessage } from "@/components/InfoMessage/InfoMessage";
import { Typography } from "@/components/Typography/Typography";

export type Props = {
  label: string;
  children?: ReactNode;
};

export const InfoMessageExpandTrigger: React.FC<Props> = ({ label, children }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <StyledButton sx={{ mt: "24px" }} onClick={() => setExpanded(!expanded)}>
        <InfoMessage variant="primary" sx={{ alignItems: "center", marginBottom: 0 }}>
          <Typography sx={{ flex: 1, wordBreak: "break-word" }} align="left">
            {t(label)}
          </Typography>
        </InfoMessage>
        <StyledExpandIcon>
          {expanded ? (
            <ExpandLess sx={{ width: "20px", height: "20px", display: "flex" }} />
          ) : (
            <ExpandMore sx={{ width: "20px", height: "20px", display: "flex" }} />
          )}
        </StyledExpandIcon>
      </StyledButton>
      {expanded && <AlertInfo style={{ marginTop: "24px" }}>{children}</AlertInfo>}
    </>
  );
};

const StyledButton = styled("button")(({ theme }) => ({
  font: "inherit",
  fontSize: "1rem",
  lineHeight: 1.2,
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(0, 0.05),
  background: "none",
  border: "none",
  padding: theme.spacing(1, 2),
  cursor: "pointer",
}));

const StyledExpandIcon = styled("span")(() => ({
  display: "inline-block",
}));
