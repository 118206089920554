import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  ButtonOutlinedLink,
  ChipNotificationStatusProps,
  Container,
  LayoutVertical,
  Loader,
  Typography,
} from "@/components";
import { CardNotification } from "@/components/CardNotification";
import "@/components/Loader/Loader";
import { FetchContext as context } from "@/contexts/FetchContext";
import { Notification as NotificationType } from "@/types/notification";
import { formatNotificationDate } from "@/utils/formatter";
import getPortalType from "@/utils/getPortalType";

export const Notification = () => {
  const { state } = useContext(context);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState<NotificationType>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const portalType = getPortalType();

  // biome-ignore lint/correctness/useExhaustiveDependencies: need to watch location
  useEffect(() => {
    if (!location.state) {
      navigate(((p): string => (p ? `/${p}_portal/notifications` : "/"))(portalType));
    } else {
      setNotification(location.state.notification);
      setIsLoading(false);
    }
  }, [location.state, navigate]);

  return (
    <Container>
      {state.loading || isLoading || !notification ? (
        <Loader />
      ) : (
        <>
          <Helmet title={t("NOTIFICATION")} />

          <LayoutVertical>
            <Typography variant="h1">{t("NOTIFICATION")}</Typography>
          </LayoutVertical>

          <ButtonOutlinedLink
            to={`/${portalType}_portal/notifications`}
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {t("BACK_TO_LIST")}
          </ButtonOutlinedLink>

          <LayoutVertical>
            <CardNotification
              title={notification.title}
              status={notification.flagType as ChipNotificationStatusProps["status"]}
              date={formatNotificationDate(notification.postedAt)}
            >
              {parse(DOMPurify.sanitize(notification.content))}
            </CardNotification>
          </LayoutVertical>

          <ButtonOutlinedLink
            to={`/${portalType}_portal/notifications`}
            style={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {t("BACK_TO_LIST")}
          </ButtonOutlinedLink>
        </>
      )}
    </Container>
  );
};
