import { useState } from "react";

export const useModal = () => {
  const [openModal, setOpenModal] = useState("");
  const handleOpen = (id: string) => setOpenModal(id);
  const handleClose = () => setOpenModal("");
  const open = (id: string) => id === openModal;

  return {
    handleOpen,
    handleClose,
    open,
  };
};
